import axios from 'axios';
import {
  Company,
  companyFromSrc,
  CreateCompany,
  createCompanyToSrc,
  UpdateCompany,
  updateCompanyToSrc,
} from '../model/Company';
import { CompanyDocumentType } from '../model/CompanyDocumentType';
import { CompanyDocument } from '../model/CompanyDocument';

export const getUserCompany = async (): Promise<Company | undefined> => {
  const response = await axios.get('company/get');

  return response.data.id ? companyFromSrc(response.data) : undefined;
};

export const createCompany = async (createCompany: CreateCompany) =>
  axios.post('company/create', createCompanyToSrc(createCompany));

export const updateCompany = async (updateCompany: UpdateCompany) =>
  axios.patch(`company/${updateCompany.id}/update`, updateCompanyToSrc(updateCompany));

export const uploadCompanyDocument = async (
  companyId: number,
  documentType: CompanyDocumentType,
  file: Blob,
  onUploadProgress: (event: { loaded: number; total: number }) => void,
) => {
  let formData = new FormData();

  formData.append('document_file', file);
  formData.append('document_type', documentType);

  return axios.post(`/company/${companyId}/upload-document`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
  });
};

export const downloadCompanyDocument = async (document: CompanyDocument): Promise<any> => {
  const createdAtDateString = new Date(document.createdAt).toISOString().substr(0, 10);

  // Download file
  const file: { data: Blob } = await axios.get(`document/download/${document.id}`, {
    responseType: 'blob',
  });

  // Save file
  saveAs(file.data, document.fileName);
};
