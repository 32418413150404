// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Internal libs

// Internal components
import PageDefaultLayout from '@common/components/layouts/PageDefaultLayout';
import ContactDataCard from '@features/user/containers/ContactDataCard';
import UserDataCard from '@features/user/containers/UserDataCard';
import SettlementsDataCard from '@features/user/containers/settlements/SettlementsDataCard';
import DeleteAccountCard from '@features/user/containers/DeleteAccountCard';
import UserDataEditModal from '@features/user/containers/UserDataEditModal';
import EmailVerifyModal from '@features/user/containers/EmailVerifyModal';
import PhoneEditModal from '@features/user/containers/PhoneEditModal';
import PhoneVerifyModal from '@features/user/containers/PhoneVerifyModal';
import PhoneChangeModal from '@features/user/containers/PhoneChangeModal';
import AccountDeleteConfirmModal from '@features/user/containers/AccountDeleteConfirmModal';
// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    gridContainer: {},
  }),
);

// Props type
type DeleteProfilePageProps = {};

// Component
const DeleteProfilePage = ({}: DeleteProfilePageProps) => {
  const { t } = useTranslation('user');
  const styles = useStyles();
  const { path } = useRouteMatch();

  return (
    <PageDefaultLayout title={t('title', '')}>
      <Grid container spacing={3} className={styles.gridContainer}>
        <Grid item xs={12} md={6} lg={4}>
          <DeleteAccountCard />
        </Grid>
      </Grid>
      <Switch>
        <Route path={`${path}/confirm`} component={AccountDeleteConfirmModal} />
      </Switch>
    </PageDefaultLayout>
  );
};

export default DeleteProfilePage;
