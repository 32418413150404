// Update User Data
import { RootThunk } from '@common/model';
import * as api from '@features/admin/api/contracts';
import { actions as driverActions } from '@features/admin';
import { actions as simpleActions } from '../slice';
import {
  ContractNameType,
  DriverContractComparison,
} from '@features/admin/drivers/model/DriverContracts';
import { RideDetails } from '@features/rides/model';

export const getContractComparison = (contractId: number): RootThunk => async (
  dispatch,
): Promise<DriverContractComparison> => {
  dispatch(simpleActions.getContractComparisonInit(contractId));
  try {
    const contractComparison: DriverContractComparison = await api.getContractComparison(
      contractId,
    );
    dispatch(simpleActions.getContractComparisonSuccess(contractComparison));

    return contractComparison;
  } catch (e) {
    dispatch(simpleActions.getContractComparisonFailure(e.response?.data || { error: 'unknown' }));
    throw e.response?.data || { error: 'unknown' };
  }
};

export const acceptContract = (contractId: string, reason?: string): RootThunk => async (
  dispatch,
) => {
  dispatch(simpleActions.updateDriverContractInit());
  try {
    await api.acceptContract(contractId /*, file*/);
    dispatch(driverActions.getDriversList());
    dispatch(simpleActions.updateDriverContractSuccess());
  } catch (e) {
    dispatch(simpleActions.updateDriverContractFailure(e.response?.data || { errors: 'unknown' }));
    throw e.response?.data || { errors: 'unknown' };
  }
};

export const rejectContract = (contractId: string, reason: string): RootThunk => async (
  dispatch,
) => {
  dispatch(simpleActions.updateDriverContractInit());
  try {
    await api.rejectContract(contractId, reason);
    dispatch(driverActions.getDriversList());
    dispatch(simpleActions.updateDriverContractSuccess());
  } catch (e) {
    dispatch(simpleActions.updateDriverContractFailure(e.response?.data || { errors: 'unknown' }));
    throw e.response?.data || { errors: 'unknown' };
  }
};

export const terminateContract = (contractId: string, reason: string): RootThunk => async (
  dispatch,
) => {
  dispatch(simpleActions.updateDriverContractInit());
  try {
    await api.terminateContract(contractId, reason);
    dispatch(driverActions.getDriversList());
    dispatch(simpleActions.updateDriverContractSuccess());
  } catch (e) {
    dispatch(simpleActions.updateDriverContractFailure(e.response?.data || { errors: 'unknown' }));
    throw e.response?.data || { errors: 'unknown' };
  }
};

export const downloadDriverContract = (
  link: string,
  fileExtension: string | undefined,
  contractType: ContractNameType,
): RootThunk => async (dispatch) => {
  dispatch(simpleActions.downloadDriverContractInit());

  try {
    await api.downloadContract(link, fileExtension, contractType);
    dispatch(simpleActions.downloadDriverContractSuccess());
  } catch (e) {
    dispatch(
      simpleActions.downloadDriverContractFailure(e.response?.data || { errors: 'unknown' }),
    );
    throw e.response?.data || { errors: 'unknown' };
  }
};
