import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { Vehicle } from '@features/vehicles/model/Vehicle';

const useVehicle = (id: string) => {
  const loadingVehicles = useSelector<RootState, boolean | undefined>(
    (state) => state.vehicles.vehiclesListState?.inProgress,
  );

  const vehicle = useSelector<RootState, Vehicle | undefined>((state) =>
    state.vehicles.vehiclesList?.find((vehicle) => vehicle.id === Number(id)),
  );

  return { loadingVehicles, vehicle };
};

export default useVehicle;
