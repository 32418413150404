import axios from 'axios';
import { Partner, PartnerSrc, partnerFromSrc } from '../model/Partner';
import { PartnerRequest, partnerRequestFromSrc } from '../model/PartnerRequest';

export const findPartner = (tin: string): Promise<Partner> => {
  return axios.get('partner/find', { params: { tax_identification_number: tin } }).then(
    (response): Partner => {
      const partnerSrc: PartnerSrc = response.data;

      return partnerFromSrc(partnerSrc);
    },
  );
};

export const joinPartner = (partnerCompanyId: number): Promise<any> => {
  return axios.post('partner/join', { partner_id: partnerCompanyId });
};

export const getPartner = async (partnerId: string): Promise<any> => {
  const response = await axios.get(`partner/${partnerId}`);

  // TODO: Update condition when API change (return null or 404 instead of {})
  return response.data.id ? partnerFromSrc(response.data) : undefined;
};

export const getPartnerRequest = async (): Promise<any> => {
  let partnerRequest: PartnerRequest;
  try {
    const response = await axios.get('partner/pending-request');
    partnerRequest = partnerRequestFromSrc(response.data);
  } catch (e) {
    return undefined;
  }

  return partnerRequest;
};

export const cancelPartnerRequest = async (partnerRequestId: number): Promise<any> => {
  return axios.delete(`/partner/cancel-request/${partnerRequestId}`);
};
