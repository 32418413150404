import { actions as simpleActions } from '../slice';
import { AuthThunk } from '@features/auth/store/model';
import { register as registerApi, RegisterData } from '../../api';

/****** Place for complex actions *****/

// Register Action
export const register = (payload: RegisterData): AuthThunk => async (dispatch) => {
  dispatch(simpleActions.registerInit(payload));

  try {
    const response = await registerApi(payload);
    dispatch(simpleActions.registerSuccess(response.data?.token || ''));
  } catch (e) {
    dispatch(simpleActions.registerFailure(e.response?.data));
  }
};
