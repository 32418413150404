// Get current User
import { RootThunk } from '@common/model';
import { actions as simpleActions } from '../slice';
import * as api from '../../api';

export const getRegions = (): RootThunk => async (dispatch) => {
  dispatch(simpleActions.getRegionsInit());

  try {
    const regions = await api.getRegions();
    dispatch(simpleActions.getRegionsSuccess(regions));
  } catch (e) {
    dispatch(simpleActions.getRegionsFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};
