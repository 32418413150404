// Core libraries
import React from 'react';

// External libs and components
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';

// Internal libs

// Internal components

const useStyles = makeStyles((theme) => ({}));

type ProgressIconButtonProps = IconButtonProps & { loading?: boolean; className?: string };

const ProgressIconButton = ({
  loading,
  className,
  children,
  ...other
}: ProgressIconButtonProps) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <IconButton {...other} className={className}>
      {loading ? (
        <CircularProgress size={theme.spacing(3)} data-testid="pibp-loading-indicator" />
      ) : (
        children
      )}
    </IconButton>
  );
};

export default ProgressIconButton;
