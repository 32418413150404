// Core libraries
import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { map, filter, reject, isNil } from 'lodash';
// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router';
// Internal libs
import { InProgressState, PaginatedData } from '@common/model';
import { actions } from '../../store';
import { Ride } from '@features/rides/model';

// Internal components

import Grid from '@material-ui/core/Grid';
import { XpressDeliveryCompany } from '@common/model/XpressDeliveryCompany';
import ProgressButton from '@common/components/ProgressButton';
import { FormProvider, useForm } from 'react-hook-form';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormCheckboxField from '@common/components/FormCheckboxField';
import FormErrors from '@common/components/FormErrors';
// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
);

// Props type
type DriverAllowedCompaniesProps = {
  allowedCompanies?: XpressDeliveryCompany[];
  afterSuccessSubmit?: () => void;
};

// Component
const DriverAllowedCompanies = (props: DriverAllowedCompaniesProps) => {
  const { t } = useTranslation(['rides', 'admin']);
  const styles = useStyles();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const { driverId }: { driverId: string } = useParams();
  const [submitState, setSubmitState] = useState<InProgressState>({ inProgress: false });
  const form = useForm();
  const { formErrors, getFieldErrors } = useResponseErrors(
    submitState?.error,
    'driverProfile',
    'admin',
  );

  const onSuccess = () =>
    props.afterSuccessSubmit ? props.afterSuccessSubmit() : history.goBack();

  const onSubmit = async (values: any) => {
    setSubmitState({ inProgress: true });
    try {
      const mapped: Array<string | null> = map(values.allowedCompanies, (value, index, key) => {
        return value ? index.toString() : null;
      });
      // @ts-ignore
      const filtered: string[] = reject(mapped, isNil);
      await dispatch(actions.setDriverAllowedCompanies(+driverId, filtered));
      onSuccess();
      setSubmitState({ inProgress: false, success: true });
    } catch (e) {
      setSubmitState({ inProgress: false, success: false, error: e });
    }
  };
  const [
    getXpressDeliveryCompaniesState,
    setGetXpressDeliveryCompaniesState,
  ] = useState<InProgressState>({
    inProgress: false,
  });
  const [xpressDeliveryCompanies, setXpressDeliveryCompanies] = useState<XpressDeliveryCompany[]>(
    [],
  );

  const getXpressDeliveryCompanies = useCallback(async () => {
    setGetXpressDeliveryCompaniesState({ inProgress: true });
    try {
      const xpressDeliveryCompanies: XpressDeliveryCompany[] = ((await dispatch(
        actions.getXpressDeliveryCompanies(),
      )) as unknown) as XpressDeliveryCompany[];
      setXpressDeliveryCompanies(xpressDeliveryCompanies);
      setGetXpressDeliveryCompaniesState({ inProgress: false, success: true });
    } catch (e) {
      setGetXpressDeliveryCompaniesState({ inProgress: false, success: false, error: e });
    }
  }, [dispatch, driverId]);

  // Init
  useEffect(() => {
    getXpressDeliveryCompanies();
  }, [getXpressDeliveryCompanies]);
  const hasCompany = (companyId: number) => {
    const ids: number[] = map(props.allowedCompanies, 'id');
    return ids.includes(companyId);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} lg={3}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            {xpressDeliveryCompanies.map((company) => (
              <FormCheckboxField
                name={`allowedCompanies.${company.id.toString()}`}
                label={company.name}
                defaultValue={hasCompany(company.id)}
                fullWidth={true}
              />
            ))}
            <FormErrors errorMessages={formErrors} />
            <ProgressButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitState.inProgress || getXpressDeliveryCompaniesState.inProgress}
              loading={submitState.inProgress}
            >
              {t('admin:allowedCompaniesForm.form.submitButton')}
            </ProgressButton>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default DriverAllowedCompanies;
