// Core libraries
import React, { useEffect, useState } from 'react';

// External libs and components
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Internal libs
import { actions } from '../store';
import { InProgressState } from '@common/model';
import { RideDetails } from '../model/RideDetails';
import { isCorrectionTypeMap } from '@features/admin/drivers/model/CorrectionType';

// Internal components
import RideDetailsView from './RideDetailsView';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: '30vh',
    },
  }),
);

// Props type
type RideModalProps = {
  onClose: () => void;
  isCorrectionButtonsVisible?: boolean;
  isCommentsTabVisible?: boolean;
  getPartnerRides?: () => void;
};

// Component
const RideModal = ({
  onClose,
  isCorrectionButtonsVisible = false,
  isCommentsTabVisible = false,
  getPartnerRides,
}: RideModalProps) => {
  const { t } = useTranslation('rides');
  const styles = useStyles();
  const dispatch = useDispatch();
  const { rideId } = useParams() as { rideId: string };

  const [getDetailsState, setGetDetailsState] = useState<InProgressState>({ inProgress: false });
  const [rideDetails, setRideDetails] = useState<RideDetails | undefined>(undefined);
  const getRideDetails = async () => {
    setGetDetailsState({ inProgress: true });

    try {
      const rideDetails: RideDetails = ((await dispatch(
        actions.getRideDetails(parseInt(rideId)),
      )) as unknown) as RideDetails;
      setRideDetails(rideDetails);
      setGetDetailsState({ inProgress: false, success: true });
    } catch (e) {
      setRideDetails(undefined);
      setGetDetailsState({ inProgress: false, success: false, error: e });
    }
  };

  useEffect(() => {
    getRideDetails();
  }, [rideId]);

  return (
    <Dialog open={true} onClose={onClose} className={styles.root} fullWidth maxWidth="md">
      <DialogTitle>
        {rideDetails && isCorrectionTypeMap[rideDetails?.type]
          ? t(`model.ride.type.map.${rideDetails.type}`)
          : t('rideModal.title')}
      </DialogTitle>
      <Divider />
      {getDetailsState.inProgress && <LinearProgress data-testid="rdv-loading" />}
      <DialogContent>
        {rideDetails && (
          <RideDetailsView
            rideDetails={rideDetails}
            isCorrectionButtonsVisible={isCorrectionButtonsVisible}
            isCommentsTabVisible={isCommentsTabVisible}
            getPartnerRides={getPartnerRides}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RideModal;
