import axios from 'axios';
import { User, userFromSrc, UserUpdate, userUpdateToSrc } from '../model/User';
import { AccountType, AccountTypeEnum } from '../model/Settlements';
import { getUserCompany } from './company';
import { getPartner, getPartnerRequest } from './partner';
import { IS_AUTHENTICATED_ROLE } from '@features/user/model/Role';

export const getCurrentUser = async (): Promise<User> => {
  const response = await axios.get('/user/get');
  const user: User = userFromSrc(response.data);

  // Get company data
  if (user.accountType === AccountTypeEnum.company) {
    user.company = await getUserCompany();
  }

  // Get partner
  if (user.partnerId) {
    user.partner = await getPartner(user.partnerId);
  }

  // Get partner request
  user.partnerRequest = await getPartnerRequest();

  // Add IS_AUTHENTICATED role
  user.roles = user.roles || [];
  if (user.roles.indexOf(IS_AUTHENTICATED_ROLE) === -1) {
    user.roles.push(IS_AUTHENTICATED_ROLE);
  }

  return user;
};

export const updateUserData = (userUpdate: UserUpdate): Promise<any> => {
  return axios.patch('/user/update', userUpdateToSrc(userUpdate));
};

export const addUserPhoneData = (phone: number, phonePrefix?: number): Promise<any> => {
  return axios.patch('/user/add-phone-number', { phone: `${phone}`, phone_prefix: phonePrefix });
};

export const verifyUserEmail = (tokenId: string, token: string): Promise<any> => {
  return axios.post('/auth/email/verify', { token_id: tokenId, token });
};

export const requestUserEmailToken = (): Promise<any> => {
  return axios.get('/auth/email/send-token');
};

export const verifyUserPhone = (code: string): Promise<any> => {
  return axios.post('/auth/phone/verify', { token: code });
};

export const requestUserPhoneCode = (): Promise<any> => {
  return axios.get('/auth/phone/send-token');
};

export const userAcceptPrivacyPolicy = (): Promise<any> => {
  return axios.post('/user/accept-privacy-policy');
};

export const requestUserPhoneChangeToken = (
  phoneNumber: number,
  password: string,
): Promise<any> => {
  return axios.post('/user/data-change/request', {
    type: 'phone',
    new_value: `${phoneNumber}`,
    password,
  });
};

export const getPendingUserPhoneChangeRequest = (): Promise<any> => {
  return axios.get('/user/data-change/get-pending?type=phone');
};

export const cancelUserPhoneChangeRequest = (dataChangeRequestId: number): Promise<any> => {
  return axios.patch(`/user/data-change/${dataChangeRequestId}/cancel`);
};

export const verifyUserPhoneChange = (dataChangeRequestId: number, code: string): Promise<any> => {
  return axios.post(`/user/data-change/${dataChangeRequestId}/verify`, { code });
};

// export const acceptGdpr = (accepted: true): Promise<any> => {
//   return axios.post('/user/accept-rodo', { rodo_accepted: accepted });
// };

export const setUserAccountType = (accountType: AccountType): Promise<any> => {
  return axios.patch('user/account-type', { account_type: accountType });
};

export const resignPartner = async (): Promise<any> => {
  return await axios.delete(`partner/resign/driver`, {});
};

export const setLanguage = async (language: string): Promise<void> => {
  return axios.post('/user/set-language', { language });
};

export const setContractLanguage = async (
  language: string,
  terminatePreviousContracts?: boolean,
): Promise<void> => {
  await axios.post('user/set-contract-language', {
    language,
    terminate_previous_contracts: !!terminatePreviousContracts,
  });
};

export const deleteAccount = async (): Promise<void> => {
  await axios.delete('user/delete');
};
