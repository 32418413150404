import { useMemo } from 'react';
import { textSearch } from '@common/tools/textSearch';

export type MUICustomSearch = (searchQuery: string, currentRow: any[], columns: any[]) => boolean;

const useMUIDataTableMultipartSearch = (): MUICustomSearch => {
  return useMemo(
    () => (searchQuery: string, currentRow: any[], columns: any[]): boolean => {
      const filterFields = (row: any[]) => {
        return row.filter((elem, idx) => {
          return columns[idx]?.searchable && typeof elem === 'string';
        });
      };

      return textSearch(searchQuery, currentRow, filterFields);
    },
    [],
  );
};

export default useMUIDataTableMultipartSearch;
