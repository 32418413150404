// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Internal libs

// Internal components
import PageDefaultLayout from '@common/components/layouts/PageDefaultLayout';
import UserDataEditModal from './UserDataEditModal';
import UserDataCard from './UserDataCard';
import ContactDataCard from './ContactDataCard';
import EmailVerifyModal from './EmailVerifyModal';
import PhoneEditModal from './PhoneEditModal';
import PhoneVerifyModal from './PhoneVerifyModal';
import PhoneChangeModal from './PhoneChangeModal';
import SettlementsDataCard from './settlements/SettlementsDataCard';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    gridContainer: {},
  }),
);

// Props type
type ProfilePageProps = {};

// Component
const ProfilePage = ({}: ProfilePageProps) => {
  const { t } = useTranslation('user');
  const styles = useStyles();
  const { path } = useRouteMatch();

  return (
    <PageDefaultLayout title={t('title', '')}>
      <Grid container spacing={3} className={styles.gridContainer}>
        <Grid item xs={12} md={6} lg={4}>
          <ContactDataCard />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <UserDataCard />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SettlementsDataCard />
        </Grid>
      </Grid>

      <Switch>
        <Route path={`${path}/edit`} component={UserDataEditModal} />
        <Route path={`${path}/email/verify`} component={EmailVerifyModal} />
        <Route path={`${path}/phone/edit`} component={PhoneEditModal} />
        <Route path={`${path}/phone/verify`} component={PhoneVerifyModal} />
        <Route path={`${path}/phone-change/request`} component={PhoneChangeModal} />
      </Switch>
    </PageDefaultLayout>
  );
};

export default ProfilePage;
