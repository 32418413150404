import { actions as simpleActions } from '../slice';
import { AuthThunk } from '@features/auth/store/model';
import * as api from '../../api';

/****** Place for complex actions *****/

// Login Action
export const login = (username: string, password: string): AuthThunk => async (dispatch) => {
  dispatch(simpleActions.loginInit({ username, password }));

  try {
    const response = await api.login(username, password);
    dispatch(simpleActions.loginSuccess(response.data?.token || ''));
  } catch (e) {
    dispatch(simpleActions.loginFailure(e.response?.data || {}));
  }
};

export const loginSocial = (serviceName: 'google' | 'uber' | 'facebook'): AuthThunk => async (
  dispatch,
) => {
  dispatch(simpleActions.loginSocialInit(serviceName));
  await api.loginSocial(serviceName);
};

export const loginSocialConfirmToken = (serviceName: string, query: string): AuthThunk => async (
  dispatch,
) => {
  dispatch(simpleActions.loginSocialConfirmTokenInit(serviceName));
  try {
    const response = await api.loginSocialConfirmToken(serviceName, query);
    dispatch(simpleActions.loginSocialConfirmTokenSuccess(response?.data?.token));
  } catch (e) {
    dispatch(simpleActions.loginSocialConfirmTokenFailure(e?.response?.data));
  }
};

// Logout Action
export const logout = (): AuthThunk => async (dispatch) => {
  dispatch(simpleActions.logoutInit());

  try {
    localStorage.removeItem('authToken');
    await api.logout();
    dispatch(simpleActions.logoutSuccess());
  } catch (e) {
    dispatch(simpleActions.logoutFailure(e.response?.data || {}));
  }
};
