// Core libraries
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
// @ts-ignore
import { debounce } from 'lodash';
// Internal libs
import { PaginatedData } from '@common/model';
import { Ride } from '@features/rides/model';
import { selectors } from '../../store';

// Internal components
import useMUIDataTableDefaultOptions from '@common/miscHooks/useMUIDataTableDefaultOptions';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type DriverRidesListProps = {
  driverId?: string;
  paginatedRides?: PaginatedData<Ride>;
  setPage: (pageNumber: number) => void;
  setShowTotalItems: (totalItems: number) => void;
  searchRideNo?: (rideNo?: string) => void;
  rideUrl?: (rideId: number) => string;
};

// Component
const DriverRidesList = ({
  paginatedRides,
  setPage,
  setShowTotalItems,
  driverId,
  searchRideNo = (rideNo?: string) => {},
  rideUrl,
}: DriverRidesListProps) => {
  const styles = useStyles();
  const history = useHistory();
  const { t } = useTranslation(['rides', 'admin']);

  // Columns definition
  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'status',
        label: t('rides:model.ride.status.label'),
        options: {
          customBodyRender: (value) =>
            value ? t(`rides:model.ride.status.map.${value}`, value) : '-',
        },
      },
      {
        name: 'no',
        label: t('rides:model.ride.no'),
      },
      {
        name: 'type',
        label: t('rides:model.ride.type.label'),
        options: {
          customBodyRender: (value) => t(`model.ride.type.map.${value}`, value),
        },
      },
      {
        name: 'payout',
        label: t('rides:model.ride.payout'),
      },
      {
        name: 'invoiceId',
        label: t('rides:model.ride.invoicedSettled.label'),
        options: {
          customBodyRender: (value: string) =>
            value
              ? t('rides:model.ride.invoicedSettled.settled.yes')
              : t('rides:model.ride.invoicedSettled.settled.no'),
        },
      },
      {
        name: 'partnerName',
        label: t('rides:model.ride.partnerName'),
        options: {
          customBodyRender: (value: string) => value || '-',
        }
      },
      {
        name: 'closedAt',
        label: t('rides:model.ride.closedAt'),
        options: {
          customBodyRender: (value: string) =>
            value ? t('common:datetime.datetime', { date: new Date(value) }) : '-',
        },
      },
      {
        name: 'createdAt',
        label: t('rides:model.ride.createdAt'),
        options: {
          customBodyRender: (value: string) =>
            value ? t('common:datetime.datetime', { date: new Date(value) }) : '-',
        },
      },
      {
        name: 'deliveredAt',
        label: t('rides:model.ride.deliveredAt'),
        options: {
          customBodyRender: (value: string) =>
            value ? t('common:datetime.datetime', { date: new Date(value) }) : '-',
        },
      },
      {
        name: 'cancelledAt',
        label: t('rides:model.ride.cancelledAt'),
        options: {
          customBodyRender: (value: string) =>
            value ? t('common:datetime.datetime', { date: new Date(value) }) : '-',
        },
      },
    ],
    [t],
  );

  const debouncedSearch = debounce((value: any) => searchRideNo(value), 1500);

  // Table options
  const options: MUIDataTableOptions = {
    ...useMUIDataTableDefaultOptions(true),
    enableNestedDataAccess: '.',
    sort: false,
    search: true,
    filter: false,
    count: paginatedRides?.total,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50],
    // @ts-ignore
    rowsPerPage: paginatedRides?.perPage && parseInt(paginatedRides.perPage),
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'search':
          debouncedSearch(tableState.searchText || undefined);
          break;
        case 'changePage':
          setPage(tableState.page + 1);
          setShowTotalItems(tableState.rowsPerPage);
          break;

        case 'changeRowsPerPage':
          setPage(1);
          setShowTotalItems(tableState.rowsPerPage);
          break;
      }
    },
    onRowClick: (rowData, { dataIndex }) => {
      const row = paginatedRides?.data && paginatedRides.data[dataIndex];
      if (row) {
        rideUrl
          ? history.push(rideUrl(row.id))
          : history.push(`/admin/drivers/driver/${row.userId}/profile/rides/${row.id}`);
      }
    },
  };
  const driver = useSelector(selectors.selectDriverById(driverId));
  const title = driverId
    ? `${t('admin:drivers:rides.title')} ${
        driver ? driver.firstName + ' ' + driver.lastName : driverId
      }`
    : `${t('admin:rides.title')}`;

  return (
    <MUIDataTable
      title={title}
      data={paginatedRides?.data || []}
      columns={columns}
      options={options}
    />
  );
};

export default DriverRidesList;
