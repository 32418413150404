import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { ContractsState } from '../model';
import { ContractTemplate } from '../../model/ContractTemplate';
import { ContractTemplateWithContract } from '@features/contracts/model/ContractTemplateWithContract';

export const getContractTemplatesWithContractsInit = (state: ContractsState, action: Action) => {
  state.contractTemplatesWithContractsState = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const getContractTemplatesWithContractsSuccess = (
  state: ContractsState,
  action: PayloadAction<ContractTemplateWithContract[]>,
) => {
  state.contractTemplatesWithContractsState = {
    inProgress: false,
    error: undefined,
    success: true,
  };
  state.contractTemplatesWithContracts = action.payload;
};
export const getContractTemplatesWithContractsFailure = (
  state: ContractsState,
  action: PayloadAction<FailureResponse>,
) => {
  state.contractTemplatesWithContractsState = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
  state.contractTemplatesWithContracts = undefined;
};

export const downloadGeneratedContractInit = (
  state: ContractsState,
  action: PayloadAction<number>,
) => {};
export const downloadGeneratedContractSuccess = (state: ContractsState, action: Action) => {};
export const downloadGeneratedContractFailure = (
  state: ContractsState,
  action: PayloadAction<FailureResponse>,
) => {};

export const signContractInit = (state: ContractsState, action: PayloadAction<number>) => {};
export const signContractSuccess = (state: ContractsState, action: Action) => {};
export const signContractFailure = (
  state: ContractsState,
  action: PayloadAction<FailureResponse>,
) => {};

export const requestPinCodeInit = (state: ContractsState, action: PayloadAction<number>) => {};
export const requestPinCodeSuccess = (state: ContractsState, action: Action) => {};
export const requestPinCodeFailure = (
  state: ContractsState,
  action: PayloadAction<FailureResponse>,
) => {};

export const getContractInit = (state: ContractsState, action: PayloadAction<number>) => {};
export const getContractSuccess = (state: ContractsState, action: Action) => {};
export const getContractFailure = (
  state: ContractsState,
  action: PayloadAction<FailureResponse>,
) => {};

export const uploadSignedContractInit = (
  state: ContractsState,
  action: PayloadAction<{ contractTemplateId: number; fileName?: string; fileSize?: number }>,
) => {};
export const uploadSignedContractSuccess = (state: ContractsState, action: Action) => {};
export const uploadSignedContractFailure = (
  state: ContractsState,
  action: PayloadAction<FailureResponse>,
) => {};

export const downloadUploadedSignedDocumentInit = (
  state: ContractsState,
  action: PayloadAction<{ url: string; fileName: string }>,
) => {};
export const downloadUploadedSignedDocumentSuccess = (state: ContractsState, action: Action) => {};
export const downloadUploadedSignedDocumentFailure = (
  state: ContractsState,
  action: PayloadAction<FailureResponse>,
) => {};
