// Core libraries
import React, { useEffect, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { User } from '../model/User';
import { actions, selectors } from '../store';
import { actions as loginActions } from '@features/auth/store';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import FormTextField from '@common/components/FormTextField';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormErrors from '@common/components/FormErrors';
import { DialogContentText } from '@material-ui/core';
import PhoneVerifySendAgainButton from '@features/user/containers/PhoneVerifySendAgainButton';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(2),
    },
    actions: { justifyContent: 'flex-end' },
    leftActions: { flexGrow: 1 },
  }),
);

// Props type
type AccountDeleteConfirmModalProps = {};

// Component
const AccountDeleteConfirmModal = ({}: AccountDeleteConfirmModalProps) => {
  const { t } = useTranslation(['user', 'common']);
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const form = useForm();

  const userDataState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.user.updateUserDataState,
  );
  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  const onClose = () => {
    history.push('.');
  };

  const [deleteAccountState, setDeleteAccountState] = useState<InProgressState>({
    inProgress: false,
  });

  const onSubmit = async (values: any) => {
    setDeleteAccountState({ inProgress: true });
    try {
      await dispatch(actions.deleteAccount());

      setDeleteAccountState({ inProgress: false, success: true });
      await dispatch(loginActions.logout());
      onClose();
    } catch (e) {
      setDeleteAccountState({ inProgress: false, error: e, success: false });
    }
  };

  const { formErrors, getFieldErrors } = useResponseErrors(
    deleteAccountState?.error,
    'deleteAccount',
    'user',
  );

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogTitle>{t('cards.deleteAccount.title', '')}</DialogTitle>
          <Divider />
          <DialogContent>
            {deleteAccountState?.inProgress && <LinearProgress />}
            <DialogContentText>{t('cards.deleteAccount.confirmBody', '')}</DialogContentText>
          </DialogContent>
          <DialogActions className={styles.actions}>
            <Button onClick={onClose}>{t('common:close')}</Button>
            <ProgressButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={deleteAccountState?.inProgress}
              loading={deleteAccountState?.inProgress}
            >
              {t(`cards.deleteAccount.confirmOk`, '')}
            </ProgressButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default AccountDeleteConfirmModal;
