export enum CorrectionTypeEnum {
  'rideAdded' = 'ride_added',
  'correction' = 'correction',
  'bonus' = 'bonus',
  'ambassador' = 'ambassador',
}

export type CorrectionType =
  | CorrectionTypeEnum.rideAdded
  | CorrectionTypeEnum.correction
  | CorrectionTypeEnum.bonus
  | CorrectionTypeEnum.ambassador;

export const isCorrectionTypeMap: { [key: string]: boolean } = {
  [CorrectionTypeEnum.bonus]: true,
  [CorrectionTypeEnum.rideAdded]: true,
  [CorrectionTypeEnum.correction]: true,
  [CorrectionTypeEnum.ambassador]: true,
};
