import { FailureResponse } from '@common/model';
import { Namespace, useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

/**
 * @param data               Data returned in failed response.
 * @param translatePrefix    For fields prefix will be appended by e.g. `.fields.${fieldName}.errors.${errorType}`
 *                           For form level error prefix will be appended by e.g. `.errors.${errorType}`
 * @param translateNamespace Namespace passed to translation function.
 */
export const useResponseErrors = (
  data: FailureResponse | undefined,
  translatePrefix: string,
  translateNamespace?: Namespace,
) => {
  const { t } = useTranslation(translateNamespace);

  const getFieldErrors = useCallback(
    (
      fieldName: string,
      backendFieldName: string = fieldName,
      translateFullPrefix?: string,
    ): string[] =>
      data?.errors && data.errors[backendFieldName]
        ? data.errors[backendFieldName].map((message) =>
            translateFullPrefix
              ? t(
                  `${translateFullPrefix}.${message.replace('.', '_')}`,
                  t(`${translateFullPrefix}.unknown`, ''),
                )
              : t(
                  `${translatePrefix}.fields.${fieldName}.errors.${message.replace('.', '_')}`,
                  t(`${translatePrefix}.fields.${fieldName}.errors.unknown`, ''),
                ),
          )
        : [],
    [data, translatePrefix, t],
  );

  const formErrors = useMemo(
    () =>
      data?.error
        ? [
            t(
              `${translatePrefix}.errors.${data.error.replace('.', '_')}`,
              t(`${translatePrefix}.errors.unknown`),
            ),
          ]
        : [],
    [data, translatePrefix, t],
  );

  return { getFieldErrors, formErrors };
};
