// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

// Internal libs
import { Invoice, InvoiceType } from '@features/company/model/Invoice';

// Internal components
import BankAccountNo from '@common/components/dataViews/BankAccountNo';
import DataItem from '@common/components/cardContent/DataItem';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type InvoicePaymentInfoProps = { invoice: Invoice };

// Component
const InvoicePaymentInfo = ({ invoice }: InvoicePaymentInfoProps) => {
  const { t } = useTranslation(['user', 'company']);
  const styles = useStyles();

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item xs={12} md={4} lg={4}>
        <DataItem
          label={t('company:model.invoice.createdAt')}
          text={new Date(invoice.createdAt).toLocaleDateString()}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <DataItem
          label={t('company:model.invoice.invoicedAt')}
          text={
            InvoiceType.Invoice === invoice.type
              ? new Date(invoice.invoicedAt).toLocaleDateString()
              : '-'
          }
        />
      </Grid>

      <Grid item xs={12} md={4} lg={4}>
        <DataItem
          label={t('company:model.invoice.soldAtTo')}
          text={new Date(invoice.soldAtFrom).toLocaleDateString()}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <DataItem label={t('company:model.invoice.paymentDeadline')} text={'Zgodnie z umową'} />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <DataItem
          label={t('user:company.fields.bankAccountNo')}
          text={<BankAccountNo bankAccountNo={invoice.companyBankAccountNumber} />}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <DataItem label={t('company:model.invoice.currency')} text={invoice.currency} />
      </Grid>
    </Grid>
  );
};

export default InvoicePaymentInfo;
