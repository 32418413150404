// Core libraries
import React, { useCallback, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AttachmentIcon from '@material-ui/icons/Attachment';

// Internal components
import { actions } from '@features/contracts';
import { useDispatch } from 'react-redux';
import ProgressButton from '@common/components/ProgressButton';
import { Contract } from '@features/contracts/model/Contract';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type DownloadContractSectionProps = {
  contract?: Contract;
};

// Component
const DowloadContractSection = ({ contract }: DownloadContractSectionProps) => {
  const { t } = useTranslation(['common', 'contracts']);
  const styles = useStyles();
  const dispatch = useDispatch();
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);

  const download = async () => {
    if (!contract) {
      return;
    }
    setDownloadInProgress(true);
    try {
      await dispatch(actions.downloadContractPdf(contract));
      setDownloadInProgress(false);
    } catch (e) {
      setDownloadInProgress(false);
    }
  };

  return (
    <div className={styles.root}>
      <ProgressButton
        variant="text"
        color="primary"
        onClick={() => download()}
        disabled={downloadInProgress}
        loading={downloadInProgress}
      >
        <AttachmentIcon style={{ marginRight: 10 }} />
        {t('contracts:contractView.downloadContract')}
      </ProgressButton>
    </div>
  );
};

export default DowloadContractSection;
