import { createSlice } from '@reduxjs/toolkit';
import { RidesState } from './model';
import * as reducers from './reducers';

const slice = createSlice<RidesState, typeof reducers>({
  name: 'rides',
  initialState: {},
  reducers: {
    ...reducers,
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;
