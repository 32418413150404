import {
  timestampable,
  timestampableFromSrc,
  timestampableSrc,
  timestampableToSrc,
} from '@common/model';

export type PartnerDriverInfo = Pick<
  PartnerDriver,
  'id' | 'firstName' | 'lastName' | 'email' | 'phone'
>;

export interface PartnerDriver extends timestampable {
  id: number;
  firstName?: string;
  lastName?: string;
  email: string;
  emailVerifiedAt?: string;
  phonePrefix?: string;
  phone?: string;
  phoneVerifiedAt?: string;
  pesel?: string;
  idNumber?: string;
  passportNumber?: string;
  privacyPolicyAcceptedAt?: string;
  regionId?: number;
  region?: string;
  accountType?: string;
  partnerId?: string;
}

export interface PartnerDriverSrc extends timestampableSrc {
  id: number;
  first_name: string | null;
  last_name: string | null;
  email: string;
  email_verified_at: string | null;
  phone_prefix: string | null;
  phone: string | null;
  phone_verified_at: string | null;
  pesel: string | null;
  id_number: string | null;
  passport_number: string | null;
  privacy_policy_accepted_at: string | null;
  region_id: number | null;
  region: string | null;
  account_type: string | null;
  partner_id: string | null;
}

export const partnerDriverFromSrc = (partnerDriverSrc: PartnerDriverSrc): PartnerDriver => ({
  id: partnerDriverSrc.id,
  firstName: partnerDriverSrc.first_name || undefined,
  lastName: partnerDriverSrc.last_name || undefined,
  email: partnerDriverSrc.email,
  emailVerifiedAt: partnerDriverSrc.email_verified_at || undefined,
  phonePrefix: partnerDriverSrc.phone_prefix || undefined,
  phone: partnerDriverSrc.phone || undefined,
  phoneVerifiedAt: partnerDriverSrc.phone_verified_at || undefined,
  pesel: partnerDriverSrc.pesel || undefined,
  idNumber: partnerDriverSrc.id_number || undefined,
  passportNumber: partnerDriverSrc.passport_number || undefined,
  privacyPolicyAcceptedAt: partnerDriverSrc.privacy_policy_accepted_at || undefined,
  regionId: partnerDriverSrc.region_id || undefined,
  region: partnerDriverSrc.region || undefined,
  accountType: partnerDriverSrc.account_type || undefined,
  partnerId: partnerDriverSrc.partner_id || undefined,
  ...timestampableFromSrc(partnerDriverSrc),
});

export const partnerDriverToSrc = (partnerDriver: PartnerDriver): PartnerDriverSrc => ({
  id: partnerDriver.id,
  first_name: partnerDriver.firstName || null,
  last_name: partnerDriver.lastName || null,
  email: partnerDriver.email,
  email_verified_at: partnerDriver.emailVerifiedAt || null,
  phone_prefix: partnerDriver.phonePrefix || null,
  phone: partnerDriver.phone || null,
  phone_verified_at: partnerDriver.phoneVerifiedAt || null,
  pesel: partnerDriver.pesel || null,
  id_number: partnerDriver.idNumber || null,
  passport_number: partnerDriver.passportNumber || null,
  privacy_policy_accepted_at: partnerDriver.privacyPolicyAcceptedAt || null,
  region_id: partnerDriver.regionId || null,
  region: partnerDriver.region || null,
  account_type: partnerDriver.accountType || null,
  partner_id: partnerDriver.partnerId || null,
  ...timestampableToSrc(partnerDriver),
});
