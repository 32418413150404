export interface NationalitySrc {
  id: number;
  name: string;
  value: string;
  type: string;
  options: {
    requires_pesel?: boolean;
    default?: boolean;
    requires_custom_input?: boolean;
    requires_identity_document?: boolean; // defaults true
  };
}

export interface Nationality {
  id: number;
  name: string;
  value: string;
  type: string;
  options?: {
    requiresPesel?: boolean;
    default?: boolean;
    requiresCustomInput?: boolean;
    requiresIdentityDocument: boolean;
  };
}

export const nationalityFromSrc = (nationalitySrc: NationalitySrc): Nationality => ({
  ...nationalitySrc,
  options: {
    ...(nationalitySrc.options?.requires_pesel !== undefined && {
      requiresPesel: nationalitySrc.options?.requires_pesel,
    }),
    ...(nationalitySrc.options?.default !== undefined && {
      default: nationalitySrc.options?.default,
    }),
    ...(nationalitySrc.options?.requires_custom_input !== undefined && {
      requiresCustomInput: nationalitySrc.options?.requires_custom_input,
    }),
    requiresIdentityDocument:
      nationalitySrc.options?.requires_identity_document !== undefined
        ? nationalitySrc.options?.requires_identity_document
        : true,
  },
});
