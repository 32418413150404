// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AccountIcon from '@material-ui/icons/AccountCircle';
import CarIcon from '@material-ui/icons/DirectionsCar';
import CompanyIcon from '@material-ui/icons/Business';
import ContractsIcon from '@material-ui/icons/Description';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DriversIcon from '@material-ui/icons/RecentActors';
import InvoicesIcon from '@material-ui/icons/DescriptionOutlined';
import PartnerIcon from '@material-ui/icons/PeopleAlt';
import RidesIcon from '@material-ui/icons/LocationOn';

// Internal libs
import { selectors as userSelectors } from '@features/user';
import { selectors as partnerSelectors } from '@features/partner';
import { selectors as contractsSelector } from '@features/contracts';

// Internal components
import { MenuItem } from '@features/main';

// Component
const Menu = () => {
  const { t } = useTranslation();

  const user = useSelector(userSelectors.selectUser);

  const pendingDriverJoinRequestsCountFromPartner = useSelector(
    partnerSelectors.selectPendingDriverJoinRequestsCount,
  );
  const pendingDriverJoinRequestsCountFromUser = useSelector(
    userSelectors.selectPartnerPendingDriverJoinRequestsCount,
  );

  const pendingDriverJoinRequestsCount = useMemo(
    () =>
      typeof pendingDriverJoinRequestsCountFromPartner !== 'undefined'
        ? pendingDriverJoinRequestsCountFromPartner
        : pendingDriverJoinRequestsCountFromUser,
    [pendingDriverJoinRequestsCountFromPartner, pendingDriverJoinRequestsCountFromUser],
  );

  const missingContractsCountFromUser = useSelector(userSelectors.selectMissingContractsCount);
  const missingContractsCountFromContracts = useSelector(
    contractsSelector.selectMissingContractsCount,
  );

  const missingContractsCount = useMemo(
    () =>
      typeof missingContractsCountFromContracts !== 'undefined'
        ? missingContractsCountFromContracts
        : missingContractsCountFromUser,
    [missingContractsCountFromUser, missingContractsCountFromContracts],
  );

  return (
    <>
      <MenuItem
        id="dashboard"
        label={t('dashboard:menu.label', '')}
        redirectTo={'/'}
        icon={<DashboardIcon />}
      />
      <MenuItem
        id="user"
        label={t('user:menu.label', '')}
        redirectTo={'/profile'}
        icon={<AccountIcon />}
      />
      <MenuItem
        id="vehicles"
        label={t('vehicles:menu.label', '')}
        redirectTo={'/vehicles'}
        icon={<CarIcon />}
      />
      <MenuItem
        id="contracts"
        label={t('contracts:menu.label', '')}
        redirectTo={'/contracts'}
        icon={<ContractsIcon />}
        badge={typeof missingContractsCount === 'undefined' ? '!' : missingContractsCount}
      />
      <MenuItem
        id="rides"
        label={t('rides:menu.label', '')}
        redirectTo={'/rides'}
        icon={<RidesIcon />}
      />

      {/* Company */}
      <MenuItem
        id="company"
        label={t('company:menu.label', '')}
        redirectTo={'/company'}
        icon={<CompanyIcon />}
        disabled={!user?.company}
      />
      <MenuItem
        id="company.invoices"
        label={t('company:menu.invoices.label')}
        redirectTo={'/company/invoices'}
        icon={<InvoicesIcon />}
      />

      {/* Partner */}
      <MenuItem
        id="partner"
        label={t('partner:menu.label')}
        redirectTo={'/partner'}
        icon={<PartnerIcon />}
        tooltip={user && !user.company?.isPartner && (t('partner:menu.notPartnerHint') as string)}
        disabled={!user?.company?.isPartner}
        badge={pendingDriverJoinRequestsCount}
      />
      <MenuItem
        id="partner.drivers"
        label={t('partner:menu.drivers.label')}
        redirectTo={'/partner/drivers'}
        icon={<DriversIcon />}
      />
      <MenuItem
        id="partner.rides"
        label={t('partner:rides.menu.label')}
        redirectTo={'/partner/rides'}
        icon={<RidesIcon />}
      />
    </>
  );
};

export default Menu;
