import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { DriverJoinRequest, PartnerDriver } from '@features/partner/model';

export const selectGetPendingDriverJoinRequestsState = (
  state: RootState,
): InProgressState | undefined => state.partner.getPendingDriverJoinRequestsState;

export const selectPendingDriverJoinRequests = (
  state: RootState,
): DriverJoinRequest[] | undefined => state.partner.pendingDriverJoinRequests;

export const selectPendingDriverJoinRequestsCount = (state: RootState): number | undefined =>
  state.partner.pendingDriverJoinRequests?.length;

export const selectGetPartnerDriversState = (state: RootState): InProgressState | undefined =>
  state.partner.getPartnerDriversState;

export const selectPartnerDrivers = (state: RootState): PartnerDriver[] | undefined =>
  state.partner.partnerDrivers;

export const selectPartnerDriversCount = (state: RootState): number | undefined =>
  state.partner.partnerDrivers?.length;
