// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { User } from '../../model/User';
import { actions, selectors } from '../../store';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import FormTextField from '@common/components/FormTextField';
import usePhoneValidator, {
  phoneMask,
  phoneToNumber,
  numberToPhone,
} from '@common/validators/phoneValidator';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormErrors from '@common/components/FormErrors';
import { PhoneChangeRequest } from '@features/user/model/PhoneChangeRequest';
import { useShowSnackbar } from '@features/snackbars';
import { useHistory, useRouteMatch } from 'react-router';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(2),
    },
  }),
);

// Props type
type PhoneChangeVerifyFormProps = {
  onClose?: VoidFunction;
  phoneChangeRequest: PhoneChangeRequest;
};

// Component
const PhoneChangeVerifyForm = ({ onClose, phoneChangeRequest }: PhoneChangeVerifyFormProps) => {
  const { t } = useTranslation('user');
  const showSnackbar = useShowSnackbar();
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const form = useForm();
  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  const [verifyPhoneChangeState, setVerifyPhoneChangeState] = useState<InProgressState>({
    inProgress: false,
  });

  const onSubmit = async (values: any) => {
    setVerifyPhoneChangeState({ inProgress: true });
    try {
      await dispatch(actions.verifyUserPhoneChange(phoneChangeRequest.id, values.code));
      setVerifyPhoneChangeState({ inProgress: false, success: true });
      onClose?.();
      showSnackbar({
        message: t('phoneChangeVerify.success'),
        severity: 'success',
      });
      history.push(`..`);
      history.push(`/profile/phone/verify`);
    } catch (e) {
      setVerifyPhoneChangeState({ inProgress: false, error: e, success: false });
    }
  };

  const { formErrors, getFieldErrors } = useResponseErrors(
    verifyPhoneChangeState?.error,
    'phoneChangeVerify',
    'user',
  );
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle>{t('phoneChangeVerify.title', '')}</DialogTitle>
        <Divider />
        <DialogContent>
          <p>{t('phoneChangeVerify.body')}</p>
          {userData && (
            <>
              <FormTextField
                className={styles.field}
                name="code"
                label={t('phoneChangeVerify.fields.code.label', '')}
                placeholder=""
                rules={{
                  required: {
                    value: true,
                    message: t('phoneChangeVerify.fields.code.errors.required', ''),
                  },
                  maxLength: {
                    value: 6,
                    message: t('phoneChangeVerify.fields.code.errors.size', ''),
                  },
                  minLength: {
                    value: 6,
                    message: t('phoneChangeVerify.fields.code.errors.size', ''),
                  },
                }}
                fullWidth={true}
                errors={getFieldErrors('code')}
              />
              {/* Errors */}
              <FormErrors errorMessages={formErrors} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('phoneChangeVerify.cancelButton')}</Button>
          <ProgressButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={verifyPhoneChangeState?.inProgress}
            loading={verifyPhoneChangeState?.inProgress}
          >
            {t(`phoneChangeVerify.submitButton`, '')}
          </ProgressButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default PhoneChangeVerifyForm;
