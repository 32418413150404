import vehiclesListReducers from './vehiclesList';
import vehicleUpsertReducers from './vehicleUpsert';

// Reducers object
const reducers = {
  ...vehiclesListReducers,
  ...vehicleUpsertReducers,
};

export default reducers;
