// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';

// Internal libs

// Internal components
import PageDefaultLayout from '@common/components/layouts/PageDefaultLayout';
import AdminInvoices from './AdminInvoices';

// Props type
type AdminInvoicesPageProps = {};

// Component
const AdminInvoicesPage = ({}: AdminInvoicesPageProps) => {
  const { t } = useTranslation('company');

  return (
    <PageDefaultLayout title={t('invoices.title')}>
      <AdminInvoices />
    </PageDefaultLayout>
  );
};

export default AdminInvoicesPage;
