import { useMemo } from 'react';

interface ChecksumParams {
  map: { [key: string]: number };
  weights: number[];
}

interface Params {
  pattern?: string;
  checksum?: ChecksumParams;
}

const countryParams: { [key: string]: Params } = {
  pl: {
    pattern: '^[ABCDEFGHIJKLMNOPQRSTUVWXYZ]{3}\\d{6}$',
    checksum: {
      map: {
        '0': 0,
        '1': 1,
        '2': 2,
        '3': 3,
        '4': 4,
        '5': 5,
        '6': 6,
        '7': 7,
        '8': 8,
        '9': 9,
        A: 10,
        B: 11,
        C: 12,
        D: 13,
        E: 14,
        F: 15,
        G: 16,
        H: 17,
        I: 18,
        J: 19,
        K: 20,
        L: 21,
        M: 22,
        N: 23,
        O: 24,
        P: 25,
        Q: 26,
        R: 27,
        S: 28,
        T: 29,
        U: 30,
        V: 31,
        W: 32,
        X: 33,
        Y: 34,
        Z: 35,
      },
      weights: [7, 3, 1, 0, 7, 3, 1, 7, 3],
    },
  },
  cz: {
    pattern: '^[A-Z0-9]{5,}$',
  },
};

/**
 * This validator considers empty values as valid.
 *
 * @param message
 * @param countryCode
 */
export const idNumberValidator = (message?: string, countryCode: string = 'pl') => (
  value: string,
) => {
  const params: Params = countryParams.hasOwnProperty(countryCode)
    ? countryParams[countryCode]
    : {};

  return (
    !value ||
    ((!params.pattern || checkPattern(value, params.pattern)) &&
      (!params.checksum || checkChecksum(value, params.checksum))) ||
    message ||
    false
  );
};

const checkPattern = (idNumber: string, pattern: string): boolean =>
  RegExp(pattern).test(idNumber.toUpperCase());

const checkChecksum = (idNumber: string, params: ChecksumParams) => {
  const id = idNumber.toUpperCase();

  const sum = params.weights.reduce((sum, weight, idx) => sum + weight * params.map[id[idx]], 0);

  // TODO `10` and `3` should also be params
  return sum % 10 === Number(id[3]);
};

const useIdNumberValidator = (message?: string, countryCode?: string) => {
  return useMemo(() => idNumberValidator(message, countryCode), [message, countryCode]);
};
export default useIdNumberValidator;
