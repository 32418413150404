import { RootThunk } from '@common/model';
import { actions as simpleActions } from '../slice';
import { actions as partnerActions } from '@features/partner';
import * as api from '../../api';
import { UserUpdate } from '../../model/User';
import { AccountType } from '../../model/Settlements';
import { getContractTemplatesWithContracts } from '@features/contracts/store/actions';
import { phoneChangeRequestFromSrc } from '@features/user/model/PhoneChangeRequest';
import { actions } from '@features/auth/store';
/****** Place for complex actions *****/

// Get current User
export const getCurrentUser = (): RootThunk => async (dispatch) => {
  dispatch(simpleActions.getCurrentUserInit());

  try {
    const user = await api.getCurrentUser();
    dispatch(simpleActions.getCurrentUserSuccess(user));
  } catch (e) {
    dispatch(simpleActions.getCurrentUserFailure(e.response?.data || {}));
  }
};

// Update User Data
export const updateUserData = (userUpdate: UserUpdate): RootThunk => async (dispatch) => {
  dispatch(simpleActions.updateUserDataInit(userUpdate));

  try {
    const response = await api.updateUserData(userUpdate);
    dispatch(simpleActions.updateUserDataSuccess());
    dispatch(getCurrentUser());
  } catch (e) {
    dispatch(simpleActions.updateUserDataFailure(e.response?.data || {}));
  }
};

// Verify email
export const verifyUserEmail = (tokenId: string, token: string): RootThunk => async (dispatch) => {
  dispatch(simpleActions.verifyUserEmailInit({ tokenId, token }));

  try {
    const response = await api.verifyUserEmail(tokenId, token);
    dispatch(simpleActions.verifyUserEmailSuccess());
    dispatch(getCurrentUser());
  } catch (e) {
    dispatch(simpleActions.verifyUserEmailFailure(e.response?.data || {}));
    throw e.response?.data || { error: 'unknown' };
  }
};

// Request email verification code
export const requestUserEmailToken = (): RootThunk => async (dispatch) => {
  dispatch(simpleActions.requestUserEmailTokenInit());

  try {
    const response = await api.requestUserEmailToken();
    dispatch(simpleActions.requestUserEmailTokenSuccess());
  } catch (e) {
    dispatch(simpleActions.requestUserEmailTokenFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};

// Update phone number
export const addUserPhone = (phone: number, phonePrefix?: number): RootThunk => async (
  dispatch,
) => {
  dispatch(simpleActions.updateUserDataInit({ phone, phonePrefix }));

  try {
    const response = await api.addUserPhoneData(phone, phonePrefix);
    await dispatch(simpleActions.updateUserDataSuccess());
    dispatch(getCurrentUser());
  } catch (e) {
    await dispatch(simpleActions.updateUserDataFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};

// Verify phone number
export const verifyUserPhone = (code: string): RootThunk => async (dispatch) => {
  dispatch(simpleActions.verifyUserPhoneInit(code));

  try {
    const response = await api.verifyUserPhone(code);
    dispatch(simpleActions.verifyUserPhoneSuccess());
    dispatch(getCurrentUser());
  } catch (e) {
    dispatch(simpleActions.verifyUserPhoneFailure(e.response?.data || {}));
    throw e.response?.data || { error: 'unknown' };
  }
};

// Request phone number verification code
export const requestUserPhoneCode = (): RootThunk => async (dispatch) => {
  dispatch(simpleActions.requestUserPhoneCodeInit());

  try {
    const response = await api.requestUserPhoneCode();
    dispatch(simpleActions.requestUserPhoneCodeSuccess());
  } catch (e) {
    dispatch(simpleActions.requestUserPhoneCodeFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};

// User Accept Privacy Policy (in fact it is form of user update therefore we reuse updateUserData reducers)
export const userAcceptPrivacyPolicy = (): RootThunk => async (dispatch) => {
  dispatch(simpleActions.updateUserDataInit({}));

  try {
    const response = await api.userAcceptPrivacyPolicy();
    dispatch(simpleActions.updateUserDataSuccess());
    dispatch(getCurrentUser());
  } catch (e) {
    dispatch(simpleActions.updateUserDataFailure(e.response?.data || {}));
  }
};

// Verify phone change
export const verifyUserPhoneChange = (
  dataChangeRequestId: number,
  code: string,
): RootThunk => async (dispatch) => {
  dispatch(simpleActions.verifyUserPhoneChangeInit());

  try {
    await api.verifyUserPhoneChange(dataChangeRequestId, code);
    dispatch(simpleActions.verifyUserPhoneChangeSuccess());
    dispatch(getCurrentUser());
  } catch (e) {
    dispatch(simpleActions.verifyUserPhoneChangeFailure(e.response?.data || {}));
    throw e.response?.data || { error: 'unknown' };
  }
};

// Request phone change verification code
export const requestUserPhoneChangeToken = (
  phoneNumber: number,
  password: string,
): RootThunk => async (dispatch) => {
  dispatch(simpleActions.requestUserPhoneChangeTokenInit());

  try {
    const response = await api.requestUserPhoneChangeToken(phoneNumber, password);
    dispatch(simpleActions.requestUserPhoneChangeTokenSuccess());
  } catch (e) {
    dispatch(simpleActions.requestUserPhoneChangeTokenFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};

// Get pending phone change request
export const getPendingUserPhoneChangeRequest = (): RootThunk => async (dispatch) => {
  dispatch(simpleActions.getPendingUserPhoneChangeRequestInit());

  try {
    const response = await api.getPendingUserPhoneChangeRequest();
    console.log('response', response);
    dispatch(
      simpleActions.getPendingUserPhoneChangeRequestSuccess(
        phoneChangeRequestFromSrc(response.data),
      ),
    );
  } catch (e) {
    dispatch(simpleActions.getPendingUserPhoneChangeRequestFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};

// export const acceptGdpr = (accepted: true): RootThunk => async (dispatch) => {
//   await dispatch(simpleActions.acceptGdprInit(accepted));
//
//   try {
//     const response = await api.acceptGdpr(accepted);
//     await dispatch(simpleActions.acceptGdprSuccess());
//     await dispatch(getCurrentUser());
//   } catch (e) {
//     await dispatch(simpleActions.acceptGdprFailure(e.response?.data || {}));
//     throw e.response?.data || {};
//   }
// };

export const setUserAccountType = (accountType: AccountType): RootThunk => async (dispatch) => {
  await dispatch(simpleActions.setUserAccountTypeInit(accountType));

  try {
    const response = await api.setUserAccountType(accountType);
    await dispatch(simpleActions.setUserAccountTypeSuccess);
    dispatch(getCurrentUser());
  } catch (e) {
    dispatch(simpleActions.setUserAccountTypeFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};

export const resignPartner = (): RootThunk => async (dispatch) => {
  await dispatch(simpleActions.resignPartnerInit());

  try {
    const response = await api.resignPartner();
    await dispatch(simpleActions.resignPartnerSuccess);
    dispatch(getCurrentUser());
  } catch (e) {
    dispatch(simpleActions.resignPartnerFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};

export const setLanguage = (language: string): RootThunk => async (dispatch) => {
  await dispatch(simpleActions.setLanguageInit(language));

  try {
    const response = await api.setLanguage(language);
    await dispatch(simpleActions.setLanguageSuccess());
    dispatch(getCurrentUser());
  } catch (e) {
    dispatch(simpleActions.setLanguageFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};

export const setContractLanguage = (
  language: string,
  terminatePreviousContracts?: boolean,
): RootThunk => async (dispatch) => {
  await dispatch(simpleActions.setContractLanguageInit(language));

  try {
    await api.setContractLanguage(language, terminatePreviousContracts);
    await dispatch(simpleActions.setContractLanguageSuccess());
    dispatch(getCurrentUser());
    dispatch(getContractTemplatesWithContracts());
  } catch (e) {
    dispatch(simpleActions.setContractLanguageFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};

// Delete account
export const deleteAccount = (): RootThunk => async (dispatch) => {
  dispatch(simpleActions.deleteAccountInit());

  try {
    const response = await api.deleteAccount();
    dispatch(simpleActions.deleteAccountSuccess());
    dispatch(getCurrentUser());
  } catch (e) {
    dispatch(simpleActions.deleteAccountFailure(e.response?.data || {}));
    throw e.response?.data || { error: 'unknown' };
  }
};
