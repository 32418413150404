// Core libraries
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  memo,
  MutableRefObject,
  DOMElement,
} from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { Document, Page, pdfjs } from 'react-pdf';

// Internal components
import { actions } from '@features/contracts';
import { useDispatch } from 'react-redux';
import ProgressButton from '@common/components/ProgressButton';
import { Contract } from '@features/contracts/model/Contract';
import * as api from '@features/contracts/api';
import { LinearProgress } from '@material-ui/core';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { useIntersectionObserver } from '@wojtekmaj/react-hooks';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

function useIntersectionObserver(element: any, options: any, observerCallback: any) {
  useEffect(() => {
    if (!element || !('IntersectionObserver' in window)) {
      return undefined;
    }

    const observer = new IntersectionObserver(observerCallback, options);
    observer.observe(element);

    return () => {
      //observer.disconnect();
    };
  }, [element, options, observerCallback]);
}

const observerConfig = {
  // How much of the page needs to be visible to consider page visible
  threshold: 0,
};

const pageWidth = window.innerWidth - 44;

type PageWithObserverProps = {
  pageNumber: number;
  width?: number;
  className?: string;
  setPageVisibility: (pageNumber: number, isIntersecting: boolean) => void;
};

const PageWithObserver = ({
  pageNumber,
  setPageVisibility,
  ...otherProps
}: PageWithObserverProps) => {
  const pageRef = useRef();
  const onIntersectionChange = useCallback(
    ([entry]) => {
      setPageVisibility(pageNumber, entry.isIntersecting);
    },
    [pageNumber, setPageVisibility],
  );

  useIntersectionObserver(pageRef?.current, observerConfig, onIntersectionChange);
  // @ts-ignore
  return <Page loading={<></>} inputRef={pageRef} pageNumber={pageNumber} {...otherProps} />;
};

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    pdfViewer: {
      width: '100%',
      height: '65vh',
      overflow: 'scroll',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    pdfPage: {},
  }),
);

// Props type
type ContractPdfViewerProps = {
  contract?: Contract;
  setLastPageViewed?: (viewed: boolean) => void;
  scrollToRef?: MutableRefObject<any>;
};

// Component
const ContractPdfViewer = ({ contract, setLastPageViewed }: ContractPdfViewerProps) => {
  const { t } = useTranslation(['common', 'contracts']);
  const styles = useStyles();
  const dispatch = useDispatch();

  const [numPages, setNumPages] = useState<number | null>(null);

  const [visiblePages, setVisiblePages] = useState({});

  const setPageVisibility = useCallback((pageNumber, isIntersecting) => {
    setVisiblePages((prevVisiblePages) => ({
      ...prevVisiblePages,
      [pageNumber]: isIntersecting,
    }));
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  useEffect(() => {
    const pages = Object.entries(visiblePages)
      .filter(([key, value]) => value)
      .map(([key]) => parseInt(key));
    if (numPages && pages.includes(numPages)) {
      setLastPageViewed?.(true);
    }
    const progress = Math.max(...pages) / (numPages || 0);
    if (progress > contractViewProgress) {
      setContractViewProgress(progress);
    }
  }, [numPages, visiblePages]);

  const fileData = useMemo(() => {
    const authToken = localStorage.getItem('authToken');
    return {
      url: contract?.downloadLink,
      httpHeaders: {
        withCredentials: true,
        Authorization: `Bearer ${authToken}`,
      },
    };
  }, [contract]);

  const [contractViewProgress, setContractViewProgress] = useState<number>(0);

  return (
    <div className={styles.root}>
      <Document
        className={styles.pdfViewer}
        file={fileData}
        loading={<LinearProgress />}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <PageWithObserver
            width={pageWidth < 600 ? pageWidth : undefined}
            className={styles.pdfPage}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            setPageVisibility={setPageVisibility}
          />
        ))}
      </Document>
      <LinearProgress
        variant="determinate"
        value={contractViewProgress * 100}
        color={contractViewProgress < 1 ? 'secondary' : 'primary'}
      />

      {/*<div>
        Visible pages:{' '}
        {Object.entries(visiblePages)
          .filter(([key, value]) => value)
          .map(([key]) => key)
          .join(', ') || '(none)'}
      </div>*/}
    </div>
  );
};

export default ContractPdfViewer;
