import axios from 'axios';
import { Invoice, invoiceFromSrc, InvoiceSrc } from '@features/company/model/Invoice';
import { ResponseInfo, responseInfoFromSrc } from '@features/admin/invoices/model/ResponseInfo';

export const getInvoices = async (
  dateFrom: string,
  dateTo: string,
  status?: string,
): Promise<Invoice[]> => {
  const response = await axios.get('/admin/invoice/list', {
    params: {
      date_from: dateFrom,
      date_to: dateTo,
    },
  });

  return response.data.map((invoiceSrc: InvoiceSrc) => invoiceFromSrc(invoiceSrc));
};

export const generateTransfersFile = async (invoicesIds: string[]): Promise<any> => {
  const params = new URLSearchParams();
  invoicesIds.forEach((item) => {
    params.append('ids[]', item);
  });

  const fileName = 'generated_transfers_file_from_invoices.txt';

  const file: { data: Blob } = await axios.get('/admin/invoice/list/transfers', {
    params,
    responseType: 'blob',
  });

  // Save file
  saveAs(file.data, fileName);
};

export const exportInvoicesToEdiFile = async (invoicesIds: string[]): Promise<any> => {
  const params = new URLSearchParams();
  invoicesIds.forEach((item) => {
    params.append('ids[]', item);
  });

  const fileName = 'invoices.epp';

  const file: { data: Blob } = await axios.get('/admin/invoice/list/exports/edi', {
    params,
    responseType: 'blob',
  });

  // Save file
  saveAs(file.data, fileName);
};

export const downloadZippedInvoicesFile = async (invoicesIds: string[]): Promise<any> => {
  const params = new URLSearchParams();
  invoicesIds.forEach((item) => {
    params.append('ids[]', item);
  });

  const fileName = 'invoices.zip';

  const file: { data: Blob } = await axios.get('/admin/invoice/list/download/pdf/zip', {
    params,
    responseType: 'blob',
  });

  // Save file
  saveAs(file.data, fileName);
};

export const generateInvoices = async (dateFrom: string, dateTo: string): Promise<ResponseInfo> => {
  const response = await axios.post('admin/invoice/generate', {
    date_from: dateFrom,
    date_to: dateTo,
  });

  return responseInfoFromSrc(response.data);
};
