export const getDateRangeFromToday = (
  yearsBack: number = 0,
  monthsBack?: number,
): { dateFrom: Date; dateTo: Date } => {
  const dateFrom = new Date();
  dateFrom.setMonth(dateFrom.getMonth() - (monthsBack || 0));
  dateFrom.setFullYear(dateFrom.getFullYear() - (yearsBack || 0));

  return {
    dateFrom: new Date(setDayStart(dateFrom)),
    dateTo: new Date(setDayEnd(new Date())),
  };
};

export const setDateTime = (
  date: Date,
  hours: number,
  min?: number,
  sec?: number,
  ms?: number,
): number => date.setHours(hours, min, sec, ms);

export const setDayStart = (date: Date): number => setDateTime(date, 0, 0, 0, 0);
export const setDayEnd = (date: Date): number => setDateTime(date, 23, 59, 59, 999);
