export interface XpressDeliveryCompany {
  id: number;
  name: string;
  externalId: string;
}

export interface XpressDeliveryCompanySrc {
  id: number;
  name: string;
  external_id: string;
}

export const xpressDeliveryCompanyFromSrc = (
  xpressDeliveryCompanySrc: XpressDeliveryCompanySrc,
): XpressDeliveryCompany => ({
  id: xpressDeliveryCompanySrc.id,
  name: xpressDeliveryCompanySrc.name,
  externalId: xpressDeliveryCompanySrc.external_id,
});

export const xpressDeliveryCompanyToSrc = (
  xpressDeliveryCompany: XpressDeliveryCompany,
): XpressDeliveryCompanySrc => ({
  id: xpressDeliveryCompany.id,
  name: xpressDeliveryCompany.name,
  external_id: xpressDeliveryCompany.externalId,
});
