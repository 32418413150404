import { RedirectState } from '@features/redirect/store/model';
import { PayloadAction } from '@reduxjs/toolkit';

const redirect = (state: RedirectState, action: PayloadAction<string>) => {
  state.to = action.payload;
};

const clear = (state: RedirectState) => {
  state.to = undefined;
};

const reducers = {
  redirect,
  clear,
};

export default reducers;
