import {
  timestampable,
  timestampableFromSrc,
  timestampableSrc,
  timestampableToSrc,
} from '@common/model';

export interface PhoneChangeRequest extends timestampable {
  id: number;
  status: string;
  oldValue: string;
  newValue: string;
}

export interface PhoneChangeRequestSrc extends timestampableSrc {
  id: number;
  status: string;
  old_value: string;
  new_value: string;
}

export const phoneChangeRequestFromSrc = (
  phoneChangeRequestSrc: PhoneChangeRequestSrc,
): PhoneChangeRequest => ({
  id: phoneChangeRequestSrc.id,
  status: phoneChangeRequestSrc.status,
  newValue: phoneChangeRequestSrc.new_value,
  oldValue: phoneChangeRequestSrc.old_value,
  ...timestampableFromSrc(phoneChangeRequestSrc),
});

export const phoneChangeRequestToSrc = (
  phoneChangeRequest: PhoneChangeRequest,
): PhoneChangeRequestSrc => ({
  id: phoneChangeRequest.id,
  status: phoneChangeRequest.status,
  new_value: phoneChangeRequest.newValue,
  old_value: phoneChangeRequest.oldValue,
  ...timestampableToSrc(phoneChangeRequest),
});
