// Core libraries
import React, { useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { FormProvider, useForm } from 'react-hook-form';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { useShowSnackbar } from '@features/snackbars';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

// Internal libs
import { InProgressState } from '@common/model';
import { PartnerDriverInfo } from '../../partner/model';
import { actions } from '../store';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import FormTextField from '@common/components/FormTextField';
import DataListItem from '@common/components/DataListItem';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type RemoveDriverModalProps = {
  open: boolean;
  onClose: () => void;
  driverInfo?: PartnerDriverInfo;
  companyId?: number;
};

// Component
const RemoveDriverModal = ({ open, onClose, driverInfo, companyId }: RemoveDriverModalProps) => {
  const { t } = useTranslation('partner');
  const styles = useStyles();
  const dispatch = useDispatch();
  const showSnackbar = useShowSnackbar();
  const form = useForm();
  const onSubmit = async (values: { comment?: string }) => {
    driverInfo && companyId && (await removeDriver(companyId, driverInfo.id, values.comment));
  };

  //Process remove partner
  const [removePartnerStatus, setRemovePartnerStatus] = useState<InProgressState>({
    inProgress: false,
  });
  const removeDriver = async (
    partnerCompanyId: number,
    driverId: number,
    terminationComment?: string,
  ) => {
    if (removePartnerStatus.inProgress) {
      return;
    }

    setRemovePartnerStatus({ inProgress: true });

    try {
      await dispatch(actions.removeDriverPartner(partnerCompanyId, driverId, terminationComment));
      setRemovePartnerStatus({
        inProgress: false,
        success: true,
      });
      dispatch(actions.getPartnerDrivers(partnerCompanyId));

      // Show success message
      showSnackbar({
        severity: 'success',
        message: t(`DriverJoinRequest.actions.remove.success`),
      });
      onClose();
    } catch (e) {
      setRemovePartnerStatus({
        inProgress: false,
        success: false,
        error: e,
      });

      // Show failure message
      showSnackbar({
        severity: 'error',
        message: t(
          `DriverJoinRequest.actions.remove.errors.${e.error}`,
          t(`DriverJoinRequest.actions.remove.errors.unknown`),
        ),
      });
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth="sm" disableBackdropClick>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogTitle>{t('driverRemove.title')}</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <DataListItem
                  label={t('DriverJoinRequest.fields.fullName')}
                  text={`${driverInfo?.firstName} ${driverInfo?.lastName}`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DataListItem
                  label={t('DriverJoinRequest.fields.email')}
                  text={driverInfo?.email}
                />
              </Grid>

              {driverInfo?.phone && (
                <Grid item xs={12} sm={6}>
                  <DataListItem
                    label={t('DriverJoinRequest.fields.phone')}
                    text={driverInfo?.phone}
                  />
                </Grid>
              )}
            </Grid>
            <FormTextField
              name="comment"
              label={t('driverRemove.field.comment.label')}
              fullWidth
              defaultValue={''}
              multiline
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              {t('driverRemove.cancelButton.label')}
            </Button>
            <ProgressButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={removePartnerStatus.inProgress}
              loading={removePartnerStatus.inProgress}
            >
              {t('driverRemove.removeButton.label')}
            </ProgressButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default RemoveDriverModal;
