// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';

// Internal libs
import { InProgressState } from '@common/model';
import { Comment, CommentSeverityEnum } from '@common/model/Comment';

// Internal components
import CreateCommentForm from './CreateCommentForm';
import CommentListContainer from './CommentListContainer';
import CommentsContainer from '@features/admin/components/CommentsContainer/CommentsContainer';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type CreateCommentModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  title?: string;
  comments: Comment[];
  getCommentsState: InProgressState;
  storeCommentState: InProgressState;
  storeComment: (severity: CommentSeverityEnum, body: string) => void;
};

// Component
const CreateCommentModal = ({
  title,
  comments,
  storeComment,
  isOpen,
  onClose,
  storeCommentState,
  getCommentsState,
}: CreateCommentModalProps) => {
  const { t } = useTranslation('admin');
  const styles = useStyles();

  const titleToDisplay = title || `${t(`createComment.modal.title`)}`;

  return (
    <Dialog onBackdropClick={onClose} open={isOpen} className={styles.root} fullWidth maxWidth="md">
      <DialogTitle>{titleToDisplay}</DialogTitle>
      <Divider />
      <DialogContent>
        <CommentsContainer
          comments={comments}
          getCommentsState={getCommentsState}
          storeComment={storeComment}
          createCommentState={storeCommentState}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateCommentModal;
