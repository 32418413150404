// Core libraries
import React, { useEffect } from 'react';

// External libs and components

// Internal libs
import { useMainContext } from '../MainContext';
import { DrawerMenuItemData } from '../model/DrawerMenuItemData';

// Internal components

// Props type
type MenuItemProps = {
  id: string;
} & DrawerMenuItemData;

const MenuItem = ({
  id,
  label,
  icon,
  badge,
  redirectTo,
  weight,
  disabled,
  tooltip,
}: MenuItemProps) => {
  const mainContext = useMainContext();

  useEffect(() => {
    mainContext &&
      mainContext.registerMenu(id, {
        label,
        icon,
        badge,
        redirectTo,
        weight,
        disabled,
        tooltip,
      });
  }, [
    mainContext?.registerMenu,
    mainContext?.unregisterMenu,
    id,
    label,
    icon,
    badge,
    redirectTo,
    weight,
    disabled,
    tooltip,
  ]);

  useEffect(() => () => mainContext && mainContext.unregisterMenu(id), []);

  return <></>;
};

export default MenuItem;
