import { Action, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from '../model';
import { FailureResponse } from '@common/model';

export const initInit = (state: AuthState, action: Action) => {
  state.init = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const initRestoreToken = (state: AuthState, action: PayloadAction<string>) => {
  state.token = action.payload;
};

export const initSuccess = (state: AuthState, action: Action) => {
  state.init = {
    inProgress: false,
    error: undefined,
    success: true,
  };
};
export const initFailure = (state: AuthState, action: PayloadAction<FailureResponse>) => {
  state.init = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
};

const initReducers = {
  initInit,
  initRestoreToken,
  initSuccess,
  initFailure,
};
export default initReducers;
