import { actions as simpleActions } from '../slice';
import { actions as authActions } from '@features/auth';
import { actions as userActions } from '@features/user';
import * as api from '@features/init/api';
import { RootThunk } from '@common/model';

/****** Place for complex actions *****/

export const init = (): RootThunk => async (dispatch) => {
  dispatch(simpleActions.initInit());

  // // Privacy policy
  // await dispatch(privacyPolicyActions.getPrivacyPolicyConsentFromBrowser());

  // Store token
  const authToken = localStorage.getItem('authToken');
  if (authToken) {
    // Restore token
    await dispatch(authActions.restoreToken(authToken));

    // Get current user
    await dispatch(userActions.getCurrentUser());
  }
  // Get available countries
  await dispatch(getCountries());

  dispatch(simpleActions.initSuccess());
};

export const getCountries = (): RootThunk => async (dispatch) => {
  dispatch(simpleActions.getCountriesInit());
  try {
    const response = await api.getCountries();
    dispatch(simpleActions.getCountriesSuccess(response ?? []));
  } catch (e) {
    dispatch(simpleActions.getCountriesFailure(e.response?.data ?? {}));
  }
};
