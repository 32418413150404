export interface Address {
  streetName: string;
  streetNumber: string;
  apartmentNumber?: string;
  zipCode: string;
  city: string;
  country?: string;
}

export interface AddressSrc {
  street_name: string;
  street_number: string;
  apartment_number?: string;
  zip_code: string;
  city: string;
  country?: string;
}

export const addressFromSrc = (addressSrc: AddressSrc): Address => ({
  streetName: addressSrc.street_name,
  streetNumber: addressSrc.street_number,
  apartmentNumber: addressSrc.apartment_number || undefined,
  zipCode: addressSrc.zip_code,
  city: addressSrc.city,
  country: addressSrc.country || undefined,
});

export const addressToSrc = (address: Address): AddressSrc => ({
  street_name: address.streetName,
  street_number: address.streetNumber,
  apartment_number: address.apartmentNumber || undefined,
  zip_code: address.zipCode,
  city: address.city,
  country: address.country || undefined,
});
