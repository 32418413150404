import { createSlice } from '@reduxjs/toolkit';
import * as reducers from './reducers';
import { RidesState } from '@features/admin/rides/store/model';

const ridesSlice = createSlice<RidesState, typeof reducers>({
  name: 'adminRides',
  initialState: {
    getRideCommentsState: {
      inProgress: false,
    },
    createRideCommentState: {
      inProgress: false,
    },
  },
  reducers: {
    ...reducers,
  },
});

export const reducer = ridesSlice.reducer;
export const actions = ridesSlice.actions;
