// Core libraries
import React, { useMemo, useRef, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import AppBarMenuItem from '@features/main/components/AppBarMenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LanguageIcon from '@material-ui/icons/Language';

import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@features/user';
import { actions as contractActions, selectors as contractSelectors } from '@features/contracts';
import ProgressButton from '@common/components/ProgressButton';
import ContractLanguageConfirmationModal from '@features/contracts/containers/ContractLanguageConfirmationModal';
import { User } from '@features/user/model/User';

// Internal libs

// Internal components

// Props type
type ContractLanguageSwitchProps = {
  hasContracts?: number;
};

// Component
const ContractLanguageSwitch = (props: ContractLanguageSwitchProps) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('common');

  const user = useSelector(selectors.selectUser);

  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(
    user?.contractLocale,
  );

  const setLanguageState = useSelector(selectors.selectSetContractLanguageState);

  const langs = useMemo(() => {
    return (
      (i18n.options.supportedLngs || [])
        // Remove testing language (continuous integration mode)
        .filter((lng) => lng !== 'cimode')
        // Move current language to top
        .sort((a, b) => (a === i18n.language ? -1 : b === i18n.language ? 1 : 0))
        // Add language id, name and native name (translation in referenced language)
        .map((lng) => ({
          id: lng,
          name: t(`languages.${lng}`, ''),
          nameNative: t(`languages.${lng}`, { lng, fallbackLng: [lng], defaultValue: '' }),
        }))
        // Add label (combin
        .map((lngObj) => ({
          ...lngObj,
          label:
            lngObj.name +
              (lngObj.nameNative && lngObj.nameNative !== lngObj.name
                ? ` - ${lngObj.nameNative}`
                : '') || lngObj.id,
        }))
    );
  }, [i18n, t]);

  const currentLang = useMemo(() => langs.find((langObj) => langObj.id === user?.contractLocale), [
    langs,
    user?.contractLocale,
  ]);

  const handleChange = async (lang: string) => {
    setSelectedLanguage(lang);
    if (user) {
      if (props.hasContracts) {
        setIsModalOpen(true);
      } else {
        try {
          await setLanguageHandler(lang, false);
        } catch (e) {}
      }
    }
  };

  const setLanguageHandler = async (lang: string, terminatePreviousContracts?: boolean) => {
    await dispatch(actions.setContractLanguage(lang, terminatePreviousContracts));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const anchorRef = useRef(null);
  // const [value, setValue] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <div className={styles.root}>
      <ContractLanguageConfirmationModal
        isModalOpen={isModalOpen}
        setModalOpen={setIsModalOpen}
        setContractLanguage={setLanguageHandler}
        selectedLanguage={selectedLanguage ?? 'pl'}
      />
      <ProgressButton
        ref={anchorRef}
        onClick={openMenu}
        color="inherit"
        disabled={setLanguageState?.inProgress}
        loading={setLanguageState?.inProgress}
        startIcon={<LanguageIcon />}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {currentLang?.name}
      </ProgressButton>
      <Menu
        open={isMenuOpen}
        onClose={closeMenu}
        keepMounted
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
      >
        {/*TODO: In the future when more langs exist might be better to create separate table with contract langs*/}
        {i18n.options.supportedLngs &&
          langs.map((lngObj) => (
            <AppBarMenuItem
              text={lngObj.label}
              onClick={() => handleChange(lngObj.id)}
              onClose={closeMenu}
              key={lngObj.id}
            />
          ))}
      </Menu>
    </div>
  );
};

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(2),
    },
  }),
);

export default ContractLanguageSwitch;
