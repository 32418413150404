// Core libraries
import React, { SetStateAction, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Typography from '@material-ui/core/Typography';

// Internal libs
import { numberToPhone } from '@common/validators/phoneValidator';
import { RideDetails } from '../model/RideDetails';
import {
  CorrectionTypeEnum,
  isCorrectionTypeMap,
} from '@features/admin/drivers/model/CorrectionType';

// Internal components
import SwipeableTabPanels from '@common/components/tabs/SwipeableTabPanels';
import SwipeableTabPanel from '@common/components/tabs/SwipeableTabPanel';
import DataListItem from '@common/components/DataListItem';
import DriverRideCorrectionActions from '@features/admin/drivers/conteiners/DriverRideCorrectionActions';
import ReferenceRideInfo from '@features/admin/drivers/conteiners/ReferenceRideInfo';
import RideCorrectionList from '@features/admin/drivers/conteiners/RideCorrectionList';
import RideCommentsContainer from '@features/admin/rides/containers/RideCommentsContainer';
import RideDocumentsContainer from '@features/rides/containers/RideDocumentsContainer';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    wordBreak: {
      wordBreak: 'break-all',
    },
  }),
);

// Props type
type RideDetailsViewProps = {
  rideDetails: RideDetails;
  isCorrectionButtonsVisible: boolean;
  isCommentsTabVisible: boolean;
  getPartnerRides?: () => void;
};

// Component
const RideDetailsView = ({
  rideDetails,
  isCorrectionButtonsVisible = false,
  isCommentsTabVisible = false,
  getPartnerRides,
}: RideDetailsViewProps) => {
  const { t } = useTranslation('rides');
  const styles = useStyles();
  const [tab, setTab] = useState('general');
  return (
    <div className={styles.root}>
      <TabContext value={tab}>
        <TabList
          onChange={(event, newValue) => {
            setTab(newValue);
          }}
        >
          <Tab value="general" label={t('rideDetails.tabs.general')} />
          <Tab value="driver" label={t('rideDetails.tabs.driver')} />
          <Tab value="addresses" label={t('rideDetails.tabs.addresses')} />
          <Tab
            value="correction"
            label={
              rideDetails.referenceRide || isCorrectionTypeMap[rideDetails.type]
                ? t('rideDetails.tabs.referenceCorrection')
                : t('rideDetails.tabs.corrections')
            }
          />
          <Tab value="attachments" label={t('rideDetails.tabs.attachments')} />
          <Tab
            value="comments"
            label={t('rideDetails.tabs.comments')}
            disabled={!isCommentsTabVisible}
          />
        </TabList>
        <SwipeableTabPanels
          value={tab}
          onChangeValue={setTab}
          values={['general', 'driver', 'addresses', 'correction', 'comments']}
        >
          <SwipeableTabPanel value="general">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <DataListItem label={t('model.ride.shortNo')} text={rideDetails.no} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DataListItem label={t('model.ride.externalId')} text={rideDetails.externalId} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DataListItem
                  label={t('model.ride.status.label')}
                  text={
                    rideDetails.status
                      ? t(`model.ride.status.map.${rideDetails.status}`, rideDetails.status)
                      : '-'
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <DataListItem
                  label={t('model.ride.createdAt')}
                  text={
                    rideDetails.createdAt ? new Date(rideDetails.createdAt).toLocaleString() : '-'
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DataListItem
                  label={t('model.ride.deliveredAt')}
                  text={
                    rideDetails.deliveredAt
                      ? t('common:datetime.datetime', { date: new Date(rideDetails.deliveredAt) })
                      : '-'
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DataListItem
                  label={t('model.ride.cancelledAt')}
                  text={
                    rideDetails.cancelledAt
                      ? t('common:datetime.datetime', { date: new Date(rideDetails.cancelledAt) })
                      : '-'
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <DataListItem
                  label={t('model.ride.type.shortLabel')}
                  text={t(`model.ride.type.map.${rideDetails.type}`, rideDetails.type)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DataListItem label={t('model.ride.region')} text={rideDetails.region} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DataListItem label={t('model.ride.payout')} text={rideDetails.payout} />
              </Grid>
            </Grid>
            {rideDetails.type === CorrectionTypeEnum.correction && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <DataListItem
                    label={t('model.ride.correctionReason.label')}
                    text={t(
                      `model.ride.correctionReason.map.${rideDetails.correctionReason}`,
                      rideDetails.correctionReason,
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </SwipeableTabPanel>
          <SwipeableTabPanel value="driver">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <DataListItem
                  label={t('user:user.fields.fullName')}
                  text={`${rideDetails.driver.firstName} ${rideDetails.driver.lastName}`}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DataListItem label={t('user:user.fields.email')} text={rideDetails.driver.email} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DataListItem
                  label={t('user:user.fields.phone')}
                  text={
                    rideDetails.driver.phone
                      ? numberToPhone(rideDetails.driver.phone, rideDetails.driver.phonePrefix)
                      : '-'
                  }
                />
              </Grid>
            </Grid>
          </SwipeableTabPanel>
          <SwipeableTabPanel value="addresses">
            {rideDetails.addresses.map((address) => (
              <Box key={address.id}>
                <Typography variant="subtitle1">
                  {t(
                    `model.rideAddress.type.map.${address.type}`,
                    'model.rideAddress.type.map.other',
                  )}
                </Typography>
                <Divider />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <DataListItem
                      label={t('model.rideAddress.name')}
                      text={<span className={styles.wordBreak}>{address.phone}</span>}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <DataListItem
                      label={t('model.rideAddress.phone')}
                      text={<span className={styles.wordBreak}>{address.phone}</span>}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <DataListItem
                      label={t('model.rideAddress.address')}
                      text={
                        <>
                          {address.city}
                          <br />
                          {address.street}
                        </>
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
          </SwipeableTabPanel>
          <SwipeableTabPanel value="correction">
            {rideDetails.referenceRide || isCorrectionTypeMap[rideDetails.type] ? (
              <ReferenceRideInfo rideDetails={rideDetails} />
            ) : (
              <RideCorrectionList connectedCorrection={rideDetails.corrections} />
            )}
          </SwipeableTabPanel>
        </SwipeableTabPanels>
        <SwipeableTabPanel value="attachments">
          <Grid container spacing={2}>
            <RideDocumentsContainer
              rideId={rideDetails.id}
              rideUploadedDocuments={rideDetails.uploadedDocuments ?? []}
            />
          </Grid>
        </SwipeableTabPanel>
        <SwipeableTabPanel value="comments">
          {isCommentsTabVisible && (
            <Grid container spacing={2}>
              <RideCommentsContainer rideId={rideDetails.id.toString()} />
            </Grid>
          )}
        </SwipeableTabPanel>
        {isCorrectionButtonsVisible && (
          <DriverRideCorrectionActions
            rideDetails={rideDetails}
            getPartnerRides={getPartnerRides}
          />
        )}
      </TabContext>
    </div>
  );
};

export default RideDetailsView;
