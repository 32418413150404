import { Action, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedData } from '@common/model';
import { Ride } from '@features/rides/model/Ride';
import { FailureResponse } from '@common/model';
import { RidesState } from '@features/admin/rides/store/model';
import { DriversState } from '@features/admin/drivers/store/model';

export const getRidesInit = (
  state: RidesState,
  action: PayloadAction<{
    page?: number;
    showTotalItems?: number;
    rideNo?: string;
  }>,
) => {};
export const getRidesSuccess = (
  state: RidesState,
  action: PayloadAction<PaginatedData<Ride>>,
) => {};
export const getRidesFailure = (state: RidesState, action: PayloadAction<FailureResponse>) => {};

export const createCommentInit = (state: RidesState, action: Action) => {
  state.createRideCommentState = {
    inProgress: true,
  };
};
export const createCommentSuccess = (state: RidesState, action: PayloadAction<void>) => {
  state.createRideCommentState = {
    inProgress: false,
    success: true,
  };
};
export const createCommentFailure = (state: RidesState, action: PayloadAction<FailureResponse>) => {
  state.createRideCommentState = {
    inProgress: false,
  };
};

export const downloadRideDocumentInit = (state: RidesState, action: Action) => {};
export const downloadRideDocumentSuccess = (state: RidesState, action: PayloadAction<void>) => {};
export const downloadRideDocumentFailure = (
  state: RidesState,
  action: PayloadAction<FailureResponse>,
) => {};

export const getCommentsInit = (state: RidesState, action: Action) => {
  state.getRideCommentsState = {
    inProgress: true,
  };
};
export const getCommentsSuccess = (state: RidesState, action: PayloadAction<void>) => {
  state.getRideCommentsState = {
    inProgress: false,
    success: true,
  };
};
export const getCommentsFailure = (state: RidesState, action: PayloadAction<FailureResponse>) => {
  state.getRideCommentsState = {
    inProgress: false,
  };
};
