// Core libraries
import React, { useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

// Internal libs
import { InvoicesFilterData } from '@features/company/containers/invoices/InvoicesFilter';
import { getDateRangeFromToday } from '@common/tools/dateUtils';
import config from '@app/config';

// Internal components
import AdminInvoicesList from '@features/admin/invoices/conteiners/AdminInvoicesList';
import AdminInvoicesGenerator from '@features/admin/invoices/conteiners/AdminInvoicesGenerator';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    topCard: {
      marginBottom: theme.spacing(2),
    },
  }),
);

// Props type
type InvoicesCardProps = {};

// Component
const AdminInvoicesCard = ({}: InvoicesCardProps) => {
  const styles = useStyles();
  const dateRange = getDateRangeFromToday(0, config.defaultReportDateRangeMonths);
  const [filter, setFilter] = useState<InvoicesFilterData>({
    dateFrom: dateRange.dateFrom,
    dateTo: dateRange.dateTo,
  });

  return (
    <>
      <Card className={styles.topCard}>
        <AdminInvoicesGenerator
          dateRange={{
            dateFrom: filter?.dateFrom || new Date(),
            dateTo: filter?.dateTo || new Date(),
          }}
        />
      </Card>
      <Card>
        <AdminInvoicesList filter={filter} setFilter={setFilter} />
      </Card>
    </>
  );
};

export default AdminInvoicesCard;
