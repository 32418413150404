import { useMemo } from 'react';

export const nipMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const regonValidator = (message?: string) => (value: string) =>
  (checkPattern(value) && checkChecksum(value)) || message || false;

const checkPattern = (regon: string): boolean => /^\d{9}$/.test(regon);

const checkChecksum = (regon: string): boolean => {
  const weights = [8, 9, 2, 3, 4, 5, 6, 7];

  // Multiply each digit by corresponding weight and add
  const sum = weights.reduce((sum, weight, idx) => sum + weight * Number(regon[idx]), 0);

  // Calc checksum...
  const checksum = sum % 11;

  // ...and compare it with the last digit.
  return (checksum === 10 ? 0 : checksum) === Number(regon[8]);
};

const useRegonValidator = (message?: string) => {
  return useMemo(() => regonValidator(message), [message]);
};
export default useRegonValidator;
