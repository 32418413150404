// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    header: {
      backgroundColor: '#eee',
      marginTop: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(-2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
    },
    titleIcon: {
      padding: theme.spacing(2),
      display: 'flex',
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    actions: {
      flexShrink: 0,
    },
  }),
);

// Props type
type PageDefaultLayoutProps = {
  children: React.ReactNode;
  title?: string;
  actions?: React.ReactNode;
};

// Component
const PageDefaultLayout = ({ children, title, actions, ...other }: PageDefaultLayoutProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root} {...other}>
      {(!!title || !!actions) && (
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            {/*<Paper className={styles.titleIcon} elevation={12}>*/}
            {/*  <HomeIcon />*/}
            {/*</Paper>*/}
            <div className={styles.title}>
              <h1>{title}</h1>
            </div>
          </div>
          {!!actions && <div className={styles.actions}>{actions}</div>}
        </div>
      )}
      {children}
    </div>
  );
};

export default PageDefaultLayout;
