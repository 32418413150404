import { createSlice } from '@reduxjs/toolkit';
import * as reducers from './reducers';
import { AdminInvoicesState } from '@features/admin/invoices/store/model';

const slice = createSlice<AdminInvoicesState, typeof reducers>({
  name: 'invoices',
  initialState: {
    getInvoicesState: undefined,
    invoices: undefined,
  },
  reducers: {
    ...reducers,
  },
});

export const invoiceReducer = slice.reducer;
export const invoiceActions = slice.actions;
