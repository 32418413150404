// Core libraries
import React, { useContext } from 'react';
import { IS_AUTHENTICATED_ROLE, Role, RolesMap } from '@features/user/model/Role';

/*** RolesMapContext ***/
const RolesMapContext = React.createContext<RolesMap>({});

/*** Provider Component ***/
// Props type
type RolesProviderProps = { map: RolesMap; children: React.ReactNode };

const mapToUpper = (map: RolesMap) =>
  Object.entries(map).reduce((newMap: RolesMap, [key, value]) => {
    newMap[key.toUpperCase()] = value.map((role) => role.toUpperCase());
    return newMap;
  }, {});

// Component
const RolesProvider = ({ map, children }: RolesProviderProps) => {
  return <RolesMapContext.Provider value={mapToUpper(map)}>{children}</RolesMapContext.Provider>;
};

export default RolesProvider;

/*** Roles resolver hook ***/
// Recursive roles resolver
const resolveRolesRec = (roles: Role[], idx: number, rolesMap: RolesMap): Role[] => {
  idx = idx || 0;

  if (idx >= roles.length) {
    return roles;
  }

  const role = roles[idx];
  if (rolesMap.hasOwnProperty(role)) {
    rolesMap[role].forEach((newRole: Role) => {
      if (roles.indexOf(newRole) === -1) {
        roles.push(newRole);
      }
    });
  }

  resolveRolesRec(roles, ++idx, rolesMap);

  return roles;
};

// Roles resolver hook.
export const useResolveRoles = () => {
  const rolesMap = useContext(RolesMapContext);

  return (roles: Role[]): Role[] =>
    resolveRolesRec(
      roles.map((r) => r.toUpperCase()),
      0,
      rolesMap,
    );
};
