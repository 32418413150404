import { CompanyDocumentType } from '@features/user/model/CompanyDocumentType';
import {
  timestampable,
  timestampableFromSrc,
  timestampableSrc,
  timestampableToSrc,
} from '@common/model';

export type CompanyDocumentStatus = 'pending' | string;

export interface CompanyDocument extends timestampable {
  id: number;
  documentType: CompanyDocumentType;
  status: CompanyDocumentStatus;
  downloadLink: string;
  originalFileName: string;
  fileName: string;
}

export interface CompanyDocumentSrc extends timestampableSrc {
  id: number;
  document_type: CompanyDocumentType;
  status: CompanyDocumentStatus;
  download_link: string;
  original_file_name: string;
  file_name: string;
}

export const companyDocumentToSrc = (companyDocument: CompanyDocument): CompanyDocumentSrc => ({
  id: companyDocument.id,
  document_type: companyDocument.documentType,
  status: companyDocument.status,
  download_link: companyDocument.downloadLink,
  original_file_name: companyDocument.originalFileName,
  file_name: companyDocument.fileName,
  ...timestampableToSrc(companyDocument),
});

export const companyDocumentFromSrc = (
  companyDocumentSrc: CompanyDocumentSrc,
): CompanyDocument => ({
  id: companyDocumentSrc.id,
  documentType: companyDocumentSrc.document_type,
  status: companyDocumentSrc.status,
  downloadLink: companyDocumentSrc.download_link,
  originalFileName: companyDocumentSrc.original_file_name,
  fileName: companyDocumentSrc.file_name,
  ...timestampableFromSrc(companyDocumentSrc),
});
