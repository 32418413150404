// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';

// Internal libs
import AdminIcon from '@material-ui/icons/Settings';
import InvoiceIcon from '@material-ui/icons/Receipt';

// Internal components
import PackageVariantClosedIcon from '@common/components/icons/PackageVariantClosed';
import PackageVariantOpenIcon from '@common/components/icons/PackageVariantOpen';
import People from '@common/components/icons/People';
import { MenuItem } from '@features/main';

type MenuProps = { basePath?: string };

// Component
const Menu = ({ basePath = '' }: MenuProps) => {
  const { t } = useTranslation();

  return (
    <>
      <MenuItem id="admin" label={t('admin:menu')} icon={<AdminIcon />} />
      <MenuItem
        id="admin.orders"
        label={t('admin:orders.menu')}
        redirectTo={`${basePath}/rides`}
        icon={<PackageVariantClosedIcon />}
      />
      <MenuItem
        id="admin.corrections"
        label={t('admin:corrections.menu')}
        redirectTo={`${basePath}/corrections`}
        icon={<PackageVariantOpenIcon />}
      />
      <MenuItem
        id="admin.drivers"
        label={t('admin:drivers.menu')}
        redirectTo={`${basePath}/drivers`}
        icon={<People />}
      />
      <MenuItem
        id="admin.invoices"
        label={t('admin:invoices.menu')}
        redirectTo={`${basePath}/invoices`}
        icon={<InvoiceIcon />}
      />
    </>
  );
};

export default Menu;
