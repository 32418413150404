import axios from 'axios';
import { Country, countryFromSrc, CountrySrc } from '@common/model/Country';

export const getCountries = async (): Promise<Country[]> => {
  const countries = await axios.get('/country/list');
  return countries.data.map((country: CountrySrc) => countryFromSrc(country));
};

// external

export const getUserCountry = async (): Promise<any> => {
  return await axios.get('https://api.country.is');
};
