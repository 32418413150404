import axios from 'axios';
import { PaginatedData, paginatedDataFromSrc } from '@common/model';
import { Ride, rideFromSrc, RideSrc } from '../model/Ride';
import { RideDetails, rideDetailsFromSrc } from '../model/RideDetails';

export const getRides = async (
  page?: number,
  showTotalItems?: number,
): Promise<PaginatedData<Ride>> => {
  const response = await axios.get('/ride/list', {
    params: { page, show_total_items: showTotalItems },
  });

  return paginatedDataFromSrc<RideSrc, Ride>(response.data, rideFromSrc);
};

export const getRideDetails = async (rideId: number): Promise<RideDetails> => {
  const response = await axios.get(`/ride/${rideId}`);

  return rideDetailsFromSrc(response.data);
};
