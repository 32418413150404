// Core libraries
import React, { useEffect } from 'react';

// External libs and components
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// Internal libs
import useQuery from '@common/miscHooks/useQuery';
import { actions } from '@features/auth/store';

// Internal components
import { ReactComponent as LogoDark } from '@assets/xd_logo.dark.svg';
import { ReactComponent as Logo } from '@assets/xd_logo.svg';
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormErrors from '@common/components/FormErrors';
import { Button } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  label: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  progress: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  goBack: {
    marginTop: theme.spacing(2),
  },
}));

const LoginSocial = () => {
  const { t } = useTranslation('auth');
  const theme = useTheme();
  const params: { socialType: string } = useParams();
  const query = useQuery();
  const dispatch = useDispatch();
  const styles = useStyle();

  const loginState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.auth.login,
  );

  const { formErrors } = useResponseErrors(loginState?.error, 'login', 'auth');

  useEffect(() => {
    dispatch(actions.loginClearState());
    dispatch(actions.loginSocialConfirmToken(params.socialType, query.toString()));
  }, []);

  return (
    <>
      {/* Logo */}
      <div className={styles.logoContainer}>
        {theme.palette.type === 'dark' ? <LogoDark width="200" /> : <Logo width="200px" />}
      </div>

      {/* "Login with" */}
      <div className={styles.label}>
        {t('login.messages.loginWith', '')}: <strong>{params.socialType}</strong>
      </div>

      {loginState?.inProgress && (
        <div className={styles.progress}>
          <CircularProgress />
        </div>
      )}

      {/* Errors */}
      <FormErrors errorMessages={formErrors} />

      {/* Go to Login */}
      {loginState?.error && (
        <Button
          variant="contained"
          component={RouterLink}
          to="/login"
          fullWidth={true}
          color="primary"
          className={styles.goBack}
        >
          {t('socialLogin.goToLoginButton.caption', '')}
        </Button>
      )}
    </>
  );
};

export default LoginSocial;
