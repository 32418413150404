import { createSlice } from '@reduxjs/toolkit';
import { UserState } from './model';
import reducers from './reducers';

const userSlice = createSlice<UserState, typeof reducers>({
  name: 'user',
  initialState: {
    getUserState: undefined,
    user: undefined,
    updateUserDataState: undefined,
  },
  reducers: {
    ...reducers,
  },
});

export const reducer = userSlice.reducer;
export const actions = userSlice.actions;
