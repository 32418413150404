// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';

// Internal libs

// Internal components
import PageDefaultLayout from '@common/components/layouts/PageDefaultLayout';
import Invoices from './Invoices';

// Props type
type InvoicesPageProps = {};

// Component
const InvoicesPage = ({}: InvoicesPageProps) => {
  const { t } = useTranslation('company');

  return (
    <PageDefaultLayout title={t('invoices.title')}>
      <Invoices />
    </PageDefaultLayout>
  );
};

export default InvoicesPage;
