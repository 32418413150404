import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { UserState } from '../model';
import { Region } from '../../model/Region';
import { Nationality } from '@features/user/model/Nationality';

export const getNationalitiesInit = (state: UserState, action: Action) => {
  state.getNationalitiesState = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const getNationalitiesSuccess = (state: UserState, action: PayloadAction<Nationality[]>) => {
  state.getNationalitiesState = {
    inProgress: false,
    error: undefined,
    success: true,
  };
  state.nationalities = action.payload;
};
export const getNationalitiesFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {
  state.getNationalitiesState = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
  state.nationalities = undefined;
};

const nationalitiesReducers = {
  getNationalitiesInit,
  getNationalitiesFailure,
  getNationalitiesSuccess,
};
export default nationalitiesReducers;
