// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import { User } from '../../model/User';
import { selectors as userSelectors, actions as userActions } from '../../store';

// Internal components
import DataListItem from '@common/components/DataListItem';
import ProgressButton from '@common/components/ProgressButton';
import FormErrors from '@common/components/FormErrors';
import JoinPartnerModal from './JoinPartnerModal';

// Props type
type PersonCardElementProps = {};

// Component
const PersonCardElement = ({}: PersonCardElementProps) => {
  const { t } = useTranslation('user');
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const missingPersonalData = useSelector(userSelectors.selectUserMissingPersonalData);
  const missingPartnerOrRequest = useSelector(userSelectors.selectUserMissingPartnerOrRequest);

  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  const [cancelState, setCancelState] = useState<InProgressState>({ inProgress: false });
  const cancelPartnerRequest = async () => {
    if (!userData?.partnerRequest) {
      return;
    }

    setCancelState({ inProgress: true });

    try {
      await dispatch(userActions.cancelPartnerRequest(userData.partnerRequest.id));
      setCancelState({ inProgress: false, success: true });
    } catch (e) {
      setCancelState({ inProgress: false, success: false, error: e });
    }
  };

  const { formErrors, getFieldErrors } = useResponseErrors(
    cancelState?.error,
    'settlements.cancelPartnerRequest',
    'user',
  );

  return (
    <>
      {missingPersonalData && (
        <Alert severity="warning" data-testid="missing-company-alert">
          {t('cards.settlementsData.person.missingPersonalData.message')}
        </Alert>
      )}

      {!missingPersonalData && missingPartnerOrRequest && (
        <Alert
          severity="warning"
          data-testid="missing-partner-or-request-alert"
          action={
            <Button
              size="small"
              color="inherit"
              onClick={() => history.push(`${path}/join-partner`)}
            >
              {t('cards.settlementsData.person.missingPartner.chooseButton')}
            </Button>
          }
        >
          {t('cards.settlementsData.person.missingPartner.message')}
        </Alert>
      )}

      {userData?.partnerRequest && (
        <Alert
          severity="info"
          data-testid="partner-request-alert"
          action={
            <ProgressButton
              size="small"
              color="inherit"
              onClick={cancelPartnerRequest}
              loading={cancelState.inProgress}
              disabled={cancelState.inProgress}
            >
              {t('cards.settlementsData.person.partnerRequest.cancelButton')}
            </ProgressButton>
          }
        >
          {t('cards.settlementsData.person.partnerRequest.message')}
          <br />
          <strong>{userData.partnerRequest.company.name}</strong> (
          {t(
            `company.fields.taxIdentificationNumber.${
              userData.partnerRequest.company.countryDefinition?.taxIdentificationNumberName ??
              'generic'
            }`,
          )}
          : {userData.partnerRequest.company.taxIdentificationNumber})
          <FormErrors errorMessages={formErrors} />
        </Alert>
      )}

      {userData?.partner && (
        <List>
          <DataListItem label={t('company.fields.name')} text={userData.partner.name} />
          <DataListItem
            label={t(
              `company.fields.taxIdentificationNumber.${
                userData.partner.countryDefinition?.taxIdentificationNumberName ?? 'generic'
              }`,
            )}
            text={userData.partner.taxIdentificationNumber}
          />
          <DataListItem
            label={t(
              `company.fields.registrationNumber.${
                userData.partner.countryDefinition?.registrationNumberName ?? 'generic'
              }`,
            )}
            text={userData.partner.taxIdentificationNumber}
          />
        </List>
      )}

      <Switch>
        <Route path={`${path}/join-partner`} component={JoinPartnerModal} />
      </Switch>
    </>
  );
};

export default PersonCardElement;
