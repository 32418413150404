import { SnackbarData, SnackbarProps } from './model';
import React, { useContext } from 'react';

export class Snackbars {
  counter = 0;
  snackbars: SnackbarData[] = [];

  constructor(private updateSnackbars: (snackbars: SnackbarData[]) => void) {
    this.showSnackbar = this.showSnackbar.bind(this);
    this.hideSnackbar = this.hideSnackbar.bind(this);
  }

  showSnackbar(props: SnackbarProps) {
    this.snackbars.push({ props, key: this.counter++, open: true });
    this.updateSnackbars([...this.snackbars]);
  }

  hideSnackbar(key: number) {
    const index = this.snackbars.findIndex((snackbar) => snackbar.key === key);
    if (~index) {
      this.snackbars[index].open = false;
      this.updateSnackbars([...this.snackbars]);
    }
  }

  removeSnackbar(key: number) {
    const index = this.snackbars.findIndex((snackbar) => snackbar.key === key);
    if (~index) {
      this.snackbars.splice(index, 1);
      this.updateSnackbars([...this.snackbars]);
    }
  }
}

const SnackbarsContext = React.createContext<Snackbars | null>(null);

export default SnackbarsContext;
