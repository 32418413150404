import { actions as simpleActions } from '../slice';
import { VehiclesThunk } from '../model';
import * as api from '../../api';
import { FuelType, VehicleSrc, VehicleType } from '@features/vehicles/model/Vehicle';

/****** Place for complex actions *****/

// Vehicle list Action
export const getVehiclesList = (): VehiclesThunk => async (dispatch) => {
  dispatch(simpleActions.getVehicleListInit());

  try {
    const vehicles = await api.getVehiclesList();
    dispatch(simpleActions.getVehiclesListSuccess(vehicles));
  } catch (e) {
    dispatch(simpleActions.getVehiclesListFailure(e.response?.data || {}));
  }
};
