// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Internal libs
import { actions } from '@features/admin';

// Internal components
import ProgressIconButton from '@common/components/ProgressIconButton';
import { InProgressState } from '@common/model';
import { ContractNameType, DriverContract } from '@features/admin/drivers/model/DriverContracts';
import { Contract } from '@features/contracts/model/Contract';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type DownloadDriverContractProps = {
  children: React.ReactNode;
  contract: DriverContract;
  title: string;
};

// Component
const DownloadDriverContract = ({ children, title, contract }: DownloadDriverContractProps) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [downloadState, setDownloadState] = useState<InProgressState>({
    inProgress: false,
  });
  const download = async () => {
    setDownloadState({ inProgress: true });
    try {
      if (contract.downloadLink) {
        await dispatch(
          actions.downloadDriverContract(
            contract.downloadLink,
            contract.fileExtension,
            contract.name,
          ),
        );
      } else {
      }

      setDownloadState({ inProgress: false, success: true });
    } catch (error) {
      setDownloadState({ inProgress: false, success: false, error });
    }
  };

  return (
    <ProgressIconButton
      onClick={download}
      loading={downloadState.inProgress}
      size={'small'}
      title={title}
      color="primary"
    >
      {children}
    </ProgressIconButton>
  );
};

export default DownloadDriverContract;
