import { PayloadAction } from '@reduxjs/toolkit';
import { PaginatedData } from '@common/model';
import { Ride } from '@features/rides/model/Ride';
import { FailureResponse } from '@common/model';
import { DriversState } from '@features/admin/drivers/store/model';

export const getDriverRidesInit = (
  state: DriversState,
  action: PayloadAction<{
    partnerId: number;
    page?: number;
    showTotalItems?: number;
  }>,
) => {};
export const getDriverRidesSuccess = (
  state: DriversState,
  action: PayloadAction<PaginatedData<Ride>>,
) => {};
export const getDriverRidesFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {};
