import { RootState } from '@app/store';

export const selectGetXpressDeliveryCompaniesState = (state: RootState) =>
  state.admin.drivers.getXpressDeliveryCompaniesState;

export const selectXpressDeliveryCompanies = (state: RootState) =>
  state.admin.drivers.xpressDeliveryCompanies;

export const selectGetXpressDeliveryCompaniesInProgress = (state: RootState) =>
  state.admin.drivers.getXpressDeliveryCompaniesState?.inProgress;
