import { useMemo } from 'react';

export const nipMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const nipValidator = (message?: string) => (value: string) =>
  (checkPattern(value) && checkChecksum(value)) || message || false;

const checkPattern = (nip: string): boolean => /^\d{10}$/.test(nip);

const checkChecksum = (nip: string): boolean => {
  const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];

  // Multiply each digit by corresponding weight and add
  const sum = weights.reduce((sum, weight, idx) => sum + weight * Number(nip[idx]), 0);

  // Calc checksum...
  const checksum = sum % 11;

  // ...and compare it with the last digit.
  return checksum === Number(nip[9]);
};

const useNipValidator = (message?: string) => {
  return useMemo(() => nipValidator(message), [message]);
};
export default useNipValidator;
