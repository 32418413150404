// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Internal libs

// Internal components
import DataListItem from '@common/components/DataListItem';
import { Correction } from '@features/admin/corrections/model/Correction';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type CorrectionDetailsViewProps = {
  correctionDetails?: Correction;
};

// Component
const CorrectionDetailsView = ({ correctionDetails }: CorrectionDetailsViewProps) => {
  const { t } = useTranslation(['rides', 'admin']);
  const styles = useStyles();
  const clientsAddress = useMemo(
    () => correctionDetails?.ride?.addresses?.find((address) => address.type === 'client'),
    [correctionDetails],
  );

  return (
    <>
      {correctionDetails && (
        <div className={styles.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <DataListItem
                label={t('admin:corrections.detailsView.rideNo')}
                text={correctionDetails.rideNo || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DataListItem
                label={t('admin:corrections.detailsView.extraDistance')}
                text={correctionDetails.extraDistance}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DataListItem
                label={t('admin:corrections.detailsView.comment')}
                text={correctionDetails.comment || ''}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <DataListItem
                label={t('admin:corrections.detailsView.clientsAddress')}
                text={correctionDetails.clientsAddress}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DataListItem
                label={t('admin:corrections.detailsView.clientsCity')}
                text={correctionDetails.clientsCity}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DataListItem
                label={t('admin:corrections.detailsView.clientsZipCode')}
                text={correctionDetails.clientsZipCode}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <DataListItem
                label={t('admin:corrections.detailsView.realDistance')}
                text={correctionDetails.realDistance}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <DataListItem
                label={t('admin:corrections.detailsView.realDuration')}
                text={correctionDetails.realDuration}
              />
            </Grid>
          </Grid>
          {correctionDetails.ride && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <DataListItem
                  label={t('admin:corrections.detailsView.clientsStreetInRide')}
                  text={clientsAddress?.street}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DataListItem
                  label={t('admin:corrections.detailsView.clientsCityInRide')}
                  text={clientsAddress?.city}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DataListItem
                  label={t('admin:corrections.detailsView.payout')}
                  text={correctionDetails.ride?.payout}
                />
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

export default CorrectionDetailsView;
