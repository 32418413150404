// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

// Internal libs
import config from '@app/config';
import useNipValidator, { nipMask } from '@common/validators/nipValidator';
import useIcoValidator, { icoMask } from '@common/validators/company/icoValidator';
import useDicValidator, { dicMask } from '@common/validators/company/dicValidator';
import useRegonValidator from '@common/validators/regonValidator';
import useIbanValidator from '@common/validators/ibanValidator';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import { FailureResponse } from '@common/model';
import { User } from '../../model/User';

// Internal components
import FormTextField from '@common/components/FormTextField';
import FormAddressGrid from '@common/components/FormAddressGrid';
import FormCheckboxField from '@common/components/FormCheckboxField';
import PartnerDriversAbandonmentConfirmation from '@features/partner/sharedComponents/PartnerDriversAbandonmentConfirmation';
import FormSelectField from '@common/components/FormSelectField';

interface CountryDefinition {
  label: string;
  value: string;
  hideVatPayer?: boolean;
  fields: {
    taxIdentificationNumber: {
      mask?: RegExp[];
      rules?: object;
      label: string;
    };
    registrationNumber: {
      mask?: RegExp[];
      rules?: object;
      label: string;
    };
  };
}

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    field: {
      marginBottom: theme.spacing(2),
    },
  }),
);

// Props type
type UpsertCompanyFieldsProps = { user: User; errors?: FailureResponse };

// Component
const UpsertCompanyFields = ({ user, errors }: UpsertCompanyFieldsProps) => {
  const { t } = useTranslation(['user', 'common']);
  const styles = useStyles();

  const { getFieldErrors } = useResponseErrors(errors, 'settlements.upsertCompany', 'user');

  const form = useFormContext();

  const isPartner = form.watch('isPartner', user?.company?.isPartner || false);

  // FIXME: When more countries are introduced refactor this so we don't have to initialize each separately
  const nipValidator = useNipValidator(t('settlements.upsertCompany.fields.nip.errors.nip'));
  const regonValidator = useRegonValidator(
    t('settlements.upsertCompany.fields.regon.errors.regon'),
  );
  const icoValidator = useIcoValidator(t('settlements.upsertCompany.fields.ico.errors.ico'));
  const dicValidator = useDicValidator(t('settlements.upsertCompany.fields.dic.errors.dic'));
  const validators = useMemo(
    () => ({
      nipValidator: nipValidator,
      regonValidator: regonValidator,
      dicValidator: dicValidator,
      icoValidator: icoValidator,
    }),
    [nipValidator, regonValidator, icoValidator, dicValidator],
  );
  // TODO: Pull this data from backend when more origins are added, it is already defined there
  const registrationCountries: CountryDefinition[] = useMemo(
    () => [
      {
        label: t(`common:countries.pl`),
        value: 'pl',
        fields: {
          taxIdentificationNumber: {
            label: t('company.fields.taxIdentificationNumber.nip'),
            mask: nipMask,
            rules: {
              required: {
                value: true,
                message: t('settlements.upsertCompany.fields.nip.errors.required'),
              },
              validate: {
                nip: validators.nipValidator,
              },
            },
          },
          registrationNumber: {
            label: t('company.fields.registrationNumber.regon'),
            mask: undefined,
            rules: {
              required: {
                value: true,
                message: t('settlements.upsertCompany.fields.regon.errors.required'),
              },
              validate: {
                regon: validators.regonValidator,
              },
            },
          },
        },
      },
      {
        label: t(`common:countries.cz`),
        value: 'cz',
        hideVatPayer: true,
        fields: {
          taxIdentificationNumber: {
            label: t('company.fields.taxIdentificationNumber.dic'),
            mask: dicMask,
            rules: {
              required: {
                value: true,
                message: t('settlements.upsertCompany.fields.dic.errors.required', ''),
              },
              validate: {
                ico: validators.dicValidator,
              },
            },
          },
          registrationNumber: {
            label: t('company.fields.registrationNumber.ico'),
            mask: icoMask,
            rules: {
              required: {
                value: true,
                message: t('settlements.upsertCompany.fields.ico.errors.required', ''),
              },
              validate: {
                dic: validators.icoValidator,
              },
            },
          },
        },
      },
    ],
    [t, validators],
  );
  const selectedRegistrationCountryCode = form.watch('registrationCountryCode');
  // @ts-ignore
  const countryDefinition: CountryDefinition = useMemo(() => {
    return registrationCountries.find(
      (country) =>
        country.value ===
        (selectedRegistrationCountryCode || user?.company?.registrationCountryCode || 'pl'),
    );
  }, [user, registrationCountries, selectedRegistrationCountryCode]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormSelectField
            className={styles.field}
            name="registrationCountryCode"
            label={t('user:company.fields.registrationCountry', '')}
            options={registrationCountries.map((country) => ({
              label: country.label,
              value: country.value,
            }))}
            disabled={!!user?.company}
            rules={{
              required: {
                value: true,
                message: t(
                  'settlements.upsertCompany.fields.registrationCountry.errors.required',
                  '',
                ),
              },
            }}
            fullWidth={true}
            errors={getFieldErrors('registrationCountryCode', 'registration_country_code')}
            defaultValue={
              user?.company?.registrationCountryCode || registrationCountries[0].value || ''
            }
          />
        </Grid>
        {/* Name */}
        <Grid item xs={12} sm={12}>
          <FormTextField
            className={styles.field}
            name="name"
            label={t('company.fields.name')}
            rules={{
              required: {
                value: true,
                message: t('settlements.upsertCompany.fields.name.errors.required'),
              },
            }}
            fullWidth={true}
            errors={getFieldErrors('name')}
            autoFocus
            defaultValue={user?.company?.name || ''}
          />
        </Grid>

        {/* Nip */}
        <Grid item xs={12} sm={6}>
          <FormTextField
            className={styles.field}
            name="taxIdentificationNumber"
            label={countryDefinition.fields.taxIdentificationNumber.label}
            mask={countryDefinition.fields.taxIdentificationNumber.mask}
            rules={countryDefinition.fields.taxIdentificationNumber.rules}
            fullWidth={true}
            disabled={!!user?.company}
            errors={getFieldErrors('taxIdentificationNumber')}
            defaultValue={user?.company?.taxIdentificationNumber || ''}
          />
        </Grid>

        {/* Regon */}
        <Grid item xs={12} sm={6}>
          <FormTextField
            className={styles.field}
            name="registrationNumber"
            label={countryDefinition.fields.registrationNumber.label}
            mask={countryDefinition.fields.registrationNumber.mask}
            rules={countryDefinition.fields.registrationNumber.rules}
            fullWidth={true}
            disabled={!!user?.company}
            errors={getFieldErrors('registrationNumber')}
            defaultValue={user?.company?.registrationNumber || ''}
          />
        </Grid>
      </Grid>

      {/* Address */}
      <FormAddressGrid getFieldErrors={getFieldErrors} address={user?.company?.address} />

      <Grid container spacing={2}>
        {/* bank account no */}
        <Grid item xs={12} sm={12}>
          <FormTextField
            className={styles.field}
            name="bankAccountNo"
            label={t('company.fields.bankAccountNo')}
            placeholder="PL 12 3000 4000 5000 6000 7000 8000"
            rules={{
              required: {
                value: true,
                message: t('settlements.upsertCompany.fields.bankAccountNo.errors.required'),
              },
              validate: {
                iban: useIbanValidator(
                  t('settlements.upsertCompany.fields.bankAccountNo.errors.iban'),
                  config.defaultIBANCountry,
                ),
              },
            }}
            fullWidth
            errors={getFieldErrors('bankAccountNo', 'bank_account_number')}
            defaultValue={user?.company?.bankAccountNo || ''}
          />
        </Grid>

        {/* Is VAT payer */}
        {!countryDefinition.hideVatPayer && (
          <Grid item xs={12} sm={12}>
            <FormCheckboxField
              data-testid="upsert-company-is-vat-payer"
              className={styles.field}
              name="isVatPayer"
              label={t('settlements.upsertCompany.fields.isVatPayer.label')}
              rules={{}}
              fullWidth
              errors={getFieldErrors('isVatPayer', 'is_vat_payer')}
              defaultValue={user?.company?.isVatPayer || false}
            />
          </Grid>
        )}
        {/* Is partner */}
        <Grid item xs={12} sm={12}>
          <FormCheckboxField
            data-testid="upsert-company-is-partner"
            className={styles.field}
            name="isPartner"
            label={t('settlements.upsertCompany.fields.isPartner.label')}
            rules={{}}
            fullWidth
            errors={getFieldErrors('isPartner', 'is_partner')}
            defaultValue={user?.company?.isPartner || false}
          />
        </Grid>
      </Grid>

      {user?.company?.isPartner && !isPartner && <PartnerDriversAbandonmentConfirmation />}
    </>
  );
};

export default UpsertCompanyFields;
