// Core libraries
import React, { useCallback, useEffect, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router';
import Card from '@material-ui/core/Card';
import LinearProgress from '@material-ui/core/LinearProgress';
// @ts-ignore
import { debounce } from 'lodash';
// Internal libs
import { InProgressState, PaginatedData } from '@common/model';
import { actions } from '../store';
import { Ride } from '@features/rides/model';

// Internal components
import RideModal from '@features/rides/containers/RideModal';
import DriverRideCorrectionModal from '@features/admin/drivers/conteiners/DriverRideCorrectionModal';
import DriverRidesList from '@features/admin/drivers/conteiners/driverRides/DriverRidesList';
import DriverRidesTopButtons from '@features/admin/drivers/conteiners/driverRides/DriverRidesTopButtons';
import PageDefaultLayout from '@common/components/layouts/PageDefaultLayout';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type DriverRidesProps = {};

// Component
const RidesPage = () => {
  const { t } = useTranslation(['rides', 'admin']);
  const styles = useStyles();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const [getRidesState, setGetRidesState] = useState<InProgressState>({
    inProgress: false,
  });
  const [paginatedRides, setPaginatedRides] = useState<PaginatedData<Ride> | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [showTotalItems, setShowTotalItems] = useState<number | undefined>(undefined);
  const [rideNo, setRideNo] = useState<string | undefined>(undefined);

  const getRides = useCallback(async () => {
    setGetRidesState({ inProgress: true });
    try {
      const paginatedRides: PaginatedData<Ride> = ((await dispatch(
        actions.getRides(page, showTotalItems, rideNo),
      )) as unknown) as PaginatedData<Ride>;
      setPaginatedRides(paginatedRides);
      setGetRidesState({ inProgress: false, success: true });
    } catch (e) {
      setGetRidesState({ inProgress: false, success: false, error: e });
    }
  }, [dispatch, page, showTotalItems, rideNo]);

  // Init
  useEffect(() => {
    getRides();
  }, [page, showTotalItems, rideNo]);

  const rideUrl = (rideId: number) => `${path}/${rideId}`;
  return (
    <>
      <PageDefaultLayout title={t('admin:rides.title')}>
        <Card>
          {getRidesState?.inProgress && <LinearProgress data-testid="loading-rides" />}
          <DriverRidesList
            paginatedRides={paginatedRides}
            setPage={setPage}
            setShowTotalItems={setShowTotalItems}
            searchRideNo={setRideNo}
            rideUrl={rideUrl}
          />
        </Card>
        <Switch>
          <Route path={`${path}/:rideId`}>
            <RideModal
              onClose={() => history.goBack()}
              isCorrectionButtonsVisible={false}
              getPartnerRides={getRides}
              isCommentsTabVisible={true}
            />
          </Route>
        </Switch>
      </PageDefaultLayout>
    </>
  );
};

export default RidesPage;
