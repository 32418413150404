import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse, PaginatedData } from '@common/model';
import { Driver } from '@common/model/Driver';
import { DriversState, GetDriversFilters } from '@features/admin/drivers/store/model';

export const getDriversListInit = (state: DriversState, action: Action) => {
  state.getDriversState = {
    inProgress: true,
  };
};
export const getDriversListSuccess = (state: DriversState, action: PayloadAction<Driver[]>) => {
  state.getDriversState = {
    inProgress: false,
    success: true,
  };
  state.driversList = action.payload;
};
export const getDriversListFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {
  state.getDriversState = {
    inProgress: true,
  };
  state.driversList = undefined;
};

export const getDriversListPaginatedInit = (
  state: DriversState,
  action: PayloadAction<{ page?: number; showTotalItems?: number }>,
) => {
  state.getDriversState = {
    inProgress: true,
  };
};
export const getDriversListPaginatedSuccess = (
  state: DriversState,
  action: PayloadAction<PaginatedData<Driver>>,
) => {
  state.getDriversState = {
    inProgress: false,
    success: true,
  };
  state.driversListPaginated = action.payload;
};
export const getDriversListPaginatedFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {
  state.getDriversState = {
    inProgress: true,
  };
  state.driversListPaginated = undefined;
};

export const setAllowedCompanyForDriversInit = (state: DriversState, action: Action) => {
  state.updateDriverState = {
    inProgress: true,
  };
};
export const setAllowedCompanyForDriversSuccess = (
  state: DriversState,
  action: PayloadAction<void>,
) => {
  state.updateDriverState = {
    inProgress: false,
    success: true,
  };
};
export const setAllowedCompanyForDriversFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {
  state.updateDriverState = {
    inProgress: true,
  };
};

export const removeAllowedCompanyForDriversInit = (state: DriversState, action: Action) => {
  state.updateDriverState = {
    inProgress: true,
  };
};
export const removeAllowedCompanyForDriversSuccess = (
  state: DriversState,
  action: PayloadAction<void>,
) => {
  state.updateDriverState = {
    inProgress: false,
    success: true,
  };
};
export const removeAllowedCompanyForDriversFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {
  state.updateDriverState = {
    inProgress: true,
  };
};

export const deleteDriversInit = (state: DriversState, action: Action) => {
  state.updateDriverState = {
    inProgress: true,
  };
};
export const deleteDriversSuccess = (state: DriversState, action: PayloadAction<void>) => {
  state.updateDriverState = {
    inProgress: false,
    success: true,
  };
};
export const deleteDriversFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {
  state.updateDriverState = {
    inProgress: true,
  };
};

export const createCommentInit = (state: DriversState, action: Action) => {
  state.updateDriverState = {
    inProgress: true,
  };
};
export const createCommentSuccess = (state: DriversState, action: PayloadAction<void>) => {
  state.updateDriverState = {
    inProgress: false,
    success: true,
  };
};
export const createCommentFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {
  state.updateDriverState = {
    inProgress: true,
  };
};

export const getCommentsInit = (state: DriversState, action: Action) => {
  state.updateDriverState = {
    inProgress: true,
  };
};
export const getCommentsSuccess = (state: DriversState, action: PayloadAction<void>) => {
  state.updateDriverState = {
    inProgress: false,
    success: true,
  };
};
export const getCommentsFailure = (state: DriversState, action: PayloadAction<FailureResponse>) => {
  state.updateDriverState = {
    inProgress: true,
  };
};

export const setGetDriversFilters = (
  state: DriversState,
  action: PayloadAction<GetDriversFilters>,
) => {
  state.getDriversFilters = {
    page: state.getDriversFilters.page,
    itemsPerPage: state.getDriversFilters.itemsPerPage,
    showDeleted: state.getDriversFilters.showDeleted,
    ...action.payload,
  };
};
export const setGetDriversShowDeletedFilter = (
  state: DriversState,
  action: PayloadAction<boolean>,
) => {
  state.getDriversFilters = {
    ...state.getDriversFilters,
    showDeleted: action.payload,
  };
};

export const restoreDriverInit = (state: DriversState, action: Action) => {
  state.updateDriverState = {
    inProgress: true,
  };
};
export const restoreDriverSuccess = (state: DriversState, action: PayloadAction<void>) => {
  state.updateDriverState = {
    inProgress: false,
    success: true,
  };
};
export const restoreDriverFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {
  state.updateDriverState = {
    inProgress: true,
  };
};
