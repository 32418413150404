import { RootState } from '@app/store';
import { Nationality } from '../../model/Nationality';
import { InProgressState } from '@common/model';

export const selectNationalities = (state: RootState): Nationality[] | undefined =>
  state.user.nationalities;

export const selectGetNationalitiesState = (state: RootState): InProgressState | undefined =>
  state.user.getNationalitiesState;

export const selectGetNationalitiesInProgress = (state: RootState): boolean | undefined =>
  state.user.getNationalitiesState?.inProgress;
