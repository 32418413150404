// Core libraries
import React from 'react';

// External libs and components
// import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

// Internal libs

// Internal components
import DriversPage from './DriversPage';
import PartnerRidesPage from './rides/PartnerRidesPage';

// Props type
type PartnerPageProps = {};

// Component
const PartnerPage = ({}: PartnerPageProps) => {
  // const { t } = useTranslation('partner');
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/drivers`} children={<DriversPage />} />
      <Route path={`${path}/rides`} children={<PartnerRidesPage />} />
      <Route path={`${path}`} children={<Redirect to={`${path}/drivers`} />} />
    </Switch>
  );
};

export default PartnerPage;
