// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { useSelector } from 'react-redux';

// Internal libs
import { useResolveRoles } from './RolesProvider';
import { Role } from '../model/Role';
import { selectors } from '../store';

// Internal components

// Component props type
type HasRolesPros = {
  roles: Role | Role[];
  loading?: React.ReactNode;
  noAccess?: React.ReactNode;
  children: React.ReactNode;
};

// Component
const HasRoles = ({ roles, children, loading, noAccess }: HasRolesPros) => {
  const resolveRoles = useResolveRoles();
  const user = useSelector(selectors.selectUser);

  const getUserState = useSelector(selectors.selectGetUserState);
  const userRoles = useMemo(() => (user?.roles ? resolveRoles(user.roles) : []), [
    resolveRoles,
    user,
  ]);
  const allowed = useMemo<boolean>(
    () =>
      (typeof roles === 'string' ? [roles] : roles).every((role) => userRoles.indexOf(role) !== -1),
    [roles, userRoles],
  );

  return <>{allowed ? children : getUserState?.inProgress ? loading || null : noAccess || null}</>;
};

export default HasRoles;
