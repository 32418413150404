// Core libraries
import React, { useMemo, useState } from 'react';

// External libs and components
import Alert, { Color } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

// Internal libs
import { SnackbarData } from '../model';
import SnackbarsContext, { Snackbars } from '../SnackbarsContext';

// Internal components

// Props type
type SnackbarsProviderProps = { children: React.ReactNode };

// Component
const SnackbarsProvider = ({ children }: SnackbarsProviderProps) => {
  const [snackbars, setSnackbars] = useState<SnackbarData[]>([]);
  const context: Snackbars = useMemo(() => new Snackbars(setSnackbars), [setSnackbars]);

  return (
    <SnackbarsContext.Provider value={context}>
      {children}
      {snackbars &&
        snackbars.map((snack) => (
          <Snackbar
            key={snack.key}
            open={snack.open}
            autoHideDuration={6000}
            onClose={(event, reason) => reason === 'timeout' && context.hideSnackbar(snack.key)}
            onExited={() => context.removeSnackbar(snack.key)}
          >
            <Alert
              severity={snack.props.severity || 'info'}
              onClose={() => context.hideSnackbar(snack.key)}
            >
              {snack.props.message}
            </Alert>
          </Snackbar>
        ))}
    </SnackbarsContext.Provider>
  );
};

export default SnackbarsProvider;
