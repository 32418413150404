import { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from '../model';
import { RegisterData } from '../../api';
import { FailureResponse } from '@common/model';

// Register reducers
const registerInit = (state: AuthState, action: PayloadAction<RegisterData>) => {
  state.register = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
const registerSuccess = (state: AuthState, action: PayloadAction<any>) => {
  state.register = {
    inProgress: false,
    error: undefined,
    success: true,
  };
  state.token = action.payload;
};
const registerFailure = (state: AuthState, action: PayloadAction<FailureResponse>) => {
  state.register = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
};

const registerReducers = {
  registerInit,
  registerSuccess,
  registerFailure,
};
export default registerReducers;
