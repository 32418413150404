import {
  timestampable,
  timestampableFromSrc,
  timestampableSrc,
  timestampableToSrc,
} from '@common/model';

import { DocumentStatus } from './DocumentStatus';

export interface Document extends timestampable {
  id: number;
  documentType: string;
  originalFileName: string;
  fileName: string;
  status: DocumentStatus;
  statusComment?: string;
  downloadLink: string;
}

export interface DocumentSrc extends timestampableSrc {
  id: number;
  document_type: string;
  original_file_name: string;
  file_name: string;
  status: DocumentStatus;
  status_comment: string | null;
  download_link: string;
}

export const documentFromSrc = (documentSrc: DocumentSrc): Document => ({
  id: documentSrc.id,
  documentType: documentSrc.document_type,
  originalFileName: documentSrc.original_file_name,
  fileName: documentSrc.file_name,
  status: documentSrc.status,
  statusComment: documentSrc.status_comment || undefined,
  downloadLink: documentSrc.download_link,
  ...timestampableFromSrc(documentSrc),
});

export const documentToSrc = (document: Document): DocumentSrc => ({
  id: document.id,
  document_type: document.documentType,
  original_file_name: document.originalFileName,
  file_name: document.fileName,
  status: document.status,
  download_link: document.downloadLink,
  status_comment: document.statusComment || null,
  ...timestampableToSrc(document),
});
