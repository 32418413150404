import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { VehiclesState } from '../model';
import { Vehicle } from '@features/vehicles/model/Vehicle';

export const vehicleListStateClear = (state: VehiclesState) => {
  state.vehiclesListState = undefined;
};

export const getVehicleListInit = (state: VehiclesState, action: Action) => {
  state.vehiclesListState = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const getVehiclesListSuccess = (state: VehiclesState, action: PayloadAction<Vehicle[]>) => {
  state.vehiclesListState = {
    inProgress: false,
    error: undefined,
    success: true,
  };
  state.vehiclesList = action.payload;
};
export const getVehiclesListFailure = (
  state: VehiclesState,
  action: PayloadAction<FailureResponse>,
) => {
  state.vehiclesListState = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
  state.vehiclesList = undefined;
};

const vehiclesListReducers = {
  vehicleListStateClear,
  getVehicleListInit,
  getVehiclesListSuccess,
  getVehiclesListFailure,
};
export default vehiclesListReducers;
