// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import CenteredPaperLayout from '@common/components/CenteredPaperLayout';
import { ReactComponent as LogoDark } from '@assets/xd_logo.dark.svg';
import { ReactComponent as Logo } from '@assets/xd_logo.svg';
import CircularProgress from '@material-ui/core/CircularProgress';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  label: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  spinner: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}));

// Props type
type SplashScreenContentProps = {};

// Component
const SplashScreenContent = ({}: SplashScreenContentProps) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <CenteredPaperLayout showBgImage={true} maxWidth="sm">
      {/* Logo */}
      <div className={styles.logoContainer}>
        {theme.palette.type === 'dark' ? <LogoDark width="200" /> : <Logo width="200px" />}
      </div>

      {/* Spinner */}
      <div className={styles.spinner}>
        <CircularProgress />
      </div>
    </CenteredPaperLayout>
  );
};

export default SplashScreenContent;
