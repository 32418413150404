import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { UserState } from '../model';
import { AllowedIdentityDocument, IdentityDocument } from '@features/user/model/IdentityDocument';
import { ContractsState } from '@features/contracts/store/model';

export const getAllowedIdentityDocumentsInit = (state: UserState, action: Action) => {
  state.getAllowedIdentityDocumentsState = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const getAllowedIdentityDocumentsSuccess = (
  state: UserState,
  action: PayloadAction<AllowedIdentityDocument[]>,
) => {
  state.getAllowedIdentityDocumentsState = {
    inProgress: false,
    error: undefined,
    success: true,
  };
  state.allowedIdentityDocuments = action.payload;
};
export const getAllowedIdentityDocumentsFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {
  state.getAllowedIdentityDocumentsState = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
  state.allowedIdentityDocuments = undefined;
};

export const getDriverIdentityDocumentsInit = (state: UserState, action: Action) => {
  state.getUserState = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const getDriverIdentityDocumentsSuccess = (
  state: UserState,
  action: PayloadAction<IdentityDocument[]>,
) => {
  state.getUserState = {
    inProgress: false,
    error: undefined,
    success: true,
  };
  if (state.user) {
    state.user.identityDocuments = action.payload;
  }
};
export const getDriverIdentityDocumentsFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {
  state.getUserState = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
  if (state.user) {
    state.user.identityDocuments = [];
  }
};

export const uploadIdentityDocumentInit = (
  state: UserState,
  action: PayloadAction<{ name: string; fileName?: string; fileSize?: number }>,
) => {};
export const uploadIdentityDocumentSuccess = (state: UserState, action: Action) => {};
export const uploadIdentityDocumentFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

export const downloadIdentityDocumentInit = (
  state: UserState,
  action: PayloadAction<{ url: string; fileName: string }>,
) => {};
export const downloadIdentityDocumentSuccess = (state: UserState, action: Action) => {};
export const downloadIdentityDocumentFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

const identityDocumentsReducers = {
  getAllowedIdentityDocumentsInit,
  getAllowedIdentityDocumentsFailure,
  getAllowedIdentityDocumentsSuccess,
  getDriverIdentityDocumentsInit,
  getDriverIdentityDocumentsSuccess,
  getDriverIdentityDocumentsFailure,
  uploadIdentityDocumentInit,
  uploadIdentityDocumentSuccess,
  uploadIdentityDocumentFailure,
  downloadIdentityDocumentInit,
  downloadIdentityDocumentSuccess,
  downloadIdentityDocumentFailure,
};
export default identityDocumentsReducers;
