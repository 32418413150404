// Core libraries
import React, { useEffect } from 'react';

// External libs and components
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

// Internal libs
import { selectors as userSelectors} from '@features/user';
import { actions as partnerActions, selectors as partnerSelectors } from '../store';

// Internal components
import FormCheckboxField from '@common/components/FormCheckboxField';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    progressIndicator: {
      textAlign: 'center',
    },
  }),
);

// Props type
type SetAccountTypePartnerCheckProps = {};

// Component
const PartnerDriversAbandonmentConfirmation = ({}: SetAccountTypePartnerCheckProps) => {
  const { t } = useTranslation('partner');
  const dispatch = useDispatch();
  const styles = useStyles();

  const user = useSelector(userSelectors.selectUser);

  const driversState = useSelector(partnerSelectors.selectGetPartnerDriversState);
  const drivers = useSelector(partnerSelectors.selectPartnerDrivers);

  const init = async (companyId: number) => {
    try {
      await dispatch(partnerActions.getPartnerDrivers(companyId));
    } catch (e) {}
  };

  useEffect(() => {
    if (user?.company?.isPartner) {
      init(user.company.id);
    }
  }, [user]);

  return (
    <>
      {!!driversState?.inProgress && (
        <Box className={styles.progressIndicator}>
          <CircularProgress data-testid={'loading-partner-drivers-indicator'} />
        </Box>
      )}
      {!!drivers && !!drivers.length && (
        <FormCheckboxField
          name="partnerDriversAbandonmentConfirmation"
          defaultValue={false}
          label={t('driversAbandonment.confirmationMessage')}
          rules={{
            required: {
              value: true,
              message: t('driversAbandonment.errors.required'),
            },
          }}
          data-testid="partner-drivers-abandonment-confirmation-field"
        />
      )}
    </>
  );
};

export default PartnerDriversAbandonmentConfirmation;
