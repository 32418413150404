export interface PaginatedData<T> {
  data: T[];
  from: number;
  to: number;
  total: number;
  currentPage: number;
  lastPage: number;
  perPage: number;
}

export interface PaginatedDataSrc<TSrc> {
  data: TSrc[];
  from: number;
  to: number;
  total: number;
  current_page: number;
  last_page: number;
  per_page: number;
}

export const paginatedDataFromSrc = <TSrc, T>(
  paginatedDataSrc: PaginatedDataSrc<TSrc>,
  dataFromSrc: (src: TSrc) => T,
): PaginatedData<T> => ({
  from: paginatedDataSrc.from,
  to: paginatedDataSrc.to,
  total: paginatedDataSrc.total,
  currentPage: paginatedDataSrc.current_page,
  lastPage: paginatedDataSrc.last_page,
  perPage: paginatedDataSrc.per_page,
  data: paginatedDataSrc.data.map((dataSrc) => dataFromSrc(dataSrc)),
});

export const paginatedDataToSrc = <T, TSrc>(
  paginatedData: PaginatedData<T>,
  dataToSrc: (data: T) => TSrc,
): PaginatedDataSrc<TSrc> => ({
  from: paginatedData.from,
  to: paginatedData.to,
  total: paginatedData.total,
  current_page: paginatedData.currentPage,
  last_page: paginatedData.lastPage,
  per_page: paginatedData.perPage,
  data: paginatedData.data.map((data) => dataToSrc(data)),
});
