// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import {
  Controller,
  useFormContext,
  Validate,
  ValidationRule,
  ValidationValueMessage,
} from 'react-hook-form';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

// Internal libs

// Internal components

type FormSelectFieldProps = {
  name: string;
  id?: string;
  label?: string;
  helperText?: string;
  className?: string;
  defaultValue?: any;
  overrideOnChange?: any;
  overrideValue?: string;
  disabled?: boolean;
  rules?:
    | Partial<{
        required: string | boolean | ValidationValueMessage<boolean>;
        min: ValidationRule<React.ReactText>;
        max: ValidationRule<React.ReactText>;
        maxLength: ValidationRule<React.ReactText>;
        minLength: ValidationRule<React.ReactText>;
        pattern: ValidationRule<RegExp>;
        validate: Validate | Record<string, Validate>;
      }>
    | undefined;
  fullWidth?: boolean;
  options: { value: any; label: string; disabled?: boolean }[];
  noneOption?: string;
  errors?: string[];
};

const FormSelectField = ({
  name,
  id,
  label,
  helperText,
  className,
  defaultValue = '',
  rules,
  fullWidth = false,
  options,
  noneOption,
  errors = [],
  overrideOnChange = null,
  overrideValue,
  disabled = false,
}: FormSelectFieldProps) => {
  const form = useFormContext();

  const hasError = !!form.errors[name] || !!errors.length;
  const hTexts = !hasError
    ? helperText
    : [
        ...(form.errors[name] ? [form.errors[name].message] : []),
        ...errors.map((error) => <div>{error}</div>),
      ];
  const disableSelect = useMemo(() => disabled, [disabled]);
  return (
    <Controller
      name={name}
      render={({ onChange, onBlur, value, ref }) => (
        <FormControl
          fullWidth={fullWidth}
          className={className}
          error={hasError}
          disabled={disableSelect}
        >
          <InputLabel
            id={`${id || name}-form-select-field`}
            data-testid={`${id || name}-form-select-field-input`}
          >
            {`${label}${rules?.required ? '*' : ''}`}
          </InputLabel>
          <Select
            id={id || name}
            labelId={`${id || name}-form-select-field`}
            data-testid={`${id || name}-form-select-field`}
            fullWidth={fullWidth}
            defaultValue={defaultValue}
            value={overrideValue || value}
            onChange={overrideOnChange || onChange}
            onBlur={onBlur}
            inputRef={ref}
          >
            {noneOption && (
              <MenuItem value="">
                <em>{noneOption}</em>
              </MenuItem>
            )}
            {options.map(({ label, value, disabled }, index) => (
              <MenuItem value={value} key={index} disabled={!!disabled}>
                {label}
              </MenuItem>
            ))}
          </Select>
          {hTexts && <FormHelperText>{hTexts}</FormHelperText>}
        </FormControl>
      )}
      control={form.control}
      defaultValue={defaultValue}
      rules={rules}
    />
  );
};

export default FormSelectField;
