// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Internal libs
import { Address } from '../../model/Address';

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    textLine: {
      display: 'block',
    },
  }),
);

// Props type
type TwoLineAddressProps = { address: Address };

// Component
const TwoLineAddress = ({ address }: TwoLineAddressProps) => {
  const styles = useStyles();

  return (
    <>
      <span className={styles.textLine}>
        {address.streetName} {address.streetNumber}
        {!!address.apartmentNumber && <> / {address.apartmentNumber}</>}
      </span>
      <span className={styles.textLine}>
        {address.zipCode} {address.city}
      </span>
    </>
  );
};

export default TwoLineAddress;
