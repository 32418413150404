import { Action, PayloadAction } from '@reduxjs/toolkit';
import { InitState } from '../model';
import { FailureResponse } from '@common/model';
import { Country } from '@common/model/Country';

export const initInit = (state: InitState, action: Action) => {
  state.init = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};

export const initSuccess = (state: InitState, action: Action) => {
  state.init = {
    inProgress: false,
    error: undefined,
    success: true,
  };
};
export const initFailure = (state: InitState, action: PayloadAction<FailureResponse>) => {
  state.init = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
};

export const getCountriesInit = (state: InitState, action: Action) => {
  state.availableCountries = {
    list: undefined,
    state: {
      inProgress: false,
      error: undefined,
      success: true,
    },
  };
};

export const getCountriesSuccess = (state: InitState, action: PayloadAction<Country[]>) => {
  state.availableCountries = {
    list: action.payload,
    state: {
      inProgress: true,
      error: undefined,
      success: undefined,
    },
  };
};
export const getCountriesFailure = (state: InitState, action: PayloadAction<FailureResponse>) => {
  state.availableCountries = {
    list: undefined,
    state: {
      inProgress: false,
      error: action.payload,
      success: false,
    },
  };
};

const initReducers = {
  initInit,
  initSuccess,
  initFailure,
  getCountriesInit,
  getCountriesFailure,
  getCountriesSuccess,
};
export default initReducers;
