import axios from 'axios';
import i18n from 'i18next';
import { saveAs } from 'file-saver';
import {
  ContractTemplate,
  contractTemplateFromScr,
  ContractTemplateSrc,
} from '../model/ContractTemplate';
import { Contract, contractFromSrc, ContractSrc } from '../model/Contract';
import { ContractTemplateWithContract } from '@features/contracts/model/ContractTemplateWithContract';

export const getContractTemplates = async (): Promise<ContractTemplate[]> => {
  const response = await axios.get('contract/templates/index');

  return response.data.map((contractTemplateSrc: ContractTemplateSrc) =>
    contractTemplateFromScr(contractTemplateSrc),
  );
};

export const getContracts = async (): Promise<Contract[]> => {
  const response = await axios.get('contract/list');

  return response.data.map((contractSrc: ContractSrc) => contractFromSrc(contractSrc));
};

export const getContractTemplatesWithContracts = async (): Promise<
  ContractTemplateWithContract[]
> => {
  const templates = await getContractTemplates();
  const contracts = await getContracts();

  const templatesWithContracts: {
    [key: string]: ContractTemplateWithContract;
  } = {};

  templates.forEach((template) => {
    templatesWithContracts[template.name] = {
      templateId: template.id,
      supported: true,
      template,
    };
  });

  contracts.forEach((contract) => {
    if (!templatesWithContracts.hasOwnProperty(contract.contractTemplate?.name)) {
      templatesWithContracts[contract.contractTemplate?.name] = {
        templateId: contract.contractTemplateId,
        supported: false,
        template: contract.contractTemplate,
      };
    }

    templatesWithContracts[contract.contractTemplate?.name].contract = contract;
  });

  return Object.keys(templatesWithContracts).map((key: string) => templatesWithContracts[key]);
};

export const getContract = async (contractTemplateId: number): Promise<any> => {
  const response = await axios.get(`contract/generate/${contractTemplateId}`);
  return contractFromSrc(response.data);
};

export const signContract = async (
  contractId: number,
  pinCode: string,
  confirmContract: boolean,
): Promise<any> => {
  return await axios.post(`contract/sign/${contractId}`, {
    pin_code: pinCode,
    confirm_contract: confirmContract,
  });
};

export const requestPinCode = async (contractId: number): Promise<any> => {
  return await axios.get(`contract/request-token/${contractId}`);
};

export const downloadContract = async (contractTemplateId: number): Promise<any> => {
  // Generate docs
  const response = await axios.get(`contract/generate/${contractTemplateId}`);
  return await downloadContractPdf(contractFromSrc(response.data));
};

export const downloadContractPdf = async (
  contract: Contract,
  save: boolean = true,
): Promise<any> => {
  // Prepare file name
  const fileNameBase =
    i18n.t(`contracts:contractNames.${contract.contractTemplate.name}.fileBaseName`) +
    (contract.contractData.user
      ? `.${contract.contractData.user.first_name}_${contract.contractData.user.last_name}`
      : '') +
    (contract.contractData.company ? `.${contract.contractData.company.name}` : '') +
    `.pdf`;

  // Download file
  const file: { data: Blob } = await axios.get(contract.downloadLink, {
    responseType: 'blob',
  });
  // Save file
  return save ? saveAs(file.data, fileNameBase) : file.data;
};

export const uploadFile = async (
  contractId: number,
  files: Blob[],
  onUploadProgress: (event: { loaded: number; total: number }) => void,
) => {
  let formData = new FormData();

  for (const file of files) {
    formData.append('document_file[]', file);
  }

  return axios.post(`/contract/upload/${contractId}/signed-contract`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
  });
};

export const downloadUploadedSignedDocument = async (
  url: string,
  fileName: string,
): Promise<any> => {
  // Download file
  const file: { data: Blob } = await axios.get(url, {
    responseType: 'blob',
  });

  // Save file
  saveAs(file.data, fileName);
};
