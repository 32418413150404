import { useMemo } from 'react';

export const amountValidator = (message?: string) => (value: string) =>
  /^[+-]?(\d+([.]\d{0,2})?|[.]\d{1,2})$/.test(value) || message || false;

export const onlyPositiveAmountValidator = (message?: string) => (value: string) =>
  /^\+?(\d+([.]\d{0,2})?|[.]\d{1,2})$/.test(value) || message || false;

const useAmountValidator = (onlyPositiveValues = false, message?: string) => {
  return useMemo(
    () => (onlyPositiveValues ? onlyPositiveAmountValidator(message) : amountValidator(message)),
    [message, onlyPositiveValues],
  );
};
export default useAmountValidator;
