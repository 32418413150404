// Core libraries
import React, { useCallback, useState } from 'react';

// External libs and components
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

// Internal libs
import { InProgressState } from '@common/model';
import { actions } from '../store';

// Internal components
import useVehicle from '@features/vehicles/hooks/useVehicle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ProgressButton from '@common/components/ProgressButton';

// Component
const VehicleActivateDialog = () => {
  const { t } = useTranslation('vehicles');
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { vehicle, loadingVehicles } = useVehicle(id);

  const onClose = useCallback(() => {
    history.push('..');
  }, [history]);

  const [setActiveState, setSetActiveState] = useState<InProgressState>({
    inProgress: false,
  });

  const setActive = useCallback(async () => {
    if (setActiveState.inProgress) {
      return;
    }

    setSetActiveState({
      inProgress: true,
    });

    try {
      await dispatch(actions.setVehicleActive(Number(id)));
      dispatch(actions.getVehiclesList());
      setSetActiveState({
        inProgress: false,
        success: true,
      });
      onClose();
    } catch (e) {
      setSetActiveState({
        inProgress: false,
        error: e,
      });
    }
  }, [dispatch, id, setActiveState, setSetActiveState, onClose]);

  return (
    <Dialog fullWidth onClose={onClose} open={true}>
      <DialogTitle>{t('activate.title')}</DialogTitle>
      <Divider />
      {!!vehicle && (
        <DialogContent>
          {t(`fields.vehicleType.types.${vehicle.vehicleType}`, vehicle.vehicleType)}{' '}
          {!!vehicle.manufacturer && ` ${vehicle.manufacturer}`}
          {!!vehicle.model && ` ${vehicle.model}`}
          {!!vehicle.registrationNumber && ` ${vehicle.registrationNumber}`}
        </DialogContent>
      )}
      {!!loadingVehicles && (
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      )}
      {!loadingVehicles && !vehicle?.active && (
        <DialogActions>
          <Button onClick={onClose}>{t('common:no')}</Button>
          <ProgressButton
            onClick={setActive}
            variant="contained"
            color="primary"
            loading={setActiveState.inProgress}
          >
            {t('common:yes')}
          </ProgressButton>
        </DialogActions>
      )}
      {!loadingVehicles && vehicle?.active && (
        <>
          <DialogContent>{t('activate.messages.alreadyActive')}</DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t('common:close')}</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default VehicleActivateDialog;
