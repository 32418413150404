// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import DoneIcon from '@material-ui/icons/Done';
import PendingIcon from '@material-ui/icons/HourglassEmpty';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    success: {
      color: theme.palette.success.main,
    },
    pending: {
      color: theme.palette.info.main,
    },
  }),
);

// Props type
type ProvideDataCardItemProps = {
  icon: React.ReactNode;
  label: React.ReactNode;
  missing: boolean;
  pending?: boolean;
  onClick?: () => void;
  loading?: boolean;
};

// Component
const ProvideDataCardItem = ({
  icon,
  label,
  missing,
  pending,
  onClick,
  loading,
}: ProvideDataCardItemProps) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <ListItem button onClick={onClick}>
      <ListItemIcon>
        {!loading &&
          (missing ? (
            <DoneIcon color="disabled" />
          ) : pending ? (
            <PendingIcon color="inherit" className={styles.pending} />
          ) : (
            <DoneIcon color="inherit" className={styles.success} />
          ))}
        {loading && <CircularProgress size={theme.spacing(3)} />}
      </ListItemIcon>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </ListItem>
  );
};

export default ProvideDataCardItem;
