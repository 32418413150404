// Core libraries
import React, { useEffect, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';

// Internal libs

// Internal components
import CorrectionForm from '@features/admin/corrections/containers/CorrectionForm';
import { correctionActions, correctionSelectors } from '../store';
import CorrectionDetailsView from "@features/admin/corrections/containers/CorrectionDetailsView";
import Grid from "@material-ui/core/Grid";

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    pendingSync: {
      color: theme.palette.secondary.main,
    }
  }),
);

// Props type
type CorrectionModalProps = {
  title?: string;
};

// Component
const CorrectionModal = ({ title }: CorrectionModalProps) => {
  const { t } = useTranslation('rides');
  const styles = useStyles();
  const dispatch = useDispatch();
  const { correctionId } = useParams() as { correctionId: string };

  const correction = useSelector(correctionSelectors.selectCorrectionById(correctionId));

  const titleToDisplay =
    title || `${t(`adminRideCorrection.correctionNumber.title`)} ${correction?.rideNo || ''}`;

  return (
    <Dialog open={true} className={styles.root} fullWidth maxWidth="md">
      <DialogTitle>
        {titleToDisplay}
        {' '}
        {
          !correction?.ride ?
            <span className={styles.pendingSync}>
              ({t('admin:corrections.detailsView.pendingSynchronization')})
            </span>
            : ''
        }
      </DialogTitle>
      <Divider />
      <DialogContent>
        <CorrectionDetailsView correctionDetails={correction} />
        <Divider />
        <CorrectionForm
          correctionDetails={correction}
        />

      </DialogContent>
    </Dialog>
  );
};

export default CorrectionModal;
