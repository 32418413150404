// Core libraries
import React from 'react';

// External libs and components
import { Route, Switch } from 'react-router-dom';

// Internal libs

// Internal components
import SelectorRoute from '@common/components/routes/SelectorRoute';
import { ProfilePage, selectors as userSelectors } from '@features/user';
import { VehiclesPage } from '@features/vehicles';
import { ContractsPage } from '@features/contracts';
import { RidesPage } from '@features/rides';
import { CompanyPage } from '@features/company';
import { PartnerPage } from '@features/partner';
import { Dashboard } from '@features/dashboard';
import Menu from './Menu';
import DeleteProfilePage from '@features/user/containers/DeleteProfilePage';

// Props type
type RoutingProps = {};

// Component
const Routing = ({}: RoutingProps) => {
  return (
    <Switch>
      <Route path="/profile/delete" children={<DeleteProfilePage />} />
      <Route path="/profile" children={<ProfilePage />} />

      <Route path="/vehicles" children={<VehiclesPage />} />
      <Route path="/contracts" children={<ContractsPage />} />
      <Route path="/rides" children={<RidesPage />} />
      <SelectorRoute
        selector={userSelectors.selectUserLoadedAndHasCompany}
        path="/company"
        children={<CompanyPage />}
        redirect="/"
      />
      <SelectorRoute
        selector={userSelectors.selectUserLoadedAndIsPartner}
        path="/partner"
        children={<PartnerPage />}
        redirect="/"
      />
      <Route path="/" children={<Dashboard />} />
    </Switch>
  );
};

export default Routing;
