import { ContractStatus } from '@features/contracts/model/ContractStatus';
import { DocumentStatusEnum } from '@features/contracts/model/DocumentStatus';

export interface AllowedIdentityDocumentSrc {
  id: number;
  name: string;
  value: string;
  options: {
    required?: boolean;
    show_all?: boolean;
  };
}

export interface AllowedIdentityDocument {
  id: number;
  name: string;
  value: string;
  options?: {
    required?: boolean;
    showAll?: boolean;
  };
}

export interface IdentityDocument {
  id: number;
  documentName: string;
  documentType: string;
  fileName: string;
  downloadLink: string;
  status: DocumentStatusEnum;
  statusComment: string;
  documentData: {
    firstName?: string;
    lastName?: string;
    pesel?: string;
    criminalRecord?: boolean;
  };
}

export interface IdentityDocumentSrc {
  id: number;
  document_name: string;
  document_type: string;
  file_name: string;
  download_link: string;
  status: DocumentStatusEnum;
  status_comment: string;
  document_data: {
    first_name?: string;
    last_name?: string;
    pesel?: string;
    criminal_record?: boolean;
  };
}

export const identityDocumentFromSrc = (
  identityDocumentSrc: IdentityDocumentSrc,
): IdentityDocument => ({
  id: identityDocumentSrc.id,
  documentName: identityDocumentSrc.document_name,
  documentType: identityDocumentSrc.document_type,
  fileName: identityDocumentSrc.file_name,
  downloadLink: identityDocumentSrc.download_link,
  status: identityDocumentSrc.status,
  statusComment: identityDocumentSrc.status_comment,
  documentData: {
    ...(identityDocumentSrc.document_data?.first_name
      ? { firstName: identityDocumentSrc.document_data?.first_name }
      : {}),
    ...(identityDocumentSrc.document_data?.last_name
      ? {
          lastName: identityDocumentSrc.document_data?.last_name,
        }
      : {}),
    ...(identityDocumentSrc.document_data?.criminal_record !== undefined
      ? {
          criminalRecord: identityDocumentSrc.document_data?.criminal_record,
        }
      : {}),
    ...(identityDocumentSrc.document_data?.pesel
      ? {
          pesel: identityDocumentSrc.document_data?.pesel,
        }
      : {}),
  },
});

export const identityDocumentToSrc = (identityDocument: IdentityDocument): IdentityDocumentSrc => ({
  id: identityDocument.id,
  document_name: identityDocument.documentName,
  document_type: identityDocument.documentType,
  file_name: identityDocument.fileName,
  download_link: identityDocument.downloadLink,
  status: identityDocument.status,
  status_comment: identityDocument.statusComment,
  document_data: {},
});

export const allowedIdentityDocumentFromSrc = (
  identityDocumentSrc: AllowedIdentityDocumentSrc,
): AllowedIdentityDocument => ({
  ...identityDocumentSrc,
  options: {
    required: identityDocumentSrc.options?.required,
    showAll: identityDocumentSrc.options?.show_all,
  },
});
