import {
  Vehicle,
  vehicleFromSrc,
  VehicleSrc,
  vehicleToSrc,
} from '@features/vehicles/model/Vehicle';
import { Address, addressFromSrc, AddressSrc, addressToSrc } from '@common/model/Address';
import { Company } from './Company';
import { PartnerRequest } from '@features/user/model/PartnerRequest';
import {
  DriverInfo,
  driverInfoFromSrc,
  DriverInfoSrc,
  driverInfoToSrc,
} from '@common/model/Driver';
import {
  IdentityDocument,
  identityDocumentFromSrc,
  IdentityDocumentSrc,
  identityDocumentToSrc,
} from '@features/user/model/IdentityDocument';

export interface UserDataFlags {
  missingPersonalData: boolean;
  missingVehicleData: boolean;
  missingEmailVerification: boolean;
  missingPhone: boolean;
  missingPhoneVerification: boolean;
  missingContracts: boolean;
}

export interface UserUpdate {
  firstName: string;
  lastName: string;
  pesel: string;
  nationalityId: number;
  customNationality?: string;
  idNumber?: string;
  passportNumber?: string;
  residenceCardNumber?: string;
  address: Address;
  regionId?: number;
}
export interface UserUpdateSrc {
  first_name: string;
  last_name: string;
  pesel: string;
  nationality_id: number;
  custom_nationality?: string;
  id_number?: string;
  passport_number?: string;
  residence_card_number?: string;
  address: AddressSrc;
  region_id?: number;
}

export interface User extends DriverInfo {
  nationality?: string;
  nationalityId?: number;
  customNationality?: string;
  pesel?: string;
  idNumber?: string;
  residenceCardNumber?: string;
  passportNumber?: string;
  privacyPolicyAcceptedAt?: string;
  // gdprAcceptedAt?: string;
  createdAt: string;
  updatedAt: string;
  activeVehicle?: Vehicle;
  address?: Address;
  regionId?: number;
  region?: string;
  partnerId?: string;

  locale?: string;
  contractLocale?: string;

  partner?: Company;
  partnerRequest?: PartnerRequest;
  company?: Company;

  driversCount?: number;
  driversPendingJoinRequestsCount?: number;
  missingContractsCount?: number;
  pendingContractsCount?: number;

  roles: string[];

  identityDocuments: IdentityDocument[];

  canGenerateInvoice?: boolean;
}

export interface UserSrc extends DriverInfoSrc {
  custom_nationality: string | null;
  nationality_id: number | null;
  nationality: string | null;
  pesel: string | null;
  id_number: string | null;
  passport_number: string | null;
  residence_card_number: string | null;
  privacy_policy_accepted_at: string | null;
  // rodo_accepted_at: string | null;
  created_at: string;
  updated_at: string;
  active_vehicle: VehicleSrc | null;
  address: AddressSrc | null;
  region_id: number | null;
  region: string | null;
  partner_id: string | null;

  locale: string | null;
  contract_locale: string | null;

  drivers_count: number | null;
  drivers_pending_join_requests_count: number | null;
  missing_contracts_count: number | null;
  pending_contracts_count: number | null;

  roles?: string[];

  identity_documents: IdentityDocumentSrc[] | null;

  can_generate_invoice: boolean | null;
}

export enum DriverStatusEnum {
  normal = 'normal',
  premium = 'premium',
}

export type DriverStatus = 'normal' | 'premium';

export const userFromSrc = (userSrc: UserSrc): User => ({
  ...driverInfoFromSrc(userSrc),
  pesel: userSrc.pesel || undefined,
  idNumber: userSrc.id_number || undefined,
  passportNumber: userSrc.passport_number || undefined,
  residenceCardNumber: userSrc.residence_card_number || undefined,
  privacyPolicyAcceptedAt: userSrc.privacy_policy_accepted_at || undefined,
  // gdprAcceptedAt: userSrc.rodo_accepted_at || undefined,
  updatedAt: userSrc.updated_at,
  activeVehicle: userSrc.active_vehicle ? vehicleFromSrc(userSrc.active_vehicle) : undefined,
  address: userSrc.address ? addressFromSrc(userSrc.address) : undefined,
  regionId: userSrc.region_id || undefined,
  region: userSrc.region || undefined,
  accountType: userSrc.account_type || undefined,
  partnerId: userSrc.partner_id || undefined,
  driversCount: userSrc.drivers_count || undefined,
  driversPendingJoinRequestsCount: userSrc.drivers_pending_join_requests_count || undefined,
  missingContractsCount:
    typeof userSrc.missing_contracts_count === 'number'
      ? userSrc.missing_contracts_count
      : undefined,
  pendingContractsCount:
    typeof userSrc.pending_contracts_count === 'number'
      ? userSrc.pending_contracts_count
      : undefined,

  roles: [...(userSrc.roles || [])],
  nationalityId: userSrc.nationality_id || undefined,
  customNationality: userSrc.custom_nationality || undefined,
  nationality: userSrc.nationality || undefined,
  identityDocuments: (userSrc.identity_documents || []).map(identityDocumentFromSrc),
  locale: userSrc.locale || undefined,
  contractLocale: userSrc.contract_locale || undefined,
  canGenerateInvoice: userSrc.can_generate_invoice || undefined,
});

export const userToSrc = (user: User): UserSrc => ({
  ...driverInfoToSrc(user),
  pesel: user.pesel || null,
  id_number: user.idNumber || null,
  passport_number: user.passportNumber || null,
  residence_card_number: user.residenceCardNumber || null,
  privacy_policy_accepted_at: user.privacyPolicyAcceptedAt || null,
  // rodo_accepted_at: user.gdprAcceptedAt || null,
  updated_at: user.updatedAt,
  active_vehicle: user.activeVehicle ? vehicleToSrc(user.activeVehicle) : null,
  address: user.address ? addressToSrc(user.address) : null,
  region_id: user.regionId || null,
  region: user.region || null,
  account_type: user.accountType || null,
  partner_id: user.partnerId || null,
  drivers_count: user.driversCount || null,
  drivers_pending_join_requests_count: user.driversPendingJoinRequestsCount || null,
  missing_contracts_count:
    typeof user.missingContractsCount !== 'undefined' ? user.missingContractsCount : null,
  pending_contracts_count:
    typeof user.pendingContractsCount !== 'undefined' ? user.pendingContractsCount : null,
  roles: user.roles,
  nationality_id: user.nationalityId || null,
  custom_nationality: user.customNationality || null,
  nationality: user.nationality || null,
  identity_documents: (user.identityDocuments || []).map(identityDocumentToSrc),
  locale: user.locale || null,
  contract_locale: user.contractLocale || null,
  can_generate_invoice: user.canGenerateInvoice || null,
});

export const userUpdateToSrc = (userUpdate: UserUpdate): UserUpdateSrc => ({
  first_name: userUpdate.firstName,
  last_name: userUpdate.lastName,
  pesel: userUpdate.pesel,
  id_number: userUpdate.idNumber || undefined,
  passport_number: userUpdate.passportNumber || undefined,
  residence_card_number: userUpdate.residenceCardNumber || undefined,
  address: addressToSrc(userUpdate.address),
  region_id: userUpdate.regionId || undefined,
  nationality_id: userUpdate.nationalityId,
  custom_nationality: userUpdate.customNationality,
});
