// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { useTabContext } from '@material-ui/lab/TabContext';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type SwipeableTabPanelsProps = {
  children: React.ReactNode;
  value: string;
  onChangeValue: (v: string) => void;
  values: string[];
};

// Component
const SwipeableTabPanels = ({
  children,
  value,
  onChangeValue,
  values,
}: SwipeableTabPanelsProps) => {
  const styles = useStyles();

  const index = useMemo(() => values.indexOf(value), [value, values]);

  const handleChangeIndex = (index: number) => {
    if (index < values.length) {
      onChangeValue(values[index]);
    }
  };

  return (
    <SwipeableViews index={index} onChangeIndex={handleChangeIndex} className={styles.root}>
      {children}
    </SwipeableViews>
  );
};

export default SwipeableTabPanels;
