// Core libraries
import React, { useCallback, useEffect, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
// Internal libs

// Internal components

import Grid from '@material-ui/core/Grid';
import DataListItem from '@common/components/DataListItem';
import { numberToPhone } from '@common/validators/phoneValidator';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { Driver } from '@common/model/Driver';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CardContent from '@material-ui/core/CardContent';
import Alert from '@material-ui/lab/Alert';
import List from '@material-ui/core/List';
// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
);

// Props type
type DriverProfileBasicInfoProps = {
  driver: Driver | undefined;
};

// Component
const DriverProfileBasicInfo = ({ driver }: DriverProfileBasicInfoProps) => {
  const { t } = useTranslation(['rides', 'user', 'admin']);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={driver ? `${driver.firstName} ${driver.lastName}` : '-'} />
          <Divider />
          <CardContent>
            {driver && (
              <List>
                <DataListItem
                  label={t('admin:drivers.fields.region')}
                  text={driver.region || '-'}
                />
                <DataListItem label={t('admin:drivers.fields.email')} text={driver.email || '-'} />
                <DataListItem
                  label={t('admin:drivers.fields.emailVerified')}
                  text={driver.emailVerifiedAt || '-'}
                />
                <DataListItem label={t('admin:drivers.fields.phone')} text={driver.phone || '-'} />
                <DataListItem
                  label={t('admin:drivers.fields.phoneVerified')}
                  text={driver.phoneVerifiedAt || '-'}
                />
                <DataListItem
                  label={t('admin:drivers.fields.personalDataCompleted')}
                  text={driver.isPersonalDataCompleted ? 'Tak' : 'Nie'}
                />
                <DataListItem
                  label={t('admin:drivers.fields.allowedCompanies')}
                  text={(driver.allowedCompanies || []).map((company) => (
                    <Chip size="small" label={company.name} />
                  ))}
                />
              </List>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DriverProfileBasicInfo;
