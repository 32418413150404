import axios from 'axios';

export const acceptDocument = async (documentId: string /*, file: Blob*/) => {
  const formData = new FormData();
  // formData.append('document_file', file);
  formData.append('_method', 'PATCH');

  return axios.post(`admin/document/${documentId}/accept`, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const rejectDocument = (documentId: string, reason: string): Promise<any> => {
  return axios.patch(`admin/document/${documentId}/reject`, {
    status_reason: reason,
  });
};

export const terminateDocument = (documentId: string, reason: string): Promise<any> => {
  return axios.patch(`admin/document/${documentId}/terminate`, {
    status_reason: reason,
  });
};
