import { pick } from 'lodash';

export interface Country {
  id: number;
  name: string;
  code: string;
  language: string;
  currency: string;
}

export interface CountrySrc {
  id: number;
  name: string;
  code: string;
  language: string;
  currency: string;
}

export const countryFromSrc = (countrySrc: CountrySrc): Country => ({
  ...pick(countrySrc, 'id', 'name', 'code', 'language', 'currency'),
});

export const countryToSrc = (country: Country): CountrySrc => ({
  ...pick(country, 'id', 'name', 'code', 'language', 'currency'),
});
