// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { Redirect, RedirectProps, Route, RouteProps } from 'react-router';

// Internal libs
import { useAuth } from '../services/auth';

// Internal components

const AnonymousRoute = ({
  redirect,
  children,
  ...rest
}: { redirect?: string | RedirectProps } & RouteProps) => {
  const auth = useAuth();

  const redirectProps = useMemo(
    () => (typeof redirect === 'string' ? { to: redirect } : redirect),
    [redirect],
  );

  const conditionalRender = () => {
    return !auth.isAuthenticated() ? (
      children
    ) : redirectProps ? (
      <Redirect {...redirectProps} />
    ) : null;
  };

  return <Route {...rest} render={conditionalRender} />;
};

export default AnonymousRoute;
