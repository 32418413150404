import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { UserState } from '../model';
import { getPendingUserPhoneChangeRequest } from '@features/user/api';
import { PhoneChangeRequest } from '@features/user/model/PhoneChangeRequest';

export const verifyUserEmailInit = (
  state: UserState,
  action: PayloadAction<{ tokenId: string; token: string }>,
) => {};
export const verifyUserEmailSuccess = (state: UserState, action: Action) => {};
export const verifyUserEmailFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

export const requestUserEmailTokenInit = (state: UserState, action: Action) => {};
export const requestUserEmailTokenSuccess = (state: UserState, action: Action) => {};
export const requestUserEmailTokenFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

export const verifyUserPhoneInit = (state: UserState, action: PayloadAction<string>) => {};
export const verifyUserPhoneSuccess = (state: UserState, action: Action) => {};
export const verifyUserPhoneFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

// Phone change
export const verifyUserPhoneChangeInit = (state: UserState, action: Action) => {};
export const verifyUserPhoneChangeSuccess = (state: UserState, action: Action) => {};
export const verifyUserPhoneChangeFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

// Delete account
export const deleteAccountInit = (state: UserState, action: Action) => {};
export const deleteAccountSuccess = (state: UserState, action: Action) => {};
export const deleteAccountFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

export const getPendingUserPhoneChangeRequestInit = (state: UserState, action: Action) => {
  state.getPhoneChangeRequestState = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const getPendingUserPhoneChangeRequestSuccess = (
  state: UserState,
  action: PayloadAction<PhoneChangeRequest>,
) => {
  state.getPhoneChangeRequestState = {
    inProgress: false,
    error: undefined,
    success: true,
  };
  state.phoneChangeRequest = action.payload;
};
export const getPendingUserPhoneChangeRequestFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {
  state.getPhoneChangeRequestState = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
  state.phoneChangeRequest = undefined;
};

export const requestUserPhoneChangeTokenInit = (state: UserState, action: Action) => {};
export const requestUserPhoneChangeTokenSuccess = (state: UserState, action: Action) => {};
export const requestUserPhoneChangeTokenFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

export const requestUserPhoneCodeInit = (state: UserState, action: Action) => {};
export const requestUserPhoneCodeSuccess = (state: UserState, action: Action) => {};
export const requestUserPhoneCodeFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

const verifyUserDataReducers = {
  verifyUserEmailInit,
  verifyUserEmailSuccess,
  verifyUserEmailFailure,
  requestUserEmailTokenInit,
  requestUserEmailTokenSuccess,
  requestUserEmailTokenFailure,
  verifyUserPhoneInit,
  verifyUserPhoneSuccess,
  verifyUserPhoneFailure,

  verifyUserPhoneChangeInit,
  verifyUserPhoneChangeSuccess,
  verifyUserPhoneChangeFailure,
  requestUserPhoneChangeTokenInit,
  requestUserPhoneChangeTokenSuccess,
  requestUserPhoneChangeTokenFailure,
  getPendingUserPhoneChangeRequestInit,
  getPendingUserPhoneChangeRequestSuccess,
  getPendingUserPhoneChangeRequestFailure,

  requestUserPhoneCodeInit,
  requestUserPhoneCodeSuccess,
  requestUserPhoneCodeFailure,

  deleteAccountInit,
  deleteAccountSuccess,
  deleteAccountFailure,
};
export default verifyUserDataReducers;
