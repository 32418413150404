// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import SomeIcon from '@material-ui/icons/AccountCircle';
import Divider from '@material-ui/core/Divider';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    item: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    textContainerReversed: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    textLine: {
      display: 'block',
    },
  }),
);

// Props type
type DataListItemProps = {
  label?: string;
  icon?: React.ReactNode;
  text: React.ReactNode | string | string[];
  actions?: React.ReactNode;
  dense?: boolean;
  divider?: boolean;
};

// Component
const DataListItem = ({ label, icon, text, actions, dense, divider, ...otherProps }: DataListItemProps) => {
  const styles = useStyles();

  const textContent = useMemo(
    () =>
      Array.isArray(text)
        ? // @ts-ignore
          text.map((line: React.ReactNode | string) =>
            typeof line === 'string' ? <span className={styles.textLine}>{line}</span> : line,
          )
        : text,
    [text],
  );

  return (
    <>
      <ListItem title="title" dense={dense} className={styles.item} {...otherProps}>
        {icon && (
          <ListItemIcon>
            <SomeIcon />
          </ListItemIcon>
        )}
        <ListItemText
          secondary={label}
          primary={textContent}
          classes={{ multiline: styles.textContainerReversed }}
        />
        {actions && <ListItemSecondaryAction>{actions}</ListItemSecondaryAction>}
      </ListItem>
      {divider && <Divider />}
    </>
  );
};

export default DataListItem;
