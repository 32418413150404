export const textSearch = <T>(
  searchQuery: string,
  data: T,
  extractFields: (element: T) => string[],
) => {
  // Extract elements from search query
  const queryElements = searchQuery
    .toLowerCase()
    .split(' ')
    .filter((elem: string) => !!elem);

  // Extract string fields to search in
  const fields = extractFields(data);

  // For every queryElement some fields contain it
  return queryElements.every((queryElement) =>
    fields.some((field) => field.toLowerCase().indexOf(queryElement) !== -1),
  );
};
