import i18n from 'i18next';
import Detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Detector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    supportedLngs: ['pl', 'en', 'cz'],
    fallbackLng: 'en',
    debug: false,
    keySeparator: '.', // we do not use keys in form messages.welcome
    ns: [
      'admin',
      'auth',
      'common',
      'company',
      'contracts',
      'dashboard',
      'partner',
      'privacyPolicy',
      'rides',
      'user',
      'vehicles',
    ],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // react already safes from xss
      //formatSeparator: ","
    },
    react: {
      useSuspense: true,
      // wait: true,
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
    },
  });

export default i18n;
