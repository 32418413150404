import { AccountType } from '@features/user/model/Settlements';
import {
  timestampable,
  timestampableFromSrc,
  timestampableSrc,
  timestampableToSrc,
} from '@common/model';

export interface ContractTemplate extends timestampable {
  id: number;
  name: string;
  description?: string;
  accountTypeRestriction?: AccountType;
  personalDataRequired: boolean;
  companyDataRequired: boolean;
}

export interface ContractTemplateSrc extends timestampableSrc {
  id: number;
  name: string;
  description: string | null;
  account_type_restriction: AccountType | null;
  personal_data_required: boolean;
  company_data_required: boolean;
}

export const contractTemplateFromScr = (
  contractTemplateSrc: ContractTemplateSrc,
): ContractTemplate => ({
  id: contractTemplateSrc.id,
  name: contractTemplateSrc.name,
  description: contractTemplateSrc.description || undefined,
  accountTypeRestriction: contractTemplateSrc.account_type_restriction || undefined,
  personalDataRequired: contractTemplateSrc.personal_data_required,
  companyDataRequired: contractTemplateSrc.company_data_required,
  ...timestampableFromSrc(contractTemplateSrc),
});

export const contractTemplateToSrc = (contractTemplate: ContractTemplate): ContractTemplateSrc => ({
  id: contractTemplate.id,
  name: contractTemplate.name,
  description: contractTemplate.description || null,
  account_type_restriction: contractTemplate.accountTypeRestriction || null,
  personal_data_required: contractTemplate.personalDataRequired,
  company_data_required: contractTemplate.companyDataRequired,
  ...timestampableToSrc(contractTemplate),
});
