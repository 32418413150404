import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';
import { InitState } from './model';

const initSlice = createSlice<InitState, typeof reducers>({
  name: 'init',
  initialState: {
    init: undefined,
    availableCountries: {
      list: undefined,
      state: undefined,
    },
  },
  reducers: {
    ...reducers,
  },
});

export const reducer = initSlice.reducer;
export const actions = initSlice.actions;
