import { createSlice } from '@reduxjs/toolkit';
import * as reducers from './reducers';
import { CorrectionsState } from '@features/admin/corrections/store/model';


const correctionsSlice = createSlice<CorrectionsState, typeof reducers>({
  name: 'corrections',
  initialState: {
    getCorrectionsState: { inProgress: false },
    correctionsList: undefined,
  },
  reducers: {
    ...reducers,
  }
})



export const correctionReducer = correctionsSlice.reducer;
export const correctionActions = correctionsSlice.actions;
