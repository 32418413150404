import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';

export const useAuth = () => {
  const token = useSelector<RootState, string | undefined>((state) => state.auth.token);

  const isAuthenticated = useCallback(() => !!token, [token]);

  // const hasRole = useCallback((role) => !!user && role in user.roles, [user]);

  return {
    isAuthenticated,
    // hasRole,
    // user,
  };
};
