import { useMemo } from 'react';
import { isValid } from 'iban';

const countryPattern = /(^[A-z]$)|(^[A-z]{2})/;

export const cleanIban = (iban: string, defaultCountry?: string) => {
  let retIban = iban.toUpperCase().replace(/[ -]/g, '');

  if (defaultCountry && !countryPattern.test(retIban)) {
    retIban = `${defaultCountry}${retIban}`;
  }

  return retIban;
};

export const ibanValidator = (message?: string, defaultCountry?: string) => (value: string) => {
  const iban = cleanIban(value, defaultCountry);
  return isValid(iban) || message || false;
};

const useIbanValidator = (message?: string, defaultCountry?: string) => {
  return useMemo(() => ibanValidator(message, defaultCountry), [message]);
};
export default useIbanValidator;
