// Core libraries
import React from 'react';

// External libs and components
// import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';

// Internal libs

// Internal components
import PageDefaultLayout from '@common/components/layouts/PageDefaultLayout';
import RideModal from '@features/rides/containers/RideModal';
import PartnerRidesCard from './PartnerRidesCard';

// // Styles hook
// const useStyles = makeStyles((theme) =>
//   createStyles({
//     root: {},
//   }),
// );

// Props type
type PartnerRidesPageProps = {};

// Component
const PartnerRidesPage = ({}: PartnerRidesPageProps) => {
  const { t } = useTranslation('rides');
  // const styles = useStyles();
  const { path } = useRouteMatch();
  const history = useHistory();

  return (
    <PageDefaultLayout title={t('title')}>
      <PartnerRidesCard />

      <Switch>
        <Route path={`${path}/ride/:rideId`}>
          <RideModal onClose={() => history.push(path)} />
        </Route>
      </Switch>
    </PageDefaultLayout>
  );
};

export default PartnerRidesPage;
