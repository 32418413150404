import { useState, useEffect } from 'react';
import { getUserCountry } from '@features/init/api';

const useGeoLocation = () => {
  const [country, setCountry] = useState(undefined);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;

    if (country || country === false) return;
    async function fetchApi() {
      setIsLoading(true);

      try {
        const response = await getUserCountry();
        if (response.data?.country && !isCancelled) {
          setCountry(response.data.country);
        }
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    }
    fetchApi();
    return () => {
      isCancelled = true;
    };
  }, []);

  return { country, error, isLoading };
};

export default useGeoLocation;
