// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Internal libs
import { Address } from '../model/Address';

// Internal components
import FormTextField from './FormTextField';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(1),
    },
  }),
);

// Props type
type FormAddressGridProps = {
  getFieldErrors: (
    fieldName: string,
    backendFieldName?: string,
    translateFullPrefix?: string,
  ) => string[];
  address?: Address;
};

// Component
const FormAddressGrid = ({ getFieldErrors, address }: FormAddressGridProps) => {
  const { t } = useTranslation('common');
  const styles = useStyles();

  return (
    <Grid container spacing={2}>
      {/* Street Name */}
      <Grid item xs={12} sm={6}>
        <FormTextField
          className={styles.field}
          name="addressStreetName"
          label={t('addressEditForm.fields.streetName.label', '')}
          rules={{
            required: {
              value: true,
              message: t('addressEditForm.fields.streetName.errors.required', ''),
            },
            minLength: {
              value: 3,
              message: t('addressEditForm.fields.streetName.errors.minLength', ''),
            },
          }}
          fullWidth={true}
          errors={getFieldErrors('address.street_name')}
          defaultValue={address?.streetName || ''}
        />
      </Grid>

      {/* Street number*/}
      <Grid item xs={6} sm={3}>
        <FormTextField
          className={styles.field}
          name="addressStreetNumber"
          label={t('addressEditForm.fields.streetNumber.label', '')}
          rules={{
            required: {
              value: true,
              message: t('addressEditForm.fields.streetNumber.errors.required', ''),
            },
          }}
          fullWidth={true}
          errors={getFieldErrors('address.street_number')}
          defaultValue={address?.streetNumber || ''}
        />
      </Grid>

      {/* Apartment number*/}
      <Grid item xs={6} sm={3}>
        <FormTextField
          className={styles.field}
          name="addressApartmentNumber"
          label={t('addressEditForm.fields.apartmentNumber.label', '')}
          rules={{}}
          fullWidth={true}
          errors={getFieldErrors('address.apartment_number')}
          defaultValue={address?.apartmentNumber || ''}
        />
      </Grid>

      {/* Zip code */}
      <Grid item xs={12} sm={6}>
        <FormTextField
          className={styles.field}
          name="addressZipCode"
          label={t('addressEditForm.fields.zipCode.label', '')}
          rules={{
            required: {
              value: true,
              message: t('addressEditForm.fields.zipCode.errors.required', ''),
            },
          }}
          fullWidth={true}
          errors={getFieldErrors('address.zip_code')}
          defaultValue={address?.zipCode || ''}
        />
      </Grid>

      {/* City */}
      <Grid item xs={12} sm={6}>
        <FormTextField
          className={styles.field}
          name="addressCity"
          label={t('addressEditForm.fields.city.label', '')}
          rules={{
            required: {
              value: true,
              message: t('addressEditForm.fields.city.errors.required', ''),
            },
          }}
          fullWidth={true}
          errors={getFieldErrors('address.city')}
          defaultValue={address?.city || ''}
        />
      </Grid>
    </Grid>
  );
};

export default FormAddressGrid;
