import { Action, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from '../model';
import { FailureResponse } from '@common/model';

// Login reducers
export const loginClearState = (state: AuthState) => {
  state.login = undefined;
};

export const loginInit = (
  state: AuthState,
  action: PayloadAction<{ username: string; password: string }>,
) => {
  state.login = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const loginSuccess = (state: AuthState, action: PayloadAction<string>) => {
  state.login = {
    inProgress: false,
    error: undefined,
    success: true,
  };
  state.token = action.payload;
};
export const loginFailure = (state: AuthState, action: PayloadAction<FailureResponse>) => {
  state.login = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
};

// Login social
// There is no success/failure actions, as this action results in redirect outside the app.
export const loginSocialInit = (state: AuthState, action: PayloadAction<string>) => {};

export const loginSocialConfirmTokenInit = (state: AuthState, action: PayloadAction<string>) => {
  state.login = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const loginSocialConfirmTokenSuccess = (state: AuthState, action: PayloadAction<string>) => {
  state.login = {
    inProgress: false,
    error: undefined,
    success: true,
  };
  state.token = action.payload;
};
export const loginSocialConfirmTokenFailure = (
  state: AuthState,
  action: PayloadAction<FailureResponse>,
) => {
  state.login = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
};

// Logout
export const logoutInit = (state: AuthState, action: Action) => {
  state.logout = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const logoutSuccess = (state: AuthState, action: Action) => {
  state.logout = {
    inProgress: false,
    error: undefined,
    success: true,
  };
  state.token = undefined;
};
export const logoutFailure = (state: AuthState, action: PayloadAction<FailureResponse>) => {
  state.logout = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
};

export const restoreToken = (state: AuthState, action: PayloadAction<string>) => {
  state.token = action.payload;
};

const loginReducers = {
  loginClearState,
  loginInit,
  loginSuccess,
  loginFailure,
  loginSocialInit,
  loginSocialConfirmTokenInit,
  loginSocialConfirmTokenSuccess,
  loginSocialConfirmTokenFailure,
  logoutInit,
  logoutSuccess,
  logoutFailure,
  restoreToken,
};
export default loginReducers;
