// Core libraries
import React, { useEffect, useMemo, useState } from 'react';

// External libs and components
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
// import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import LinearProgress from '@material-ui/core/LinearProgress';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

// Internal libs
import useMUIDataTableDefaultOptions from '@common/miscHooks/useMUIDataTableDefaultOptions';
import { PaginatedData, InProgressState } from '@common/model';
import { Ride } from '../model';
import { actions } from '../store';

// Internal components

// Styles hook
// const useStyles = makeStyles((theme) =>
//   createStyles({
//     root: {},
//   }),
// );

// Props type
type RidesCardProps = {};

// Component
const RidesCard = ({}: RidesCardProps) => {
  const { t } = useTranslation('rides');
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  // const styles = useStyles();

  const [getRidesState, setGetRidesState] = useState<InProgressState>({ inProgress: false });
  const [paginatedRides, setPaginatedRides] = useState<PaginatedData<Ride> | undefined>(undefined);

  const mappedRides = useMemo(() => {
    return (
      paginatedRides &&
      paginatedRides.data.map((ride) => ({
        ...ride,
      }))
    );
  }, [paginatedRides]);

  // Fetch drivers
  const getRides = async (page?: number, showTotalItems?: number) => {
    setGetRidesState({ inProgress: true });
    try {
      const paginatedRides: PaginatedData<Ride> = ((await dispatch(
        actions.getRides(page, showTotalItems),
      )) as unknown) as PaginatedData<Ride>;
      setPaginatedRides(paginatedRides);
      setGetRidesState({ inProgress: false, success: true });
    } catch (e) {
      setGetRidesState({ inProgress: false, success: false, error: e });
    }
  };

  // Init
  useEffect(() => {
    getRides();
  }, []);

  // Columns definition
  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'status',
        label: t('model.ride.status.label'),
        options: {
          customBodyRender: (value) => (value ? t(`model.ride.status.map.${value}`, value) : '-'),
        },
      },
      {
        name: 'no',
        label: t('model.ride.no'),
      },
      {
        name: 'type',
        label: t('model.ride.type.label'),
        options: {
          customBodyRender: (value) => t(`model.ride.type.map.${value}`, value),
        },
      },
      {
        name: 'payout',
        label: t('model.ride.payout'),
      },
      {
        name: 'invoiceId',
        label: t('rides:model.ride.invoicedSettled.label'),
        options: {
          customBodyRender: (value: string) =>
            value
              ? t('rides:model.ride.invoicedSettled.settled.yes')
              : t('rides:model.ride.invoicedSettled.settled.no'),
        },
      },
      {
        name: 'partnerName',
        label: t('rides:model.ride.partnerName'),
        options: {
          customBodyRender: (value: string) => value || '-',
        }
      },
      {
        name: 'closedAt',
        label: t('rides:model.ride.closedAt'),
        options: {
          customBodyRender: (value: string) => (value ? new Date(value).toLocaleString() : '-'),
        },
      },
      {
        name: 'createdAt',
        label: t('model.ride.createdAt'),
        options: {
          customBodyRender: (value: string) => (value ? new Date(value).toLocaleDateString() : '-'),
        },
      },
      {
        name: 'deliveredAt',
        label: t('model.ride.deliveredAt'),
        options: {
          customBodyRender: (value: string) => (value ? new Date(value).toLocaleDateString() : '-'),
        },
      },
      {
        name: 'cancelledAt',
        label: t('model.ride.cancelledAt'),
        options: {
          customBodyRender: (value: string) => (value ? new Date(value).toLocaleDateString() : '-'),
        },
      },
    ],
    [t],
  );

  // Table options
  const options: MUIDataTableOptions = {
    ...useMUIDataTableDefaultOptions(true),
    sort: false,
    search: false,
    filter: false,
    count: paginatedRides?.total,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50],
    // @ts-ignore
    rowsPerPage: paginatedRides?.perPage && parseInt(paginatedRides.perPage),
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          getRides(tableState.page + 1, tableState.rowsPerPage);
          break;

        case 'changeRowsPerPage':
          getRides(1, tableState.rowsPerPage);
          break;

        default:
          console.log('action', action);
      }
    },
    onRowClick: (rowData, { dataIndex }) => {
      const row = paginatedRides?.data && paginatedRides.data[dataIndex];
      if (row) {
        history.push(`${path}/ride/${row.id}`);
      }
    },
  };

  return (
    <Card>
      {getRidesState?.inProgress && <LinearProgress data-testid="loading-rides" />}
      <MUIDataTable
        title={t('ridesList.title')}
        data={paginatedRides?.data || []}
        columns={columns}
        options={options}
      />
    </Card>
  );
};

export default RidesCard;
