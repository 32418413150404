// Core libraries
import React, { useEffect, useMemo, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// Internal libs
import { InProgressState } from '@common/model';
import { actions as authActions } from '../../store';

// Internal components
import SinglePageLayout from '@common/components/layouts/SinglePageLayout';
import SetNewPasswordForm from './SetNewPasswordForm';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    labelWrapper: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',

      marginTop: theme.spacing(4),
    },
    errorLabel: {
      color: theme.palette.error.main,
    },
    successLabel: {
      color: theme.palette.success.main,
    },
  }),
);

// Props type
type SetNewPasswordProps = {};

// Component
const SetNewPasswordPage = ({}: SetNewPasswordProps) => {
  const { t } = useTranslation('auth');
  const params: { tokenId: string; token: string } = useParams();
  const dispatch = useDispatch();
  const styles = useStyles();

  const [verifyTokenState, setVerifyTokenState] = useState<InProgressState>({ inProgress: false });

  useEffect(() => {
    verifyToken();
  }, [params]);

  const verifyToken = async () => {
    setVerifyTokenState({ inProgress: true });

    try {
      await dispatch(authActions.verifySetNewPasswordToken(params.tokenId, params.token));
      setVerifyTokenState({ inProgress: false, success: true });
    } catch (e) {
      setVerifyTokenState({ inProgress: false, success: false, error: e });
    }
  };

  const errorLabel = useMemo(() => {
    if (!verifyTokenState.error?.error) {
      return t('setNewPassword.tokenVerification.errors.unknown');
    }

    const error = verifyTokenState.error.error.replace('.', '_');

    return t(
      `setNewPassword.tokenVerification.errors.${error}` /*, t('setNewPassword.tokenVerification.unknown')*/,
    );
  }, [verifyTokenState.error]);

  return (
    <SinglePageLayout showBgImage={true} maxWidth="sm">
      <div className={styles.labelWrapper}>
        {/* Verifying */}
        {verifyTokenState.inProgress && (
          <>
            <SinglePageLayout.SinglePageLayoutLabel
              label={t('setNewPassword.tokenVerification.messages.inProgress')}
            />
            <SinglePageLayout.SinglePageLayoutSpinner />
          </>
        )}

        {/* Success */}
        {!verifyTokenState.inProgress && verifyTokenState.success && (
          <SetNewPasswordForm token={params.token} tokenId={params.tokenId} />
        )}

        {/* Failure */}
        {!verifyTokenState.inProgress && verifyTokenState.error && (
          <>
            <SinglePageLayout.SinglePageLayoutLabel
              label={errorLabel}
              className={styles.errorLabel}
            />
            <div className={styles.buttonWrapper}>
              <Button component={RouterLink} to="/" variant="contained" color="primary" fullWidth>
                {t('setNewPassword.tokenVerification.goBackButton')}
              </Button>
            </div>
          </>
        )}
      </div>
    </SinglePageLayout>
  );
};

export default SetNewPasswordPage;
