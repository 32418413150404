// Core libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// Internal libs
import { InProgressState } from '@common/model';
import { actions as userActions } from '../../store';

// Internal components
import SinglePageLayout from '@common/components/layouts/SinglePageLayout';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    labelWrapper: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',

      marginTop: theme.spacing(4),
    },
    errorLabel: {
      color: theme.palette.error.main,
    },
    successLabel: {
      color: theme.palette.success.main,
    },
  }),
);

// Props type
type EmailVerifyPageProps = {};

// Component
const EmailVerifyPage = ({}: EmailVerifyPageProps) => {
  const { t } = useTranslation('user');
  const params: { tokenId: string; token: string } = useParams();
  const dispatch = useDispatch();
  const styles = useStyles();

  const [verifyEmailState, setVerifyEmailState] = useState<InProgressState>({ inProgress: false });

  const verifyEmail = useCallback(async () => {
    setVerifyEmailState({ inProgress: true });

    try {
      await dispatch(userActions.verifyUserEmail(params.tokenId, params.token));
      setVerifyEmailState({ inProgress: false, success: true });
    } catch (e) {
      setVerifyEmailState({ inProgress: false, success: false, error: e });
    }
  }, [dispatch, setVerifyEmailState, params.tokenId, params.token]);

  useEffect(() => {
    verifyEmail().then();
  }, [verifyEmail]);

  const errorLabel = useMemo(() => {
    if (!verifyEmailState.error?.error) {
      return t('verifyEmail.errors.unknown');
    }

    const error = verifyEmailState.error.error.replace('.', '_');

    return t(`verifyEmail.errors.${error}`, t('verifyEmail.errors.unknown'));
  }, [verifyEmailState.error, t]);

  return (
    <SinglePageLayout showBgImage={true} maxWidth="sm">
      <div className={styles.labelWrapper}>
        {/* Verifying */}
        {verifyEmailState.inProgress && (
          <>
            <SinglePageLayout.SinglePageLayoutLabel label={t('verifyEmail.messages.inProgress')} />
            <SinglePageLayout.SinglePageLayoutSpinner />
          </>
        )}

        {/* Success */}
        {!verifyEmailState.inProgress && verifyEmailState.success && (
          <SinglePageLayout.SinglePageLayoutLabel label={t('verifyEmail.messages.success')} />
        )}

        {/* Failure */}
        {!verifyEmailState.inProgress && !verifyEmailState.success && (
          <SinglePageLayout.SinglePageLayoutLabel
            label={errorLabel}
            className={styles.errorLabel}
          />
        )}
      </div>

      {/* Go back button */}
      {!verifyEmailState.inProgress && (
        <div className={styles.buttonWrapper}>
          <Button
            component={RouterLink}
            to="/"
            variant="contained"
            color="primary"
            onClick={() => {}}
          >
            {t('verifyEmail.okButton')}
          </Button>
        </div>
      )}
    </SinglePageLayout>
  );
};

export default EmailVerifyPage;
