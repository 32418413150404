// Core libraries
import React from 'react';

// External libs and components
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import PartnerIcon from '@material-ui/icons/PeopleAlt';

// Internal libs
import { RootState } from '@app/store';
import { User } from '../../model/User';
import { selectors as userSelectors } from '../../store';

// Internal components
import SimpleHeader from '@common/components/cardContent/SimpleHeader';
import DataItem from '@common/components/cardContent/DataItem';
import UpsertCompanyModal from './UpsertCompanyModal';
import CompanyCardUploadDocuments from './CompanyCardUploadDocuments';
import TwoLineAddress from '@common/components/dataViews/TwoLineAddress';
import BankAccountNo from '@common/components/dataViews/BankAccountNo';

// Props type
type CompanyCardElementProps = {};

// Component
const CompanyCardElement = ({}: CompanyCardElementProps) => {
  const { t } = useTranslation('user');
  const history = useHistory();
  const { path } = useRouteMatch();

  const missingCompany = useSelector(userSelectors.selectUserMissingCompany);

  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  return (
    <>
      {missingCompany && (
        <Alert
          severity="warning"
          data-testid="missing-company-alert"
          action={
            <Button
              size="small"
              color="inherit"
              onClick={() => history.push(`${path}/edit-company`)}
            >
              {t('cards.settlementsData.company.missingCompany.editButton')}
            </Button>
          }
        >
          {t('cards.settlementsData.company.missingCompany.message')}
        </Alert>
      )}

      {userData?.company && (
        <>
          <SimpleHeader
            title={userData.company.name}
            chips={
              userData.company.isPartner
                ? [
                    {
                      label: t('company.fields.isPartner.badge'),
                      color: 'secondary',
                      icon: <PartnerIcon />,
                    },
                  ]
                : undefined
            }
            action={
              <IconButton edge="end" onClick={() => history.push(`${path}/edit-company`)}>
                <EditIcon />
              </IconButton>
            }
          />
          <Divider />

          <Grid container spacing={1}>
            <Grid item md={6}>
              <DataItem
                label={t('company.fields.registrationCountry')}
                text={t(`common:countries.${userData.company.registrationCountryCode}`)}
              />
            </Grid>
            <Grid item md={6}>
              <DataItem
                label={t(
                  `company.fields.taxIdentificationNumber.${
                    userData.company.countryDefinition?.taxIdentificationNumberName ?? 'generic'
                  }`,
                )}
                text={userData.company.taxIdentificationNumber}
              />
            </Grid>
            <Grid item md={6}>
              <DataItem
                label={t('company.fields.isVatPayer')}
                text={userData.company.isVatPayer ? t('common:Yes') : t('common:No')}
              />
            </Grid>
            <Grid item md={6}>
              <DataItem
                label={t(
                  `company.fields.registrationNumber.${
                    userData.company.countryDefinition?.registrationNumberName ?? 'generic'
                  }`,
                )}
                text={userData.company.registrationNumber}
              />
            </Grid>
            <Grid item md={6}>
              <DataItem
                label={t('company.fields.address')}
                text={
                  userData.company.address ? (
                    <TwoLineAddress address={userData.company.address} />
                  ) : (
                    '-'
                  )
                }
              />
            </Grid>
            <Grid item md={12}>
              <DataItem
                label={t('company.fields.bankAccountNo')}
                text={<BankAccountNo bankAccountNo={userData.company.bankAccountNo} />}
              />
            </Grid>
            <Grid item xs={12}>
              <CompanyCardUploadDocuments />
            </Grid>
          </Grid>
        </>
      )}

      <Switch>
        <Route path={`${path}/edit-company`} component={UpsertCompanyModal} />
      </Switch>
    </>
  );
};

export default CompanyCardElement;
