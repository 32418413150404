import {
  timestampable,
  timestampableFromSrc,
  timestampableSrc,
  timestampableToSrc,
} from '@common/model';
import { Partner, partnerFromSrc, PartnerSrc, partnerToSrc } from '@features/user/model/Partner';

export interface PartnerRequest extends timestampable {
  id: number;
  status: string;
  company: Partner;
}

export interface PartnerRequestSrc extends timestampableSrc {
  id: number;
  status: string;
  company: PartnerSrc;
}

export const partnerRequestFromSrc = (partnerRequestSrc: PartnerRequestSrc): PartnerRequest => ({
  id: partnerRequestSrc.id,
  status: partnerRequestSrc.status,
  company: partnerFromSrc(partnerRequestSrc.company),
  ...timestampableFromSrc(partnerRequestSrc),
});

export const partnerRequestToSrc = (partnerRequest: PartnerRequest): PartnerRequestSrc => ({
  id: partnerRequest.id,
  status: partnerRequest.status,
  company: partnerToSrc(partnerRequest.company),
  ...timestampableToSrc(partnerRequest),
});
