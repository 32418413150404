// Core libraries
import React from 'react';

// External libs and components
import { Route, Switch, useRouteMatch } from 'react-router';

// Internal libs

// Internal components
import AdminInvoicesCard from '@features/admin/invoices/conteiners/AdminInvoicesCard';

// Props type
type InvoicesProps = {};

// Component
const AdminInvoices = ({}: InvoicesProps) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} component={AdminInvoicesCard} />
    </Switch>
  );
};

export default AdminInvoices;
