import { createSlice } from '@reduxjs/toolkit';
import { VehiclesState } from './model';
import reducers from './reducers';

const vehiclesSlice = createSlice<VehiclesState, typeof reducers>({
  name: 'vehicles',
  initialState: {
    vehiclesListState: undefined,
    vehiclesList: undefined,
  },
  reducers: {
    ...reducers,
  },
});

export const reducer = vehiclesSlice.reducer;
export const actions = vehiclesSlice.actions;
