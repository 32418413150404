// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Route, Switch, useRouteMatch } from 'react-router';

// Internal libs

// Internal components
import PageDefaultLayout from '@common/components/layouts/PageDefaultLayout';
import DriversCard from '@features/admin/drivers/conteiners/DriversCard';
import DriverContractModal from '@features/admin/drivers/conteiners/DriverContractModal';
import Drivers from '@features/admin/drivers/conteiners/Drivers';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type DriversPageProps = {};

// Component
const DriversPage = ({}: DriversPageProps) => {
  const { t } = useTranslation('admin');
  const { path } = useRouteMatch();

  return (
    <PageDefaultLayout title={t('drivers.title')}>
      <Switch>
        <Route path={`${path}/contract/:driverId`}>
          <DriverContractModal />
        </Route>
      </Switch>
      <Drivers />
    </PageDefaultLayout>
  );
};

export default DriversPage;
