// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { Redirect, RedirectProps, Route, RouteProps } from 'react-router';

// Internal libs
import { RootState } from '@app/store';
import { useSelector } from 'react-redux';

// Internal components

type SelectorRouteProps = {
  selector: (state: RootState) => any;
  condition?: (value: any) => boolean;
  redirect: string | RedirectProps;
} & RouteProps;

const SelectorRoute = ({
  selector,
  condition,
  redirect,
  children,
  ...rest
}: SelectorRouteProps) => {
  const redirectProps = useMemo(
    () => (typeof redirect === 'string' ? { to: redirect } : redirect),
    [redirect],
  );

  const value = useSelector(selector);

  const isConditionMet = useMemo(() => (condition ? condition(value) : !!value), [value]);

  const conditionalRender = () => {
    return isConditionMet ? children : <Redirect {...redirectProps} />;
  };

  return <Route {...rest} render={conditionalRender} />;
};

export default SelectorRoute;
