import axios from 'axios';
import {
  DriverJoinRequest,
  driverJoinRequestFromSrc,
  DriverJoinRequestSrc,
  PartnerDriver,
  partnerDriverFromSrc,
  PartnerDriverSrc,
} from '@features/partner/model';

// Join Requests
export const getPartnerPendingRequests = async (
  partnerCompanyId: number,
): Promise<DriverJoinRequest[]> => {
  const response = await axios.get(`/partner/${partnerCompanyId}/driver/pending_list`);

  return response.data.map((driverJoinRequestSrc: DriverJoinRequestSrc) =>
    driverJoinRequestFromSrc(driverJoinRequestSrc),
  );
};

export const acceptPartnerPendingRequest = async (joinRequestId: number) => {
  return await axios.post(`/partner/accept-request/${joinRequestId}`);
};

export const rejectPartnerPendingRequest = async (joinRequestId: number) => {
  return await axios.post(`/partner/reject-request/${joinRequestId}`);
};

export const removeDriverPartner = async (
  partnerCompanyId: number,
  driverId: number,
  terminationComment?: string,
) => {
  const params = new URLSearchParams();
  if (terminationComment && terminationComment.trim().length) {
    params.append('termination_comment', terminationComment);
  }

  return await axios.delete(`partner/${partnerCompanyId}/driver/${driverId}`, { params });
};

// Drivers
export const getPartnerDrivers = async (partnerCompanyId: number): Promise<PartnerDriver[]> => {
  const response = await axios.get(`/partner/${partnerCompanyId}/driver/list`);

  return response.data.map((driver: PartnerDriverSrc) => partnerDriverFromSrc(driver));
};
