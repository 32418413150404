import { DriversState, ContractComparisonState } from '@features/admin/drivers/store/model';
import { PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { DriverContractComparison } from '@features/admin/drivers/model/DriverContracts';

export const downloadDriverContractInit = (state: DriversState) => {};
export const downloadDriverContractSuccess = (state: DriversState) => {};
export const downloadDriverContractFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {};

export const getContractComparisonInit = (
  state: ContractComparisonState,
  action: PayloadAction<number>,
) => {};
export const getContractComparisonSuccess = (
  state: ContractComparisonState,
  action: PayloadAction<DriverContractComparison>,
) => {};
export const getContractComparisonFailure = (
  state: ContractComparisonState,
  action: PayloadAction<FailureResponse>,
) => {};
