// Get current User
import { RootThunk } from '@common/model';
import { actions as simpleActions } from '../slice';
import * as api from '../../api';
import { getContractTemplatesWithContracts } from '@features/contracts/store/actions';

export const getAllowedIdentityDocuments = (): RootThunk => async (dispatch) => {
  dispatch(simpleActions.getAllowedIdentityDocumentsInit());

  try {
    const identityDocuments = await api.getAllowedIdentityDocuments();
    dispatch(simpleActions.getAllowedIdentityDocumentsSuccess(identityDocuments));
  } catch (e) {
    dispatch(simpleActions.getAllowedIdentityDocumentsFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};

export const uploadIdentityDocument = (
  identityDocumentFile: File,
  name: string,
  progressCallback: (event: { loaded: number; total: number }) => void,
): RootThunk => async (dispatch): Promise<any> => {
  dispatch(
    simpleActions.uploadIdentityDocumentInit({
      name,
    }),
  );

  try {
    await api.uploadIdentityDocument(identityDocumentFile, name, progressCallback);
    dispatch(simpleActions.uploadIdentityDocumentSuccess());
    // Update identity documents fetch from user
    // dispatch(getContractTemplatesWithContracts());
  } catch (e) {
    dispatch(simpleActions.uploadIdentityDocumentFailure(e.response?.data || { error: 'unknown' }));
    throw e.response?.data || { error: 'unknown' };
  }
};

export const downloadIdentityDocument = (url: string, fileName: string): RootThunk => async (
  dispatch,
): Promise<any> => {
  dispatch(simpleActions.downloadIdentityDocumentInit({ url, fileName }));

  try {
    const response = await api.downloadIdentityDocument(url, fileName);
    dispatch(simpleActions.downloadIdentityDocumentSuccess());
  } catch (e) {
    dispatch(
      simpleActions.downloadIdentityDocumentFailure(e.response?.data || { error: 'unknown' }),
    );
    throw e.response?.data || { error: 'unknown' };
  }
};

export const getDriverIdentityDocuments = (): RootThunk => async (dispatch) => {
  dispatch(simpleActions.getDriverIdentityDocumentsInit());

  try {
    const currentUser = await api.getCurrentUser();
    dispatch(simpleActions.getDriverIdentityDocumentsSuccess(currentUser.identityDocuments));
  } catch (e) {
    dispatch(simpleActions.getDriverIdentityDocumentsFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};
