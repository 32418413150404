// Core libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// External libs and components
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
// import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import LinearProgress from '@material-ui/core/LinearProgress';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';

// Internal libs
import { PaginatedData, InProgressState } from '@common/model';
import useMUIDataTableDefaultOptions from '@common/miscHooks/useMUIDataTableDefaultOptions';
import { selectors as userSelectors } from '@features/user';
import { Ride } from '@features/rides/model';
import { PartnerDriverRide } from '../../model';
import { actions } from '../../store';

// Internal components
import PartnerRidesFilter, { PartnerRidesFilters } from './PartnerRidesFilter';
import { Driver } from '@common/model/Driver';

// Styles hook
// const useStyles = makeStyles((theme) =>
//   createStyles({
//     root: {},
//   }),
// );

// Props type
type PartnerRidesCardProps = {};

// Component
const PartnerRidesCard = ({}: PartnerRidesCardProps) => {
  const { t } = useTranslation('rides');
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  // const styles = useStyles();

  const user = useSelector(userSelectors.selectUser);

  const [getPartnerRidesState, setGetPartnerRidesState] = useState<InProgressState>({
    inProgress: false,
  });
  const [paginatedRides, setPaginatedRides] = useState<PaginatedData<Ride> | undefined>(undefined);

  const mappedRides = useMemo(() => {
    return (
      paginatedRides &&
      paginatedRides.data.map((ride) => ({
        ...ride,
      }))
    );
  }, [paginatedRides]);

  // Fetch drivers
  const getPartnerRides = async () => {
    if (!user?.company?.id || !filters) {
      return;
    }

    setGetPartnerRidesState({ inProgress: true });
    try {
      const paginatedRides = ((await dispatch(
        actions.getPartnerRides(
          user.company.id,
          filters.dateFrom.toISOString(),
          filters.dateTo.toISOString(),
          page,
          showTotalItems,
        ),
      )) as unknown) as PaginatedData<PartnerDriverRide>;
      setPaginatedRides(paginatedRides);
      setGetPartnerRidesState({ inProgress: false, success: true });
    } catch (e) {
      setGetPartnerRidesState({ inProgress: false, success: false, error: e });
    }
  };

  const [page, setPage] = useState<number>(1);
  const [showTotalItems, setShowTotalItems] = useState<number | undefined>(undefined);
  const [filters, setFilters] = useState<PartnerRidesFilters | undefined>();
  const onFiltersChange = (filters: PartnerRidesFilters) => {
    setFilters(filters);
  };

  // Init
  useEffect(() => {
    if (user) {
      getPartnerRides();
    }
  }, [user, filters, page, showTotalItems]);

  // Columns definition
  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'driver.email',
        label: t('model.ride.driver'),
      },
      {
        name: 'driver.firstName',
        label: t('model.ride.firstName'),
      },
      {
        name: 'driver.lastName',
        label: t('model.ride.lastName'),
      },
      {
        name: 'driver.region',
        label: t('model.ride.region'),
      },
      {
        name: 'status',
        label: t('model.ride.status.label'),
        options: {
          customBodyRender: (value) => (value ? t(`model.ride.status.map.${value}`, value) : '-'),
        },
      },
      {
        name: 'no',
        label: t('model.ride.no'),
        // options: {},
      },
      {
        name: 'type',
        label: t('model.ride.type.label'),
        options: {
          customBodyRender: (value) => t(`model.ride.type.map.${value}`, value),
        },
      },
      {
        name: 'payout',
        label: t('model.ride.payout'),
      },
      {
        name: 'createdAt',
        label: t('model.ride.createdAt'),
        options: {
          customBodyRender: (value: string) => (value ? new Date(value).toLocaleString() : '-'),
        },
      },
      {
        name: 'deliveredAt',
        label: t('model.ride.deliveredAt'),
        options: {
          customBodyRender: (value: string) => (value ? new Date(value).toLocaleString() : '-'),
        },
      },
      {
        name: 'cancelledAt',
        label: t('model.ride.cancelledAt'),
        options: {
          customBodyRender: (value: string) => (value ? new Date(value).toLocaleString() : '-'),
        },
      },
    ],
    [],
  );

  // Table options
  const options: MUIDataTableOptions = {
    ...useMUIDataTableDefaultOptions(true),
    enableNestedDataAccess: '.',
    download: true,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    sort: false,
    search: false,
    filter: false,
    count: paginatedRides?.total,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100, 500],
    // @ts-ignore
    rowsPerPage: paginatedRides?.perPage && parseInt(paginatedRides.perPage),
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setPage(tableState.page + 1);
          setShowTotalItems(tableState.rowsPerPage);
          break;

        case 'changeRowsPerPage':
          setPage(1);
          setShowTotalItems(tableState.rowsPerPage);
          break;
      }
    },
    onRowClick: (rowData, { dataIndex }) => {
      const row = mappedRides && mappedRides[dataIndex];
      if (row) {
        history.push(`${path}/ride/${row.id}`);
      }
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const customRenderColumns = ['cancelledAt', 'deliveredAt', 'createdAt', 'status', 'type'];
      data.map((ride: any) => {
        ride.data = ride.data.map((value: any, index: number) => {
          const renderer = columns[index];
          if (customRenderColumns.includes(renderer.name)) {
            return renderer.customBodyRender(value);
          }
          return value;
        });
        return ride;
      });
      return `${buildHead(columns)}${buildBody(data)}`.trim();
    },
  };

  return (
    <Card>
      {getPartnerRidesState?.inProgress && <LinearProgress data-testid="loading-rides" />}
      <PartnerRidesFilter onSubmit={onFiltersChange} />
      <MUIDataTable title="" data={mappedRides || []} columns={columns} options={options} />
    </Card>
  );
};

export default PartnerRidesCard;
