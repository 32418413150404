// import { RootState } from '@app/store';
import { Middleware } from 'redux';
// import { actions as privacyPolicyActions } from '../';
// import { actions as userActions } from '@features/user';

// // Disabled setting users accepted privacy policy based on flag stored in the browser
// const onGetUserSuccessMiddleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
//   next(action);
//
//   switch (action.type) {
//     case userActions.getCurrentUserSuccess.type:
//       // @ts-ignore
//       store.dispatch(privacyPolicyActions.checkUserPrivacyPolicy(action.payload));
//
//       break;
//   }
// };

export const middlewares: Middleware[] = [
  /*onGetUserSuccessMiddleware*/
];
