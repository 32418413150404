import axios from 'axios';
import { Region, RegionSrc, regionFromSrc } from '../model/Region';

export const getRegions = (): Promise<Region[]> => {
  return axios.get('/region/list').then((response): Region[] => {
    const regionsSrc: RegionSrc[] = response.data;

    return regionsSrc.map((regionSrc) => regionFromSrc(regionSrc));
  });
};
