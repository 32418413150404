// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Internal libs
import config from '@app/config';
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import { cleanIban } from '@common/validators/ibanValidator';
import { useShowSnackbar } from '@features/snackbars';
import { User } from '../../model/User';
import { CreateCompany } from '../../model/Company';
import { actions } from '../../store';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import FormErrors from '@common/components/FormErrors';
import UpsertCompanyFields from './UpsertCompanyFields';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(2),
    },
    nipWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    findButton: {
      marginLeft: theme.spacing(2),
    },
  }),
);

// Props type
type UpsertCompanyProps = {};

// Component
const UpsertCompanyModal = ({}: UpsertCompanyProps) => {
  const { t } = useTranslation('user');
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const showSnackbar = useShowSnackbar();

  const form = useForm();

  const close = () => {
    history.push('.');
  };

  const userDataState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.user.getUserState,
  );
  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  // Find partner
  const [submitState, setSubmitState] = useState<InProgressState>({ inProgress: false });

  const submit = async (values: any) => {
    setSubmitState({ inProgress: true });

    try {
      if (userData?.company) {
        await dispatch(
          actions.updateCompany({
            name: values.name,
            bankAccountNo: cleanIban(values.bankAccountNo, config.defaultIBANCountry),
            ...(typeof values.isVatPayer !== undefined && { isVatPayer: values.isVatPayer }),
            isPartner: values.isPartner,
            address: {
              streetName: values.addressStreetName,
              streetNumber: values.addressStreetNumber,
              apartmentNumber: values.addressApartmentNumber,
              zipCode: values.addressZipCode,
              city: values.addressCity,
            },
            id: userData.company.id,
          }),
        );
      } else {
        await dispatch(
          actions.createCompany({
            name: values.name,
            taxIdentificationNumber: values.taxIdentificationNumber,
            registrationNumber: values.registrationNumber,
            bankAccountNo: cleanIban(values.bankAccountNo, config.defaultIBANCountry),
            ...(typeof values.isVatPayer !== undefined && { isVatPayer: values.isVatPayer }),
            isPartner: values.isPartner,
            registrationCountryCode: values.registrationCountryCode,
            address: {
              streetName: values.addressStreetName,
              streetNumber: values.addressStreetNumber,
              apartmentNumber: values.addressApartmentNumber,
              zipCode: values.addressZipCode,
              city: values.addressCity,
            },
          }),
        );
      }

      setSubmitState({ inProgress: false, success: true });
      showSnackbar({
        message: t('settlements.upsertCompany.success'),
        severity: 'success',
      });
      close();
    } catch (e) {
      setSubmitState({ inProgress: false, success: false, error: e });
    }
  };

  const { formErrors, getFieldErrors } = useResponseErrors(
    submitState?.error,
    'settlements.upsertCompany',
    'user',
  );

  return (
    <Dialog open={true} onClose={close} disableBackdropClick>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(submit)}>
          <DialogTitle>{t('settlements.upsertCompany.title')}</DialogTitle>
          <Divider />

          <DialogContent>
            {userDataState?.inProgress && <LinearProgress />}

            {userData && (
              <>
                <DialogContentText>{t('settlements.upsertCompany.message')}</DialogContentText>

                <UpsertCompanyFields user={userData} errors={submitState.error} />

                <FormErrors errorMessages={formErrors} />
              </>
            )}
          </DialogContent>

          <Divider />
          <DialogActions>
            <Button onClick={close}>{t('settlements.upsertCompany.cancelButton')}</Button>
            <ProgressButton
              variant="contained"
              color="primary"
              disabled={submitState?.inProgress}
              loading={submitState?.inProgress}
              type="submit"
            >
              {t(`settlements.upsertCompany.submitButton`, '')}
            </ProgressButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default UpsertCompanyModal;
