import { PayloadAction } from '@reduxjs/toolkit';
import { Invoice } from '@features/company/model/Invoice';
import { FailureResponse } from '@common/model';
import { AdminInvoicesState } from '@features/admin/invoices/store/model';
import { Action } from 'redux';

export const getInvoicesInit = (
  state: AdminInvoicesState,
  action: PayloadAction<{ dateFrom: string; dateTo: string; status?: string }>,
) => {
  state.getInvoicesState = {
    inProgress: true,
  };
};

export const getInvoicesSuccess = (state: AdminInvoicesState, action: PayloadAction<Invoice[]>) => {
  state.getInvoicesState = {
    inProgress: false,
    success: true,
  };
  state.invoices = action.payload;
};

export const getInvoicesFailure = (
  state: AdminInvoicesState,
  action: PayloadAction<FailureResponse>,
) => {
  state.getInvoicesState = {
    inProgress: true,
  };
  state.invoices = undefined;
};

export const generatedTransfersFileInit = (
  state: AdminInvoicesState,
  action: PayloadAction<{ invoicesId: string[] }>,
) => {};

export const generatedTransfersFileSuccess = (state: AdminInvoicesState, action: Action) => {};

export const generatedTransfersFileFailure = (
  state: AdminInvoicesState,
  action: PayloadAction<FailureResponse>,
) => {};

export const exportInvoicesToEdiFileInit = (
  state: AdminInvoicesState,
  action: PayloadAction<{ invoicesId: string[] }>,
) => {};

export const exportInvoicesToEdiFileSuccess = (state: AdminInvoicesState, action: Action) => {};

export const exportInvoicesToEdiFileFailure = (
  state: AdminInvoicesState,
  action: PayloadAction<FailureResponse>,
) => {};

export const downloadZippedInvoicesFileInit = (
  state: AdminInvoicesState,
  action: PayloadAction<{ invoicesId: string[] }>,
) => {};

export const downloadZippedInvoicesFileSuccess = (state: AdminInvoicesState, action: Action) => {};

export const downloadZippedInvoicesFileFailure = (
  state: AdminInvoicesState,
  action: PayloadAction<FailureResponse>,
) => {};

export const generateInvoicesInit = (
  state: AdminInvoicesState,
  action: PayloadAction<{ dateFrom: string; dateTo: string }>,
) => {};

export const generateInvoicesSuccess = (state: AdminInvoicesState, action: Action) => {};

export const generateInvoicesFailure = (
  state: AdminInvoicesState,
  action: PayloadAction<FailureResponse>,
) => {};
