// Core libraries
import React, { useEffect, useMemo } from 'react';

// External libs and components
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LinearProgress from '@material-ui/core/LinearProgress';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import clsx from 'clsx';

// Internal libs
import { numberToPhone } from '@common/validators/phoneValidator';
import { selectors as userSelectors } from '@features/user';
import { actions, selectors } from '../store';

// Internal components
import useMUIDataTableDefaultOptions from '@common/miscHooks/useMUIDataTableDefaultOptions';
import ProcessJoinRequestActions from '@features/partner/containers/ProcessJoinRequestActions';

// // Styles hook
// const useStyles = makeStyles((theme) =>
//   createStyles({
//     root: {},
//   }),
// );

// Props type
type PendingRequestsProps = {};

// Component
const PendingRequestsCard = ({}: PendingRequestsProps) => {
  const { t } = useTranslation('partner');
  const dispatch = useDispatch();
  // const styles = useStyles();

  const user = useSelector(userSelectors.selectUser);

  const getPendingRequestsState = useSelector(selectors.selectGetPendingDriverJoinRequestsState);
  const pendingRequests = useSelector(selectors.selectPendingDriverJoinRequests);
  const mappedRequests = useMemo(() => {
    return (
      pendingRequests &&
      pendingRequests.map((request) => ({
        ...request,
        fullName:
          request.user?.firstName && request.user?.lastName
            ? `${request.user.firstName} ${request.user.lastName}`
            : undefined,
        email: request.user?.email,
        phone: request.user?.phone
          ? numberToPhone(request.user.phone, request.user.phonePrefix)
          : '-',
        actions: request.id,
      }))
    );
  }, [pendingRequests]);

  // Fetch Requests helper
  const getPendingDriverJoinRequests = async () => {
    if (!user?.company?.id) {
      return;
    }

    try {
      await dispatch(actions.getPendingDriverJoinRequests(user.company.id));
    } catch (e) {}
  };

  // Fetch data on user init.
  useEffect(() => {
    getPendingDriverJoinRequests();
  }, [user?.id]);

  // Columns
  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'fullName',
        label: t('DriverJoinRequest.fields.fullName'),
      },
      {
        name: 'email',
        label: t('DriverJoinRequest.fields.email'),
      },
      {
        name: 'phone',
        label: t('DriverJoinRequest.fields.phone'),
      },
      {
        name: 'actions',
        label: t('common:fields.actions'),
        options: {
          customBodyRender: (requestId: number) => (
            <ProcessJoinRequestActions partnerCompanyId={user?.company?.id} requestId={requestId} />
          ),
          searchable: false,
          filter: false,
          print: false,
          download: false,
          sort: false,
        },
      },
    ],
    [],
  );

  // Table options
  const defaultOptions = useMUIDataTableDefaultOptions(true);

  return (
    <Card>
      {getPendingRequestsState?.inProgress && (
        <LinearProgress data-testid="loading-pending-requests" />
      )}
      <MUIDataTable
        columns={columns}
        data={mappedRequests || []}
        title={t('driverJoinRequestsList.title')}
        options={defaultOptions}
      />
    </Card>
  );
};

export default PendingRequestsCard;
