import { CompanyState } from '../model/CompanyState';
import { Action } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { Invoice } from '@features/company/model/Invoice';
import { FailureResponse } from '@common/model';

export const getInvoicesInit = (state: CompanyState, action: Action) => {
  state.getInvoicesState = {
    inProgress: true,
  };
};
export const getInvoicesSuccess = (state: CompanyState, action: PayloadAction<Invoice[]>) => {
  state.getInvoicesState = {
    inProgress: false,
    success: true,
  };
  state.invoices = action.payload;
};
export const getInvoicesFailure = (state: CompanyState, action: PayloadAction<FailureResponse>) => {
  state.getInvoicesState = {
    inProgress: true,
  };
  state.invoices = undefined;
};

export const generateInvoiceInit = (state: CompanyState, action: Action) => {
  state.getGenerateInvoiceState = {
    inProgress: true,
  };
};
export const generateInvoiceSuccess = (state: CompanyState, action: PayloadAction<void>) => {
  state.getGenerateInvoiceState = {
    inProgress: false,
    success: true,
  };
};
export const generateInvoiceFailure = (state: CompanyState, action: PayloadAction<FailureResponse>) => {
  state.getGenerateInvoiceState = {
    inProgress: false,
    error: action.payload,
  };
};

export const downloadInvoiceInit = (
  state: CompanyState,
  action: PayloadAction<{ invoiceId: number; invoiceNo: string }>,
) => {};
export const downloadInvoiceSuccess = (state: CompanyState, action: Action) => {};
export const downloadInvoiceFailure = (
  state: CompanyState,
  action: PayloadAction<FailureResponse>,
) => {};
