// Core libraries
import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { map, filter, reject, isNil } from 'lodash';
// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';

import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router';
// Internal libs
import { InProgressState, PaginatedData } from '@common/model';
import { actions } from '../../store';
import { Ride } from '@features/rides/model';

// Internal components

import Grid from '@material-ui/core/Grid';
import { XpressDeliveryCompany } from '@common/model/XpressDeliveryCompany';
import ProgressButton from '@common/components/ProgressButton';
import { FormProvider, useForm } from 'react-hook-form';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormCheckboxField from '@common/components/FormCheckboxField';
import FormErrors from '@common/components/FormErrors';
import CommentsContainer from '@features/admin/components/CommentsContainer/CommentsContainer';
import { Comment, CommentSeverityEnum } from '@common/model/Comment';
// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

// Props type
type DriverCommentsTabProps = {
  driverId: string;
};

// Component
const DriverCommentsTab = ({ driverId }: DriverCommentsTabProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [getCommentsState, setGetCommentsState] = useState<InProgressState>({
    inProgress: false,
  });
  const [comments, setComments] = useState<Comment[]>([]);

  const getComments = useCallback(async () => {
    setGetCommentsState({ inProgress: true });
    try {
      const comments: Comment[] = ((await dispatch(
        actions.getDriverComments(driverId),
      )) as unknown) as Comment[];
      setComments(comments);
      setGetCommentsState({ inProgress: false, success: true });
    } catch (e) {
      setGetCommentsState({ inProgress: false, success: false, error: e });
    }
  }, [dispatch, driverId]);

  // Init
  useEffect(() => {
    getComments();
  }, [getComments, driverId]);

  // Add comment
  const [createCommentState, setCreateCommentState] = useState<InProgressState>({
    inProgress: false,
  });
  const onSubmitCreateComment = async (severity: CommentSeverityEnum, body: string) => {
    setCreateCommentState({ inProgress: true });
    try {
      await dispatch(
        actions.createDriverComment(driverId, {
          body,
          severity,
        }),
      );
      setCreateCommentState({ inProgress: false, success: true });
      getComments();
    } catch (e) {
      setCreateCommentState({ inProgress: false, success: false, error: e });
    }
  };

  return (
    <Paper>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={6}>
          <CommentsContainer
            comments={comments}
            getCommentsState={getCommentsState}
            createCommentState={createCommentState}
            storeComment={onSubmitCreateComment}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DriverCommentsTab;
