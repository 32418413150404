import { RootState } from '@app/store';

import { Correction } from "@features/admin/corrections/model/Correction";

export const selectGetCorrectionsState = (state: RootState) => state.admin.corrections.getCorrectionsState;

export const selectCorrections = (state: RootState) => state.admin.corrections.correctionsList;

export const selectCorrectionById = (correctionId: string) => (state: RootState) =>
  state.admin.corrections.correctionsList?.find((correction: Correction) => correction.id.toString() === correctionId);
