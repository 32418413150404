// Update User Data
import { RootThunk } from '@common/model';
import * as api from '@features/admin/api/documents';

import { actions as driverActions } from '@features/admin';
import { actions as simpleActions } from '../slice';
import {
  ContractNameType,
  DriverContractComparison,
} from '@features/admin/drivers/model/DriverContracts';
import { RideDetails } from '@features/rides/model';

export const acceptDocument = (documentId: string, reason?: string): RootThunk => async (
  dispatch,
) => {
  dispatch(simpleActions.updateDriverDocumentInit());
  try {
    await api.acceptDocument(documentId /*, file*/);
    dispatch(driverActions.getDriversList());
    dispatch(simpleActions.updateDriverDocumentSuccess());
  } catch (e) {
    dispatch(simpleActions.updateDriverDocumentFailure(e.response?.data || { errors: 'unknown' }));
    throw e.response?.data || { errors: 'unknown' };
  }
};

export const rejectDocument = (documentId: string, reason: string): RootThunk => async (
  dispatch,
) => {
  dispatch(simpleActions.updateDriverContractInit());
  try {
    await api.rejectDocument(documentId, reason);
    dispatch(driverActions.getDriversList());
    dispatch(simpleActions.updateDriverContractSuccess());
  } catch (e) {
    dispatch(simpleActions.updateDriverContractFailure(e.response?.data || { errors: 'unknown' }));
    throw e.response?.data || { errors: 'unknown' };
  }
};

export const terminateDocument = (documentId: string, reason: string): RootThunk => async (
  dispatch,
) => {
  dispatch(simpleActions.updateDriverDocumentInit());
  try {
    await api.terminateDocument(documentId, reason);
    dispatch(driverActions.getDriversList());
    dispatch(simpleActions.updateDriverDocumentSuccess());
  } catch (e) {
    dispatch(simpleActions.updateDriverDocumentFailure(e.response?.data || { errors: 'unknown' }));
    throw e.response?.data || { errors: 'unknown' };
  }
};
