// Core libraries
import React from 'react';

// External libs and components
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

// Internal libs

// Internal components
import InvoicesPage from './invoices/InvoicesPage';

// Props type
type CompanyPageProps = {};

// Component
const CompanyPage = ({}: CompanyPageProps) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/invoices`} children={<InvoicesPage />} />
      <Route path={`${path}`} children={<Redirect to={`${path}/invoices`} />} />
    </Switch>
  );
};

export default CompanyPage;
