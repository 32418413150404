export interface RegionSrc {
  id: number;
  name: string;
}

export interface Region {
  id: number;
  name: string;
}

export const regionFromSrc = (regionSrc: RegionSrc): Region => ({
  ...regionSrc,
});

export const regionToSrc = (region: Region): RegionSrc => ({
  ...region,
});
