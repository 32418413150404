// Core libraries
import React, { useCallback, useEffect, useState } from 'react';

// External libs and components
import { useDispatch } from 'react-redux';

// Internal libs
import { InProgressState } from '@common/model';
import { Comment, CommentSeverityEnum } from '@common/model/Comment';
import { actions } from '@features/admin';

// Internal components
import CreateCommentModal from '../../components/CommentsContainer/CreateCommentModal';

// Props type
type DriversCreateCommentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (severity: CommentSeverityEnum, body: string) => void;
  driverId: string;
};

// Component
const DriversCreateCommentModal = ({
  isOpen,
  onClose,
  onSubmit,
  driverId,
}: DriversCreateCommentModalProps) => {
  const dispatch = useDispatch();

  const [getCommentsState, setGetCommentsState] = useState<InProgressState>({
    inProgress: false,
  });
  const [comments, setComments] = useState<Comment[]>([]);

  const getComments = useCallback(async () => {
    setGetCommentsState({ inProgress: true });
    try {
      const comments: Comment[] = ((await dispatch(
        actions.getDriverComments(driverId),
      )) as unknown) as Comment[];
      setComments(comments);
      setGetCommentsState({ inProgress: false, success: true });
    } catch (e) {
      setGetCommentsState({ inProgress: false, success: false, error: e });
    }
  }, [dispatch, driverId]);

  // Init
  useEffect(() => {
    getComments();
  }, [getComments]);

  return (
    <CreateCommentModal
      isOpen={isOpen}
      onClose={onClose}
      storeComment={onSubmit}
      comments={comments}
      getCommentsState={getCommentsState}
      storeCommentState={getCommentsState}
    />
  );
};

export default DriversCreateCommentModal;
