// Core libraries
import React, { useEffect, useState } from 'react';

// External libs and components
import {
  useForm,
  FormProvider,
  SubmitHandler,
  UnpackNestedValue,
  UseFormMethods,
} from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
);

type FiltersProps<F> = {
  onSubmit?: SubmitHandler<F>;
  onChange?: SubmitHandler<F>;
  children: React.ReactNode;
  setForm?: (form: UseFormMethods<F>) => void;
};

// Component
const Filters = <F,>({ onSubmit, onChange, children }: FiltersProps<F>) => {
  const styles = useStyles();

  const form = useForm<F>();

  const [sentData, setSentData] = useState<string>(JSON.stringify(undefined));

  const handleSubmit = (values: UnpackNestedValue<F>) => {
    onSubmit && onSubmit(values);
  };

  const handleChange = (values: UnpackNestedValue<F>) => {
    onChange && onChange(values);
  };

  // Trigger submit handler on init
  useEffect(() => {
    handleSubmit(form.getValues());
  }, [form]);

  const values = onChange && form.watch();
  // Trigger change handler when data changes
  useEffect(() => {
    if (!values || !Object.keys(values).length) {
      return;
    }

    const jsonData = JSON.stringify(values);
    if (sentData != jsonData) {
      setSentData(jsonData);
      handleChange(values);
    }
  }, [values]);

  return (
    <Box padding={2} className={styles.root}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>{children}</form>
      </FormProvider>
    </Box>
  );
};

export default Filters;
