// Core libraries
import React from 'react';

// External libs and components
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

// Internal libs
import { RootState } from '@app/store';

// Internal components

const StoreRedirect = () => {
  const to = useSelector<RootState, string | undefined>(
    (state) => state?.redirect?.to || undefined,
  );

  return (to && <Redirect push to={to} />) || null;
};

export default StoreRedirect;
