import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { VehiclesState } from '../model';
import { VehicleInsert, VehicleUpdate } from '../../model/Vehicle';

export const vehicleUpsertStateClear = (state: VehiclesState) => {
  state.vehicleUpsertState = undefined;
};

export const vehicleInsertInit = (state: VehiclesState, action: PayloadAction<VehicleInsert>) => {
  state.vehicleUpsertState = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const vehicleInsertSuccess = (state: VehiclesState, action: Action) => {
  state.vehicleUpsertState = {
    inProgress: false,
    error: undefined,
    success: true,
  };
};
export const vehicleInsertFailure = (
  state: VehiclesState,
  action: PayloadAction<FailureResponse>,
) => {
  state.vehicleUpsertState = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
};

export const vehicleUpdateInit = (state: VehiclesState, action: PayloadAction<VehicleUpdate>) => {
  state.vehicleUpsertState = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const vehicleUpdateSuccess = (state: VehiclesState, action: Action) => {
  state.vehicleUpsertState = {
    inProgress: false,
    error: undefined,
    success: true,
  };
};
export const vehicleUpdateFailure = (
  state: VehiclesState,
  action: PayloadAction<FailureResponse>,
) => {
  state.vehicleUpsertState = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
};

export const vehicleSetActiveInit = (state: VehiclesState, action: PayloadAction<number>) => {};
export const vehicleSetActiveSuccess = (state: VehiclesState, action: Action) => {};
export const vehicleSetActiveFailure = (
  state: VehiclesState,
  action: PayloadAction<FailureResponse>,
) => {};

const vehicleUpsertReducers = {
  vehicleUpsertStateClear,
  vehicleInsertInit,
  vehicleInsertSuccess,
  vehicleInsertFailure,
  vehicleUpdateInit,
  vehicleUpdateSuccess,
  vehicleUpdateFailure,
  vehicleSetActiveInit,
  vehicleSetActiveSuccess,
  vehicleSetActiveFailure,
};
export default vehicleUpsertReducers;
