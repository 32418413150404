// Core libraries
import React, { useEffect, useMemo } from 'react';

// External libs and components
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, UseFormMethods } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { FuelType, Vehicle, VehicleInsert, VehicleType } from '../model/Vehicle';

// Internal components
import FormSelectField from '@common/components/FormSelectField';
import FormTextField from '@common/components/FormTextField';
import FormErrors from '@common/components/FormErrors';
import ProgressButton from '@common/components/ProgressButton';
import { useResponseErrors } from '@common/validators/backendResponseErrors';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      paddingTop: `${theme.spacing(1)}px!important` as any,
    },
    field: {
      marginBottom: theme.spacing(1),
    },
  }),
);

// Form fields available for different vehicle type
const formFields: AvailableFormFields = {
  car: { manufacturer: true, model: true, registrationNumber: true, color: true, fuelType: true },
  motorbike: {
    manufacturer: true,
    model: true,
    registrationNumber: true,
    color: true,
    fuelType: true,
  },
  bicycle: {
    manufacturer: false,
    model: false,
    registrationNumber: false,
    color: false,
    fuelType: false,
  },
  pedestrian: {
    manufacturer: false,
    model: false,
    registrationNumber: false,
    color: false,
    fuelType: false,
  },
  scooter: {
    manufacturer: false,
    model: false,
    registrationNumber: false,
    color: false,
    fuelType: false,
  },
};

// FormFieldsType
type AvailableFormFields = { [key: string]: { [key: string]: boolean } };

// Props type
type VehicleFormProps = {
  onSubmit: (values: VehicleInsert) => void;
  onClose?: () => void;
  vehicle?: Vehicle;
  testForm?: (form: UseFormMethods) => void;
};

// Component
const VehicleForm = ({ onSubmit, onClose, vehicle, testForm }: VehicleFormProps) => {
  const { t } = useTranslation('vehicles');
  const styles = useStyles();

  const form = useForm();
  const formType = vehicle ? 'update' : 'insert';
  const editValueType = vehicle?.vehicleType || '';
  const vehiclesList = useSelector<RootState, Vehicle[] | undefined>(
    (state) => state.vehicles.vehiclesList,
  );

  const usedVehicleTypes = useMemo(
    () => ({
      bicycle: !!vehiclesList?.find(
        (vehicle: Vehicle) => vehicle.vehicleType === VehicleType.bicycle,
      ),
      scooter: !!vehiclesList?.find(
        (vehicle: Vehicle) => vehicle.vehicleType === VehicleType.scooter,
      ),
      pedestrian: !!vehiclesList?.find(
        (vehicle: Vehicle) => vehicle.vehicleType === VehicleType.pedestrian,
      ),
    }),
    [vehiclesList],
  );

  // Only for testing purposes
  useEffect(() => testForm && testForm(form), [form]);

  const watchVehicleType = form.watch('vehicleType');
  const vehicleFields = useMemo(
    () => formFields[watchVehicleType] || formFields[editValueType] || [],
    [watchVehicleType],
  );
  const vehicleUpsertState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.vehicles.vehicleUpsertState,
  );

  const { formErrors, getFieldErrors } = useResponseErrors(
    vehicleUpsertState?.error,
    'upsert',
    'vehicles',
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogContent className={styles.content}>
          <Grid>
            {/* Vehicle Type */}
            <Grid item>
              <FormSelectField
                className={styles.field}
                name="vehicleType"
                label={t('fields.vehicleType.label')}
                options={[
                  { label: t('fields.vehicleType.types.car'), value: VehicleType.car },
                  {
                    label: t('fields.vehicleType.types.motorbike'),
                    value: VehicleType.motorbike,
                  },
                  {
                    label: t('fields.vehicleType.types.bicycle'),
                    value: VehicleType.bicycle,
                    disabled: usedVehicleTypes.bicycle,
                  },
                  {
                    label: t('fields.vehicleType.types.scooter'),
                    value: VehicleType.scooter,
                    disabled: usedVehicleTypes.scooter,
                  },
                  {
                    label: t('fields.vehicleType.types.pedestrian'),
                    value: VehicleType.pedestrian,
                    disabled: usedVehicleTypes.pedestrian,
                  },
                ]}
                rules={{
                  required: {
                    value: true,
                    message: t('upsert.fields.vehicleType.errors.required'),
                  },
                }}
                fullWidth={true}
                errors={getFieldErrors('vehicleType', 'vehicle_type')}
                defaultValue={vehicle?.vehicleType || ''}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {/* Manufacturer */}
            {!!vehicleFields['manufacturer'] && (
              <Grid item xs={12} sm={6}>
                <FormTextField
                  className={styles.field}
                  name="manufacturer"
                  label={t('fields.manufacturer')}
                  rules={{
                    required: {
                      value: true,
                      message: t('upsert.fields.manufacturer.errors.required'),
                    },
                    minLength: {
                      value: 3,
                      message: t('upsert.fields.manufacturer.errors.minLength'),
                    },
                  }}
                  fullWidth={true}
                  errors={getFieldErrors('manufacturer')}
                  defaultValue={vehicle?.manufacturer || ''}
                />
              </Grid>
            )}
            {/* Model */}
            {!!vehicleFields['model'] && (
              <Grid item xs={12} sm={6}>
                <FormTextField
                  className={styles.field}
                  name="model"
                  label={t('fields.model')}
                  rules={{
                    required: {
                      value: true,
                      message: t('upsert.fields.model.errors.required'),
                    },
                  }}
                  fullWidth={true}
                  errors={getFieldErrors('modal')}
                  defaultValue={vehicle?.model || ''}
                />
              </Grid>
            )}
            {/* Registration number */}
            {!!vehicleFields['registrationNumber'] && (
              <Grid item xs={12} sm={6}>
                <FormTextField
                  className={styles.field}
                  name="registrationNumber"
                  label={t('fields.registrationNumber')}
                  rules={{
                    required: {
                      value: true,
                      message: t('upsert.fields.registrationNumber.errors.required'),
                    },
                    pattern: {
                      value: /^[A-Za-z0-9]+$/,
                      message: t('upsert.fields.registrationNumber.errors.pattern'),
                    },
                  }}
                  fullWidth={true}
                  errors={getFieldErrors('registrationNumber', 'registration_number')}
                  defaultValue={vehicle?.registrationNumber || ''}
                />
              </Grid>
            )}
            {/* Color */}
            {!!vehicleFields['color'] && (
              <Grid item xs={12} sm={6}>
                <FormTextField
                  className={styles.field}
                  name="color"
                  label={t('fields.color')}
                  rules={{
                    required: {
                      value: true,
                      message: t('upsert.fields.color.errors.required'),
                    },
                    minLength: {
                      value: 3,
                      message: t('upsert.fields.color.errors.minLength'),
                    },
                  }}
                  fullWidth={true}
                  errors={getFieldErrors('color')}
                  defaultValue={vehicle?.color || ''}
                />
              </Grid>
            )}
            {/* Fuel Type */}
            {!!vehicleFields['fuelType'] && (
              <Grid item xs={12} sm={6}>
                <FormSelectField
                  className={styles.field}
                  name="fuelType"
                  label={t('fields.fuelType.label')}
                  options={[
                    { label: t('fields.fuelType.types.gasoline'), value: FuelType.gasoline },
                    { label: t('fields.fuelType.types.diesel'), value: FuelType.diesel },
                    { label: t('fields.fuelType.types.electric'), value: FuelType.electric },
                    { label: t('fields.fuelType.types.hybrid'), value: FuelType.hybrid },
                  ]}
                  rules={{
                    required: {
                      value: true,
                      message: t('upsert.fields.fuelType.errors.required'),
                    },
                  }}
                  fullWidth={true}
                  errors={getFieldErrors('fuelType')}
                  defaultValue={vehicle?.fuelType || ''}
                />
              </Grid>
            )}
            {/* Errors */}
            <Grid item xs={12}>
              <FormErrors errorMessages={formErrors} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {onClose && <Button onClick={onClose}>{t(`upsert.cancelButton`)}</Button>}
          <ProgressButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={vehicleUpsertState?.inProgress}
            loading={vehicleUpsertState?.inProgress}
          >
            {t(`upsert.upsertButton.${formType}`)}
          </ProgressButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default VehicleForm;
