import {
  timestampable,
  timestampableFromSrc,
  timestampableSrc,
  timestampableToSrc,
} from '@common/model';
import { Address, addressFromSrc, AddressSrc, addressToSrc } from '@common/model/Address';
import {
  CompanyDocument,
  companyDocumentFromSrc,
  CompanyDocumentSrc,
  companyDocumentToSrc,
} from '@features/user/model/CompanyDocument';

export interface CountryDefinition {
  taxIdentificationNumberName: string;
  registrationNumberName: string;
}

export interface CountryDefinitionSrc {
  tax_identification_number: {
    name: string;
  };
  registration_number: {
    name: string;
  };
}

export interface Company extends timestampable {
  id: number;
  userId: string;
  isPartner: boolean;
  name: string;
  taxIdentificationNumber: string;
  registrationNumber: string;
  registrationCountryCode: string;
  countryDefinition: CountryDefinition;
  bankAccountNo: string;
  address: Address;
  document?: CompanyDocument;
  isVatPayer: boolean;
}

export interface CompanySrc extends timestampableSrc {
  id: number;
  user_id: string;
  is_partner: boolean | 0 | 1;
  name: string;
  tax_identification_number: string;
  registration_number: string;
  bank_account_number: string;
  registration_country_code: string;
  country_definition: CountryDefinitionSrc;
  address: AddressSrc;
  document: CompanyDocumentSrc | null;
  is_vat_payer: boolean | null;
}

export interface CreateCompany {
  name: string;
  taxIdentificationNumber: string;
  registrationNumber: string;
  bankAccountNo: string;
  isVatPayer?: boolean;
  isPartner: boolean;
  address: Address;
  registrationCountryCode: string;
}

export interface CreateCompanySrc {
  name: string;
  tax_identification_number: string;
  registration_number: string;
  bank_account_number: string;
  is_vat_payer?: boolean;
  is_partner: boolean;
  address: AddressSrc;
  registration_country_code: string;
}

export interface UpdateCompanySrc {
  id: number;
  name: string;
  bank_account_number: string;
  is_vat_payer?: boolean;
  is_partner: boolean;
  address: AddressSrc;
}

export interface UpdateCompany {
  id: number;
  name: string;
  bankAccountNo: string;
  isVatPayer?: boolean;
  isPartner: boolean;
  address: Address;
}

export const countryDefinitionFromSrc = (
  countryDefinitionSrc: CountryDefinitionSrc,
): CountryDefinition => ({
  taxIdentificationNumberName: countryDefinitionSrc.tax_identification_number?.name,
  registrationNumberName: countryDefinitionSrc.registration_number?.name,
});

export const countryDefinitionToSrc = (
  countryDefinition: CountryDefinition,
): CountryDefinitionSrc => ({
  tax_identification_number: {
    name: countryDefinition.taxIdentificationNumberName,
  },
  registration_number: {
    name: countryDefinition.registrationNumberName,
  },
});

export const companyFromSrc = (companySrc: CompanySrc): Company => ({
  id: companySrc.id,
  userId: companySrc.user_id,
  isPartner: !!companySrc.is_partner,
  name: companySrc.name,
  taxIdentificationNumber: companySrc.tax_identification_number,
  registrationNumber: companySrc.registration_number,
  bankAccountNo: companySrc.bank_account_number,
  address: addressFromSrc(companySrc.address),
  document: companySrc.document ? companyDocumentFromSrc(companySrc.document) : undefined,
  isVatPayer: !!companySrc.is_vat_payer,
  registrationCountryCode: companySrc.registration_country_code,
  countryDefinition: countryDefinitionFromSrc(companySrc.country_definition),
  ...timestampableFromSrc(companySrc),
});

export const companyToSrc = (company: Company): CompanySrc => ({
  id: company.id,
  user_id: company.userId,
  name: company.name,
  tax_identification_number: company.taxIdentificationNumber,
  registration_number: company.registrationNumber,
  bank_account_number: company.bankAccountNo,
  is_partner: company.isPartner,
  address: addressToSrc(company.address),
  document: company.document ? companyDocumentToSrc(company.document) : null,
  is_vat_payer: company.isVatPayer,
  registration_country_code: company.registrationCountryCode,
  country_definition: countryDefinitionToSrc(company.countryDefinition),
  ...timestampableToSrc(company),
});

export const createCompanyToSrc = (createCompany: CreateCompany): CreateCompanySrc => ({
  name: createCompany.name,
  tax_identification_number: createCompany.taxIdentificationNumber,
  registration_number: createCompany.registrationNumber,
  bank_account_number: createCompany.bankAccountNo,
  is_vat_payer: createCompany.isVatPayer,
  is_partner: createCompany.isPartner,
  address: addressToSrc(createCompany.address),
  registration_country_code: createCompany.registrationCountryCode,
});

export const updateCompanyToSrc = (updateCompany: UpdateCompany): UpdateCompanySrc => ({
  id: updateCompany.id,
  name: updateCompany.name,
  bank_account_number: updateCompany.bankAccountNo,
  is_vat_payer: updateCompany.isVatPayer,
  is_partner: updateCompany.isPartner,
  address: addressToSrc(updateCompany.address),
});
