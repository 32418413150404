// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),

      display: 'flex',
      justifyContent: 'space-between',
    },
    field: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    text: {
      lineHeight: 1.25,
      color: theme.palette.text.primary,
    },
    label: { lineHeight: 1.25, color: theme.palette.text.secondary },
    actions: {},
  }),
);

// Props type
type CardContentHeaderProps = {
  text: string | React.ReactNode;
  label?: string | React.ReactNode;
  action?: React.ReactNode;
};

// Component
const DataItem = ({ text, label, action }: CardContentHeaderProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.field}>
        {/* Label */}
        {label && <Typography className={styles.label}>{label}</Typography>}

        {/* Text */}
        <Typography className={styles.text}>{text}</Typography>
      </div>

      {/* Actions */}
      {action && <div className={styles.actions}>{action}</div>}
    </div>
  );
};

export default DataItem;
