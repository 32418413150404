// Core libraries
import React from 'react';

// External libs and components
import List from '@material-ui/core/List';

// Internal libs
import { Comment } from '@common/model/Comment';

// Internal components
import CommentListItem from './CommentListItem';
import { Divider, makeStyles } from '@material-ui/core';

// Props type
type CommentListProps = {
  list: Comment[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '50ch',
  },
}));

// Component
const CommentList = ({ list }: CommentListProps) => {
  const classes = useStyles();
  return (
    <List dense={true} className={classes.root}>
      {list.map((item) => (
        <React.Fragment key={item.id}>
          <CommentListItem item={item} />
          <Divider component="li" />
        </React.Fragment>
      ))}
    </List>
  );
};

export default CommentList;
