// Core libraries
import React, { useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ProgressButton from '@common/components/ProgressButton';
import { useTranslation } from 'react-i18next';
import { InProgressState } from '@common/model';
import { useDispatch } from 'react-redux';

// Internal libs
import { actions } from '../store';
import { useShowSnackbar } from '@features/snackbars';

// Internal components

// Props type
type PhoneVerifySendAgainButtonProps = { disabled?: boolean };

// Component
const PhoneVerifySendAgainButton = ({ disabled = false }: PhoneVerifySendAgainButtonProps) => {
  const { t } = useTranslation('user');
  const dispatch = useDispatch();
  const showSnackbar = useShowSnackbar();

  const [requestCodeState, setRequestCodeState] = useState<InProgressState>({ inProgress: false });

  const requestCode = async () => {
    setRequestCodeState({ inProgress: true });
    try {
      await dispatch(actions.requestUserPhoneCode());
      setRequestCodeState({ inProgress: false, success: true });
      showSnackbar({
        message: t('verifyPhone.requestCode.success', ''),
        severity: 'success',
      });
    } catch (e) {
      setRequestCodeState({ inProgress: false, error: e });
      showSnackbar({
        message: t(
          `verifyPhone.requestCode.errors.${e.error.replace('.', '_')}`,
          t(`verifyPhone.requestCode.errors.unknown`, ''),
        ),
        severity: 'error',
      });
    }
  };

  return (
    <>
      <ProgressButton
        variant="outlined"
        color="primary"
        disabled={disabled || requestCodeState?.inProgress}
        loading={requestCodeState?.inProgress}
        onClick={requestCode}
      >
        {t(`verifyPhone.requestCode.button`, '')}
      </ProgressButton>
    </>
  );
};

export default PhoneVerifySendAgainButton;
