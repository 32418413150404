// Core libraries
import React from 'react';

// External libs and components
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import {
  Controller,
  useFormContext,
  Validate,
  ValidationRule,
  ValidationValueMessage,
} from 'react-hook-form';

// Internal libs

// Internal components

type FormCheckboxFieldProps = {
  name: string;
  id?: string;
  label?: string | React.ReactNode;
  helperText?: string;
  className?: string;
  defaultValue?: boolean;
  disabled?: boolean;
  rules?:
    | Partial<{
        required: string | boolean | ValidationValueMessage<boolean>;
        min: ValidationRule<React.ReactText>;
        max: ValidationRule<React.ReactText>;
        maxLength: ValidationRule<React.ReactText>;
        minLength: ValidationRule<React.ReactText>;
        pattern: ValidationRule<RegExp>;
        validate: Validate | Record<string, Validate>;
      }>
    | undefined;
  fullWidth?: boolean;
  errors?: string[];
  onConfirmed?: VoidFunction;
};

const FormCheckboxField = ({
  name,
  id,
  label = '',
  helperText,
  className,
  defaultValue = false,
  rules,
  fullWidth = false,
  errors = [],
  disabled = false,
  onConfirmed,
  ...other
}: FormCheckboxFieldProps) => {
  const form = useFormContext();

  const hasError = !!form.errors[name] || !!errors.length;
  const hTexts = !hasError
    ? helperText
    : [
        ...(form.errors[name] ? [form.errors[name].message] : []),
        ...errors.map((error) => <div>{error}</div>),
      ];

  // const hText = form.errors[name] ? form.errors[name].message : helperText;

  return (
    <Controller
      name={name}
      render={({ onChange, value }) => (
        <FormControl fullWidth={fullWidth} className={className} error={hasError} id={id || name}>
          <FormControlLabel
            label={label}
            control={
              <Checkbox
                disabled={disabled}
                onChange={(e) => {
                  onChange(e.target.checked);
                  if (onConfirmed && e.target.checked) {
                    onConfirmed();
                  }
                }}
                checked={value}
              />
            }
            {...other}
          />
          {hTexts && <FormHelperText>{hTexts}</FormHelperText>}
        </FormControl>
      )}
      control={form.control}
      defaultValue={defaultValue}
      rules={rules}
    />
  );
};

export default FormCheckboxField;
