// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import Card from '@material-ui/core/Card';
import NumberFormat from "react-number-format";
// Internal libs
import { InvoiceItem } from '@features/company/model/InvoiceItem';

// Internal components
import useMUIDataTableDefaultOptions from '@common/miscHooks/useMUIDataTableDefaultOptions';


// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type InvoicesItemsDetailsProps = {
  items: InvoiceItem[];
};

// Component
const InvoicesItemsDetails = ({ items }: InvoicesItemsDetailsProps) => {
  const { t } = useTranslation('company');
  const styles = useStyles();

  const options: MUIDataTableOptions = {
    ...useMUIDataTableDefaultOptions(false),
    search: false,
    filter: false,
    pagination: false,
    viewColumns: false,
  };

  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'id',
        label: '#',
      },
      {
        name: 'description',
        label: t('invoicesDetails.headers.name'),
      },
      {
        name: 'quantity',
        label: t('invoicesDetails.headers.quantity'),
      },
      {
        name: 'net',
        label: t('invoicesDetails.headers.net'),
        options: {
          customBodyRender: (value) => <NumberFormat value={value} thousandSeparator={' '} displayType={'text'} decimalScale={2} />
        }
      },
      {
        name: 'vatPercentageDisplay',
        label: t('invoicesDetails.headers.vatText'),
      },
      {
        name: 'vat',
        label: t('invoicesDetails.headers.vat'),
        options: {
          customBodyRender: (value) => <NumberFormat value={value} thousandSeparator={' '} displayType={'text'} decimalScale={2} />
        }
      },
      {
        name: 'gross',
        label: t('invoicesDetails.headers.gross'),
        options: {
          customBodyRender: (value) => <NumberFormat value={value} thousandSeparator={' '} displayType={'text'} decimalScale={2} />
        }
      },
    ],
    [],
  );

  return <MUIDataTable columns={columns} data={items} title="" options={options} />;
};

export default InvoicesItemsDetails;
