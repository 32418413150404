// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Internal libs
import SinglePageLayout from '@common/components/layouts/SinglePageLayout';

// Internal components
import PrivacyPolicyContent from './PrivacyPolicyContent';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type CookiesPolicyPageProps = {};

// Component
const PrivacyPolicyPage = ({}: CookiesPolicyPageProps) => {
  const styles = useStyles();

  return (
    <SinglePageLayout maxWidth="md">
      <PrivacyPolicyContent />
    </SinglePageLayout>
  );
};

export default PrivacyPolicyPage;
