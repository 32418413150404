// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';

// Internal libs
import { InProgressState } from '@common/model';
import { Comment, CommentSeverityEnum } from '@common/model/Comment';

// Internal components
import CreateCommentForm from './CreateCommentForm';
import CommentListContainer from './CommentListContainer';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type CommentsContainerProps = {
  comments: Comment[];
  getCommentsState: InProgressState;
  storeComment: (severity: CommentSeverityEnum, body: string) => void;
  createCommentState: InProgressState;
};

// Component
const CommentsContainer = ({
  comments,
  storeComment,
  getCommentsState,
  createCommentState,
}: CommentsContainerProps) => {
  const { t } = useTranslation('admin');
  console.log(comments, getCommentsState);
  return (
    <>
      <CreateCommentForm onSubmit={storeComment} state={createCommentState} />
      <Divider />
      <CommentListContainer list={comments ?? []} state={getCommentsState} />
    </>
  );
};

export default CommentsContainer;
