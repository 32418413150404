import { useMemo } from 'react';

export const dicMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const dicValidator = (message?: string) => (value: string) =>
  checkPattern(value) || message || false;

const checkPattern = (dic: string): boolean => /^\w{8,10}$/.test(dic);

const useDicValidator = (message?: string) => {
  return useMemo(() => dicValidator(message), [message]);
};
export default useDicValidator;
