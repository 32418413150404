// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type AdminInvoicesGeneratorAlertProps = {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
};

// Component
const AdminInvoicesGeneratorAlert = ({
  open,
  onClose,
  onAccept,
}: AdminInvoicesGeneratorAlertProps) => {
  const styles = useStyles();
  const { t } = useTranslation('admin');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{t('invoices.dialog.generate.title')}</DialogTitle>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t('invoices.dialog.generate.cancel')}
        </Button>
        <Button onClick={onAccept} color="primary" autoFocus>
          {t('invoices.dialog.generate.accept')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminInvoicesGeneratorAlert;
