import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/pl';
moment.locale('pl');

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    field: {
      marginBottom: theme.spacing(1),
    },
    showField: {},
    divider: {
      marginBottom: theme.spacing(2),
    },
  }),
);

interface Props {
  label: string;
  onChange: (newValue: moment.Moment | null) => void;
  value: moment.Moment | null;
  minDateTime?: Date | undefined;
  fieldState?: {
    error?: any;
    invalid: boolean;
  };
  name: string;
}

export function DateTimeSelect(props: Props) {
  const { t } = useTranslation('common');
  const styles = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={'pl'}>
      <DateTimePicker
        ampm={false}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        TextFieldComponent={(params) => (
          <TextField
            name={props.name}
            value={props.value}
            error={!!props.fieldState?.error}
            helperText={
              !!props.fieldState?.error ? (
                <p data-cy={`input_error-${props.name}`}>
                  {props.fieldState?.error?.message?.replace(`"${props.name}"`, 'Value')}
                </p>
              ) : undefined
            }
            {...params}
          />
        )}
        todayLabel={t('dateTimePicker.todayText')}
        cancelLabel={t('dateTimePicker.cancelText')}
        okLabel={t('dateTimePicker.okText')}
        clearLabel={t('dateTimePicker.clearText')}
      />
    </MuiPickersUtilsProvider>
  );
}
