import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { UserState } from '../model';

export const setLanguageStateClear = (state: UserState) => {
  state.setLanguageState = undefined;
};

export const setLanguageInit = (state: UserState, action: PayloadAction<string | any>) => {
  state.setLanguageState = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const setLanguageSuccess = (state: UserState, action: Action) => {
  state.setLanguageState = {
    inProgress: false,
    error: undefined,
    success: true,
  };
};
export const setLanguageFailure = (state: UserState, action: PayloadAction<FailureResponse>) => {
  state.setLanguageState = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
};

export const setContractLanguageStateClear = (state: UserState) => {
  state.setContractLanguageState = undefined;
};

export const setContractLanguageInit = (state: UserState, action: PayloadAction<string | any>) => {
  state.setContractLanguageState = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const setContractLanguageSuccess = (state: UserState, action: Action) => {
  state.setContractLanguageState = {
    inProgress: false,
    error: undefined,
    success: true,
  };
};
export const setContractLanguageFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {
  state.setContractLanguageState = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
};

export default {
  setLanguageStateClear,
  setLanguageInit,
  setLanguageFailure,
  setLanguageSuccess,
  setContractLanguageStateClear,
  setContractLanguageInit,
  setContractLanguageFailure,
  setContractLanguageSuccess,
};
