// Core libraries
import React from 'react';

// External libs and components
import { Route, Switch, useRouteMatch } from 'react-router';

// Internal libs

// Internal components
import DriversCard from '@features/admin/drivers/conteiners/DriversCard';
import DriverRides from '@features/admin/drivers/conteiners/driverRides/DriverRides';
import DriverProfilePage from '@features/admin/drivers/conteiners/driverProfile/DriverProfilePage';

// Props type
type DriversProps = {};

// Component
const Drivers = ({}: DriversProps) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/driver/:driverId/profile`} component={DriverProfilePage} />
      <Route path={`${path}`} component={DriversCard} />
    </Switch>
  );
};

export default Drivers;
