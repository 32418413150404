import { CorrectionType } from '@features/admin/drivers/model/CorrectionType';
import { CorrectionReasonType } from '@features/admin/drivers/model/CorrectionReason';

export interface DriverRideCorrection extends CorrectionInfo {
  userId: number;
}

export interface CorrectionInfo {
  type: CorrectionType;
  correctionReason?: CorrectionReasonType;
  payout: number;
  referenceRideId?: number;
}

export interface CorrectionUpdate extends Partial<CorrectionInfo> {}

export interface DriverRideCreateCorrectionSrc extends CorrectionSrc {
  user_id: number;
}

export interface CorrectionSrc {
  type: CorrectionType;
  correction_reason?: CorrectionReasonType;
  payout: number;
  reference_ride_id?: number;
}

export interface DriverRideUpdateCorrectionSrc extends Partial<CorrectionSrc> {}

export interface DriverRideCorrectionSrc {
  user_id: number;
  type: CorrectionType;
  correction_reason: CorrectionReasonType | null;
  payout: number;
  reference_ride_id?: number | null;
}

export const driverRideCorrectionFromSrc = (
  correctionSrc: DriverRideCorrectionSrc,
): DriverRideCorrection => ({
  userId: correctionSrc.user_id,
  type: correctionSrc.type,
  correctionReason: correctionSrc.correction_reason || undefined,
  payout: correctionSrc.payout,
  referenceRideId: correctionSrc.reference_ride_id || undefined,
});

export const driverRideCorrectionToSrc = (
  correction: DriverRideCorrection,
): DriverRideCorrectionSrc => ({
  user_id: correction.userId,
  type: correction.type,
  correction_reason: correction.correctionReason || null,
  payout: correction.payout,
  reference_ride_id: correction.referenceRideId || null,
});

export const driverRideCreateCorrectionToSrc = (
  correction: DriverRideCorrection,
): DriverRideCreateCorrectionSrc => ({
  user_id: correction.userId,
  type: correction.type,
  correction_reason: correction.correctionReason || undefined,
  payout: correction.payout,
  reference_ride_id: correction.referenceRideId || undefined,
});

export const driverRideUpdateCorrectionToSrc = (
  correction: CorrectionUpdate,
): DriverRideUpdateCorrectionSrc => ({
  type: correction.type || undefined,
  correction_reason: correction.correctionReason || undefined,
  payout: correction.payout || undefined,
  reference_ride_id: correction.referenceRideId || undefined,
});
