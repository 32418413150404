// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// Internal libs
import { InProgressState } from '@common/model';
import usePasswordValidator from '@common/validators/passwordValidator';
import usePasswordConfirmationValidator from '@common/validators/passwordConfirmationValidation';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import { actions } from '../../store';

// Internal components
import SinglePageLayout from '@common/components/layouts/SinglePageLayout';
import FormTextField from '@common/components/FormTextField';
import FormErrors from '@common/components/FormErrors';
import ProgressButton from '@common/components/ProgressButton';


// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(2),
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
    cancelButton: {
      marginTop: theme.spacing(2),
    },
  }),
);

// Props type
type SetNewPasswordFormProps = { token: string; tokenId: string };

// Component
const SetNewPasswordForm = ({ token, tokenId }: SetNewPasswordFormProps) => {
  const { t } = useTranslation('auth');
  const styles = useStyles();
  const dispatch = useDispatch();
  const form = useForm();
  const [submitState, setSubmitState] = useState<InProgressState>({ inProgress: false });

  const submit = async (fields: any) => {
    setSubmitState({ inProgress: true });
    try {
      await dispatch(actions.setNewPassword(tokenId, token, fields.password));
      setSubmitState({ inProgress: false, success: true });
    } catch (e) {
      setSubmitState({ inProgress: false, success: false, error: e });
    }
  };

  const { formErrors, getFieldErrors } = useResponseErrors(
    submitState?.error,
    'setNewPassword',
    'auth',
  );

  const passwordValidator = usePasswordValidator(
    t('setNewPassword.fields.password.errors.pattern', ''),
  );

  const passwordConfirmationValidator = usePasswordConfirmationValidator(
    form.getValues,
    'password',
    t('setNewPassword.fields.passwordConfirmation.errors.mismatch', ''),
  );

  return (
    <>
      {!submitState.success && (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(submit)}>
            <SinglePageLayout.SinglePageLayoutLabel label={t('setNewPassword.message')} />

            {/* Password */}
            <FormTextField
              className={styles.field}
              name="password"
              label={t('setNewPassword.fields.password.label', '')}
              rules={{
                required: {
                  value: true,
                  message: t('setNewPassword.fields.password.errors.required', ''),
                },
                minLength: {
                  value: 7,
                  message: t('setNewPassword.fields.password.errors.minLength', ''),
                },
                validate: {
                  password: passwordValidator,
                },
              }}
              fullWidth={true}
              type="password"
              errors={getFieldErrors('password')}
            />

            {/* Password confirmation*/}
            <FormTextField
              className={styles.field}
              name="passwordConfirmation"
              label={t('setNewPassword.fields.passwordConfirmation.label', '')}
              rules={{
                required: {
                  value: true,
                  message: t('setNewPassword.fields.passwordConfirmation.errors.required', ''),
                },
                validate: {
                  matchPassword: passwordConfirmationValidator,
                },
              }}
              fullWidth={true}
              type="password"
              errors={getFieldErrors('passwordConfirmation', 'password_confirmation')}
            />

            {/* Errors */}
            <FormErrors errorMessages={formErrors} />

            {/* Submit Button */}
            <ProgressButton
              className={styles.submitButton}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth={true}
              disabled={submitState?.inProgress}
              loading={submitState?.inProgress}
            >
              {t('setNewPassword.submitButton', '')}
            </ProgressButton>

            {/* Cancel Button */}
            <Button
              component={RouterLink}
              className={styles.cancelButton}
              variant="text"
              fullWidth={true}
              disabled={submitState?.inProgress}
              to="/"
            >
              {t('setNewPassword.cancelButton', '')}
            </Button>
          </form>
        </FormProvider>
      )}

      {submitState.success && (
        <>
          <SinglePageLayout.SinglePageLayoutLabel label={t('setNewPassword.successMessage')} />

          {/* Go back Button */}
          <Button
            component={RouterLink}
            className={styles.cancelButton}
            variant="contained"
            color="primary"
            fullWidth={true}
            disabled={submitState?.inProgress}
            to="/"
          >
            {t('setNewPassword.goBackButton', '')}
          </Button>
        </>
      )}
    </>
  );
};
export default SetNewPasswordForm;
