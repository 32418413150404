import { User, userFromSrc, UserSrc, userToSrc } from '@features/user/model/User';
import { Ride, rideFromSrc, RideSrc, rideToSrc } from './Ride';
import { RideAddress, rideAddressFromSrc, RideAddressSrc, rideAddressToSrc } from './RideAddress';

export interface RideUploadedDocument {
  id: number;
  externalRideId: string;
  createdAt: Date;
  comment: string;
  documentPath: string;
  originalFileName: string;
}

export interface RideDetails extends Ride {
  driver: User;
  addresses: RideAddress[];
  referenceRide?: Ride;
  corrections: Ride[];
  uploadedDocuments?: RideUploadedDocument[];
}

export interface RideUploadedDocumentSrc {
  id: number;
  external_ride_id: string;
  created_at: string;
  comment: string;
  document_path: string;
  original_file_name: string;
}

export interface RideDetailsSrc extends RideSrc {
  driver: UserSrc;
  addresses: RideAddressSrc[];
  reference_ride: RideSrc | null;
  corrections: RideSrc[];
  uploaded_documents?: RideUploadedDocumentSrc[];
}

export interface CorrectionRideDetails extends Ride {
  addresses: RideAddress[];
}

export interface CorrectionRideDetailsSrc extends RideSrc {
  addresses: RideAddressSrc[];
}

export const correctionRideDetailsFromSrc = (
  correctionRideDetailsSrc: CorrectionRideDetailsSrc,
): CorrectionRideDetails => ({
  ...rideFromSrc(correctionRideDetailsSrc),
  addresses: correctionRideDetailsSrc.addresses.map((addressSrc) => rideAddressFromSrc(addressSrc)),
});

export const correctionRideDetailsToSrc = (
  correctionRideDetails: CorrectionRideDetails,
): CorrectionRideDetailsSrc => ({
  ...rideToSrc(correctionRideDetails),
  addresses: correctionRideDetails.addresses.map((address) => rideAddressToSrc(address)),
});

export const rideDetailsFromSrc = (rideDetailsSrc: RideDetailsSrc): RideDetails => ({
  ...rideFromSrc(rideDetailsSrc),
  driver: userFromSrc(rideDetailsSrc.driver),
  addresses: rideDetailsSrc.addresses.map((addressSrc) => rideAddressFromSrc(addressSrc)),
  referenceRide:
    (rideDetailsSrc.reference_ride && rideFromSrc(rideDetailsSrc.reference_ride)) || undefined,
  corrections: rideDetailsSrc.corrections.map((ride) => rideFromSrc(ride)) || [],
  uploadedDocuments:
    rideDetailsSrc.uploaded_documents?.map((uploadedDocument) =>
      rideUploadedDocumentFromSrc(uploadedDocument),
    ) || [],
});

export const rideDetailsToSrc = (rideDetails: RideDetails): RideDetailsSrc => ({
  ...rideToSrc(rideDetails),
  driver: userToSrc(rideDetails.driver),
  addresses: rideDetails.addresses.map((address) => rideAddressToSrc(address)),
  reference_ride: (rideDetails.referenceRide && rideToSrc(rideDetails.referenceRide)) || null,
  corrections: rideDetails.corrections.map((ride) => rideToSrc(ride)) || [],
  uploaded_documents:
    rideDetails.uploadedDocuments?.map((uploadedDocument) =>
      rideUploadedDocumentToSrc(uploadedDocument),
    ) || [],
});

export const rideUploadedDocumentFromSrc = (
  rideUploadedDocumentSrc: RideUploadedDocumentSrc,
): RideUploadedDocument => ({
  id: rideUploadedDocumentSrc.id,
  documentPath: rideUploadedDocumentSrc.document_path,
  originalFileName: rideUploadedDocumentSrc.original_file_name,
  createdAt: new Date(rideUploadedDocumentSrc.created_at),
  comment: rideUploadedDocumentSrc.comment,
  externalRideId: rideUploadedDocumentSrc.external_ride_id,
});

export const rideUploadedDocumentToSrc = (
  rideUploadedDocument: RideUploadedDocument,
): RideUploadedDocumentSrc => ({
  id: rideUploadedDocument.id,
  document_path: rideUploadedDocument.documentPath,
  original_file_name: rideUploadedDocument.originalFileName,
  created_at: rideUploadedDocument.createdAt.toString(),
  comment: rideUploadedDocument.comment,
  external_ride_id: rideUploadedDocument.externalRideId,
});
