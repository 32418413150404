// Core libraries
import React from 'react';

// External libs and components
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// Internal libs

// Internal components

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -theme.spacing(1.5),
    marginLeft: -theme.spacing(1.5),
    color: theme.palette.secondary.main,
  },
}));

type ProgressButtonProps = ButtonProps & { loading?: boolean; className?: string };

const ProgressButton: React.FC<ProgressButtonProps> = React.forwardRef(
  ({ loading, className, ...other }, ref) => {
    const styles = useStyles();
    const theme = useTheme();

    return (
      <div className={clsx(styles.wrapper, className)}>
        <Button {...other} ref={ref} />
        {loading && <CircularProgress className={styles.progress} size={theme.spacing(3)} />}
      </div>
    );
  },
);

export default ProgressButton;
