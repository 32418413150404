import axios from 'axios';
import { Nationality, nationalityFromSrc, NationalitySrc } from '@features/user/model/Nationality';

export const getNationalities = async (): Promise<Nationality[]> => {
  return axios.get('/dictionary/nationality').then((response): Nationality[] => {
    const nationalitiesSrc: NationalitySrc[] = response.data;

    return nationalitiesSrc.map((nationalitySrc) => nationalityFromSrc(nationalitySrc));
  });
};
