// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import DeleteCorrection from '@material-ui/icons/Delete';

// Internal libs
import { actions } from '../store';
import { InProgressState } from '@common/model';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import ProgressIconButton from '@common/components/ProgressIconButton';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type RemoveCorrectionButtonProps = {
  rideId: number;
  text?: string;
  afterSuccess?: () => void;
  icon?: boolean;
};

// Component
const RemoveCorrectionButton = ({
  rideId,
  text,
  afterSuccess,
  icon = true,
}: RemoveCorrectionButtonProps) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const onClose = () => {
    history.goBack();
  };
  const [deleteState, setDeleteState] = useState<InProgressState>({ inProgress: false });
  const onDelete = async () => {
    setDeleteState({ inProgress: true });
    try {
      await dispatch(actions.removeDriverRideCorrection(rideId));
      setDeleteState({ inProgress: false, success: true });
      if (afterSuccess) {
        afterSuccess();
      }
      onClose();
    } catch (e) {
      setDeleteState({ inProgress: false, success: false, error: e });
    }
  };

  return icon ? (
    <ProgressButton
      onClick={onDelete}
      variant="contained"
      size={'medium'}
      loading={deleteState.inProgress}
      disabled={deleteState.inProgress}
    >
      {text}
    </ProgressButton>
  ) : (
    <ProgressIconButton
      onClick={onDelete}
      children={<DeleteCorrection />}
      size={'medium'}
      loading={deleteState.inProgress}
      disabled={deleteState.inProgress}
    />
  );
};

export default RemoveCorrectionButton;
