// Core libraries
import React, { useEffect, useMemo } from 'react';

// External libs and components
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import Alert from '@material-ui/lab/Alert';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';

// Internal libs
import { actions, selectors } from '../store';

// Internal components
import ContractTemplateDataRow from './ContractTemplateDataRow';
import ContractLanguageSwitch from '@features/contracts/containers/ContractLanguageSwitch';
import ContractWizardRow from '@features/contracts/containers/ContractWizardRow';
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { User } from '@features/user/model/User';
import { selectors as userSelectors } from '@features/user';
import PhoneEditForm from '@features/user/containers/PhoneEditForm';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type AvailableContractsProps = {};

// Component
const AvailableContracts = ({}: AvailableContractsProps) => {
  const { t } = useTranslation('contracts');
  const styles = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();

  const contractTemplatesState = useSelector(selectors.selectContractTemplatesWithContractsState);
  const contractTemplatesData = useSelector(selectors.selectContractTemplatesWithContracts);

  const userDataState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.user.getUserState,
  );
  const missingPhone = useSelector(userSelectors.selectUserMissingPhone);

  const missingEmailVerification = useSelector(userSelectors.selectUserMissingEmailVerification);
  const missingPersonalInformation = useSelector(userSelectors.selectUserMissingPersonalData);

  useEffect(() => {
    dispatch(actions.getContractTemplatesWithContracts());
  }, [dispatch]);

  const isAllowedToSignContract = useMemo(() => {
    return !missingPhone && !missingEmailVerification && !missingPersonalInformation;
  }, [missingPhone, missingEmailVerification, missingPersonalInformation]);

  return (
    <Card>
      <CardContent>
        {(contractTemplatesState?.inProgress || userDataState?.inProgress) && (
          <LinearProgress data-testid="contract-templates-loading" />
        )}
        <Grid container>
          <Grid item>
            {missingPersonalInformation && (
              <Alert severity="warning">{t('availableContracts.missingPersonalInformation')}</Alert>
            )}
            {missingEmailVerification && (
              <Alert severity="warning">{t('availableContracts.missingEmailVerification')}</Alert>
            )}
            {missingPhone && (
              <>
                <Alert severity="warning">{t('availableContracts.missingPhone')}</Alert>
                <PhoneEditForm />
              </>
            )}
            {/*<Alert severity="info">
              <Trans t={t} i18nKey={'availableContracts.message'}>
                {''}
                <Link
                  href="https://moj.gov.pl/nforms/signer/upload?xFormsAppName=SIGNER"
                  target="_blank"
                >
                  {' '}
                </Link>
                {''}
              </Trans>
            </Alert>*/}
          </Grid>
          <Grid item container>
            <Grid item xs={'auto'}>
              <p>{t('availableContracts.selectLanguage')}</p>
            </Grid>
            <Grid item xs>
              <ContractLanguageSwitch
                hasContracts={
                  (contractTemplatesData ?? []).filter(
                    (contractTemplate) => !!contractTemplate?.contract,
                  )?.length
                }
              />
            </Grid>
          </Grid>
        </Grid>

        {contractTemplatesData && (
          <List>
            {contractTemplatesData.map((contractTemplateData) => (
              <ContractWizardRow
                contractTemplateData={contractTemplateData}
                verticalSteps={isXs}
                disabled={!isAllowedToSignContract}
                expanded
              />
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default AvailableContracts;
