// Core libraries
import React, { SetStateAction, useMemo, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useDispatch } from 'react-redux';

// Internal libs
import { correctionActions } from '../store';
import {
  CorrectionReasonEnum,
  CorrectionReasonType,
} from '@features/admin/drivers/model/CorrectionReason';
import { InProgressState } from '@common/model';

// Internal components
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormTextField from '@common/components/FormTextField';
import useAmountValidator from '@common/validators/amount';
import ProgressButton from '@common/components/ProgressButton';
import FormErrors from '@common/components/FormErrors';
import FormSelectField from '@common/components/FormSelectField';
import { Correction } from '@features/admin/corrections/model/Correction';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    field: {
      marginBottom: theme.spacing(1),
    },
    button: {
      padding: theme.spacing(2),
    },
  }),
);

// Props type
type CorrectionFormProps = {
  afterSuccessSubmit?: (editMode: SetStateAction<boolean>) => void;
  correctionDetails?: Correction;
};

// Component
const CorrectionForm = ({ afterSuccessSubmit, correctionDetails }: CorrectionFormProps) => {
  const { t } = useTranslation(['admin', 'rides']);
  const styles = useStyles();
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { correctionId }: { correctionId: string } = useParams();
  const [submitState, setSubmitState] = useState<InProgressState>({ inProgress: false });
  const form = useForm();
  const { formErrors, getFieldErrors } = useResponseErrors(
    submitState?.error,
    'correctionForm',
    'corrections',
  );

  const onSuccess = () => (afterSuccessSubmit ? afterSuccessSubmit(false) : history.goBack());
  const onSubmit = async (values: any) => {
    setSubmitState({ inProgress: true });
    try {
      await dispatch(
        correctionActions.approveCorrection(+correctionId, {
          correctionReason: values.correctionReason || undefined,
          payout: values.payout,
          comment: values.comment || undefined,
        }),
      );
      setSubmitState({ inProgress: false, success: true });
      onSuccess();
      await dispatch(correctionActions.getCorrectionsList());
    } catch (e) {
      setSubmitState({ inProgress: false, success: false, error: e });
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid item xs={12} md={6} lg={4}>
            <FormTextField
              className={styles.field}
              name="payout"
              label={t('rides:driverRideForm.fields.payout.label', '')}
              rules={{
                required: {
                  value: true,
                  message: t('rides:driverRideForm.fields.payout.errors.required'),
                },
                max: {
                  value: 999999.99,
                  message: t('rides:driverRideForm.fields.payout.errors.max'),
                },
                validate: {
                  payout: useAmountValidator(
                    true,
                    t('rides:driverRideForm.fields.payout.errors.payout'),
                  ),
                },
              }}
              fullWidth={true}
              errors={getFieldErrors('payout', 'payout')}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormSelectField
              className={styles.field}
              name="correctionReason"
              label={t('rides:driverRideForm.fields.correctionReason.label', '')}
              options={[
                {
                  label: t('rides:driverRideForm.fields.correctionReason.types.other'),
                  value: CorrectionReasonEnum.other,
                },
                {
                  label: t(
                    'rides:driverRideForm.fields.correctionReason.types.rideDistanceCalculatedIncorrectly',
                  ),
                  value: CorrectionReasonEnum.rideDistanceCalculatedIncorrectly,
                },
              ]}
              rules={{
                required: {
                  value: true,
                  message: t('rides:driverRideForm.fields.correctionReason.errors.required'),
                },
              }}
              fullWidth={true}
              errors={getFieldErrors('correctionReason', 'correction_reason')}
              defaultValue={(correctionDetails?.correctionReason as CorrectionReasonType) || ''}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <FormTextField
              className={styles.field}
              name="comment"
              label={t('admin:corrections.form.fields.comment.label')}
              fullWidth={true}
              errors={getFieldErrors('comment', 'comment')}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={styles.button}
          spacing={2}
          direction="row"
          alignItems="center"
          justify="flex-end"
        >
          <ProgressButton
            variant="contained"
            color="secondary"
            onClick={() => {
              return history.push(`/admin/corrections/${correctionId}/reject`);
            }}
            disabled={submitState.inProgress}
            loading={submitState.inProgress}
          >
            {t('admin:corrections.form.reject')}
          </ProgressButton>
          <FormErrors errorMessages={formErrors} />
          <Button onClick={onSuccess}>{t('rides:driverRideForm.cancelButton')}</Button>
          <ProgressButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={submitState.inProgress}
            loading={submitState.inProgress}
          >
            {t('admin:corrections.form.approve')}
          </ProgressButton>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default CorrectionForm;
