import { RootThunk, PaginatedData } from '@common/model';
import { PartnerDriverRide } from '../../model';
import * as api from '../../api';
import { actions as simpleActions } from '../slice';

export const getPartnerRides = (
  partnerId: number,
  dateFrom: string,
  dateTo: string,
  page?: number,
  showTotalItems?: number,
): RootThunk => async (dispatch): Promise<PaginatedData<PartnerDriverRide>> => {
  dispatch(
    simpleActions.getPartnerRidesInit({ partnerId, dateFrom, dateTo, page, showTotalItems }),
  );
  try {
    const paginatedRides = await api.getPartnerRides(
      partnerId,
      dateFrom,
      dateTo,
      page,
      showTotalItems,
    );
    dispatch(simpleActions.getPartnerRidesSuccess(paginatedRides));

    return paginatedRides;
  } catch (e) {
    dispatch(simpleActions.getPartnerRidesFailure(e.response?.data || { error: 'unknown' }));
    throw e.response?.data || { error: 'unknown' };
  }
};
