import * as regionSelectors from './regionSelectors';
import * as userDataSelectors from './userDataSelectors';
import * as nationalitySelectors from './nationalitySelectors';
import * as identityDocumentSelectors from './identityDocumentSelectors';

export const selectors = {
  ...userDataSelectors,
  ...regionSelectors,
  ...nationalitySelectors,
  ...identityDocumentSelectors,
};
