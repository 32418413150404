// Get current User
import { RootThunk } from '@common/model';
import { actions as simpleActions } from '../slice';
import * as api from '../../api';

export const getNationalities = (): RootThunk => async (dispatch) => {
  dispatch(simpleActions.getNationalitiesInit());

  try {
    const nationalities = await api.getNationalities();
    dispatch(simpleActions.getNationalitiesSuccess(nationalities));
  } catch (e) {
    dispatch(simpleActions.getNationalitiesFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};
