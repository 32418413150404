import axios from 'axios';

import {
  Correction,
  CorrectionSrc,
  correctionFromSrc,
  CorrectionApprovePayload,
  correctionApproveToSrc,
  CorrectionRejectPayload,
  correctionRejectToSrc,
} from '@features/admin/corrections/model/Correction';

export const getCorrectionsList = async (): Promise<Correction[]> => {
  const response = await axios.get('/admin/ride/correction/list');

  return response.data.map((correctionSrc: CorrectionSrc) => correctionFromSrc(correctionSrc));
};

export const approveCorrection = async (
  correctionId: number,
  correctionPayload: CorrectionApprovePayload,
): Promise<void> => {
  return axios.post(
    `/admin/ride/correction/${correctionId}/approve`,
    correctionApproveToSrc(correctionPayload),
  );
};

export const rejectCorrection = async (
  correctionId: number,
  correctionPayload: CorrectionRejectPayload,
): Promise<void> => {
  return axios.post(
    `/admin/ride/correction/${correctionId}/reject`,
    correctionRejectToSrc(correctionPayload),
  );
};
