export interface DriverContract {
  contractId: number;
  name: ContractNameType;
  contractStatus?: string;
  contractStatusTranslated?: string;
  contractStatusReason?: string;
  downloadLink?: string;
  fileExtension?: string;
  uploadedContractContent?: string;
  uploadedContractDifference?: boolean;
  generatedContractContent?: string;
  contractAcceptedAt?: string;
}

export interface DriverContractSrc {
  contract_id: number;
  name: ContractNameType;
  contract_status: string | null;
  contract_status_reason: string | null;
  download_link: string | null;
  file_extension: string | null;
  uploaded_contract_content: string | null;
  generated_contract_content: string | null;
  uploaded_contract_difference: boolean | null;
  contract_accepted_at: string | null;
}

export enum ContractName {
  transport = 'transport_services_agreement',
  property = 'property_entrustment_agreement',
}

export type ContractNameType = ContractName.property | ContractName.transport;

export const driverContractFromSrc = (driverContractSrc: DriverContractSrc): DriverContract => ({
  contractId: driverContractSrc.contract_id,
  name: driverContractSrc.name,
  contractStatus: driverContractSrc.contract_status || undefined,
  contractStatusReason: driverContractSrc.contract_status_reason || undefined,
  downloadLink: driverContractSrc.download_link || undefined,
  fileExtension: driverContractSrc.file_extension || undefined,
  uploadedContractDifference: driverContractSrc.uploaded_contract_difference || undefined,
  uploadedContractContent: driverContractSrc.uploaded_contract_content || undefined,
  generatedContractContent: driverContractSrc.generated_contract_content || undefined,
  contractAcceptedAt: driverContractSrc.contract_accepted_at || undefined,
});

export const driverContractToSrc = (driverContract: DriverContract): DriverContractSrc => ({
  contract_id: driverContract.contractId,
  name: driverContract.name,
  contract_status: driverContract.contractStatus || null,
  contract_status_reason: driverContract.contractStatusReason || null,
  download_link: driverContract.downloadLink || null,
  file_extension: driverContract.fileExtension || null,
  uploaded_contract_difference: driverContract.uploadedContractDifference ?? null,
  uploaded_contract_content: driverContract.uploadedContractContent || null,
  generated_contract_content: driverContract.generatedContractContent || null,
  contract_accepted_at: driverContract.contractAcceptedAt || null,
});

export interface DriverContractComparison {
  contractId: number;
  difference?: boolean;
  uploadedContractContent?: string;
  generatedContractContent?: string;
  similarity?: number;
}

export interface DriverContractComparisonSrc {
  contract_id: number;
  uploaded_contract_content: string | null;
  generated_contract_content: string | null;
  difference: boolean | null;
  similarity: number | null;
}

export const driverContractComparisonFromSrc = (
  driverContractComparisonSrc: DriverContractComparisonSrc,
): DriverContractComparison => ({
  contractId: driverContractComparisonSrc.contract_id,
  difference: driverContractComparisonSrc.difference || undefined,
  uploadedContractContent: driverContractComparisonSrc.uploaded_contract_content || undefined,
  generatedContractContent: driverContractComparisonSrc.generated_contract_content || undefined,
  similarity: driverContractComparisonSrc.similarity || undefined,
});

export const driverContractComparisonToSrc = (
  driverContractComparison: DriverContractComparison,
): DriverContractComparisonSrc => ({
  contract_id: driverContractComparison.contractId,
  difference: driverContractComparison.difference ?? null,
  uploaded_contract_content: driverContractComparison.uploadedContractContent || null,
  generated_contract_content: driverContractComparison.generatedContractContent || null,
  similarity: driverContractComparison.similarity || null,
});
