// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

// Internal libs

// Internal components
import PageDefaultLayout from '@common/components/layouts/PageDefaultLayout';
import AvailableContracts from './AvailableContracts';
import { Route, Switch, useRouteMatch } from 'react-router';
import CorrectionRejectModal from '@features/admin/corrections/containers/CorrectionRejectModal';
import CorrectionModal from '@features/admin/corrections/containers/CorrectionModal';
import ContractView from '@features/contracts/containers/ContractView';

// Component
const ContractsPage = () => {
  const { t } = useTranslation('contracts');
  const { path } = useRouteMatch();
  const actionsBlock = <></>;

  return (
    <Switch>
      <Route path={`${path}/:contractTemplateId`}>
        <ContractView />
      </Route>
      <Route path={`${path}`}>
        <PageDefaultLayout title={t('title')} actions={actionsBlock}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <AvailableContracts />
            </Grid>
          </Grid>
        </PageDefaultLayout>
      </Route>
    </Switch>
  );
};

export default ContractsPage;
