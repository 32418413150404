// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContractsIcon from '@material-ui/icons/Description';
import CarIcon from '@material-ui/icons/DirectionsCar';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/PhoneAndroid';
import PhoneVerifiedIcon from '@material-ui/icons/MobileFriendly';

// Internal libs
import { selectors as userSelectors } from '@features/user/store';
import { selectors as contractsSelector } from '@features/contracts';

// Internal components
import ProvideDataCardItem from './ProvideDataCardItem';

const ProvideDataCard = () => {
  const { t } = useTranslation('dashboard');
  const history = useHistory();

  const userDataFlags = useSelector(userSelectors.selectUserDataFlags);
  const missingContractsCountFromUser = useSelector(userSelectors.selectMissingContractsCount);
  const missingContractsCountFromContracts = useSelector(
    contractsSelector.selectMissingContractsCount,
  );
  const missingContractsCount = useMemo(
    () => missingContractsCountFromContracts ?? missingContractsCountFromUser,
    [missingContractsCountFromUser, missingContractsCountFromContracts],
  );

  const pendingContractsCountFromUser = useSelector(userSelectors.selectPendingContractsCount);

  const pendingContractsCountFromContracts = useSelector(
    contractsSelector.selectPendingContractsCount,
  );

  const pendingContractsCount = useMemo(
    () => pendingContractsCountFromContracts ?? pendingContractsCountFromUser,
    [pendingContractsCountFromUser, pendingContractsCountFromContracts],
  );

  return (
    <Card>
      <CardHeader title={t('cards.provideData.title', '')} />
      <Divider />
      <CardContent>
        <List>
          {userDataFlags && (
            <>
              {/* Email verification */}
              <ProvideDataCardItem
                icon={<EmailIcon color="primary" />}
                label={t('cards.provideData.elements.emailVerification.label')}
                missing={userDataFlags.missingEmailVerification}
                onClick={() => history.push('/profile/email/verify')}
              />

              {/* Phone */}
              <ProvideDataCardItem
                icon={<PhoneIcon color="primary" />}
                label={t('cards.provideData.elements.phone.label')}
                missing={userDataFlags.missingPhone}
                onClick={
                  userDataFlags.missingPhone ? () => history.push('/profile/phone/edit') : undefined
                }
              />

              {/* Phone verification */}
              <ProvideDataCardItem
                icon={<PhoneVerifiedIcon color="primary" />}
                label={t('cards.provideData.elements.phoneVerification.label')}
                missing={userDataFlags.missingPhoneVerification}
                onClick={() => history.push('/profile/phone/verify')}
              />

              {/* Personal Data */}
              <ProvideDataCardItem
                icon={<AccountCircleIcon color="primary" />}
                label={t('cards.provideData.elements.personalData.label', '')}
                missing={userDataFlags.missingPersonalData}
                onClick={() => history.push('/profile/edit')}
              />

              {/* Active Vehicle */}
              <ProvideDataCardItem
                icon={<CarIcon color="primary" />}
                label={t('cards.provideData.elements.vehicles.label', '')}
                missing={userDataFlags.missingVehicleData}
                onClick={() => history.push('/vehicles')}
              />

              {/* Missing Contracts */}
              <ProvideDataCardItem
                icon={<ContractsIcon color="primary" />}
                label={
                  missingContractsCount
                    ? t('cards.provideData.elements.contracts.labelWithCount', {
                        missingContractsCount,
                      })
                    : t('cards.provideData.elements.contracts.label')
                }
                missing={missingContractsCount !== 0}
                pending={!!pendingContractsCount}
                onClick={() => history.push('/contracts')}
              />
            </>
          )}
        </List>
      </CardContent>
    </Card>
  );
};

export default ProvideDataCard;
