// Core libraries
import React, { useMemo, useState } from 'react';

// External libs and components
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ContractIcon from '@material-ui/icons/Description';
import DownloadIcon from '@material-ui/icons/GetApp';
import SignIcon from '@material-ui/icons/Edit';
import UploadIcon from '@material-ui/icons/Publish';
import DoneIcon from '@material-ui/icons/Done';
import PendingIcon from '@material-ui/icons/HourglassEmpty';
import RejectedIcon from '@material-ui/icons/HighlightOff';
import TerminatedIcon from '@material-ui/icons/Close';

// Internal libs
import { InProgressState } from '@common/model';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import { ContractTemplateWithContract } from '../model/ContractTemplateWithContract';
import { DocumentStatusEnum } from '../model/DocumentStatus';
import { actions } from '../store';

// Internal components
import { ThemeColorName } from '@common/components/theme/xdThemeColor';
import FormErrors from '@common/components/FormErrors';
import UploadSignedContract from './UploadSignedContract';
import DataColorActionIcon from '@common/components/dataViews/DataColorActionIcon';
import DataColorField from '@common/components/dataViews/DataColorField';
import stepIconComponentFactory from '@common/components/dataViews/StepIconComponent';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    stepper: {
      padding: 0,
    },

    root: {},
    mainIcon: {
      flexGrow: 0,
      flexShrink: 0,
      minWidth: theme.spacing(4),
      marginRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    pendingIcon: {
      color: theme.palette.text.secondary,
    },
    successIcon: {
      color: theme.palette.success.light,
    },
    warningIcon: {
      color: theme.palette.warning.light,
    },
    rejectedIcon: {
      color: theme.palette.warning.dark,
    },
    terminatedIcon: {
      color: theme.palette.error.dark,
    },
    circularSpinner: {
      width: '1em !important',
      height: '1em !important',
    },
  }),
);

enum Actions {
  'generateContract',
  'downloadSigned',
}

// Props type
type ContractWizardRowProps = {
  contractTemplateData: ContractTemplateWithContract;
  verticalSteps?: boolean;
  expanded?: boolean;
  disabled: boolean;
};

// Component
const ContractWizardRow = ({
  contractTemplateData,
  verticalSteps = false,
  expanded = false,
  disabled = true,
}: ContractWizardRowProps) => {
  const { t } = useTranslation('contracts');
  const dispatch = useDispatch();
  const styles = useStyles();

  const history = useHistory();

  // Download
  const [downloadState, setDownloadState] = useState<InProgressState>({
    inProgress: false,
  });
  const downloadGeneratedContract = async (templateId: number) => {
    setDownloadState({ inProgress: true });
    try {
      await dispatch(actions.downloadGeneratedContract(templateId));
      setDownloadState({ inProgress: false, success: true });
      dispatch(actions.getContractTemplatesWithContracts());
    } catch (e) {
      setDownloadState({ inProgress: false, success: false, error: e });
    }
  };
  // Download Errors
  const { formErrors: downloadErrors } = useResponseErrors(
    downloadState.error,
    'availableContracts.downloadUnsignedContract',
    'contracts',
  );

  // Upload
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const toggleUpload = () => {
    setOpenUpload(!openUpload);
  };
  const uploadSuccess = async () => {
    setOpenUpload(false);
    dispatch(actions.getContractTemplatesWithContracts());
  };

  // Download signed document
  const [downloadDocumentState, setDownloadDocumentState] = useState<InProgressState>({
    inProgress: false,
  });
  const downloadUploadedSignedDocument = async () => {
    if (!contractTemplateData?.contract) {
      return;
    }

    setDownloadDocumentState({ inProgress: true });
    try {
      if (contractTemplateData.contract?.document) {
        await dispatch(
          actions.downloadUploadedSignedDocument(
            contractTemplateData.contract.document.downloadLink,
            contractTemplateData.contract.document.fileName,
          ),
        );
      } else {
        await dispatch(actions.downloadContractPdf(contractTemplateData.contract));
      }
      setDownloadDocumentState({ inProgress: false, success: true });
    } catch (e) {
      setDownloadDocumentState({ inProgress: false, success: false, error: e });
    }
  };

  // Download signed document Errors
  const { formErrors: downloadDocumentErrors } = useResponseErrors(
    downloadDocumentState.error,
    'availableContracts.downloadUnsignedContract',
    'contracts',
  );

  const contractState = useMemo(() => {
    // Missing
    if (!contractTemplateData?.contract) {
      return states.missing;
    }

    if (
      contractTemplateData?.contract?.status &&
      states.hasOwnProperty(contractTemplateData?.contract?.status)
    ) {
      return states[contractTemplateData.contract.status];
    }

    return states.unknown;
  }, [contractTemplateData]);

  return (
    <DataColorField
      style={{ margin: '3px' }}
      left={contractState.iconComponent && <contractState.iconComponent />}
      {...(contractState.actions
        ? {
            actions: contractState.actions
              .map((action: Actions) => {
                switch (action) {
                  case Actions.generateContract:
                    return (
                      <DataColorActionIcon
                        color={contractState.color}
                        onClick={() =>
                          history.push(`/contracts/${contractTemplateData.templateId}`)
                        }
                        tooltip={t('availableContracts.generateContract.tooltip')}
                        loading={downloadState.inProgress}
                        key={action}
                        disabled={disabled}
                      >
                        <SignIcon />
                      </DataColorActionIcon>
                    );
                  case Actions.downloadSigned:
                    return (
                      <DataColorActionIcon
                        color={contractState.color}
                        onClick={downloadUploadedSignedDocument}
                        tooltip={t('availableContracts.downloadUploadedContractDocument.tooltip')}
                        loading={downloadDocumentState.inProgress}
                        key={action}
                      >
                        <ContractIcon />
                      </DataColorActionIcon>
                    );
                }

                return null;
              })
              .filter((a) => !!a),
          }
        : {})}
      color={contractState.color}
      primaryText={t(`contractNames.${contractTemplateData.template.name}.name`)}
      secondaryText={
        typeof contractState.description === 'function'
          ? contractState.description(t, contractTemplateData.contract?.statusReason)
          : t(
              contractState.description,
              contractState.descriptionFallback && t(contractState.descriptionFallback),
            )
      }
      expandable
      expanded={expanded}
      additionalContent={
        ((downloadErrors && !!downloadErrors.length) ||
          (downloadDocumentErrors && !!downloadDocumentErrors.length)) && (
          <div>
            <FormErrors errorMessages={[...downloadErrors, ...downloadDocumentErrors]} />
          </div>
        )
      }
    >
      {contractState.steps && (
        <Stepper
          alternativeLabel={!verticalSteps}
          classes={{ root: styles.stepper }}
          orientation={verticalSteps ? 'vertical' : 'horizontal'}
          activeStep={contractState.currentStep || 0}
        >
          {contractState.steps.map((step) => (
            <Step key={step.id}>
              <StepLabel StepIconComponent={step.iconComponent}>{t(step.label)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
    </DataColorField>
  );
};

interface StepDef {
  id: string;
  iconComponent: React.ElementType;
  label: string;
}

const steps: { [key: string]: StepDef } = {
  generateContract: {
    id: 'generateContract',
    iconComponent: stepIconComponentFactory(SignIcon, 'info'),
    label: 'availableContracts.steps.generateContract',
  },
  verification: {
    id: 'verification',
    iconComponent: stepIconComponentFactory(PendingIcon, 'warning'),
    label: 'availableContracts.steps.verification',
  },
  verified: {
    id: 'verified',
    iconComponent: stepIconComponentFactory(DoneIcon, 'success'),
    label: 'availableContracts.steps.verified',
  },
  rejected: {
    id: 'rejected',
    iconComponent: stepIconComponentFactory(RejectedIcon, 'error'),
    label: 'availableContracts.steps.rejected',
  },
  terminated: {
    id: 'terminated',
    iconComponent: stepIconComponentFactory(TerminatedIcon, 'warning'),
    label: 'availableContracts.steps.terminated',
  },
};

interface State {
  description:
    | string
    | ((
        t: (key: string, fallback?: string) => string,
        statusComment: string | undefined,
      ) => string);
  descriptionFallback?: string;
  color: ThemeColorName;
  iconComponent?: any;
  actions?: Actions[];
  steps?: StepDef[];
  currentStep?: number;
}

const states: {
  [key: string]: State;
} = {
  missing: {
    description: 'availableContracts.status.missing.tooltip',
    color: 'primary',
    actions: [Actions.generateContract],
    steps: [steps.generateContract, steps.verification, steps.verified],
    currentStep: 0,
  },
  [DocumentStatusEnum.pending]: {
    description: 'availableContracts.status.pending.tooltip',
    color: 'primary',
    actions: [Actions.generateContract],
    steps: [steps.generateContract, steps.verification, steps.verified],
    currentStep: 0,
  },
  [DocumentStatusEnum.uploaded]: {
    description: 'availableContracts.status.uploaded.tooltip',
    color: 'info',
    actions: [Actions.downloadSigned],
    steps: [steps.generateContract, steps.verification, steps.verified],
    currentStep: 1,
  },
  [DocumentStatusEnum.rejected]: {
    description: (
      t: (key: string, fallback?: string) => string,
      statusReason: string | undefined = '',
    ) =>
      t('availableContracts.status.rejected.tooltip') +
      ' ' +
      (statusReason || t('availableContracts.status.rejected.missingReason')),
    color: 'error',
    actions: [Actions.generateContract],
    steps: [steps.generateContract, steps.verification, steps.rejected],
    currentStep: 2,
  },
  [DocumentStatusEnum.accepted]: {
    description: 'availableContracts.status.accepted.tooltip',
    color: 'success',
    actions: [Actions.downloadSigned],
    steps: [steps.generateContract, steps.verification, steps.verified],
    currentStep: 2,
  },
  [DocumentStatusEnum.terminated]: {
    description: (
      t: (key: string, fallback?: string) => string,
      statusReason: string | undefined = '',
    ) =>
      t('availableContracts.status.terminated.tooltip') +
      ' ' +
      (statusReason || t('availableContracts.status.terminated.missingReason')),
    color: 'warning',
    actions: [Actions.generateContract],
    steps: [steps.generateContract, steps.verification, steps.verified, steps.terminated],
    currentStep: 3,
  },
  unknown: {
    description: 'error',
    color: 'warning',
  },
};

export default ContractWizardRow;
