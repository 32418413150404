// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';

// Internal libs

// Internal components
import { ReactComponent as LogoDark } from '@assets/xd_logo.dark.svg';
import { ReactComponent as Logo } from '@assets/xd_logo.svg';
import CenteredPaperLayout, {
  CenteredPaperLayoutProps,
} from '@common/components/CenteredPaperLayout';
import SinglePageLayoutLabel from '@common/components/layouts/SinglePageLayout/SinglePageLayoutLabel';
import SinglePageLayoutSpinner from '@common/components/layouts/SinglePageLayout/SinglePageLayoutSpinner';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
  }),
);

// Props type
type SinglePageLayoutProps = {
  children: React.ReactNode;
  showLogo?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
} & CenteredPaperLayoutProps;

// Component
const SinglePageLayout = ({
  children,
  showLogo = true,
  maxWidth = 'sm',
}: SinglePageLayoutProps) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <CenteredPaperLayout showBgImage={true} maxWidth={maxWidth}>
      {/* Logo */}
      {showLogo && (
        <div className={styles.logoContainer}>
          {theme.palette.type === 'dark' ? <LogoDark width="200" /> : <Logo width="200px" />}
        </div>
      )}

      {children}
    </CenteredPaperLayout>
  );
};

SinglePageLayout.SinglePageLayoutLabel = SinglePageLayoutLabel;
SinglePageLayout.SinglePageLayoutSpinner = SinglePageLayoutSpinner;

export default SinglePageLayout;
