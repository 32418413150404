// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { User } from '../model/User';
import { selectors as userSelectors } from '../store';

// Internal components
import DataListItem from '@common/components/DataListItem';

const UserDataCard = () => {
  const { t } = useTranslation('user');
  const history = useHistory();
  const { path } = useRouteMatch();

  const userDataState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.user.getUserState,
  );
  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  const missingPersonalData = useSelector(userSelectors.selectUserMissingPersonalData);
  const missingExtraDocument = useSelector(userSelectors.selectUserMissingExtraDocument);
  return (
    <Card>
      <CardHeader
        title={t('cards.userData.title', '')}
        action={
          <IconButton onClick={() => history.push(`${path}/edit`)}>
            <EditIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent>
        {missingPersonalData && (
          <Alert severity="warning">{t('cards.userData.messages.missingData', '')}</Alert>
        )}
        {missingExtraDocument && (
          <Alert severity="info">{t('cards.userData.messages.missingExtraDocument', '')}</Alert>
        )}

        {userDataState?.inProgress && <LinearProgress />}
        {userData && (
          <List>
            {/* First name */}
            <DataListItem label={t('user.fields.firstName', '')} text={userData.firstName || '-'} />
            {/* Last name */}
            <DataListItem label={t('user.fields.lastName', '')} text={userData.lastName || '-'} />
            {/* Nationality */}
            <DataListItem
              label={t('user.fields.nationality', '')}
              text={t(`common:nationalities.${userData.nationality}`, userData.nationality)}
            />
            {/* Address */}
            <DataListItem
              label={t('user.fields.address', '')}
              text={
                userData.address
                  ? [
                      `${userData.address.streetName} ${userData.address.streetNumber}` +
                        (userData.address.apartmentNumber
                          ? ` / ${userData.address.apartmentNumber}`
                          : ''),
                      `${userData.address.zipCode} ${userData.address.city}`,
                    ]
                  : '-'
              }
            />
            {/* Pesel */}
            {(!userData.country?.code || userData.country.code === 'pl') && (
              <DataListItem label={t('user.fields.pesel', '')} text={userData.pesel || '-'} />
            )}
            {/* Id Number */}
            {(userData.idNumber || !userData.passportNumber) && (
              <DataListItem label={t('user.fields.idNumber', '')} text={userData.idNumber || '-'} />
            )}
            {/* Passport */}
            {userData.passportNumber && (
              <DataListItem
                label={t('user.fields.passportNumber', '')}
                text={userData.passportNumber || '-'}
              />
            )}
            {/* Residence Card */}
            {userData.residenceCardNumber && (
              <DataListItem
                label={t('user.fields.residenceCardNumber', '')}
                text={userData.residenceCardNumber || '-'}
              />
            )}
            {/* Vehicle */}
            <DataListItem
              label={t('user.fields.activeVehicle', '')}
              text={
                userData.activeVehicle
                  ? [
                      `${userData.activeVehicle.manufacturer} ${userData.activeVehicle.model}`,
                      userData.activeVehicle.registrationNumber,
                    ]
                  : '-'
              }
            />
            {/* Region */}
            <DataListItem label={t('user.fields.region', '')} text={userData.region || '-'} />
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default UserDataCard;
