import axios from 'axios';
import { PaginatedData, paginatedDataFromSrc } from '@common/model';
import { PartnerDriverRide, partnerDriverRideFromSrc, PartnerDriverRideSrc } from '../model';

export const getPartnerRides = async (
  partnerId: number,
  dateFrom: string,
  dateTo: string,
  page?: number,
  showTotalItems?: number,
): Promise<PaginatedData<PartnerDriverRide>> => {
  const response = await axios.get(`/partner/${partnerId}/rides`, {
    params: {
      page,
      date_from: dateFrom,
      date_to: dateTo,
      show_total_items: showTotalItems,
    },
  });

  return paginatedDataFromSrc<PartnerDriverRideSrc, PartnerDriverRide>(
    response.data,
    partnerDriverRideFromSrc,
  );
};
