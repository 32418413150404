import { Action, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from '../model';
import { FailureResponse } from '@common/model';

// Login reducers
export const passwordResetInit = (state: AuthState, action: PayloadAction<string>) => {};
export const passwordResetSuccess = (state: AuthState, action: Action) => {};
export const passwordResetFailure = (
  state: AuthState,
  action: PayloadAction<FailureResponse>,
) => {};

// Login reducers
export const verifySetNewPasswordTokenInit = (
  state: AuthState,
  action: PayloadAction<{ tokenId: string; token: string }>,
) => {};
export const verifySetNewPasswordTokenSuccess = (state: AuthState, action: Action) => {};
export const verifySetNewPasswordTokenFailure = (
  state: AuthState,
  action: PayloadAction<FailureResponse>,
) => {};

// Login reducers
export const setNewPasswordInit = (
  state: AuthState,
  action: PayloadAction<{ tokenId: string; token: string; password: string }>,
) => {};
export const setNewPasswordSuccess = (state: AuthState, action: Action) => {};
export const setNewPasswordFailure = (
  state: AuthState,
  action: PayloadAction<FailureResponse>,
) => {};

const passwordResetReducers = {
  passwordResetInit,
  passwordResetSuccess,
  passwordResetFailure,
  verifySetNewPasswordTokenInit,
  verifySetNewPasswordTokenSuccess,
  verifySetNewPasswordTokenFailure,
  setNewPasswordInit,
  setNewPasswordSuccess,
  setNewPasswordFailure,
};
export default passwordResetReducers;
