import axios from 'axios';
import { saveAs } from 'file-saver';
import {
  ContractNameType,
  DriverContractComparison,
  driverContractComparisonFromSrc,
} from '@features/admin/drivers/model/DriverContracts';

export const getContractComparison = async (
  contractId: number,
): Promise<DriverContractComparison> => {
  const response = await axios.get(`admin/contract/${contractId}/comparison`);
  return driverContractComparisonFromSrc(response.data);
};

export const acceptContract = async (contractId: string /*, file: Blob*/) => {
  const formData = new FormData();
  // formData.append('document_file', file);
  formData.append('_method', 'PATCH');

  return axios.post(`admin/contract/${contractId}/accept`, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const rejectContract = (contractId: string, reason: string): Promise<any> => {
  return axios.patch(`admin/contract/${contractId}/reject`, {
    status_reason: reason,
  });
};

export const terminateContract = (contractId: string, reason: string): Promise<any> => {
  return axios.patch(`admin/contract/${contractId}/terminate`, {
    status_reason: reason,
  });
};

export const downloadContract = async (
  downloadLink: string,
  fileExtension: string | undefined,
  contractType: ContractNameType,
): Promise<any> => {
  const fileName = `contract_${contractType}.${fileExtension || 'pdf'}`;

  // Download file
  const file: { data: Blob } = await axios.get(downloadLink, {
    responseType: 'blob',
  });

  // Save file
  saveAs(file.data, fileName);
};
