import {
  timestampable,
  timestampableFromSrc,
  timestampableSrc,
  timestampableToSrc,
} from '@common/model';

export enum RideAddressTypeEnum {
  pickupPoint = 'pickup_point',
}
export type RideAddressType = typeof RideAddressTypeEnum | string;

export interface RideAddress extends timestampable {
  id: number;
  rideId: number;
  type: RideAddressType;
  name: string;
  phone: string;
  latitude: number;
  longitude: number;
  city: string;
  street: string;
}

export interface RideAddressSrc extends timestampableSrc {
  id: number;
  ride_id: number;
  type: RideAddressType;
  name: string;
  phone: string;
  address_latitude: number;
  address_longitude: number;
  address_city: string;
  address_street: string;
}

export const rideAddressFromSrc = (rideAddressSrc: RideAddressSrc): RideAddress => ({
  id: rideAddressSrc.id,
  rideId: rideAddressSrc.ride_id,
  type: rideAddressSrc.type,
  name: rideAddressSrc.name,
  phone: rideAddressSrc.phone,
  latitude: rideAddressSrc.address_latitude,
  longitude: rideAddressSrc.address_longitude,
  city: rideAddressSrc.address_city,
  street: rideAddressSrc.address_street,
  ...timestampableFromSrc(rideAddressSrc),
});

export const rideAddressToSrc = (rideAddress: RideAddress): RideAddressSrc => ({
  id: rideAddress.id,
  ride_id: rideAddress.rideId,
  type: rideAddress.type,
  name: rideAddress.name,
  phone: rideAddress.phone,
  address_latitude: rideAddress.latitude,
  address_longitude: rideAddress.longitude,
  address_city: rideAddress.city,
  address_street: rideAddress.street,
  ...timestampableToSrc(rideAddress),
});
