// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { Redirect, RedirectProps, Route, RouteProps } from 'react-router';
import LinearProgress from '@material-ui/core/LinearProgress';

// Internal libs
import { Role } from '../model/Role';

// Internal components
import HasRoles from './HasRoles';

// Component props type
type RoleRoutePros = {
  redirect: string | RedirectProps;
  roles: Role | Role[];
  loading?: React.ReactNode;
} & RouteProps;

// Component
const RoleRoute = ({ redirect, roles, loading, ...rest }: RoleRoutePros) => {
  const redirectProps = useMemo(
    () => (typeof redirect === 'string' ? { to: redirect } : redirect),
    [redirect],
  );

  return (
    <HasRoles
      roles={roles}
      loading={loading || <LinearProgress />}
      noAccess={<Redirect {...redirectProps} />}
    >
      <Route {...rest} />
    </HasRoles>
  );
};

export default RoleRoute;
