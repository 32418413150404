// Core libraries
import React, { ReactNode, useEffect, Suspense } from 'react';

// External libs and components
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// Internal libs
import { RootState } from '@app/store';
import { actions } from '../store';

// Internal components
import { InProgressState } from '@common/model';
import SplashScreenContent from '@features/init/components/SplashScreenContent';

type SplashScreenProps = {
  useSuspense?: boolean;
  children: ReactNode;
};

const SplashScreen = ({ useSuspense = false, children }: SplashScreenProps) => {
  const dispatch = useDispatch();

  const initState = useSelector<RootState, InProgressState | undefined>((state) => state.init.init);

  useEffect(() => {
    // This allow another initialization when store state is cleared.
    if (typeof initState === 'undefined') {
      dispatch(actions.init());
    }
  }, [initState]);

  return !initState || initState.inProgress ? (
    <SplashScreenContent />
  ) : useSuspense ? (
    <Suspense fallback={<SplashScreenContent />}>{children}</Suspense>
  ) : (
    <>{children}</>
  );
};

export default SplashScreen;
