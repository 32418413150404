// Core libraries
import React from 'react';

// External libs and components
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';

// Internal libs

// Internal components
import { ReactComponent as Logo } from '@assets/xd_logo.svg';
import { ReactComponent as LogoDark } from '@assets/xd_logo.dark.svg';
import LoginForm from './LoginForm';
import PasswordReset from './PasswordReset';
// import OAuthLinks from './OAuthLinks';

const useStyle = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  extLoginLabel: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  intLoginLabel: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  register: {
    marginTop: theme.spacing(1),
  },
  passwordReset: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    fontSize: '0.9em',
  },
}));

const LoginTraditional = () => {
  const styles = useStyle();
  const theme = useTheme();
  const { t } = useTranslation('auth');

  return (
    <>
      {/* Logo */}
      <div className={styles.logoContainer}>
        {theme.palette.type === 'dark' ? <LogoDark width="200" /> : <Logo width="200px" />}
      </div>

      {/* "Login with" */}
      {/*<div className={styles.extLoginLabel}>{t('login.messages.loginWithExternalService', '')}</div>
       */}
      {/* External services (uber, google, facebook) */}
      {/*<OAuthLinks />*/}

      {/* "or" */}
      {/*<div className={styles.intLoginLabel}>{t('login.messages.loginTraditionally', '')}</div>
       */}
      {/* Login form */}
      <LoginForm />

      {/* Go to Register */}
      <Button
        component={RouterLink}
        to="/register"
        fullWidth={true}
        color="primary"
        className={styles.register}
      >
        {t('login.goToRegisterButton.caption', '')}
      </Button>

      <PasswordReset className={styles.passwordReset} />
    </>
  );
};

export default LoginTraditional;
