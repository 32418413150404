import { actions as simpleActions } from '../slice';
import { VehiclesThunk } from '../model';
import * as api from '../../api';
import { VehicleInsert, VehicleUpdate } from '../../model/Vehicle';
import { actions as userActions } from '@features/user';
import { RootThunk } from '@common/model';

/****** Place for complex actions *****/

// Insert Vehicle
export const insertVehicle = (vehicle: VehicleInsert): RootThunk => async (
  dispatch,
): Promise<number | undefined> => {
  dispatch(simpleActions.vehicleUpsertStateClear());
  dispatch(simpleActions.vehicleInsertInit(vehicle));

  try {
    const response = await api.insertVehicle(vehicle);
    dispatch(simpleActions.vehicleInsertSuccess());
    dispatch(userActions.getCurrentUser());
    return response.data.id;
  } catch (e) {
    dispatch(simpleActions.vehicleInsertFailure(e.response?.data || {}));
  }
};

// Update Vehicle
export const updateVehicle = (vehicle: VehicleUpdate): RootThunk => async (dispatch) => {
  dispatch(simpleActions.vehicleUpdateInit(vehicle));

  try {
    const response = await api.updateVehicle(vehicle);
    dispatch(simpleActions.vehicleUpdateSuccess());
    dispatch(userActions.getCurrentUser());
  } catch (e) {
    dispatch(simpleActions.vehicleUpdateFailure(e.response?.data || {}));
  }
};

export const setVehicleActive = (vehicleId: number): RootThunk => async (dispatch) => {
  dispatch(simpleActions.vehicleSetActiveInit(vehicleId));

  try {
    await api.setVehicleActive(vehicleId);
    await dispatch(simpleActions.vehicleSetActiveSuccess());
    dispatch(userActions.getCurrentUser());
  } catch (e) {
    await dispatch(simpleActions.vehicleSetActiveFailure(e.response?.data || {}));
    throw new Error(e.response?.data);
  }
};
