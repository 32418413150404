// Core libraries
import React, { useEffect, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';

// import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { User } from '../model/User';
import { actions as userActions, selectors as userSelectors } from '../store';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import UserDataEditFormFields from './UserDataEditFormFields';

// // Styles hook
// const useStyles = makeStyles((theme) =>
//   createStyles({
//     root: {},
//   }),
// );

// Props type
type UserDataEditModalProps = {};

// Component
const UserDataEditModal = ({}: UserDataEditModalProps) => {
  const { t } = useTranslation('user');
  // const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const form = useForm();

  const userDataState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.user.updateUserDataState,
  );
  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  const getRegionsInProgress = useSelector(userSelectors.selectGetRegionsInProgress);
  const regions = useSelector(userSelectors.selectRegions);
  const nationalities = useSelector(userSelectors.selectNationalities);
  const allowedIdentityDocuments = useSelector(userSelectors.selectAllowedIdentityDocuments);

  const onClose = () => {
    history.push('.');
  };

  const [init, setInit] = useState(false);
  useEffect(() => {
    dispatch(userActions.updateUserDataStateClear());
    setInit(true);
  }, []);

  useEffect(() => {
    if (init && userDataState?.success) {
      onClose();
    }
  }, [userDataState]);

  useEffect(() => {
    dispatch(userActions.getRegions());
    dispatch(userActions.getNationalities());
    dispatch(userActions.getAllowedIdentityDocuments());
  }, []);

  const onSubmit = (values: any) => {
    dispatch(
      userActions.updateUserData({
        firstName: values.firstName,
        lastName: values.lastName,
        idNumber: values.idNumber,
        passportNumber: values.passportNumber,
        residenceCardNumber: values.residenceCardNumber,
        pesel: values.pesel,
        nationalityId: values.nationalityId,
        customNationality: values.customNationality,
        address: {
          streetName: values.addressStreetName,
          streetNumber: values.addressStreetNumber,
          apartmentNumber: values.addressApartmentNumber,
          zipCode: values.addressZipCode,
          city: values.addressCity,
          // country: values.addressCountry,
        },
        regionId: values.regionId,
      }),
    );
  };
  return (
    <Dialog open={true} onClose={onClose} fullWidth disableBackdropClick>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogTitle>{t('userDataEdit.title', '')}</DialogTitle>
          <Divider />
          <DialogContent>
            {(userDataState?.inProgress || getRegionsInProgress) && <LinearProgress />}
            {userData && regions && nationalities && allowedIdentityDocuments && (
              <UserDataEditFormFields
                userData={userData}
                regions={regions}
                nationalities={nationalities}
                allowedIdentityDocuments={allowedIdentityDocuments}
                errors={userDataState?.error}
              />
            )}
          </DialogContent>
          <DialogActions>
            <p>{t('requiredFieldsInfo')}</p>
            <Button onClick={onClose}>{t('userDataEdit.cancelButton')}</Button>
            <ProgressButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={userDataState?.inProgress}
              loading={userDataState?.inProgress}
            >
              {t(`userDataEdit.submitButton`, '')}
            </ProgressButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default UserDataEditModal;
