import axios from 'axios';

export interface RegisterData {
  email: string;
  password: string;
  privacyPolicyAccepted: boolean;
  countryId: number;
  locale: string;
}

export const register = (data: RegisterData) => {
  return axios.post('/auth/register', {
    email: data.email,
    password: data.password,
    password_confirmation: data.password,
    privacy_policy_accepted: data.privacyPolicyAccepted,
    country_id: data.countryId,
    locale: data.locale,
  });
};
