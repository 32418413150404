export enum ContractStatusEnum {
  pending = 'pending',
  uploaded = 'uploaded',
  accepted = 'accepted',
  rejected = 'rejected',
  terminated = 'terminated',
}

export type ContractStatus =
  | ContractStatusEnum.pending
  | ContractStatusEnum.uploaded
  | ContractStatusEnum.accepted
  | ContractStatusEnum.rejected
  | ContractStatusEnum.terminated;
