// Core libraries
import React, { useEffect, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector} from 'react-redux';
import { useParams } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogContent from '@material-ui/core/DialogContent';

// Internal libs
import { InProgressState } from '@common/model';

// Internal components
import { correctionActions, correctionSelectors } from '../store';
import CorrectionRejectForm from "@features/admin/corrections/containers/CorrectionRejectForm";

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type CorrectionRejectModalProps = {
  title?: string;
};

// Component
const CorrectionRejectModal = ({ title }: CorrectionRejectModalProps) => {
  const { t } = useTranslation('rides');
  const styles = useStyles();

  const { correctionId } = useParams() as { correctionId: string };

  const correction = useSelector(correctionSelectors.selectCorrectionById(correctionId));
  
  const titleToDisplay =
    title || `${t(`adminRideCorrection.correctionNumber.title`)} ${correction?.rideNo || ''}`;

  return (
    <Dialog open={true} className={styles.root} fullWidth maxWidth="md">
      <DialogTitle>{titleToDisplay}</DialogTitle>
      <Divider />
      <DialogContent>
        <CorrectionRejectForm
          correctionDetails={correction}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CorrectionRejectModal;
