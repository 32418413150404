import axios from 'axios';
import {
  Vehicle,
  vehicleFromSrc,
  VehicleInsert,
  VehicleSrc,
  VehicleUpdate,
} from '@features/vehicles/model/Vehicle';

export const getVehiclesList = (): Promise<Vehicle[]> => {
  return axios
    .get('/vehicle/list')
    .then((response) =>
      (response.data || []).map((vehicleSrc: VehicleSrc) => vehicleFromSrc(vehicleSrc)),
    );
};

export const insertVehicle = (vehicle: VehicleInsert) => {
  return axios.post('/vehicle/create', {
    manufacturer: vehicle.manufacturer,
    model: vehicle.model,
    registration_number: vehicle.registrationNumber,
    color: vehicle.color,
    fuel_type: vehicle.fuelType,
    vehicle_type: vehicle.vehicleType,
  });
};

export const updateVehicle = (vehicle: VehicleUpdate) => {
  return axios.patch(`/vehicle/${vehicle.id}/update`, {
    manufacturer: vehicle.manufacturer,
    model: vehicle.model,
    registration_number: vehicle.registrationNumber,
    color: vehicle.color,
    fuel_type: vehicle.fuelType,
    vehicle_type: vehicle.vehicleType,
  });
};

export const setVehicleActive = (vehicleId: number) => {
  return axios.patch(`/vehicle/${vehicleId}/set-active`);
};
