// Core libraries
import React, { useEffect } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

// Internal libs
import { actions } from '@features/auth/store';

// Internal components
import { makeStyles } from '@material-ui/core/styles';
import FormTextField from '@common/components/FormTextField';
import FormCheckboxField from '@common/components/FormCheckboxField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import ProgressButton from '@common/components/ProgressButton';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormErrors from '@common/components/FormErrors';

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(2),
  },
}));

const LoginForm = () => {
  const { t } = useTranslation('auth');
  const form = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();

  const loginState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.auth.login,
  );

  const { formErrors, getFieldErrors } = useResponseErrors(loginState?.error, 'login', 'auth');

  const styles = useStyles();

  const onSubmit = (values: any) => {
    dispatch(actions.login(values.username, values.password));
  };

  // Clear login state
  useEffect(() => {
    dispatch(actions.loginClearState());
  }, []);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {/* Username*/}
        <FormTextField
          className={styles.field}
          name="username"
          label={t('login.fields.username.label', '')}
          rules={{
            required: {
              value: true,
              message: t('login.fields.username.errors.required', ''),
            },
          }}
          fullWidth={true}
          errors={getFieldErrors('username')}
        />

        {/* Password */}
        <FormTextField
          className={styles.field}
          name="password"
          label={t('login.fields.password.label', '')}
          rules={{
            required: {
              value: true,
              message: t('login.fields.password.errors.required', ''),
            },
          }}
          fullWidth={true}
          type="password"
          errors={getFieldErrors('password')}
        />

        {/* Remember me */}
        <FormCheckboxField
          className={styles.field}
          name="rememberMe"
          label={t('login.fields.rememberMe.label', '')}
          fullWidth={true}
        />

        {/* Errors */}
        <FormErrors errorMessages={formErrors} />

        {/* Submit Button */}
        <ProgressButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth={true}
          disabled={!form.formState.isValid || loginState?.inProgress}
          loading={loginState?.inProgress}
        >
          {t('login.submit.caption', '')}
        </ProgressButton>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
