// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Internal libs

// Internal components
import FormTextField from '@common/components/FormTextField';
import Filters from '@common/components/filters/Filters';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Filters data types
export type InvoicesFilterData = {
  dateFrom?: Date;
  dateTo?: Date;
  search?: string;
};

type InvoicesFilterFormData = { dateFrom: string; dateTo: string; search: string };

// Props type
type InvoicesFilterProps = {
  onSubmit?: (values: InvoicesFilterData) => void;
  onChange?: (values: InvoicesFilterData) => void;
  onChangeSearchText?: {
    searchText: string;
    setSearchText: (values: string) => void;
  };

  overrideDateFrom?: Date;
  maxDateTo?: Date;
};

// Component
const InvoicesFilter = ({
  onSubmit,
  onChange,
  overrideDateFrom,
  maxDateTo,
  onChangeSearchText,
}: InvoicesFilterProps) => {
  const { t } = useTranslation('company');
  const styles = useStyles();

  const passData = (
    values: InvoicesFilterFormData,
    callback: (values: InvoicesFilterData) => void,
  ) => {
    const dateFrom = values.dateFrom ? new Date(values.dateFrom) : undefined;
    const dateTo = values.dateTo ? new Date(values.dateTo) : undefined;
    dateFrom?.setHours(0, 0, 0, 0);
    dateTo?.setHours(23, 59, 59, 0);

    callback({
      dateFrom,
      dateTo,
      search: values.search,
    });
  };

  const handleSubmit = (values: InvoicesFilterFormData) => {
    onSubmit && passData(values, onSubmit);
  };

  const handleChange = (values: InvoicesFilterFormData) => {
    if (onChangeSearchText && values.search !== onChangeSearchText.searchText) {
      onChangeSearchText.setSearchText(values.search);
    } else {
      onChange && passData(values, onChange);
    }
  };

  const dateToTextValue = (date: Date) => date.toISOString().substr(0, 10);

  const [defaultValues] = useState(() => {
    const dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 14);

    return {
      dateFrom: overrideDateFrom || dateFrom,
      dateTo: new Date(),
      search: '',
    };
  });

  return (
    <Filters onChange={handleChange}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormTextField
            name="dateFrom"
            label={t('invoices.filter.dateFrom.label')}
            type="date"
            fullWidth
            {...(defaultValues?.dateFrom
              ? { defaultValue: dateToTextValue(defaultValues.dateFrom) }
              : {})}
            inputProps={maxDateTo && { max: dateToTextValue(maxDateTo) }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormTextField
            name="dateTo"
            label={t('invoices.filter.dateTo.label')}
            type="date"
            fullWidth
            defaultValue={dateToTextValue(defaultValues.dateTo)}
            inputProps={maxDateTo && { max: dateToTextValue(maxDateTo) }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormTextField
            name="search"
            label={t('invoices.filter.search')}
            fullWidth
            defaultValue={defaultValues.search}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </Filters>
  );
};

export default InvoicesFilter;
