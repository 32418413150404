// Core libraries
import React, { useEffect, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { FormHelperText } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

// Internal libs
import { DocumentStatus, DocumentStatusEnum } from '@features/contracts/model/DocumentStatus';
import {
  DriverContract,
  DriverContractComparison,
} from '@features/admin/drivers/model/DriverContracts';
import { InProgressState } from '@common/model';

// Internal components
import FormTextField from '@common/components/FormTextField';
import FormSelectField from '@common/components/FormSelectField';
import DriverContractComparisonModal from '@features/admin/drivers/conteiners/DriverContractComparisonModal';
import { useDispatch } from 'react-redux';
import { actions } from '@features/admin';
import { IdentityDocument } from '@features/user/model/IdentityDocument';
import DriverContractIdentityDocumentsContainer from '@features/admin/drivers/conteiners/DriverContractIdentityDocumentsContainer';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    field: {
      marginBottom: theme.spacing(1),
    },
    chooseButton: {
      flexShrink: 0,
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
    fileName: {
      flexShrink: 1,
      flexGrow: 1,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      wordWrap: 'break-word',
      overflow: 'hidden',
    },
    error: {
      fontSize: 16,
      color: theme.palette.error.main,
    },
    success: {
      fontSize: 16,
      color: theme.palette.success.main,
    },
  }),
);

// Props type
type DriverExtraDocumentInfoProps = {
  extraDocument: IdentityDocument;
  getFieldErrors: (
    fieldName: string,
    backendFieldName?: string,
    translateFullPrefix?: string,
  ) => string[];
  disableUploadButton: boolean;
};

// Component
const DriverExtraDocumentInfo = ({
  extraDocument,
  getFieldErrors,
  disableUploadButton,
}: DriverExtraDocumentInfoProps) => {
  const { t } = useTranslation('common');
  const styles = useStyles();

  const dispatch = useDispatch();

  const [getDetailsState, setGetDetailsState] = useState<InProgressState>({ inProgress: false });
  const form = useFormContext();
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [file, setFile] = useState<any>();
  const selectFile = (event: any) => {
    setFile(event.target?.files ? event.target.files[0] : undefined);
    setIsFileSelected(!!file);
  };

  const isAnyActionToChoose: { [key: string]: boolean } = {
    [DocumentStatusEnum.uploaded]: true,
    [DocumentStatusEnum.accepted]: true,
    [DocumentStatusEnum.rejected]: false,
    [DocumentStatusEnum.terminated]: false,
    [DocumentStatusEnum.pending]: true,
  };
  const actionSelected = form.watch('adminAction');
  const isReasonFieldVisible = (status: string | undefined, selectedAction: string): boolean =>
    !status
      ? false
      : (status === DocumentStatusEnum.pending && selectedAction === DocumentStatusEnum.rejected) ||
        (status === DocumentStatusEnum.accepted &&
          selectedAction === DocumentStatusEnum.terminated);

  const fieldToSelect: {
    [key: string]: Array<{ label: string; value: DocumentStatus | string; disabled?: boolean }>;
  } = {
    [DocumentStatusEnum.uploaded]: [
      {
        label: t('identityDocument.adminAction.accepted'),
        value: DocumentStatusEnum.accepted,
      },
      {
        label: t('identityDocument.adminAction.rejected'),
        value: DocumentStatusEnum.rejected,
      },
    ],
    [DocumentStatusEnum.accepted]: [
      {
        label: t('identityDocument.adminAction.terminated'),
        value: DocumentStatusEnum.terminated,
      },
    ],
    [DocumentStatusEnum.terminated]: [
      {
        label: t('identityDocument.adminAction.null'),
        value: 'empty',
        disabled: true,
      },
    ],
    [DocumentStatusEnum.rejected]: [
      {
        label: t('identityDocument.adminAction.null'),
        value: 'empty',
        disabled: true,
      },
    ],
    [DocumentStatusEnum.pending]: [
      {
        label: t('identityDocument.adminAction.accepted'),
        value: DocumentStatusEnum.accepted,
      },
      {
        label: t('identityDocument.adminAction.rejected'),
        value: DocumentStatusEnum.rejected,
      },
    ],
  };
  return (
    <>
      <Grid container spacing={2} className={styles.field}>
        <Grid item xs={12} md={6}>
          {getDetailsState.inProgress && (
            <p>
              <CircularProgress size={18} />
              {t('agreements.comparison.loading')}
            </p>
          )}
          <DriverContractIdentityDocumentsContainer identityDocuments={[extraDocument]} />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={styles.field}>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="contractId"
            disabled
            label={t('common:identityDocument.fields.id')}
            fullWidth
            defaultValue={
              extraDocument.id
                ? extraDocument.id.toString()
                : t('identityDocument.adminAction.null')
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            disabled
            name="status"
            label={t(`common:identityDocument.fields.status`)}
            fullWidth
            defaultValue={
              !extraDocument?.status
                ? t(`common:identityDocument.extraDocument.status.null`)
                : t(`common:identityDocument.extraDocument.status.${extraDocument?.status}`)
            }
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <FormSelectField
            className={styles.field}
            name="adminAction"
            label={t('identityDocument.fields.action.label')}
            options={
              extraDocument?.status
                ? fieldToSelect[extraDocument?.status]
                : [
                    {
                      label: t('identityDocument.adminAction.null'),
                      value: 'empty',
                      disabled: true,
                    },
                  ]
            }
            rules={{
              required: {
                value: true,
                message: t('identityDocument.fields.action.errors.required'),
              },
            }}
            errors={getFieldErrors('adminAction')}
            fullWidth
            defaultValue={
              extraDocument?.status && isAnyActionToChoose[extraDocument?.status] ? '' : 'empty'
            }
          />
        </Grid>
        <Grid container justify="flex-start" alignItems="center">
          {isReasonFieldVisible(extraDocument?.status, actionSelected) && (
            <FormTextField
              className={styles.field}
              name="comment"
              multiline={true}
              label={t('identityDocument.fields.comment.label')}
              fullWidth
              errors={getFieldErrors('reason')}
              rules={{
                required: {
                  value: true,
                  message: t('identityDocument.fields.comment.errors.required'),
                },
                minLength: {
                  value: 4,
                  message: t('identityDocument.fields.comment.errors.minLength'),
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DriverExtraDocumentInfo;
