// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { User } from '../model/User';
import { actions } from '../store';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import FormTextField from '@common/components/FormTextField';
import usePhoneValidator, {
  phoneMask,
  phoneToNumber,
  numberToPhone,
} from '@common/validators/phoneValidator';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormErrors from '@common/components/FormErrors';
import FormSelectField from '@common/components/FormSelectField';
import PhoneEditForm from '@features/user/containers/PhoneEditForm';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(2),
    },
  }),
);

// Props type
type PhoneEditModalProps = {};

// Component
const PhoneEditModal = ({}: PhoneEditModalProps) => {
  const { t } = useTranslation('user');
  const styles = useStyles();
  const history = useHistory();
  const onClose = () => {
    history.push('..');
  };
  const onSubmit = async () => {
    history.push('./verify');
  };
  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <PhoneEditForm onCloseCallback={onClose} onSubmitCallback={onSubmit} />
    </Dialog>
  );
};

export default PhoneEditModal;
