// Core libraries
import React, { ReactNode } from 'react';

// External libs and components
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router';

// Internal libs

// Internal components

type AppBarMenuItemProps = {
  text: string;
  redirectTo?: string | object;
  onClose?: () => void;
  onClick?: () => void;
};

const AppBarMenuItem = ({ text, redirectTo, onClose, onClick }: AppBarMenuItemProps) => {
  const history = useHistory();

  const handleClick = () => {
    if (redirectTo) {
      history.push(redirectTo);
    }

    if (onClick) {
      onClick();
    }

    if (onClose) {
      onClose();
    }
  };

  return <MenuItem onClick={handleClick}>{text}</MenuItem>;
};

export default AppBarMenuItem;
