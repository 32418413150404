// Core libraries
import React, { useCallback, useEffect, useState } from 'react';

// External libs and components
import { useDispatch } from 'react-redux';

// Internal libs
import { InProgressState } from '@common/model';
import { Comment, CommentSeverityEnum } from '@common/model/Comment';
import { actions, rideActions } from '@features/admin';

// Internal components
import CreateCommentModal from '../../components/CommentsContainer/CreateCommentModal';
import CommentsContainer from '@features/admin/components/CommentsContainer/CommentsContainer';

// Props type
type RideCommentsContainerProps = {
  rideId: string;
};

// Component
const RideCommentsContainer = ({ rideId }: RideCommentsContainerProps) => {
  const dispatch = useDispatch();

  const [getCommentsState, setGetCommentsState] = useState<InProgressState>({
    inProgress: false,
  });
  const [comments, setComments] = useState<Comment[]>([]);

  const getComments = useCallback(async () => {
    setGetCommentsState({ inProgress: true });
    try {
      const comments: Comment[] = ((await dispatch(
        rideActions.getRideComments(rideId),
      )) as unknown) as Comment[];
      setComments(comments);
      setGetCommentsState({ inProgress: false, success: true });
    } catch (e) {
      setGetCommentsState({ inProgress: false, success: false, error: e });
    }
  }, [dispatch, rideId]);

  // Add comment
  const [createCommentState, setCreateCommentState] = useState<InProgressState>({
    inProgress: false,
  });
  const onSubmitCreateComment = async (severity: CommentSeverityEnum, body: string) => {
    setCreateCommentState({ inProgress: true });
    try {
      await dispatch(
        rideActions.createRideComment(rideId, {
          body,
          severity,
        }),
      );
      setCreateCommentState({ inProgress: false, success: true });
      getComments();
    } catch (e) {
      setCreateCommentState({ inProgress: false, success: false, error: e });
    }
  };

  // Init
  useEffect(() => {
    getComments();
  }, [getComments, rideId]);

  return (
    <CommentsContainer
      comments={comments}
      getCommentsState={getCommentsState}
      storeComment={onSubmitCreateComment}
      createCommentState={createCommentState}
    />
  );
};

export default RideCommentsContainer;
