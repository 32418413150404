import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { AllowedIdentityDocument, IdentityDocument } from '@features/user/model/IdentityDocument';

export const selectAllowedIdentityDocuments = (
  state: RootState,
): AllowedIdentityDocument[] | undefined => state.user.allowedIdentityDocuments;

export const selectIdentityDocuments = (state: RootState): IdentityDocument[] | undefined =>
  state.user.user?.identityDocuments;

export const selectGetAllowedIdentityDocumentsState = (
  state: RootState,
): InProgressState | undefined => state.user.getAllowedIdentityDocumentsState;

export const selectGetAllowedIdentityDocumentsInProgress = (
  state: RootState,
): boolean | undefined => state.user.getAllowedIdentityDocumentsState?.inProgress;
