// Core libraries
import React from 'react';

// External libs and components

// Internal libs

// Internal components
import { useMainMenu } from '../MainContext';
import DrawerMenuItems from '@features/main/containers/DrawerMenuItems';

// Props type
type DrawerMenuProps = { onSelect?: () => void };

const DrawerMenu = ({ onSelect }: DrawerMenuProps) => {
  const menu = useMainMenu();

  return <DrawerMenuItems items={menu} onSelect={onSelect} />;
};

export default DrawerMenu;
