import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

export const LOCAL_STORAGE_PRIVACY_POLICY_KEY = 'isPrivacyPolicyAccepted';

export interface PrivacyPolicyState {
  isPrivacyPolicyAccepted: boolean;
}

export type PrivacyPolicyThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  PrivacyPolicyState,
  unknown,
  Action<string>
>;
