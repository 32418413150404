// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Internal libs
import PageDefaultLayout from '@common/components/layouts/PageDefaultLayout';

// Internal components
import ProvideDataCard from './ProvideDataCard';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    gridContainer: {
      display: 'flex',
    },
  }),
);

const Dashboard = () => {
  const { t } = useTranslation('dashboard');
  const styles = useStyles();

  return (
    <PageDefaultLayout title={t('title', '')}>
      <Grid container spacing={3} className={styles.gridContainer}>
        <Grid item xs={12} md={6} lg={4}>
          <ProvideDataCard />
        </Grid>
      </Grid>
    </PageDefaultLayout>
  );
};

export default Dashboard;
