import { RootThunk } from '@common/model';
import { actions as simpleActions } from '../slice';
import * as api from '../../api';

export const getInvoices = (): RootThunk => async (dispatch) => {
  dispatch(simpleActions.getInvoicesInit());

  try {
    const invoices = await api.getInvoices();
    dispatch(simpleActions.getInvoicesSuccess(invoices));
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.getInvoicesFailure(error));
    throw error;
  }
};

export const downloadInvoice = (invoiceId: number, invoiceNo: string): RootThunk => async (
  dispatch,
) => {
  dispatch(simpleActions.downloadInvoiceInit({ invoiceId, invoiceNo }));

  try {
    await api.downloadInvoice(invoiceId, invoiceNo);
    dispatch(simpleActions.downloadInvoiceSuccess());
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.downloadInvoiceFailure(error));
    throw error;
  }
};

export const generateInvoice = (invoiceId: number, invoiceNo: string): RootThunk => async (
  dispatch,
) => {
  dispatch(simpleActions.generateInvoiceInit());

  try {
    await api.generateInvoice(invoiceId, invoiceNo);
    dispatch(simpleActions.generateInvoiceSuccess());
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.generateInvoiceFailure(error));
    throw error;
  }
};

export const downloadInvoiceRidesAttachments = (
  invoiceId: number,
  invoiceNo: string,
  link: string,
): RootThunk => async (dispatch) => {
  dispatch(simpleActions.downloadInvoiceInit({ invoiceId, invoiceNo }));

  try {
    await api.downloadInvoiceRidesAttachments(invoiceNo, link);
    dispatch(simpleActions.downloadInvoiceSuccess());
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.downloadInvoiceFailure(error));
    throw error;
  }
};
