import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { UserState } from '../model';
import { User } from '../../model/User';

export const getCurrentUserInit = (state: UserState, action: Action) => {
  state.getUserState = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const getCurrentUserSuccess = (state: UserState, action: PayloadAction<User>) => {
  state.getUserState = {
    inProgress: false,
    error: undefined,
    success: true,
  };
  state.user = action.payload;
};
export const getCurrentUserFailure = (state: UserState, action: PayloadAction<FailureResponse>) => {
  state.getUserState = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
  state.user = undefined;
};

const getUserReducers = {
  getCurrentUserInit,
  getCurrentUserFailure,
  getCurrentUserSuccess,
};
export default getUserReducers;
