// Core libraries
import React from 'react';

// External libs and components
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// Internal libs

// Internal components
import backgroundImageDark from '@assets/background-earth.dark.png';
import backgroundImage from '@assets/background-earth.light.png';
import I18nSwitch from '@common/components/I18nSwitch';

const useStyle = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  rootBgImage: {
    position: 'fixed',
    overflow: 'auto',
    backgroundImage:
      theme.palette.type === 'dark' ? `url('${backgroundImageDark}')` : `url('${backgroundImage}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto min(95vh, 95vh)',
    backgroundPosition:
      'max(min(calc(90vw - 1.6 * min(max(80vh, 80vw), 95vh)), -10vw), -0.8 * min(max(80vh, 80vw), 95vh)) bottom',
    backgroundAttachment: 'fixed',
  },
  container: {
    paddingBottom: theme.spacing(8),
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    zIndex: 1,
    position: 'relative',
  },
  languageSwitchWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    boxShadow: ' 0px 0px 5px #fff',
    borderRadius: theme.spacing(1),
    background: 'rgba(255, 255, 255, 0.8)',
    zIndex: 0,
  },
}));

export type CenteredPaperLayoutProps = {
  children: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  showBgImage?: boolean;
  paperClasses?: string;
  showLanguageSelector?: boolean;
};

const CenteredPaperLayout = ({
  children,
  maxWidth = 'sm',
  showBgImage = false,
  paperClasses,
  showLanguageSelector = false,
}: CenteredPaperLayoutProps) => {
  const styles = useStyle();

  return (
    <>
      <CssBaseline />
      <Grid container className={clsx(styles.root, showBgImage && styles.rootBgImage)}>
        <Grid item xs={12}>
          <Container maxWidth={maxWidth} className={styles.container}>
            <Paper elevation={3} className={clsx(styles.paper, paperClasses)}>
              {children}
            </Paper>
            {showLanguageSelector && (
              <div className={styles.languageSwitchWrapper}>
                <I18nSwitch />
              </div>
            )}
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default CenteredPaperLayout;
