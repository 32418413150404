import { createSlice } from '@reduxjs/toolkit';
import { ContractsState } from './model';
import * as reducers from './reducers';

const slice = createSlice<ContractsState, typeof reducers>({
  name: 'contracts',
  initialState: {
    contractTemplatesWithContractsState: undefined,
    contractTemplatesWithContracts: undefined,
  },
  reducers: {
    ...reducers,
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;
