// Core libraries
import React, { useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DownloadIcon from '@material-ui/icons/GetApp';

// Internal libs
import { actions as driverContractActions, selectors } from '@features/admin';
import { DocumentStatusEnum } from '@features/contracts/model/DocumentStatus';
import { InProgressState, RootThunk } from '@common/model';
import { ContractName } from '@features/admin/drivers/model/DriverContracts';

// Internal components
import DriverContractDetailsView from '@features/admin/drivers/conteiners/DriverContractDetailsView';
import ProgressButton from '@common/components/ProgressButton';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormErrors from '@common/components/FormErrors';
import DownloadDriverContract from '@features/admin/drivers/conteiners/DownloadDriverContract';
import { mergeResponseErrors } from '@common/tools/mergeResponseErrors';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

export enum TabName {
  transport = 'transport_services_agreement',
  property = 'property_entrustment_agreement',
  criminalRecord = 'criminal_record',
  residenceProof = 'residence_proof',
}

// Props type
type DriverContractModalProps = {};

// Component
const DriverContractModal = ({}: DriverContractModalProps) => {
  const { t } = useTranslation('contracts');
  const { driverId } = useParams() as { driverId: string };
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitState, setSubmitState] = useState<InProgressState>({ inProgress: false });
  const [disabledSubmitButtonState, setDisabledSubmitButtonState] = useState<boolean>();
  const [selectedTabName, setSelectedTabName] = useState(TabName.property);
  const contract = useSelector(selectors.selectDriverAgreement(driverId, selectedTabName));
  const form = useForm();
  const [errors, setErrors] = useState<string[] | undefined>();

  const onClose = () => {
    history.push('.');
  };

  const onSubmit = async (values: any) => {
    const actionMap: {
      [key: string]: {
        action: (contractId: string, reason: string) => RootThunk;
      };
    } = contract
      ? {
          [DocumentStatusEnum.rejected]: { action: driverContractActions.rejectContract },
          [DocumentStatusEnum.terminated]: { action: driverContractActions.terminateContract },
          [DocumentStatusEnum.accepted]: { action: driverContractActions.acceptContract },
        }
      : {
          [DocumentStatusEnum.rejected]: { action: driverContractActions.rejectDocument },
          [DocumentStatusEnum.terminated]: { action: driverContractActions.terminateDocument },
          [DocumentStatusEnum.accepted]: { action: driverContractActions.acceptDocument },
        };

    setSubmitState({ inProgress: true });
    try {
      await dispatch(
        actionMap[values.adminAction]
          ? actionMap[values.adminAction].action(values.contractId, values.reason)
          : driverContractActions.acceptContract(values.contractId /*, values.file[0]*/),
      );
      setSubmitState({ inProgress: false, success: true });
      onClose();
    } catch (e) {
      setSubmitState({ inProgress: false, success: false, error: e });
      const errorMessages = mergeResponseErrors(e)
        .map((error: string) => t(`agreements.upload.errors.${error}`, ''))
        .filter((msg: string) => !!msg);
      setErrors(errorMessages.length ? errorMessages : [t('agreements.upload.errors.unknown')]);
    }
  };

  const { formErrors, getFieldErrors } = useResponseErrors(
    submitState?.error,
    'agreements',
    'contracts',
  );

  return (
    <Dialog
      open={true}
      onClose={onClose}
      className={styles.root}
      fullWidth
      maxWidth="md"
      disableBackdropClick
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogTitle>{t('menu.label')}</DialogTitle>
          <Divider />
          <DialogContent>
            {submitState.inProgress && <LinearProgress />}
            <DriverContractDetailsView
              driverId={driverId}
              getFieldErrors={getFieldErrors}
              setTabName={setSelectedTabName}
              isSubmitInProgress={submitState.inProgress}
              onSubmitButtonPress={setDisabledSubmitButtonState}
            />
            {errors && <FormErrors errorMessages={errors} />}
          </DialogContent>
          <DialogActions>
            {contract && (
              <Grid container justify="flex-start">
                <DownloadDriverContract
                  contract={contract}
                  title={t('contractAction.download')}
                  children={<DownloadIcon />}
                />
              </Grid>
            )}
            <Button onClick={onClose}>{t('contractAction.cancelButton')}</Button>
            <ProgressButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={disabledSubmitButtonState || submitState.inProgress}
              loading={submitState.inProgress}
            >
              {t('contractAction.submit')}
            </ProgressButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default DriverContractModal;
