import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { CorrectionsState } from '@features/admin/corrections/store/model';
import { Correction } from '@features/admin/corrections/model/Correction';

export const getCorrectionsListInit = (state: CorrectionsState, action: Action) => {
  state.getCorrectionsState = {
    inProgress: true,
  };
};
export const getCorrectionsListSuccess = (state: CorrectionsState, action: PayloadAction<Correction[]>) => {
  state.getCorrectionsState = {
    inProgress: false,
    success: true,
    error: undefined,
  };
  state.correctionsList = action.payload;
};
export const getCorrectionsListFailure = (
  state: CorrectionsState,
  action: PayloadAction<FailureResponse>,
) => {
  state.getCorrectionsState = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
  state.correctionsList = undefined;
};

// Approve and reject
export const CorrectionApproveInit = (
  state: CorrectionsState,
  action: PayloadAction<any>,
) => {};
export const CorrectionApproveSuccess = (state: CorrectionsState) => {};
export const CorrectionApproveFailure = (
  state: CorrectionsState,
  action: PayloadAction<FailureResponse>,
) => {};

export const CorrectionRejectInit = (
  state: CorrectionsState,
  action: PayloadAction<{
    correctionId: number;
  }>,
) => {};
export const CorrectionRejectSuccess = (state: CorrectionsState) => {};
export const CorrectionRejectFailure = (
  state: CorrectionsState,
  action: PayloadAction<FailureResponse>,
) => {};
