// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';

// Internal libs
import { Vehicle } from '../model/Vehicle';

// Internal components
import { useHistory, useRouteMatch } from 'react-router';
import { useDispatch } from 'react-redux';
import { InProgressState } from '@common/model';
import { actions } from '@features/vehicles/store';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import useMUIDataTableDefaultOptions from '@common/miscHooks/useMUIDataTableDefaultOptions';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const notApplicable = '-';

// Props type
type VehiclesListProps = { vehicles: Vehicle[] };

// Component
const VehiclesList = ({ vehicles }: VehiclesListProps) => {
  const { t } = useTranslation(['vehicles', 'common']);
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const [setActiveState, setSetActiveState] = useState<InProgressState & { vehicleId?: number }>({
    inProgress: false,
  });

  const setActive = async (vehicleId: number) => {
    if (setActiveState.inProgress) {
      return;
    }

    setSetActiveState({
      inProgress: true,
      vehicleId,
    });

    try {
      await dispatch(actions.setVehicleActive(vehicleId));
      dispatch(actions.getVehiclesList());
      setSetActiveState({
        inProgress: false,
        success: true,
        vehicleId,
      });
    } catch (e) {
      setSetActiveState({
        inProgress: false,
        error: e,
        vehicleId,
      });
    }
  };

  const renderActions = (dataIndex: number) => {
    const vehicle = vehicles && vehicles[dataIndex];

    return (
      // <div className={styles.actions}>
      <Grid container spacing={1} justifyContent="center">
        <Grid item>
          <Button
            color="primary"
            fullWidth
            size="small"
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() => {
              history.push(`${path}/edit/${vehicle.id}`);
            }}
          >
            {t('common:edit')}
          </Button>
        </Grid>
        {!vehicle.active && (
          <Grid item>
            <Button
              color="primary"
              fullWidth
              size="small"
              variant="contained"
              startIcon={<CheckIcon />}
              onClick={() => setActive(vehicle.id)}
            >
              {t('common:activate')}
            </Button>
          </Grid>
        )}
      </Grid>
      // </div>
    );
  };

  const options = useMUIDataTableDefaultOptions(true);
  const columns: MUIDataTableColumn[] = [
    {
      name: 'active',
      label: t('fields.isActive'),
      options: {
        customBodyRender: (value: boolean) => value && <CheckIcon />,
        searchable: false,
        filter: false,
      },
    },
    {
      name: 'manufacturer',
      label: t('fields.manufacturer'),
      options: {
        customBodyRender: (value: string) => value || notApplicable,
        filter: false,
      },
    },
    {
      name: 'model',
      label: t('fields.model'),
      options: {
        customBodyRender: (value: string) => value || notApplicable,
        filter: false,
      },
    },
    {
      name: 'registrationNumber',
      label: t('fields.registrationNumber'),
      options: {
        customBodyRender: (value: string) => value || notApplicable,
        filter: false,
      },
    },
    {
      name: 'color',
      label: t('fields.color'),
      options: {
        customBodyRender: (value: string) => value || notApplicable,
        filter: false,
      },
    },
    {
      name: 'fuelType',
      label: t('fields.fuelType.label'),
      options: {
        customBodyRender: (value: string) =>
          value ? t(`fields.fuelType.types.${value}`, value) : notApplicable,
      },
    },
    {
      name: 'vehicleType',
      label: t('fields.vehicleType.label'),
      options: {
        customBodyRender: (value: string) => t(`fields.vehicleType.types.${value}`, value),
      },
    },
    {
      name: 'createdAt',
      label: t('fields.createdAt'),
      options: {
        customBodyRender: (value: string) =>
          t('common:datetime.datetime', { date: new Date(value) }),
        filter: false,
      },
    },
    {
      name: 'updatedAt',
      label: t('fields.updatedAt'),
      options: {
        customBodyRender: (value: string) =>
          t('common:datetime.datetime', { date: new Date(value) }),
        filter: false,
      },
    },
    {
      name: 'actions',
      label: t('fields.actions'),
      options: {
        customBodyRenderLite: renderActions,
        download: false,
        filter: false,
        print: false,
      },
    },
  ];

  return <MUIDataTable columns={columns} data={vehicles} title="" options={options} />;
};

export default VehiclesList;
