import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { ContractTemplateWithContract } from '../../model/ContractTemplateWithContract';
import { ContractStatusEnum } from '@features/contracts/model/ContractStatus';

export const selectContractTemplatesWithContractsState = (
  state: RootState,
): InProgressState | undefined => state.contracts.contractTemplatesWithContractsState;
export const selectContractTemplatesWithContracts = (
  state: RootState,
): ContractTemplateWithContract[] | undefined => state.contracts.contractTemplatesWithContracts;

export const selectMissingContractsCount = (state: RootState): number | undefined =>
  state.contracts.contractTemplatesWithContracts &&
  state.contracts.contractTemplatesWithContracts.length
    ? state.contracts.contractTemplatesWithContracts.filter(
        (contract) =>
          contract.supported &&
          (!contract.contract ||
            [ContractStatusEnum.uploaded, ContractStatusEnum.accepted].indexOf(
              contract.contract.status,
            ) === -1),
      ).length
    : undefined;

export const selectPendingContractsCount = (state: RootState): number | undefined =>
  state.contracts.contractTemplatesWithContracts &&
  state.contracts.contractTemplatesWithContracts.length
    ? state.contracts.contractTemplatesWithContracts.filter(
        (contract) =>
          contract.supported && contract.contract?.status === ContractStatusEnum.uploaded,
      ).length
    : undefined;
