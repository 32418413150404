// Core libraries
import React, { useMemo } from 'react';

// Internal libs

// Internal components

// Props type
type BankAccountNoProps = { bankAccountNo?: string; onErrorValue?: string };

// Component
const BankAccountNo = ({ bankAccountNo, onErrorValue = '' }: BankAccountNoProps) => {
  const accountElements = useMemo(() => {
    if (!bankAccountNo) {
      return onErrorValue;
    }

    const match = bankAccountNo
      .toUpperCase()
      .match(/(^[A-z]{2})(\d{2})(([\dA-z]{4})+)([\dA-z]+)?$/);

    return match
      ? [
          match[1],
          match[2],
          match[3].replace(/(.{4})/g, '$1 ').trimEnd(),
          ...(match[5] ? [match[5]] : []),
        ].join(' ')
      : onErrorValue;
  }, [bankAccountNo]);

  return <span>{accountElements}</span>;
};

export default BankAccountNo;
