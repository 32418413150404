// Core libraries
import React, { useEffect, useMemo, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';

// Internal libs
import { correctionActions, correctionSelectors } from '../store';

// Internal components
import TableFiltersToolbar from '@common/components/filters/TableFiltersToolbar';
import useMUIDataTableDefaultOptions from '@common/miscHooks/useMUIDataTableDefaultOptions';
import LinearProgress from '@material-ui/core/LinearProgress';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type CorrectionsCardProps = {};

// Component
const CorrectionsCard = ({}: CorrectionsCardProps) => {
  const { t } = useTranslation(['admin']);
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const getCorrections = async () => {
    try {
      await dispatch(correctionActions.getCorrectionsList());
    } catch (e) {}
  };

  useEffect(() => {
    getCorrections();
  }, []);
  const getCorrectionsStatus = useSelector(correctionSelectors.selectGetCorrectionsState);
  const corrections = useSelector(correctionSelectors.selectCorrections) || [];

  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'id',
        label: t('admin:corrections.fields.id'),
      },
      {
        name: 'rideId',
        label: t('admin:corrections.fields.rideId'),
        options: {
          customBodyRender: (value: string) =>
            value
              ? t('admin:corrections.rideSynchronized.yes')
              : t('admin:corrections.rideSynchronized.no'),
          customFilterListOptions: {
            render: (v: string) => `${t('admin:corrections.detailsView.rideId')}: ${v}`,
          },
        },
      },
      {
        name: 'companyName',
        label: t('admin:corrections.fields.companyName'),
      },
      {
        name: 'createdAt',
        label: t('admin:corrections.fields.createdAt'),
        options: {
          customBodyRender: (value: string) =>
            t('common:datetime.datetime', { date: new Date(value) }),
        },
      },
      {
        name: 'driverName',
        label: t('admin:corrections.fields.driverName'),
      },
      {
        name: 'driverEmail',
        label: t('admin:corrections.fields.driverEmail'),
      },
      {
        name: 'rideNo',
        label: t('admin:corrections.fields.rideNo'),
      },
    ],
    [t],
  );

  const options: MUIDataTableOptions = {
    ...useMUIDataTableDefaultOptions(true),
    search: true,
    filter: true,
    onRowClick: (currentRowsSelected: any, allRowsSelected: any) => {
      return history.push(`${path}/${currentRowsSelected[0]}`);
    },
  };
  return (
    <Card>
      {getCorrectionsStatus?.inProgress && <LinearProgress data-testid="loading-rides" />}
      <MUIDataTable
        columns={columns}
        data={corrections}
        title={t('admin:corrections.title')}
        options={options}
      />
    </Card>
  );
};

export default CorrectionsCard;
