export enum VehicleType {
  'car' = 'car',
  'motorbike' = 'motorbike',
  'bicycle' = 'bicycle',
  'pedestrian' = 'pedestrian',
  'scooter' = 'scooter',
}

export enum FuelType {
  'gasoline' = 'gasoline',
  'diesel' = 'diesel',
  'electric' = 'electric',
  'hybrid' = 'hybrid',
}

export interface VehicleInsert {
  manufacturer?: string;
  model?: string;
  registrationNumber?: string;
  color?: string;
  fuelType?: FuelType;
  vehicleType: VehicleType;
}

export interface VehicleUpdate extends Partial<VehicleInsert> {
  id: number;
}

export interface Vehicle extends VehicleInsert {
  id: number;
  userId: number;
  active: boolean;
  deletedAt: string | undefined;
  createdAt: string;
  updatedAt: string;
}

export interface VehicleSrc {
  id: number;
  user_id: number;
  manufacturer: string | null;
  model: string | null;
  registration_number: string | null;
  color: string | null;
  fuel_type: FuelType | null;
  vehicle_type: VehicleType;
  active: number;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
}

export const vehicleFromSrc = (vehicleSrc: VehicleSrc): Vehicle => ({
  id: vehicleSrc.id,
  userId: vehicleSrc.user_id,
  manufacturer: vehicleSrc.manufacturer !== null ? vehicleSrc.manufacturer : undefined,
  model: vehicleSrc.model !== null ? vehicleSrc.model : undefined,
  registrationNumber:
    vehicleSrc.registration_number !== null ? vehicleSrc.registration_number : undefined,
  color: vehicleSrc.color !== null ? vehicleSrc.color : undefined,
  fuelType: vehicleSrc.fuel_type !== null ? vehicleSrc.fuel_type : undefined,
  vehicleType: vehicleSrc.vehicle_type,
  active: !!vehicleSrc.active,
  deletedAt: vehicleSrc.deleted_at || undefined,
  createdAt: vehicleSrc.created_at,
  updatedAt: vehicleSrc.updated_at,
});

export const vehicleToSrc = (vehicle: Vehicle): VehicleSrc => ({
  id: vehicle.id,
  user_id: vehicle.userId,
  manufacturer: vehicle.manufacturer || null,
  model: vehicle.manufacturer || null,
  registration_number: vehicle.registrationNumber || null,
  color: vehicle.color || null,
  fuel_type: vehicle.fuelType || null,
  vehicle_type: vehicle.vehicleType,
  active: Number(vehicle.active),
  deleted_at: vehicle.deletedAt || null,
  created_at: vehicle.createdAt,
  updated_at: vehicle.updatedAt,
});
