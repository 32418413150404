// Core libraries
import React, { useRef, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import PersonIcon from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';

// Internal libs
import { actions } from '@features/auth/store';
import { selectors as userSelectors } from '@features/user';

// Internal components
import AppBarMenuItem from '@features/main/components/AppBarMenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import I18nSwitch from '@common/components/I18nSwitch';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      maxWidth: '80%',
    },
    username: {
      display: 'block',
      marginRight: theme.spacing(1),
      flexShrink: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
);

const AppBarMenu = () => {
  const { t } = useTranslation(['user', 'auth']);
  const dispatch = useDispatch();
  const styles = useStyles();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const anchorRef = useRef(null);

  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  const user = useSelector(userSelectors.selectUser);

  const logout = () => dispatch(actions.logout());

  return (
    <div className={styles.root}>
      <I18nSwitch />

      <span className={styles.username}>{!!user && user.email}</span>
      <IconButton ref={anchorRef} edge="start" color="inherit" aria-label="menu" onClick={openMenu}>
        <PersonIcon />
      </IconButton>
      <Menu
        open={isMenuOpen}
        onClose={closeMenu}
        keepMounted
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
      >
        <AppBarMenuItem text={t('user:menu.label', '')} redirectTo="/profile" onClose={closeMenu} />
        <AppBarMenuItem
          text={t('auth:logout.menu.label', '')}
          onClick={logout}
          onClose={closeMenu}
        />
      </Menu>
    </div>
  );
};

export default AppBarMenu;
