import { Ride, rideFromSrc, RideSrc, rideToSrc } from '@features/rides/model/Ride';
import {
  PartnerDriver,
  partnerDriverFromSrc,
  PartnerDriverSrc,
  partnerDriverToSrc,
} from './PartnerDriver';

export interface PartnerDriverRide extends Ride {
  driver: PartnerDriver;
}

export interface PartnerDriverRideSrc extends RideSrc {
  driver: PartnerDriverSrc;
}

export const partnerDriverRideFromSrc = (
  partnerDriverRideSrc: PartnerDriverRideSrc,
): PartnerDriverRide => ({
  ...rideFromSrc(partnerDriverRideSrc),
  driver: partnerDriverFromSrc(partnerDriverRideSrc.driver),
});

export const partnerDriverRideToSrc = (
  partnerDriverRide: PartnerDriverRide,
): PartnerDriverRideSrc => ({
  ...rideToSrc(partnerDriverRide),
  driver: partnerDriverToSrc(partnerDriverRide.driver),
});
