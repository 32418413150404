import axios from 'axios';
import config from '@app/config';

export const login = (username: string, password: string) => {
  return axios.post('/auth/login', { username, password });
};

export const loginSocial = (serviceName: string) => {
  window.location.href = `${config.backendBaseUrl}/redirect/${serviceName}`;
};
export const loginSocialConfirmToken = (serviceName: string, query: string) => {
  return axios.get(`/auth/login/${serviceName}/callback?${query}`);
};

export const logout = () => {
  return axios.get('/auth/logout');
};

export const requestPasswordResetLink = (email: string) => {
  return axios.post('/auth/password_reset/send_token', { email });
};

export const verifySetNewPasswordToken = (tokenId: string, token: string) => {
  return axios.post('/auth/password_reset/verify_token', { token_id: tokenId, token });
};

export const setNewPassword = (tokenId: string, token: string, password: string) => {
  return axios.post('/auth/password_reset/change_password', {
    token_id: tokenId,
    token,
    password,
    password_confirmation: password,
  });
};
