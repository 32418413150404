// Core libraries
import React from 'react';

// External libs and components

// Internal libs
import { IdentifiedDrawerMenuItem } from '../model/DrawerMenuItemData';

// Internal components
import DrawerItem from '../components/DrawerItem';

// Props type
type DrawerMenuItemsProps = { items: IdentifiedDrawerMenuItem[]; onSelect?: () => void };

const DrawerMenuItems = ({ items, onSelect }: DrawerMenuItemsProps) => {
  return (
    <>
      {items.map((item) => (
        <DrawerItem
          text={item.label}
          icon={item.icon}
          key={item.id}
          badge={item.badge}
          {...(item.redirectTo ? { redirectTo: item.redirectTo } : {})}
          {...(item.children.length
            ? { children: <DrawerMenuItems items={item.children} onSelect={onSelect} /> }
            : {})}
          {...(typeof item.disabled !== 'undefined' ? { disabled: item.disabled } : {})}
          onSelect={!item.children.length ? onSelect : undefined}
        />
      ))}
    </>
  );
};

export default DrawerMenuItems;
