import { useCallback } from 'react';

const usePasswordConfirmationValidator = (
  getValues: (field: string) => string,
  passwordFieldName: string,
  message?: string,
) =>
  useCallback((value: string) => getValues(passwordFieldName) === value || message || false, [
    getValues,
    passwordFieldName,
    message,
  ]);

export default usePasswordConfirmationValidator;
