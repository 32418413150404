// Core libraries
import React, { useCallback, useEffect, useState } from 'react';

// External libs and components
import { useDispatch } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CircularProgress from '@material-ui/core/CircularProgress';

// Internal libs
import { InProgressState } from '@common/model';
import { rideActions } from '@features/admin';

// Internal components
import { RideUploadedDocument } from '@features/rides/model';

// Props type
type RideDocumentsContainerProps = {
  rideId: number;
  rideUploadedDocuments: RideUploadedDocument[];
};

// Component
const RideDocumentsContainer = ({ rideId, rideUploadedDocuments }: RideDocumentsContainerProps) => {
  const dispatch = useDispatch();
  const [downloadState, setDownloadState] = useState<InProgressState>({
    inProgress: false,
  });
  const download = async (documentId: number, originalFileName: string) => {
    setDownloadState({ inProgress: true });
    try {
      await dispatch(rideActions.downloadRideDocument(rideId, documentId, originalFileName));
      setDownloadState({ inProgress: false, success: true });
    } catch (error) {
      setDownloadState({ inProgress: false, success: false, error });
    }
  };

  return (
    <List component="nav">
      {rideUploadedDocuments.map((rideUploadedDocument) => (
        <ListItem
          button
          disabled={downloadState.inProgress}
          onClick={() => download(rideUploadedDocument.id, rideUploadedDocument.originalFileName)}
        >
          <ListItemIcon>
            {downloadState.inProgress ? <CircularProgress /> : <AttachmentIcon />}
          </ListItemIcon>
          <ListItemText primary={rideUploadedDocument.comment} />
        </ListItem>
      ))}
    </List>
  );
};

export default RideDocumentsContainer;
