// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TableToolbar } from 'mui-datatables';
import { useTableFilter } from './TableFiltersProvider';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'top',
    },
  }),
);

// Component
const TableFiltersToolbar = (props: any) => {
  const filters = useTableFilter();
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {!!filters && filters}
      <TableToolbar {...props} />
    </div>
  );
};

export default TableFiltersToolbar;
