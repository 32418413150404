// Core libraries
import React from 'react';

// External libs and components
import PageDefaultLayout from '@common/components/layouts/PageDefaultLayout';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

// Internal libs

// Internal components
import PendingRequestsCard from './PendingRequestsCard';
import PartnerDriversCard from './PartnerDriversCard';

// Props type
type DriversPageProps = {};

// Component
const DriversPage = ({}: DriversPageProps) => {
  const { t } = useTranslation('partner');

  return (
    <PageDefaultLayout title={t('title')}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <PendingRequestsCard />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PartnerDriversCard />
        </Grid>
      </Grid>
    </PageDefaultLayout>
  );
};

export default DriversPage;
