// Core libraries
import React from 'react';

// External libs and components
import { Redirect, Route, Switch } from 'react-router-dom';

// Internal libs

// Internal components
import RidesPage from '@features/admin/rides/containers/RidesPage';
import DriversPage from '@features/admin/drivers/conteiners/DriversPage';
import AdminCorrectionsPage from '@features/admin/corrections/containers/CorrectionsPage';
import AdminInvoicesPage from '@features/admin/invoices/conteiners/AdminInvoicesPage';

// Props type
type RoutingProps = { basePath?: string };

// Component
const Routing = ({ basePath = '' }: RoutingProps) => {
  return (
    <Switch>
      <Route path={`${basePath}/drivers`} children={<DriversPage />} />
      <Route path={`${basePath}/rides`} children={<RidesPage />} />
      <Route path={`${basePath}/invoices`} children={<AdminInvoicesPage />} />
      <Route path={`${basePath}/corrections`} children={<AdminCorrectionsPage />} />
      <Route path={`${basePath}`} children={<Redirect to={`${basePath}/rides`} />} />
    </Switch>
  );
};

export default Routing;
