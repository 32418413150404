import {
  timestampable,
  timestampableFromSrc,
  timestampableSrc,
  timestampableToSrc,
} from '@common/model';

export interface Ride extends timestampable {
  id: number;
  uuid: string;
  partnerId: number;
  partnerName?: string;
  userId: number;
  xpressDeliveryCompanyId: number;
  type: string;
  no: string;
  externalId: string;
  status: string;
  region: string;
  estimatedPayout: number;
  payout: number;
  estimatedDuration: number;
  estimatedDistance: number;
  cancelledBy?: string;
  cancellationReason?: string;
  closedAt?: string;
  scheduledAt?: string;
  deliveredAt?: string;
  cancelledAt?: string;
  invoiceId?: string;
  settledAt?: string;
  correctionReason?: string;
  referenceRideId?: number;
}

export interface RideSrc extends timestampableSrc {
  id: number;
  uuid: string;
  partner_id: number;
  user_id: number;
  xpress_delivery_company_id: number;
  type: string;
  no: string;
  external_id: string;
  status: string;
  region: string;
  estimated_payout: string;
  payout: string;
  estimated_duration: string;
  estimated_distance: string;
  cancelled_by: string | null;
  cancellation_reason: string | null;
  closed_at: string | null;
  scheduled_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  invoice_id: string | null;
  settled_at: string | null;
  correction_reason: string | null;
  reference_ride_id: number | null;
  partner: PartnerSrc | null;
}

export interface PartnerSrc {
  name: string;
}

export const rideFromSrc = (rideSrc: RideSrc): Ride => ({
  id: rideSrc.id,
  uuid: rideSrc.uuid,
  partnerId: rideSrc.partner_id,
  partnerName: rideSrc.partner?.name,
  userId: rideSrc.user_id,
  xpressDeliveryCompanyId: rideSrc.xpress_delivery_company_id,
  type: rideSrc.type,
  no: rideSrc.no,
  externalId: rideSrc.external_id,
  status: rideSrc.status,
  region: rideSrc.region,
  estimatedPayout: parseFloat(rideSrc.estimated_payout),
  payout: parseFloat(rideSrc.payout),
  estimatedDuration: parseFloat(rideSrc.estimated_duration),
  estimatedDistance: parseFloat(rideSrc.estimated_distance),
  cancelledBy: rideSrc.cancelled_by || undefined,
  cancellationReason: rideSrc.cancellation_reason || undefined,
  closedAt: rideSrc.closed_at || undefined,
  scheduledAt: rideSrc.scheduled_at || undefined,
  deliveredAt: rideSrc.delivered_at || undefined,
  cancelledAt: rideSrc.cancelled_at || undefined,
  invoiceId: rideSrc.invoice_id || undefined,
  settledAt: rideSrc.settled_at || undefined,
  correctionReason: rideSrc.correction_reason || undefined,
  referenceRideId: rideSrc.reference_ride_id || undefined,
  ...timestampableFromSrc(rideSrc),
});

export const rideToSrc = (ride: Ride): RideSrc => ({
  id: ride.id,
  uuid: ride.uuid,
  partner_id: ride.partnerId,
  partner: null,
  user_id: ride.userId,
  xpress_delivery_company_id: ride.xpressDeliveryCompanyId,
  type: ride.type,
  no: ride.no,
  external_id: ride.externalId,
  status: ride.status,
  region: ride.region,
  estimated_payout: ride.estimatedPayout.toFixed(2),
  payout: ride.payout.toFixed(2),
  estimated_duration: ride.estimatedDuration.toFixed(2),
  estimated_distance: ride.estimatedDistance.toFixed(2),
  cancelled_by: ride.cancelledBy || null,
  cancellation_reason: ride.cancellationReason || null,
  closed_at: ride.closedAt || null,
  scheduled_at: ride.scheduledAt || null,
  delivered_at: ride.deliveredAt || null,
  cancelled_at: ride.cancelledAt || null,
  invoice_id: ride.invoiceId || null,
  settled_at: ride.settledAt || null,
  correction_reason: ride.correctionReason || null,
  reference_ride_id: ride.referenceRideId || null,
  ...timestampableToSrc(ride),
});
