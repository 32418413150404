import { RootState } from '@app/store';
import { Region } from '../../model/Region';
import { InProgressState } from '@common/model';

export const selectRegions = (state: RootState): Region[] | undefined => state.user.regions;

export const selectGetRegionsState = (state: RootState): InProgressState | undefined =>
  state.user.getRegionsState;

export const selectGetRegionsInProgress = (state: RootState): boolean | undefined =>
  state.user.getRegionsState?.inProgress;
