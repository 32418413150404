// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';

// Internal libs
import { Address } from '@common/model/Address';

// Internal components
import TwoLineAddress from '@common/components/dataViews/TwoLineAddress';
import DataItem from '@common/components/cardContent/DataItem';
import { Card } from '@material-ui/core';
import { CountryDefinition } from '@features/user/model/Company';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type InvoiceUserDataProps = {
  name: string;
  address: Address;
  taxIdentificationNumber: string;
  registrationNumber: string;
  countryDefinition: CountryDefinition;
  phone?: string;
};

// Component
const InvoiceUserData = ({
  name,
  address,
  taxIdentificationNumber,
  registrationNumber,
  phone,
  countryDefinition,
}: InvoiceUserDataProps) => {
  const { t } = useTranslation('user');
  const styles = useStyles();

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={12} md={6} lg={2}>
          <DataItem label={t('company.fields.name')} text={name} />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <DataItem
            label={t('company.fields.address')}
            text={address ? <TwoLineAddress address={address} /> : '-'}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <DataItem
            label={t(
              `company.fields.taxIdentificationNumber.${
                countryDefinition?.taxIdentificationNumberName ?? 'generic'
              }`,
            )}
            text={taxIdentificationNumber}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <DataItem
            label={t(
              `company.fields.registrationNumber.${
                countryDefinition?.registrationNumberName ?? 'generic'
              }`,
            )}
            text={registrationNumber}
          />
        </Grid>
        {phone && (
          <Grid item xs={12} md={6} lg={2}>
            <DataItem label={t('company.fields.phone')} text={phone} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default InvoiceUserData;
