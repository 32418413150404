// Core libraries
import React, { useCallback, useEffect, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import AttachmentIcon from '@material-ui/icons/Attachment';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// Internal libs
import { InProgressState } from '@common/model/InProgressState';

// Internal components
import { IdentityDocument } from '@features/user/model/IdentityDocument';
import DownloadIcon from '@material-ui/icons/GetApp';
import DataColorActionIcon from '@common/components/dataViews/DataColorActionIcon';
import { actions } from '@features/user';
import { useDispatch, useSelector } from 'react-redux';
import { useShowSnackbar } from '@features/snackbars';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    progress: { marginBottom: theme.spacing(1) },
    itemActionContainer: {
      display: 'flex',
    },
    noCriminalRecord: {
      color: theme.palette.success.light,
    },
    criminalRecord: {
      color: theme.palette.error.light,
    },
  }),
);

// Props type
type DriverContractIdentityDocumentsContainerProps = {
  identityDocuments?: IdentityDocument[];
};

// Component
const DriverContractIdentityDocumentsContainer = ({
  identityDocuments,
}: DriverContractIdentityDocumentsContainerProps) => {
  const { t } = useTranslation(['common', 'user']);
  const styles = useStyles();
  const dispatch = useDispatch();
  const showSnackbar = useShowSnackbar();

  // Download uploaded document
  const [downloadDocumentState, setDownloadDocumentState] = useState<InProgressState>({
    inProgress: false,
  });
  const downloadIdentityDocument = async (documentLink: string, fileName: string) => {
    setDownloadDocumentState({ inProgress: true });
    try {
      await dispatch(actions.downloadIdentityDocument(documentLink, fileName));
      setDownloadDocumentState({ inProgress: false, success: true });
    } catch (e) {
      setDownloadDocumentState({ inProgress: false, success: false, error: e });
    }
  };
  if (!identityDocuments) {
    return <></>;
  }
  return (
    <List dense>
      {(identityDocuments || []).map((identityDocument, index) => (
        <ListItem key={index}>
          <ListItemAvatar>
            <AttachmentIcon />
          </ListItemAvatar>
          <ListItemText
            primary={t(`identityDocument.types.${identityDocument.documentName}`)}
            secondary={
              identityDocument.status === 'accepted' ? (
                <Typography className={styles.noCriminalRecord}>
                  {t('identityDocument.extraDocument.status.accepted')}
                </Typography>
              ) : (
                t(`identityDocument.extraDocument.status.${identityDocument.status}`)
              )
            }
          />
          <p>{identityDocument.documentData?.criminalRecord !== undefined}</p>
          <p>{identityDocument.documentData?.criminalRecord}</p>
          <ListItemSecondaryAction className={styles.itemActionContainer}>
            {identityDocument.documentName === 'criminal_record' &&
              identityDocument.documentData?.criminalRecord !== undefined && (
                <DataColorActionIcon>
                  <Tooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">
                          {identityDocument.documentData?.firstName}{' '}
                          {identityDocument.documentData?.lastName}
                        </Typography>
                        <Typography color="inherit">
                          {identityDocument.documentData?.pesel}
                        </Typography>
                        {identityDocument.documentData?.criminalRecord ? (
                          <Typography color="error">KARANY</Typography>
                        ) : (
                          <Typography className={styles.noCriminalRecord}>Niekarany</Typography>
                        )}
                      </React.Fragment>
                    }
                  >
                    <InfoIcon
                      className={
                        identityDocument.documentData?.criminalRecord
                          ? styles.criminalRecord
                          : styles.noCriminalRecord
                      }
                    />
                  </Tooltip>
                </DataColorActionIcon>
              )}
            <DataColorActionIcon
              onClick={() =>
                downloadIdentityDocument(identityDocument.downloadLink, identityDocument.fileName)
              }
              tooltip={t('user:identityDocumentUpload.downloadIdentityDocument')}
              loading={downloadDocumentState.inProgress}
              key={'action'}
            >
              <DownloadIcon />
            </DataColorActionIcon>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default DriverContractIdentityDocumentsContainer;
