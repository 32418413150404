// Core libraries
import React, { useEffect } from 'react';

// External libs and components
import { Route, Switch, useRouteMatch } from 'react-router';
import { useDispatch } from 'react-redux';

// Internal libs
import { actions } from '../../store';

// Internal components
import InvoicesCard from '@features/company/containers/invoices/InvoicesCard';
import InvoiceDetails from '@features/company/containers/InvoiceInfo/InvoiceDetails';

// Props type
type InvoicesProps = {};

// Component
const Invoices = ({}: InvoicesProps) => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const getInvoices = async () => {
    try {
      await dispatch(actions.getInvoices());
    } catch (e) {}
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <Switch>
      <Route path={`${path}/invoice/:invoiceId`} component={InvoiceDetails} />
      <Route path={`${path}`} component={InvoicesCard} />
    </Switch>
  );
};

export default Invoices;
