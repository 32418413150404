import { actions as simpleActions } from '../slice';
import * as api from '../../api';
import { RootThunk } from '@common/model';

/****** Place for complex actions *****/

// Password reset action
export const passwordReset = (email: string): RootThunk => async (dispatch) => {
  dispatch(simpleActions.passwordResetInit(email));

  try {
    const response = await api.requestPasswordResetLink(email);
    dispatch(simpleActions.passwordResetSuccess());
  } catch (e) {
    dispatch(simpleActions.passwordResetFailure(e.response?.data));
    throw e.response?.data;
  }
};

// Verify password reset token
export const verifySetNewPasswordToken = (tokenId: string, token: string): RootThunk => async (
  dispatch,
) => {
  dispatch(simpleActions.verifySetNewPasswordTokenInit({ tokenId, token }));

  try {
    const response = await api.verifySetNewPasswordToken(tokenId, token);
    dispatch(simpleActions.verifySetNewPasswordTokenSuccess());
  } catch (e) {
    dispatch(simpleActions.verifySetNewPasswordTokenFailure(e.response?.data));
    throw e.response?.data;
  }
};

// Set new password
export const setNewPassword = (
  tokenId: string,
  token: string,
  password: string,
): RootThunk => async (dispatch) => {
  dispatch(simpleActions.setNewPasswordInit({ tokenId, token, password }));

  try {
    const response = await api.setNewPassword(tokenId, token, password);
    dispatch(simpleActions.setNewPasswordSuccess());
  } catch (e) {
    dispatch(simpleActions.setNewPasswordFailure(e.response?.data));
    throw e.response?.data;
  }
};
