// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import LinearProgress from '@material-ui/core/LinearProgress';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { User } from '../model/User';

// Internal components
import DataListItem from '@common/components/DataListItem';
import { numberToPhone } from '@common/validators/phoneValidator';
import { selectors as userSelectors } from '../store';

const DeleteAccountCard = () => {
  const { t } = useTranslation('user');
  const history = useHistory();
  const { path } = useRouteMatch();

  const userDataState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.user.getUserState,
  );
  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  const missingPhone = useSelector(userSelectors.selectUserMissingPhone);
  const missingPhoneVerification = useSelector(userSelectors.selectUserMissingPhoneVerification);

  const missingEmailVerification = useSelector(userSelectors.selectUserMissingEmailVerification);

  return (
    <Card>
      <CardHeader title={t('cards.deleteAccount.title', '')} />
      <Divider />
      <CardContent>
        {userDataState?.inProgress && <LinearProgress />}
        {userData && (
          <List>
            <p>{t('cards.deleteAccount.body')}</p>
            <Button color="inherit" size="small" onClick={() => history.push(`${path}/confirm`)}>
              {t('cards.deleteAccount.deleteAccountButton')}
            </Button>
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default DeleteAccountCard;
