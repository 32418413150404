export interface ResponseInfo {
  errors: ResponseInfoError[];
  generatedCount: number;
}

export interface ResponseInfoError {
  companyId: number;
  companyName: string;
  error: string;
}

export interface ResponseInfoSrc {
  errors: ResponseInfoError[];
  generated_count: number;
}

export interface ResponseInfoErrorSrc {
  company_id: number;
  company_name: string;
  error: string;
}

export const responseInfoFromSrc = (responseSrc: ResponseInfoSrc): ResponseInfo => ({
  errors: responseSrc.errors,
  generatedCount: responseSrc.generated_count,
});

export const responseInfoToSrc = (response: ResponseInfo): ResponseInfoSrc => ({
  errors: response.errors,
  generated_count: response.generatedCount,
});
