import { PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { DriversState } from '@features/admin/drivers/store/model';
import {
  CorrectionUpdate,
  DriverRideCorrection,
} from '@features/admin/drivers/model/DriverRideCorrection';

export const addDriverRideCorrectionInit = (
  state: DriversState,
  action: PayloadAction<{
    createdCorrection: DriverRideCorrection;
  }>,
) => {};
export const addDriverRideCorrectionSuccess = (state: DriversState) => {};
export const addDriverRideCorrectionFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {};

export const removeDriverRideCorrectionInit = (
  state: DriversState,
  action: PayloadAction<{
    rideId: number;
  }>,
) => {};
export const removeDriverRideCorrectionSuccess = (state: DriversState) => {};
export const removeDriverRideCorrectionFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {};

export const updateDriverRideCorrectionInit = (
  state: DriversState,
  action: PayloadAction<{
    rideId: number;
    createdCorrection: CorrectionUpdate;
  }>,
) => {};
export const updateDriverRideCorrectionSuccess = (state: DriversState) => {};
export const updateDriverRideCorrectionFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {};
