import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { UserState } from '../model';
import { Partner } from '../../model/Partner';

export const findPartnerInit = (state: UserState, action: PayloadAction<string>) => {};
export const findPartnerSuccess = (state: UserState, action: PayloadAction<Partner>) => {};
export const findPartnerFailure = (state: UserState, action: PayloadAction<FailureResponse>) => {};

export const joinPartnerInit = (state: UserState, action: PayloadAction<number>) => {};
export const joinPartnerSuccess = (state: UserState, action: Action) => {};
export const joinPartnerFailure = (state: UserState, action: PayloadAction<FailureResponse>) => {};

export const cancelPartnerRequestInit = (state: UserState, action: PayloadAction<number>) => {};
export const cancelPartnerRequestSuccess = (state: UserState, action: Action) => {};
export const cancelPartnerRequestFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

const partnerReducers = {
  findPartnerInit,
  findPartnerSuccess,
  findPartnerFailure,
  joinPartnerInit,
  joinPartnerSuccess,
  joinPartnerFailure,
  cancelPartnerRequestInit,
  cancelPartnerRequestSuccess,
  cancelPartnerRequestFailure,
};
export default partnerReducers;
