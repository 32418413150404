// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { User } from '../model/User';
import { actions } from '../store';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import FormTextField from '@common/components/FormTextField';
import usePhoneValidator, {
  phoneMask,
  phoneToNumber,
  numberToPhone,
} from '@common/validators/phoneValidator';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormErrors from '@common/components/FormErrors';
import FormSelectField from '@common/components/FormSelectField';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(2),
    },
  }),
);

// Props type
type PhoneEditModalProps = {
  onCloseCallback?: VoidFunction;
  onSubmitCallback?: VoidFunction;
};

// Component
const PhoneEditModal = ({ onCloseCallback, onSubmitCallback }: PhoneEditModalProps) => {
  const { t } = useTranslation('user');
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const form = useForm();

  const userDataState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.user.updateUserDataState,
  );
  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  const onClose = () => {
    onCloseCallback?.();
  };

  const [addPhoneState, setAddPhoneState] = useState<InProgressState>({ inProgress: false });

  const onSubmit = async (values: any) => {
    setAddPhoneState({ inProgress: true });
    try {
      // For now we don't allow setting phone prefix other than default
      await dispatch(actions.addUserPhone(phoneToNumber(values.phone), values.phonePrefix));
      setAddPhoneState({ inProgress: false, success: true });
      onSubmitCallback?.();
    } catch (e) {
      setAddPhoneState({ inProgress: false, error: e, success: false });
    }
  };

  const { formErrors, getFieldErrors } = useResponseErrors(
    addPhoneState?.error,
    'addPhone',
    'user',
  );

  const phoneValidator = usePhoneValidator(t('addPhone.fields.phone.errors.phone', ''));

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle>{t('addPhone.title', '')}</DialogTitle>
        <Divider />
        <DialogContent>
          {userDataState?.inProgress && <LinearProgress />}
          {userData && (
            <>
              <FormSelectField
                className={styles.field}
                name="phonePrefix"
                label={t('user.fields.phonePrefix', '')}
                options={[
                  { label: '(+48)', value: 48 },
                  { label: '(+420)', value: 420 },
                ]}
                rules={{
                  required: {
                    value: true,
                    message: t('addPhone.fields.phonePrefix.errors.required', ''),
                  },
                }}
                errors={getFieldErrors('phonePrefix', 'phone_prefix')}
                defaultValue={userData.phonePrefix ?? 48}
              />
              <FormTextField
                className={styles.field}
                name="phone"
                label={t('user.fields.phone', '')}
                placeholder="987 654 321"
                mask={phoneMask}
                rules={{
                  required: {
                    value: true,
                    message: t('addPhone.fields.phone.errors.required', ''),
                  },
                  validate: {
                    phone: phoneValidator,
                  },
                }}
                fullWidth={true}
                errors={getFieldErrors('phone')}
                defaultValue={userData.phone}
              />

              {/* Errors */}
              <FormErrors errorMessages={formErrors} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {onCloseCallback && <Button onClick={onClose}>{t('addPhone.cancelButton')}</Button>}
          <ProgressButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={addPhoneState?.inProgress}
            loading={addPhoneState?.inProgress}
          >
            {t(`addPhone.submitButton`, '')}
          </ProgressButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default PhoneEditModal;
