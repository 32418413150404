// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { RideDetails } from '@features/rides/model';
import Grid from '@material-ui/core/Grid';

// Internal libs
import DataListItem from '@common/components/DataListItem';

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    noReference: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

// Props type
type ReferenceRideInfoProps = { rideDetails: RideDetails };

// Component
const ReferenceRideInfo = ({ rideDetails }: ReferenceRideInfoProps) => {
  const styles = useStyles();
  const { t } = useTranslation('rides');

  return rideDetails.referenceRide ? (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <DataListItem
          label={t('model.ride.type.shortLabel')}
          text={t(`model.ride.type.map.${rideDetails.type}`, rideDetails.type)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DataListItem label={t('model.ride.shortNo')} text={rideDetails.referenceRide?.no} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DataListItem label={t('model.ride.payout')} text={rideDetails.referenceRide?.payout} />
      </Grid>
    </Grid>
  ) : (
    <div className={styles.noReference}>{t('ridesCorrection.noMatch.noReference')}</div>
  );
};

export default ReferenceRideInfo;
