// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormProvider, useForm } from 'react-hook-form';

// Internal libs
import { invoiceActions } from '../store';
import { InProgressState } from '@common/model';
import { ResponseInfo } from '@features/admin/invoices/model/ResponseInfo';
import { DataRange, TextDataRange } from '@features/admin/invoices/model/DateRange';
import { getDateRangeFromToday, setDayEnd, setDayStart } from '@common/tools/dateUtils';
import config from '@app/config';

// Internal components
import FormTextField from '@common/components/FormTextField';
import ProgressButton from '@common/components/ProgressButton';
import AdminInvoicesGeneratorAlert from '@features/admin/invoices/conteiners/AdminInvoicesGeneratorAlert';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
  }),
);

type InvoicesFilterFormData = { invoicesDateFrom: string; invoicesDateTo: string; search: string };

// Props type
type AdminInvoicesGeneratorProps = { dateRange: DataRange };

// Component
const AdminInvoicesGenerator = ({ dateRange }: AdminInvoicesGeneratorProps) => {
  const { t } = useTranslation(['company', 'admin']);
  const styles = useStyles();
  const form = useForm();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const dateToTextValue = (date: Date) => date.toISOString().substr(0, 10);
  const [selectedDateRange, setSelectedDateRangeState] = useState<TextDataRange>({
    dateFrom: dateToTextValue(dateRange.dateFrom),
    dateTo: dateToTextValue(dateRange.dateTo),
  });
  const [generateState, setGenerateState] = useState<InProgressState>({ inProgress: false });
  const [responseInfoState, setResponseInfoState] = useState<ResponseInfo>({
    errors: [],
    generatedCount: 0,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values: InvoicesFilterFormData) => {
    setSelectedDateRangeState({
      dateFrom: values.invoicesDateFrom,
      dateTo: values.invoicesDateTo,
    });
    setGenerateState({ inProgress: true });
    try {
      handleClose();
      const responseInfo = await dispatch(
        invoiceActions.generateInvoices(values.invoicesDateFrom, values.invoicesDateTo),
      );
      // @ts-ignore
      setResponseInfoState(responseInfo);
      setGenerateState({ inProgress: false, success: true });
      await dispatch(
        invoiceActions.getInvoices(
          new Date(setDayStart(dateRange.dateFrom)).toISOString(),
          new Date(setDayEnd(dateRange.dateTo)).toISOString(),
        ),
      );
    } catch (e) {
      setGenerateState({ inProgress: false, success: false, error: e });
    }
  };

  const [defaultValues] = useState(() => {
    const dateRange = getDateRangeFromToday(0, config.defaultReportDateRangeMonths);

    return {
      dateFrom: dateRange.dateFrom,
      dateTo: dateRange.dateTo,
      search: '',
    };
  });

  return (
    <div className={styles.root}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} data-testid="settlement-generate-form">
          <Grid container spacing={2} justify="space-between" alignItems="center">
            <Grid item xs={12} sm={8} md={6} lg={6}>
              <Grid container spacing={2} justify="flex-start" alignItems="center">
                <Grid item xs={12} sm={6} md={6}>
                  <FormTextField
                    name="invoicesDateFrom"
                    label={t('company:invoices.filter.dateFrom.label')}
                    type="date"
                    fullWidth
                    defaultValue={dateToTextValue(defaultValues.dateFrom)}
                    inputProps={{ max: dateToTextValue(defaultValues.dateTo) }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormTextField
                    name="invoicesDateTo"
                    label={t('company:invoices.filter.dateTo.label')}
                    type="date"
                    fullWidth
                    defaultValue={dateToTextValue(defaultValues.dateTo)}
                    inputProps={{ max: dateToTextValue(defaultValues.dateTo) }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item>
                <ProgressButton
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen}
                  disabled={generateState.inProgress}
                  loading={generateState.inProgress}
                >
                  {t('admin:invoices.generator.button.label')}
                </ProgressButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} justify="flex-end">
            {generateState.success && (
              <FormHelperText>
                {`${t('admin:invoices.generator.infoText.for')} ${selectedDateRange.dateFrom} ${t(
                  'admin:invoices.generator.infoText.to',
                )} ${selectedDateRange.dateTo} ${t(
                  'admin:invoices.generator.infoText.generated',
                )} ${responseInfoState.generatedCount} ${t(
                  'admin:invoices.generator.infoText.items',
                )}`}
              </FormHelperText>
            )}
          </Grid>
        </form>
      </FormProvider>
      <AdminInvoicesGeneratorAlert
        open={open}
        onClose={handleClose}
        onAccept={form.handleSubmit(onSubmit)}
      />
    </div>
  );
};

export default AdminInvoicesGenerator;
