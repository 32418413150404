import { UserState } from '@features/user/store/model';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { CreateCompany, UpdateCompany } from '../../model/Company';
import { CompanyDocumentType } from '@features/user/model/CompanyDocumentType';

export const createCompanyInit = (state: UserState, action: PayloadAction<CreateCompany>) => {};
export const createCompanySuccess = (state: UserState, action: Action) => {};
export const createCompanyFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

export const updateCompanyInit = (state: UserState, action: PayloadAction<UpdateCompany>) => {};
export const updateCompanySuccess = (state: UserState, action: Action) => {};
export const updateCompanyFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

export const uploadCompanyDocumentInit = (
  state: UserState,
  action: PayloadAction<{
    companyId: number;
    documentType: CompanyDocumentType;
    fileName: string;
    fileSize: number;
  }>,
) => {};
export const uploadCompanyDocumentSuccess = (state: UserState, action: Action) => {};
export const uploadCompanyDocumentFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

export const downloadCompanyDocumentInit = (state: UserState, action: PayloadAction<number>) => {};
export const downloadCompanyDocumentSuccess = (state: UserState, action: Action) => {};
export const downloadCompanyDocumentFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

const companyReducers = {
  createCompanyInit,
  createCompanySuccess,
  createCompanyFailure,
  updateCompanyInit,
  updateCompanySuccess,
  updateCompanyFailure,
  uploadCompanyDocumentInit,
  uploadCompanyDocumentSuccess,
  uploadCompanyDocumentFailure,
  downloadCompanyDocumentInit,
  downloadCompanyDocumentFailure,
  downloadCompanyDocumentSuccess,
};
export default companyReducers;
