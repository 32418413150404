// Core libraries
import React from 'react';

// External libs and components
import { FormHelperText } from '@material-ui/core';

// Internal libs

// Internal components

type FormErrorsProps = { errorMessages?: string[] };

const FormErrors = ({ errorMessages = [] }: FormErrorsProps) =>
  (errorMessages.length && (
    <FormHelperText error={true}>
      {errorMessages.map((message, idx) => (
        <div key={idx}>{message}</div>
      ))}
    </FormHelperText>
  )) ||
  null;

export default FormErrors;
