// Core libraries
import React, { SetStateAction, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';

// Internal libs
import { correctionActions } from '../store';
import { InProgressState } from '@common/model';

// Internal components
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormTextField from '@common/components/FormTextField';
import ProgressButton from '@common/components/ProgressButton';
import FormErrors from '@common/components/FormErrors';
import { Correction } from '@features/admin/corrections/model/Correction';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    field: {
      marginBottom: theme.spacing(1),
    },
    button: {
      padding: theme.spacing(2),
    },
  }),
);

// Props type
type CorrectionRejectFormProps = {
  afterSuccessSubmit?: (editMode: SetStateAction<boolean>) => void;
  correctionDetails?: Correction;
};

// Component
const CorrectionRejectForm = ({ afterSuccessSubmit }: CorrectionRejectFormProps) => {
  const { t } = useTranslation(['admin', 'rides']);
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { correctionId }: { correctionId: string } = useParams();
  const [submitState, setSubmitState] = useState<InProgressState>({ inProgress: false });
  const form = useForm();
  const { formErrors, getFieldErrors } = useResponseErrors(
    submitState?.error,
    'correctionRejectForm',
    'corrections',
  );

  const onSuccess = () =>
    afterSuccessSubmit ? afterSuccessSubmit(false) : history.push('/admin/corrections');
  const onSubmit = async (values: any) => {
    setSubmitState({ inProgress: true });
    try {
      await dispatch(
        correctionActions.rejectCorrection(+correctionId, {
          rejectionComment: values.rejectionComment || undefined,
        }),
      );
      setSubmitState({ inProgress: false, success: true });
      onSuccess();
      await dispatch(correctionActions.getCorrectionsList());
    } catch (e) {
      setSubmitState({ inProgress: false, success: false, error: e });
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid item xs={12} md={12} lg={12}>
            <FormTextField
              className={styles.field}
              name="rejectionComment"
              label={t('admin:corrections.form.fields.rejectionComment.label')}
              rules={{
                required: {
                  value: true,
                  message: t('admin:corrections.form.fields.rejectionComment.errors.required'),
                },
              }}
              fullWidth={true}
              errors={getFieldErrors('rejectionComment', 'rejection_comment')}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={styles.button}
          spacing={2}
          direction="row"
          alignItems="center"
          justify="flex-end"
        >
          <FormErrors errorMessages={formErrors} />
          <Button onClick={() => history.goBack()}>{t('rides:driverRideForm.cancelButton')}</Button>
          <ProgressButton
            variant="contained"
            color="secondary"
            type="submit"
            disabled={submitState.inProgress}
            loading={submitState.inProgress}
          >
            {t('admin:corrections.form.reject')}
          </ProgressButton>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default CorrectionRejectForm;
