import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';
import { PrivacyPolicyState } from './model';

const privacyPolicySlice = createSlice<PrivacyPolicyState, typeof reducers>({
  name: 'init',
  initialState: {
    isPrivacyPolicyAccepted: false,
  },
  reducers: {
    ...reducers,
  },
});

export const reducer = privacyPolicySlice.reducer;
export const actions = privacyPolicySlice.actions;
