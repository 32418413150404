// Core libraries
import React from 'react';

// External libs and components
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { AccountTypeEnum } from '../../model/Settlements';
import { User } from '../../model/User';
import { selectors as userSelectors } from '../../store';

// Internal components
import SetAccountTypeModal from './SetAccountTypeModal';
import PersonCardElement from './PersonCardElement';
import CompanyCardElement from './CompanyCardElement';
import ResignPartnerModal from '@features/user/containers/settlements/ResignPartnerModal';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    accountTypes: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

// Props type
type SettlementsDataCardProps = {};

// Component
const SettlementsDataCard = ({}: SettlementsDataCardProps) => {
  const { t } = useTranslation('user');
  const styles = useStyles();
  const history = useHistory();
  const { path } = useRouteMatch();

  const userDataState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.user.getUserState,
  );
  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  const missingAccountType = useSelector(userSelectors.selectUserMissingAccountType);

  return (
    <>
      <Card className={styles.root}>
        <CardHeader title={t('cards.settlementsData.title', '')} />
        <Divider />
        <CardContent>
          {userDataState?.inProgress && <LinearProgress />}
          {userData && (
            <>
              {missingAccountType && (
                <Alert severity="warning" data-testid="missing-account-type-alert">
                  {t('cards.settlementsData.missingAccountType.message')}
                </Alert>
              )}
              {userData.accountType === AccountTypeEnum.person && userData.partnerId
                ? [
                    <ButtonGroup fullWidth className={styles.accountTypes}>
                      <Button
                        variant={'outlined'}
                        color="secondary"
                        onClick={() => history.push(`${path}/partner/resign`)}
                      >
                        {t(`settlements.changePartner.button`)}
                      </Button>
                    </ButtonGroup>,
                    <Divider />,
                  ]
                : null}
              <ButtonGroup fullWidth className={styles.accountTypes}>
                {[AccountTypeEnum.person, AccountTypeEnum.company].map((type) => (
                  <Button
                    key={type}
                    variant={userData.accountType === type ? 'contained' : 'outlined'}
                    color="primary"
                    {...(userData.accountType !== type
                      ? { onClick: () => history.push(`${path}/account-type/${type}`) }
                      : {})}
                  >
                    {t(`settlements.fields.accountType.values.${type}`)}
                  </Button>
                ))}
              </ButtonGroup>

              {userData.accountType === AccountTypeEnum.person && <PersonCardElement />}
              {userData.accountType === AccountTypeEnum.company && <CompanyCardElement />}
            </>
          )}
        </CardContent>
      </Card>

      <Switch>
        <Route path={`${path}/account-type/:accountType`} component={SetAccountTypeModal} />
        <Route path={`${path}/partner/resign`} component={ResignPartnerModal} />
      </Switch>
    </>
  );
};

export default SettlementsDataCard;
