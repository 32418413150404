import { FailureResponse } from '@common/model';

export const mergeResponseErrors = (
  e: FailureResponse,
  fieldGlue: string = '!',
  replaceDots: boolean | string = true,
): string[] => {
  const errors = [];
  if (e.error) {
    errors.push(e.error);
  }

  if (e.errors) {
    // This fix warning that e.errors may be undefined
    const fieldErrors: { [key: string]: string[] } = e.errors;
    Object.keys(fieldErrors).map((key: string) =>
      fieldErrors[key].forEach((error: string) => errors.push(key + fieldGlue + error)),
    );
  }

  // Replace dots
  const dotsReplacer = typeof replaceDots === 'string' ? replaceDots : !!replaceDots && '_';
  if (dotsReplacer) {
    errors.forEach(
      (error: string, index: number) => (errors[index] = error.replace(/\./g, dotsReplacer)),
    );
  }

  return errors;
};
