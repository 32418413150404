// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { Description, InsertDriveFile } from '@material-ui/icons';
import { DocumentStatusEnum } from '@features/contracts/model/DocumentStatus';
import { useTranslation } from 'react-i18next';
import Badge from '@material-ui/core/Badge';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

// Internal libs

// Internal components
import { DriverContract } from '@features/admin/drivers/model/DriverContracts';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    empty: {},
    null: {
      opacity: 0.2,
    },
    uploaded: {
      backgroundColor: theme.palette.info.dark,
    },
    pending: {
      backgroundColor: theme.palette.warning.light,
    },
    accepted: {
      backgroundColor: theme.palette.success.dark,
    },
    rejected: {
      backgroundColor: theme.palette.error.dark,
    },
    terminated: {
      backgroundColor: theme.palette.error.light,
    },
  }),
);

// Props type
type DriverContractsStatusProps = { contracts: DriverContract[]; driverId: number };

// Component
const DriverContractsStatus = ({ contracts, driverId }: DriverContractsStatusProps) => {
  const { t } = useTranslation('admin');
  const styles = useStyles();
  const { path } = useRouteMatch();
  const history = useHistory();

  const getStyleForStatus = (status: DocumentStatusEnum | undefined) => {
    return !status
      ? styles.empty
      : [
          DocumentStatusEnum.accepted,
          DocumentStatusEnum.pending,
          DocumentStatusEnum.rejected,
          DocumentStatusEnum.terminated,
        ].indexOf(status) !== -1
      ? styles[status]
      : styles.uploaded;
  };

  const getValuesToDisplay = (contract: DriverContract) => (
      {
          contractId: contract.contractId,
          status: contract.contractStatus || null,
          badge: getStyleForStatus(contract.contractStatus as DocumentStatusEnum),
          icon: contract.contractStatus ? '' : styles.null,
          tooltip: `${t(`drivers.servicesAgreement.${contract.name}`)} - 
            ${t(`drivers.agreementStatus.${contract.contractStatus || null}`)} 
            ${contract.contractStatus === DocumentStatusEnum.rejected ? `(${contract.contractStatusReason})` : ''}`,
      }
    );
  const agreements = useMemo(
    () => (contracts || []).map(contract => getValuesToDisplay(contract)),
    [contracts],
  );
  const onIconClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    history.push(`${path}/contract/${driverId}`);
  };

  return (
    <Grid container justify="space-evenly" alignItems="flex-start">
      {agreements.map((agreement, index) => (
        <Tooltip
          title={agreement.tooltip}
          key={index}
        >
          <IconButton onClick={onIconClick}>
            <Badge classes={{ badge: agreement.badge }} variant="dot">
              <Description className={agreement.icon} />
            </Badge>
          </IconButton>
        </Tooltip>
      ))}
    </Grid>
  );
};

export default DriverContractsStatus;
