// Core libraries
import React, { useCallback, useEffect, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import FormSelectField from '@common/components/FormSelectField';
import { InProgressState } from '@common/model';
import { FormProvider, useForm } from 'react-hook-form';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormErrors from '@common/components/FormErrors';
import { XpressDeliveryCompany } from '@common/model/XpressDeliveryCompany';
import { actions, selectors } from '@features/admin';
import { useDispatch, useSelector } from 'react-redux';
import ProgressButton from '@common/components/ProgressButton';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type DriversRemoveAllowedCompanyModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (xpressDeliveryCompanyId: number) => void;
};

// Component
const DriversRemoveAllowedCompanyModal = ({
  open,
  onClose,
  onSubmit,
}: DriversRemoveAllowedCompanyModalProps) => {
  const styles = useStyles();
  const { t } = useTranslation('admin');
  const dispatch = useDispatch();
  const [submitState, setSubmitState] = useState<InProgressState>({ inProgress: false });
  const form = useForm();

  const { formErrors, getFieldErrors } = useResponseErrors(
    submitState?.error,
    'driversRemoveAllowedCompany.form',
    'admin',
  );
  const companies = useSelector(selectors.selectXpressDeliveryCompanies);
  const options = companies?.map((xdCompany) => {
    return {
      label: xdCompany.name,
      value: xdCompany.id,
    };
  });

  const submitHandler = async (values: any) => {
    onSubmit(values.xpressDeliveryCompanyId);
  };

  return (
    <FormProvider {...form}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{t('driversRemoveAllowedCompany.titleText')}</DialogTitle>

        <form onSubmit={form.handleSubmit(submitHandler)}>
          <DialogContent>
            <DialogContentText>{t('driversRemoveAllowedCompany.bodyText')}</DialogContentText>
            <FormSelectField
              name="xpressDeliveryCompanyId"
              label={t('driversRemoveAllowedCompany.form.fields.xpressDeliveryCompanyId.label')}
              options={options ?? []}
              rules={{
                required: {
                  value: true,
                  message: t(
                    'driversRemoveAllowedCompany.form.fields.xpressDeliveryCompanyId.errors.required',
                  ),
                },
              }}
              fullWidth={true}
              errors={getFieldErrors('xpressDeliveryCompanyId', 'xpress_delivery_company_id')}
              defaultValue={''}
            />
            <FormErrors errorMessages={formErrors} />
          </DialogContent>
          <DialogActions>
            <ProgressButton
              onClick={onClose}
              color="primary"
              disabled={submitState.inProgress}
              loading={submitState.inProgress}
            >
              {t('driversRemoveAllowedCompany.form.cancelButton')}
            </ProgressButton>
            <ProgressButton
              type="submit"
              color="primary"
              disabled={submitState.inProgress}
              loading={submitState.inProgress}
              autoFocus
            >
              {t('driversRemoveAllowedCompany.form.submitButton')}
            </ProgressButton>
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
};

export default DriversRemoveAllowedCompanyModal;
