import {
  timestampable,
  timestampableFromSrc,
  timestampableSrc,
  timestampableToSrc,
} from '@common/model';
import { Address, addressFromSrc, AddressSrc, addressToSrc } from '@common/model/Address';
import {
  InvoiceItem,
  invoiceItemFromSrc,
  InvoiceItemSrc,
  invoiceItemToSrc,
} from '@features/company/model/InvoiceItem';
import { InvoiceStatusEnum } from '@common/model/InvoiceStatus';
import {
  CountryDefinition,
  countryDefinitionFromSrc,
  CountryDefinitionSrc,
  countryDefinitionToSrc,
} from '@features/user/model/Company';

export interface Invoice extends timestampable {
  id: number;
  userId: number;
  companyId: number;
  no: string;
  status: InvoiceStatusEnum;
  invoicedAt: string;
  soldAtFrom: string;
  soldAtTo: string;
  exportedAt?: string;
  grossTotal: number;
  netTotal: number;
  vatTotal: number;
  items: InvoiceItem[];
  addresses: Address[];
  attachments: Attachment[];
  companyBankAccountNumber: string;
  companyName: string;
  companyTaxIdentificationNumber: string;
  companyPhone: string;
  companyRegistrationNumber: string;
  companyRegistrationCountryCode: string;
  countryDefinition: CountryDefinition;
  payerName: string;
  payerNip: string;
  payerRegon: string;
  type: InvoiceType;
  currency: string;
}
export enum InvoiceType {
  Settlement = 'settlement',
  Invoice = 'invoice',
}

export interface Attachment {
  download_link: string;
  type: string;
}

export interface InvoiceSrc extends timestampableSrc {
  id: number;
  user_id: number;
  company_id: number;
  no: string;
  status: InvoiceStatusEnum;
  invoiced_at: string;
  sold_at_from: string;
  sold_at_to: string;
  exported_at?: string;
  gross_total: number;
  net_total: number;
  vat_total: number;
  items: InvoiceItemSrc[];
  addresses: AddressSrc[];
  attachments: Attachment[];
  company_bank_account_number: string;
  company_name: string;
  company_tax_identification_number: string;
  company_phone: string;
  company_registration_number: string;
  company_registration_country_code: string;
  country_definition: CountryDefinitionSrc;
  payer_name: string;
  payer_nip: string;
  payer_regon: string;
  type: InvoiceType;
  currency: string;
}

export const invoiceFromSrc = (invoiceSrc: InvoiceSrc): Invoice => ({
  id: invoiceSrc.id,
  userId: invoiceSrc.user_id,
  companyId: invoiceSrc.company_id,
  no: invoiceSrc.no,
  type: invoiceSrc.type,
  status: invoiceSrc.status,
  invoicedAt: invoiceSrc.invoiced_at,
  soldAtFrom: invoiceSrc.sold_at_from,
  soldAtTo: invoiceSrc.sold_at_to,
  exportedAt: invoiceSrc.exported_at,
  grossTotal: invoiceSrc.gross_total,
  netTotal: invoiceSrc.net_total,
  vatTotal: invoiceSrc.vat_total,
  items: invoiceSrc.items.map((item) => invoiceItemFromSrc(item)),
  addresses: [addressFromSrc(invoiceSrc.addresses[0]), addressFromSrc(invoiceSrc.addresses[1])],
  attachments: invoiceSrc.attachments,
  companyBankAccountNumber: invoiceSrc.company_bank_account_number,
  companyName: invoiceSrc.company_name,
  companyTaxIdentificationNumber: invoiceSrc.company_tax_identification_number,
  companyPhone: invoiceSrc.company_phone,
  companyRegistrationNumber: invoiceSrc.company_registration_number,
  companyRegistrationCountryCode: invoiceSrc.company_registration_country_code,
  countryDefinition: countryDefinitionFromSrc(invoiceSrc.country_definition),
  payerName: invoiceSrc.payer_name,
  payerNip: invoiceSrc.payer_nip,
  payerRegon: invoiceSrc.payer_regon,
  currency: invoiceSrc.currency,
  ...timestampableFromSrc(invoiceSrc),
});

export const invoiceToSrc = (invoice: Invoice): InvoiceSrc => ({
  id: invoice.id,
  user_id: invoice.userId,
  company_id: invoice.companyId,
  no: invoice.no,
  type: invoice.type,
  status: invoice.status,
  invoiced_at: invoice.invoicedAt,
  sold_at_from: invoice.soldAtFrom,
  sold_at_to: invoice.soldAtTo,
  exported_at: invoice.exportedAt,
  gross_total: invoice.grossTotal,
  net_total: invoice.netTotal,
  vat_total: invoice.vatTotal,
  items: invoice.items.map((item) => invoiceItemToSrc(item)),
  addresses: [addressToSrc(invoice.addresses[0]), addressToSrc(invoice.addresses[1])],
  attachments: invoice.attachments,
  company_bank_account_number: invoice.companyBankAccountNumber,
  company_name: invoice.companyName,
  company_tax_identification_number: invoice.companyTaxIdentificationNumber,
  company_phone: invoice.companyPhone,
  company_registration_number: invoice.companyRegistrationNumber,
  company_registration_country_code: invoice.companyRegistrationCountryCode,
  country_definition: countryDefinitionToSrc(invoice.countryDefinition),
  payer_name: invoice.payerName,
  payer_nip: invoice.payerNip,
  payer_regon: invoice.payerRegon,
  currency: invoice.currency,
  ...timestampableToSrc(invoice),
});
