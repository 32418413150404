import getUserReducers from './getUser';
import updateUserDataReducers from './updateUserData';
import verifyUserDataReducers from './verifyUserData';
import regionsReducers from './regions';
import gdprReducers from './gdpr';
import settlementsReducers from './settlements';
import partnerReducers from './partner';
import companyReducers from './company';
import nationalitiesReducers from './nationalities';
import identityDocumentsReducers from './identityDocuments';
import setLanguageReducers from './setLanguage';

// Reducers object
const reducers = {
  ...getUserReducers,
  ...updateUserDataReducers,
  ...verifyUserDataReducers,
  ...regionsReducers,
  ...gdprReducers,
  ...settlementsReducers,
  ...partnerReducers,
  ...companyReducers,
  ...nationalitiesReducers,
  ...identityDocumentsReducers,
  ...setLanguageReducers,
};

export default reducers;
