import { useContext } from 'react';
import SnackbarsContext from './SnackbarsContext';

export { default as SnackbarsProvider } from './components/SnackbarsProvider';

export const useShowSnackbar = () => {
  const context = useContext(SnackbarsContext);

  if (!context) {
    throw new Error('Snackbars context not initiated. Use <SnacbarsProvider/>');
  }

  return context.showSnackbar;
};
