import { useMemo } from 'react';

export const phonePattern = /([1-9]\d{2}) (\d{3}) (\d{3})/;
export const phoneMask = [/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];

export const phoneValidator = (message?: string) => (value: string) =>
  /[1-9]\d{2} \d{3} \d{3}$/.test(value) || message || false;

export const phoneToNumber = (phone: string): number => {
  const match = phone.match(phonePattern);

  if (!match || !match[1] || !match[2] || !match[3]) {
    throw new Error('Phone has incorrect format');
  }

  return Number(match[1] + match[2] + match[3]);
};

export const numberToPhone = (
  phoneNumber: number | string,
  prefix: number | string = '48',
): string => {
  const pattern = /^(\d{0,3})(\d{0,3})(\d{0,3})$/;
  const match = `${phoneNumber}`.match(pattern);
  if (!match || !match[1]) {
    return '';
  }

  return (
    `(+${prefix})` +
    (match[1] ? ` ${match[1]}` : '') +
    (match[2] ? ` ${match[2]}` : '') +
    (match[3] ? ` ${match[3]}` : '')
  );
};

const usePhoneValidator = (message?: string) => {
  return useMemo(() => phoneValidator(message), [message]);
};

export default usePhoneValidator;
