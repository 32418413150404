import {
  timestampable,
  timestampableFromSrc,
  timestampableSrc,
  timestampableToSrc,
} from '@common/model';
import { User, userFromSrc, UserSrc, userToSrc } from '@features/user/model/User';

export interface DriverJoinRequest extends timestampable {
  id: number;
  userId: number;
  partnerId: number;
  status: string;
  user: User;
}

export interface DriverJoinRequestSrc extends timestampableSrc {
  id: number;
  user_id: number;
  partner_id: number;
  status: string;
  user: UserSrc;
}

export const driverJoinRequestFromSrc = (
  driverJoinRequestSrc: DriverJoinRequestSrc,
): DriverJoinRequest => ({
  id: driverJoinRequestSrc.id,
  userId: driverJoinRequestSrc.user_id,
  partnerId: driverJoinRequestSrc.partner_id,
  status: driverJoinRequestSrc.status,
  user: userFromSrc(driverJoinRequestSrc.user),
  ...timestampableFromSrc(driverJoinRequestSrc),
});

export const driverJoinRequestToSrc = (
  driverJoinRequest: DriverJoinRequest,
): DriverJoinRequestSrc => ({
  id: driverJoinRequest.id,
  user_id: driverJoinRequest.userId,
  partner_id: driverJoinRequest.partnerId,
  status: driverJoinRequest.status,
  user: userToSrc(driverJoinRequest.user),
  ...timestampableToSrc(driverJoinRequest),
});
