// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

// Internal libs
import { InProgressState } from '@common/model';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import { useShowSnackbar } from '@features/snackbars';
import { Company } from '../../model/Company';
import { actions } from '../../store';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import FormErrors from '@common/components/FormErrors';
import FormTextField from '@common/components/FormTextField';
import DataListItem from '@common/components/DataListItem';
import DataItem from '@common/components/cardContent/DataItem';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(2),
    },
    nipWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    findButton: {
      marginLeft: theme.spacing(2),
    },
  }),
);

// Props type
type JoinPartnerProps = {};

// Component
const JoinPartnerModal = ({}: JoinPartnerProps) => {
  const { t } = useTranslation('user');
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const showSnackbar = useShowSnackbar();

  const form = useForm();

  const close = () => {
    history.push('.');
  };

  // Find partner
  const [findState, setFindState] = useState<InProgressState>({ inProgress: false });
  const [partner, setPartner] = useState<Company | undefined>();

  const findPartner = async ({ taxIdentificationNumber }: { taxIdentificationNumber: string }) => {
    setFindState({ inProgress: true });

    try {
      // @ts-ignore
      const partner: Company = await dispatch(actions.findPartner(taxIdentificationNumber));
      setFindState({ inProgress: false, success: true });
      setPartner(partner);
    } catch (e) {
      setFindState({ inProgress: false, success: false, error: e });
      setPartner(undefined);
    }
  };

  const { formErrors, getFieldErrors } = useResponseErrors(
    findState?.error,
    'settlements.joinPartner.findPartner',
    'user',
  );

  // Join partner
  const [joinState, setJoinState] = useState<InProgressState>({ inProgress: false });

  const joinPartner = async () => {
    setJoinState({ inProgress: true });

    if (!partner) {
      setJoinState({ inProgress: false, success: false, error: { error: 'undefined_partner' } });
      return;
    }

    try {
      await dispatch(actions.joinPartner(partner.id));
      setJoinState({ inProgress: false, success: true });
      showSnackbar({
        message: t('settlements.joinPartner.success'),
        severity: 'success',
      });
      close();
    } catch (e) {
      setJoinState({ inProgress: false, success: false, error: e });
    }
  };

  const { formErrors: joinErrors, getFieldErrors: getJoinFieldErrors } = useResponseErrors(
    joinState?.error,
    'settlements.joinPartner',
    'user',
  );

  return (
    <Dialog open={true} onClose={close}>
      <DialogTitle>{t('settlements.joinPartner.title')}</DialogTitle>
      <Divider />

      <DialogContent>
        <DialogContentText>{t('settlements.joinPartner.message')}</DialogContentText>

        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(findPartner)}>
            <div className={styles.nipWrapper}>
              <FormTextField
                className={styles.field}
                name="taxIdentificationNumber"
                label={t(
                  'settlements.joinPartner.findPartner.fields.taxIdentificationNumber.label',
                  '',
                )}
                rules={{
                  required: {
                    value: true,
                    message: t(
                      'settlements.joinPartner.findPartner.fields.taxIdentificationNumber.errors.required',
                      '',
                    ),
                  },
                }}
                fullWidth={true}
                errors={getFieldErrors('taxIdentificationNumber')}
                autoFocus
              />
              <ProgressButton
                className={styles.findButton}
                variant="contained"
                color="primary"
                disabled={findState?.inProgress}
                loading={findState?.inProgress}
                type="submit"
              >
                {t(`settlements.joinPartner.findPartner.submitButton`, '')}
              </ProgressButton>
            </div>

            <FormErrors errorMessages={formErrors} />
          </form>
        </FormProvider>

        {partner && (
          <>
            <Grid container>
              <Grid item xs={6}>
                <DataListItem
                  label={t('settlements.joinPartner.partner.name', '')}
                  text={<strong>{partner.name}</strong>}
                />
              </Grid>
              <Grid item xs={6}>
                <DataItem
                  label={t('company.fields.registrationCountry')}
                  text={t(`common:countries.${partner.registrationCountryCode}`)}
                />
              </Grid>
              <Grid item xs={6}>
                <DataListItem
                  label={t(
                    `company.fields.taxIdentificationNumber.${
                      partner.countryDefinition?.taxIdentificationNumberName ?? 'generic'
                    }`,
                  )}
                  text={partner.taxIdentificationNumber}
                />
              </Grid>
              <Grid item xs={6}>
                <DataListItem
                  label={t(
                    `company.fields.registrationNumber.${
                      partner.countryDefinition?.registrationNumberName ?? 'generic'
                    }`,
                  )}
                  text={partner.registrationNumber}
                />
              </Grid>
            </Grid>
            <FormErrors errorMessages={joinErrors} />
          </>
        )}
      </DialogContent>

      <Divider />
      <DialogActions>
        <Button onClick={close}>{t('settlements.joinPartner.cancelButton')}</Button>
        <ProgressButton
          variant="contained"
          color="primary"
          disabled={!partner || joinState?.inProgress}
          loading={joinState?.inProgress}
          onClick={joinPartner}
        >
          {t(`settlements.setAccountType.submitButton`, '')}
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
};

export default JoinPartnerModal;
