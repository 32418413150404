import { createSlice } from '@reduxjs/toolkit';
import { CompanyState } from './model';
import * as reducers from './reducers';

const slice = createSlice<CompanyState, typeof reducers>({
  name: 'company',
  initialState: {
    getGenerateInvoiceState: {
      inProgress: false,
    }
  },
  reducers: {
    ...reducers,
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;
