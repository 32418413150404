import { RootThunk, PaginatedData } from '@common/model';
import { Ride } from '../../model/Ride';
import { actions as simpleActions } from '../slice';
import * as api from '../../api';
import { RideDetails } from '@features/rides/model/RideDetails';

export const getRides = (page?: number, showTotalItems?: number): RootThunk => async (
  dispatch,
): Promise<PaginatedData<Ride>> => {
  dispatch(simpleActions.getRidesInit({ page, showTotalItems }));
  try {
    const paginatedRides = await api.getRides(page, showTotalItems);
    dispatch(simpleActions.getRidesSuccess(paginatedRides));

    return paginatedRides;
  } catch (e) {
    dispatch(simpleActions.getRidesFailure(e.response?.data || { error: 'unknown' }));
    throw e.response?.data || { error: 'unknown' };
  }
};

export const getRideDetails = (rideId: number): RootThunk => async (
  dispatch,
): Promise<RideDetails> => {
  dispatch(simpleActions.getRideDetailsInit(rideId));
  try {
    const rideDetails: RideDetails = await api.getRideDetails(rideId);
    dispatch(simpleActions.getRideDetailsSuccess(rideDetails));

    return rideDetails;
  } catch (e) {
    dispatch(simpleActions.getRideDetailsFailure(e.response?.data || { error: 'unknown' }));
    throw e.response?.data || { error: 'unknown' };
  }
};
