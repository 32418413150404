// Core libraries
import React, { useMemo, useState } from 'react';

// External libs and components
import clsx from 'clsx';
import { createStyles, makeStyles, Theme, lighten } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ChevronUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ChevronDownIcon from '@material-ui/icons/KeyboardArrowDown';

// Internal libs
import { ThemeColorName } from '@common/components/theme/xdThemeColor';

// Internal components

// Styles hook
const useStyles = makeStyles<Theme, { color?: ThemeColorName }>((theme) =>
  createStyles({
    root: {},
    mainWrapper: ({ color }: { color?: ThemeColorName }) => ({
      display: 'flex',
      flexDirection: 'column',
      ...(color && theme.palette[color]?.main
        ? // @ts-ignore
          { backgroundColor: lighten(theme.palette[color].main, 0.9) }
        : {}),
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    }),
    main: ({ color }: { color?: ThemeColorName }) => ({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }),
    texts: {
      flexGrow: 1,
    },
    primaryText: {
      fontSize: '1.25em',
    },
    secondaryText: {
      fontSize: '0.9em',
    },
    left: {
      marginRight: theme.spacing(2),
    },
    actions: {
      marginLeft: theme.spacing(1),
      flexShrink: 0,
      flexGrow: 0,
    },
    expandButtonWrapper: {
      marginLeft: theme.spacing(0.1),
    },
    expandButton: {
      padding: theme.spacing(1),
    },
    additionalContent: {
      marginTop: theme.spacing(1),
    },
    children: {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }),
);

// Props type
type DataColorFieldProps = {
  primaryText: string | React.ReactNode;
  secondaryText?: string | React.ReactNode;
  left?: React.ReactNode;
  actions?: React.ReactNode | React.ReactNode[];
  color?: ThemeColorName;
  expandable?: boolean;
  expanded?: boolean;
  additionalContent?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode;
  className?: string;
  style?: object;
};

// Component
const DataColorField = ({
  primaryText,
  secondaryText,
  actions,
  left,
  color,
  expandable,
  expanded = false,
  additionalContent,
  children,
  className,
  style,
}: DataColorFieldProps) => {
  const styles = useStyles({ color });

  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);
  const toggleIsExpanded = () => setIsExpanded(!isExpanded);

  const actionsArray: React.ReactNode[] | undefined = useMemo(
    () => (!actions ? undefined : Array.isArray(actions) ? actions : [actions]),
    [actions],
  );

  const additionalContentArray: React.ReactNode[] | undefined = useMemo(
    () =>
      (!additionalContent
        ? undefined
        : Array.isArray(additionalContent)
        ? additionalContent
        : [additionalContent]
      )?.filter((ac) => !!ac),
    [additionalContent],
  );

  return (
    <div className={clsx(styles.root, className)} {...(style ? { style } : {})}>
      <div className={styles.mainWrapper}>
        <div className={styles.main}>
          {left && <div className={styles.left} children={left} />}
          <div className={styles.texts}>
            <div className={styles.primaryText}>{primaryText}</div>
            {secondaryText && <div className={styles.secondaryText}>{secondaryText}</div>}
          </div>
          {actionsArray && (
            <div className={styles.actions}>
              <Grid container direction="row" spacing={1}>
                {actionsArray.map((action, idx) => (
                  <Grid item key={idx}>
                    {action}
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
          {expandable && (
            <div className={styles.expandButtonWrapper}>
              <IconButton
                edge="end"
                {...(color ? { style: { color } } : {})}
                onClick={toggleIsExpanded}
                className={styles.expandButton}
                data-testid="expand-button"
              >
                {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </IconButton>
            </div>
          )}
        </div>
        {!!additionalContentArray?.length && (
          <div className={styles.additionalContent}>{additionalContent}</div>
        )}
      </div>
      {children && isExpanded && <div className={styles.children}>{children}</div>}
    </div>
  );
};

export default DataColorField;
