import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
  reducer as authReducer,
  actions as authActions,
  middlewares as authMiddlewares,
} from '@features/auth';
import redirectReducer from '@features/redirect/store';
import vehiclesReducers from '@features/vehicles/store';
import { userReducer } from '@features/user';
import { initReducer } from '@features/init';
import {
  reducer as privacyPolicyReducer,
  middlewares as privacyPolicyMiddlewares,
} from '@features/privacyPolicy';
import { reducer as contractsReducer } from '@features/contracts';
import { reducer as companyReducer } from '@features/company';
import { reducer as partnerReducer } from '@features/partner';
import { reducer as ridesReducer } from '@features/rides';
import { reducer as driverReducer, invoiceReducer, correctionReducer } from '@features/admin/';
const appReducer = combineReducers({
  admin: combineReducers({
    drivers: driverReducer,
    invoices: invoiceReducer,
    corrections: correctionReducer,
    rides: ridesReducer,
  }),
  auth: authReducer,
  redirect: redirectReducer,
  vehicles: vehiclesReducers,
  user: userReducer,
  init: initReducer,
  privacyPolicy: privacyPolicyReducer,
  contracts: contractsReducer,
  company: companyReducer,
  partner: partnerReducer,
  rides: ridesReducer,
});

// Root reducer is defined in the way that allows easy clean after logout.
// Solution based on: https://stackoverflow.com/a/35641992
const rootReducer = (state: any, action: any) => {
  if (
    // Even if logout call to backend fail, user is logout (credentials are removed) in the frontend
    action.type === authActions.logoutSuccess.type ||
    action.type === authActions.logoutFailure.type
  ) {
    state = undefined;
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([...authMiddlewares, ...privacyPolicyMiddlewares]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
