// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import GoBackIcon from '@material-ui/icons/ExitToApp';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    backButton: {
      margin: '0 10px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

// Props type
type DriverRidesTopButtonsProps = { driverId: string };

// Component
const DriverRidesTopButtons = ({ driverId }: DriverRidesTopButtonsProps) => {
  const styles = useStyles();
  const { t } = useTranslation(['rides', 'admin']);
  const history = useHistory();

  return (
    <Grid className={styles.backButton}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => history.push(`/admin/drivers/driver/${driverId}/profile/rides/add`)}
      >
        {t('rides:adminRideCorrection.addCorrectionButton.title')}
      </Button>
      <IconButton onClick={() => history.push('../..')} title={t('admin:drivers.title')}>
        <GoBackIcon />
      </IconButton>
    </Grid>
  );
};

export default DriverRidesTopButtons;
