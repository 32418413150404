// Core libraries
import React, { useEffect, useMemo, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { useShowSnackbar } from '@features/snackbars';
import { actions, selectors } from '../store';

// Internal components
import ProgressButton from '@common/components/ProgressButton';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(2),
    },
    error: {
      color: theme.palette.error.main,
    },
  }),
);

// Props type
type EmailVerifyModalProps = {};

// Component
const EmailVerifyModal = ({}: EmailVerifyModalProps) => {
  const { t } = useTranslation(['user', 'common']);
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const showSnackbar = useShowSnackbar();

  const userDataState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.user.updateUserDataState,
  );

  const emailVerified = useSelector(selectors.selectUserEmailVerified);
  const emailNotVerified = useSelector(selectors.selectUserMissingEmailVerification);

  const onClose = () => {
    history.push('..');
  };

  const [requestTokenState, setRequestTokenState] = useState<InProgressState>({
    inProgress: false,
  });

  const requestToken = async () => {
    setRequestTokenState({ inProgress: true });
    try {
      await dispatch(actions.requestUserEmailToken());
      setRequestTokenState({ inProgress: false, success: true });
      showSnackbar({
        message: t('requestEmailToken.success'),
        severity: 'success',
      });
      onClose();
    } catch (e) {
      setRequestTokenState({ inProgress: false, error: e });
    }
  };

  const requestTokenError = useMemo(() => {
    if (!requestTokenState.error) {
      return false;
    }

    return t(
      `requestEmailToken.errors.${requestTokenState.error?.error?.replace('.', '_')}`,
      t(`requestEmailToken.errors.unknown`),
    );
  }, [requestTokenState.error]);

  // useEffect(() => {
  //   if (emailVerified) {
  //     onClose();
  //   }
  // }, [emailVerified]);

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>{t('requestEmailToken.title')}</DialogTitle>
      <Divider />
      <DialogContent>
        {userDataState?.inProgress && <LinearProgress />}
        {emailNotVerified && (
          <DialogContentText>
            <Typography variant="body1">
              <p>{t('requestEmailToken.messages.clickLink')}</p>
              <p>{t('requestEmailToken.messages.requestLink')}</p>
            </Typography>
          </DialogContentText>
        )}
        {requestTokenError && (
          <DialogContentText className={styles.error}>{requestTokenError}</DialogContentText>
        )}
        {emailVerified && (
          <DialogContentText>
            <Typography variant="body1">
              {t('requestEmailToken.messages.alreadyVerified')}
            </Typography>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {emailNotVerified && (
          <>
            <Button onClick={onClose} data-testid="cancel-button">
              {t('requestEmailToken.cancelButton')}
            </Button>
            <ProgressButton
              variant="contained"
              color="primary"
              onClick={requestToken}
              disabled={requestTokenState.inProgress}
              loading={requestTokenState.inProgress}
              data-testid="request-button"
            >
              {t(`requestEmailToken.requestButton`)}
            </ProgressButton>
          </>
        )}
        {emailVerified && (
          <Button onClick={onClose} data-testid="cancel-button">
            {t('common:close')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EmailVerifyModal;
