import {
  timestampable,
  timestampableFromSrc,
  timestampableSrc,
  timestampableToSrc,
} from '@common/model';
import {
  ContractTemplate,
  contractTemplateFromScr,
  ContractTemplateSrc,
  contractTemplateToSrc,
} from '@features/contracts/model/ContractTemplate';
import {
  Document,
  DocumentSrc,
  documentFromSrc,
  documentToSrc,
} from '@features/contracts/model/Document';
import { ContractStatus } from '@features/contracts/model/ContractStatus';

export interface Contract extends timestampable {
  id: number;
  userId: number;
  contractTemplateId: number;
  contractData: any;
  status: ContractStatus;
  statusReason?: string;
  downloadLink: string;
  contractTemplate: ContractTemplate;
  document?: Document;
  pages: number;
}

export interface ContractSrc extends timestampableSrc {
  id: number;
  user_id: number;
  contract_template_id: number;
  contract_data: any;
  status: ContractStatus;
  status_reason: string | null;
  download_link: string;
  contract_template: ContractTemplateSrc;
  document: DocumentSrc | null;
  pages: number;
}

export const contractFromSrc = (contractSrc: ContractSrc): Contract => ({
  id: contractSrc.id,
  userId: contractSrc.user_id,
  contractTemplateId: contractSrc.contract_template_id,
  contractData: contractSrc.contract_data,
  status: contractSrc.status,
  statusReason: contractSrc.status_reason || undefined,
  downloadLink: contractSrc.download_link,
  contractTemplate: contractTemplateFromScr(contractSrc.contract_template),
  document: (contractSrc.document && documentFromSrc(contractSrc.document)) || undefined,
  pages: contractSrc.pages,
  ...timestampableFromSrc(contractSrc),
});

export const contractToSrc = (contract: Contract): ContractSrc => ({
  id: contract.id,
  user_id: contract.userId,
  contract_template_id: contract.contractTemplateId,
  contract_data: contract.contractData,
  status: contract.status,
  status_reason: contract.statusReason || null,
  download_link: contract.downloadLink,
  contract_template: contractTemplateToSrc(contract.contractTemplate),
  document: (contract.document && documentToSrc(contract.document)) || null,
  pages: contract.pages,
  ...timestampableToSrc(contract),
});
