import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { UserState } from '../model';

export const acceptGdprInit = (state: UserState, action: PayloadAction<boolean>) => {};
export const acceptGdprSuccess = (state: UserState, action: Action) => {};
export const acceptGdprFailure = (state: UserState, action: PayloadAction<FailureResponse>) => {};

const gdprReducers = {
  acceptGdprInit,
  acceptGdprSuccess,
  acceptGdprFailure,
};
export default gdprReducers;
