import axios from 'axios';
import { commentFromSrc, CommentSrc } from '@common/model/Comment';
import { CreateCommentPayload } from '@features/admin/api/driver';
import { saveAs } from 'file-saver';

export const createRideComment = async (
  rideId: string,
  payload: CreateCommentPayload,
): Promise<void> => {
  await axios.post(`/admin/ride/${rideId}/comment`, {
    body: payload.body,
    severity: payload.severity,
  });
};

export const getRideComments = async (rideId: string): Promise<Comment[]> => {
  const response = await axios.get(`/admin/ride/${rideId}/comments`);
  return response?.data.map((comment: CommentSrc) => commentFromSrc(comment));
};

export const downloadRideDocument = async (
  rideId: number,
  documentId: number,
  originalFileName: string,
): Promise<any> => {
  // Download file
  const file: { data: Blob } = await axios.get(
    `/admin/ride/${rideId}/document/${documentId}/download`,
    {
      responseType: 'blob',
    },
  );

  // Save file
  saveAs(file.data, originalFileName);
};
