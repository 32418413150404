// Core libraries
import React, { useMemo, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DisplayData } from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Internal libs
import { InProgressState } from '@common/model';
import { actions, selectors } from '../store';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import DriversAddAllowedCompanyModal from '@features/admin/drivers/conteiners/DriversAddAllowedCompanyModal';
import DriversDeleteModal from '@features/admin/drivers/conteiners/DriversDeleteModal';
import DriversCreateCommentModal from '@features/admin/drivers/conteiners/DriversCreateCommentModal';
import { CommentSeverityEnum } from '@common/model/Comment';
import DriversRemoveAllowedCompanyModal from '@features/admin/drivers/conteiners/DriversRemoveAllowedCompanyModal';
import DriverRestoreModal from '@features/admin/drivers/conteiners/DriverRestoreModal';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    iconContainer: {
      marginRight: '24px',
    },
    inverseIcon: {
      transform: 'rotate(90deg)',
    },
  }),
);

// Props type
type DriversToolbarSelectProps = {
  selectedRows: {
    data: Array<{ index: number; dataIndex: number }>;
    lookup: { [key: number]: boolean };
  };
  displayData: DisplayData;
  driversInTable: { id: number }[];
};

// Component
const DriversToolbarSelect = ({
  selectedRows,
  displayData,
  driversInTable,
}: DriversToolbarSelectProps) => {
  const styles = useStyles();
  const { t } = useTranslation('admin');
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [generateState, setGenerateState] = useState<InProgressState>({ inProgress: false });
  const getDriversFilters = useSelector(selectors.selectGetDriversFilters);
  // const selectedDrivers = useMemo(() => {
  //   return driversInTable.filter((driver, driverIndex) =>
  //     selectedRows.data.find((row) => row.dataIndex === driverIndex),
  //   );
  // }, [displayData, selectedRows]);

  const selectedDriverIds = useMemo(
    () =>
      driversInTable
        .filter((driver, driverIndex) =>
          selectedRows.data.find((row) => row.dataIndex === driverIndex),
        )
        .map((driver) => driver.id.toString()),
    [driversInTable, selectedRows],
  );

  const onSubmit = async (xpressDeliveryCompanyId: number) => {
    setGenerateState({ inProgress: true });
    try {
      handleClose();
      await dispatch(
        actions.setAllowedCompanyForDrivers(selectedDriverIds, xpressDeliveryCompanyId),
      );
      setGenerateState({ inProgress: false, success: true });
      await dispatch(actions.getDriversListPaginated(getDriversFilters));
    } catch (e) {
      setGenerateState({ inProgress: false, success: false, error: e });
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openRemoveCompany, setOpenRemoveCompany] = useState(false);

  const onSubmitRemoveAllowedCompany = async (xpressDeliveryCompanyId: number) => {
    setGenerateState({ inProgress: true });
    try {
      handleCloseRemoveAllowedCompany();
      await dispatch(
        actions.removeAllowedCompanyForDrivers(selectedDriverIds, xpressDeliveryCompanyId),
      );
      setGenerateState({ inProgress: false, success: true });
      await dispatch(actions.getDriversListPaginated(getDriversFilters));
    } catch (e) {
      setGenerateState({ inProgress: false, success: false, error: e });
    }
  };
  const handleClickOpenRemoveAllowedCompany = () => {
    setOpenRemoveCompany(true);
  };

  const handleCloseRemoveAllowedCompany = () => {
    setOpenRemoveCompany(false);
  };

  const [openDeleteUsers, setOpenDeleteUsers] = useState(false);

  const onSubmitDeleteUsers = async () => {
    setGenerateState({ inProgress: true });
    try {
      handleCloseDeleteUsers();
      await dispatch(actions.deleteDrivers(selectedDriverIds));
      setGenerateState({ inProgress: false, success: true });
      await dispatch(actions.getDriversListPaginated(getDriversFilters));
    } catch (e) {
      setGenerateState({ inProgress: false, success: false, error: e });
    }
  };
  const handleClickOpenDeleteUsers = () => {
    setOpenDeleteUsers(true);
  };

  const handleCloseDeleteUsers = () => {
    setOpenDeleteUsers(false);
  };

  // Add comment
  const [openCreateComment, setOpenCreateComment] = useState(false);

  const onSubmitCreateComment = async (severity: CommentSeverityEnum, body: string) => {
    setGenerateState({ inProgress: true });
    try {
      handleCloseCreateComment();
      await dispatch(
        actions.createDriverComment(selectedDriverIds[0], {
          body,
          severity,
        }),
      );
      setGenerateState({ inProgress: false, success: true });
      await dispatch(actions.getDriversListPaginated(getDriversFilters));
    } catch (e) {
      setGenerateState({ inProgress: false, success: false, error: e });
    }
  };
  const handleClickOpenCreateComment = () => {
    setOpenCreateComment(true);
  };

  const handleCloseCreateComment = () => {
    setOpenCreateComment(false);
  };

  const [openRestoreDriver, setOpenRestoreDriver] = useState(false);

  const onSubmitRestoreDriver = async () => {
    setGenerateState({ inProgress: true });
    try {
      handleCloseRestoreDriver();
      await dispatch(actions.restoreDriver(selectedDriverIds[0]));
      setGenerateState({ inProgress: false, success: true });
      await dispatch(actions.getDriversListPaginated(getDriversFilters));
    } catch (e) {
      setGenerateState({ inProgress: false, success: false, error: e });
    }
  };
  const handleClickOpenRestoreDriver = () => {
    setOpenRestoreDriver(true);
  };

  const handleCloseRestoreDriver = () => {
    setOpenRestoreDriver(false);
  };

  return (
    <div className={styles.iconContainer}>
      {getDriversFilters.showDeleted && selectedDriverIds.length === 1 && (
        <ProgressButton
          variant="contained"
          color="primary"
          type="button"
          onClick={handleClickOpenRestoreDriver}
          disabled={generateState.inProgress}
          loading={generateState.inProgress}
        >
          {t('admin:drivers.toolbar.restore.button.label')}
        </ProgressButton>
      )}
      {!getDriversFilters.showDeleted && (
        <ProgressButton
          variant="contained"
          color="primary"
          type="button"
          onClick={handleClickOpen}
          disabled={generateState.inProgress}
          loading={generateState.inProgress}
        >
          {t('admin:drivers.toolbar.allowedCompanies.button.label')}
        </ProgressButton>
      )}

      <ProgressButton
        variant="contained"
        color="secondary"
        type="button"
        onClick={handleClickOpenRemoveAllowedCompany}
        disabled={generateState.inProgress}
        loading={generateState.inProgress}
      >
        {t('admin:drivers.toolbar.removeAllowedCompany.button.label')}
      </ProgressButton>
      {!getDriversFilters.showDeleted && (
        <ProgressButton
          variant="contained"
          color="secondary"
          type="button"
          onClick={handleClickOpenDeleteUsers}
          disabled={generateState.inProgress}
          loading={generateState.inProgress}
        >
          {t('admin:drivers.toolbar.deleteUsers.button.label')}
        </ProgressButton>
      )}

      {selectedDriverIds.length === 1 && (
        <ProgressButton
          variant="contained"
          color="primary"
          type="button"
          onClick={handleClickOpenCreateComment}
          disabled={generateState.inProgress}
          loading={generateState.inProgress}
        >
          {t('admin:drivers.toolbar.createComment.button.label')}
        </ProgressButton>
      )}
      <DriversDeleteModal
        open={openDeleteUsers}
        onClose={handleCloseDeleteUsers}
        onSubmit={onSubmitDeleteUsers}
      />
      <DriverRestoreModal
        open={openRestoreDriver}
        onClose={handleCloseRestoreDriver}
        onSubmit={onSubmitRestoreDriver}
      />
      <DriversAddAllowedCompanyModal open={open} onClose={handleClose} onSubmit={onSubmit} />
      <DriversRemoveAllowedCompanyModal
        open={openRemoveCompany}
        onClose={handleCloseRemoveAllowedCompany}
        onSubmit={onSubmitRemoveAllowedCompany}
      />
      <DriversCreateCommentModal
        isOpen={openCreateComment}
        driverId={selectedDriverIds[0]}
        onClose={handleCloseCreateComment}
        onSubmit={onSubmitCreateComment}
      />
    </div>
  );
};

export default DriversToolbarSelect;
