import { RootThunk, PaginatedData } from '@common/model';
import * as api from '../../../api';
import { actions as simpleActions } from '../slice';
import { Ride } from '@features/rides/model';

export const getDriverRides = (
  partnerId: number,
  page?: number,
  showTotalItems?: number,
  rideNo?: string,
): RootThunk => async (dispatch): Promise<PaginatedData<Ride>> => {
  dispatch(simpleActions.getDriverRidesInit({ partnerId, page, showTotalItems }));
  try {
    const paginatedRides = await api.getDriverRides(partnerId, page, showTotalItems, rideNo);
    dispatch(simpleActions.getDriverRidesSuccess(paginatedRides));

    return paginatedRides;
  } catch (e) {
    dispatch(simpleActions.getDriverRidesFailure(e.response?.data || { error: 'unknown' }));
    throw e.response?.data || { error: 'unknown' };
  }
};
