export interface InvoiceItem {
  id: number;
  description: string;
  quantity: number;
  net: string;
  gross: string;
  vat: string;
  vatPercentageDisplay: string;
}

export interface InvoiceItemSrc {
  id: number;
  description: string;
  quantity: number;
  net: string;
  gross: string;
  vat: string;
  vatPercentageDisplay: string;
}

export const invoiceItemFromSrc = (invoiceItemSrc: InvoiceItemSrc): InvoiceItem => ({
  id: invoiceItemSrc.id,
  description: invoiceItemSrc.description,
  quantity: invoiceItemSrc.quantity,
  net: invoiceItemSrc.net,
  gross: invoiceItemSrc.gross,
  vat: invoiceItemSrc.vat,
  vatPercentageDisplay: invoiceItemSrc.vatPercentageDisplay,
});

export const invoiceItemToSrc = (invoiceItem: InvoiceItem): InvoiceItemSrc => ({
  id: invoiceItem.id,
  description: invoiceItem.description,
  quantity: invoiceItem.quantity,
  net: invoiceItem.net,
  gross: invoiceItem.gross,
  vat: invoiceItem.vat,
  vatPercentageDisplay: invoiceItem.vatPercentageDisplay,
});
