// Core libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// External libs and components
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { useDispatch, useSelector } from 'react-redux';

// Internal libs
import { actions, selectors } from '../store';

// Internal components
import LinearProgress from '@material-ui/core/LinearProgress';
import DriversTablePaginated from '@features/admin/drivers/conteiners/DriversTablePaginated';
import { GetDriversFilters } from '@features/admin/drivers/store/model';
import { actions as userActions, selectors as userSelectors } from '@features/user/store';

// Props type
type DriversCardProps = {};

const useStyles = makeStyles((theme) =>
  createStyles({
    topLevelFilter: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);

// Component
const DriversCard = ({}: DriversCardProps) => {
  const { t } = useTranslation(['admin', 'vehicles', 'common']);
  const styles = useStyles();
  const dispatch = useDispatch();

  const getDriversStatus = useSelector(selectors.selectGetDriversState);
  const getDriversFilters = useSelector(selectors.selectGetDriversFilters);
  //  const drivers = useSelector(selectors.selectDrivers);
  const driversPaginated = useSelector(selectors.selectDriversPaginated);
  const getDrivers = useCallback((filters: GetDriversFilters) => {
    try {
      dispatch(actions.getDriversListPaginated(filters));
    } catch (e) {}
  }, []);

  const setGetDriversFilters = async (driverFilters: GetDriversFilters) => {
    try {
      await dispatch(actions.setGetDriversFilters(driverFilters));
    } catch (e) {}
  };

  const setGetDriversShowDeletedFilter = async (showDeleted: boolean) => {
    try {
      await dispatch(actions.setGetDriversShowDeletedFilter(showDeleted));
    } catch (e) {}
  };

  useEffect(() => {
    getDrivers(getDriversFilters);
  }, [getDriversFilters]);

  // Get regions
  const regions = useSelector(userSelectors.selectRegions);
  const regionsInProgress = useSelector(userSelectors.selectGetRegionsInProgress);
  // Get companies
  const companies = useSelector(selectors.selectXpressDeliveryCompanies);
  const companiesInProgress = useSelector(selectors.selectGetXpressDeliveryCompaniesInProgress);

  useEffect(() => {
    dispatch(userActions.getRegions());
    dispatch(actions.getXpressDeliveryCompanies());
  }, []);
  return (
    <Card>
      <div className={styles.topLevelFilter}>
        <FormControlLabel
          control={
            <Switch
              checked={!getDriversFilters.showDeleted}
              onChange={(event) => setGetDriversShowDeletedFilter(!event.target.checked)}
              name="showDeleted"
              color="primary"
            />
          }
          label={getDriversFilters.showDeleted ? 'Usunięci' : 'Aktywni'}
        />
      </div>

      {getDriversStatus?.inProgress && <LinearProgress data-testid="loading-rides" />}
      {!companiesInProgress && !regionsInProgress && (
        <DriversTablePaginated
          drivers={driversPaginated}
          companies={companies ?? []}
          regions={regions ?? []}
          setFilters={setGetDriversFilters}
        />
      )}
    </Card>
  );
};

export default DriversCard;
