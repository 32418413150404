import axios from 'axios';
import config from '@app/config';

axios.interceptors.request.use((requestConfig) => {
  const absolute = !!requestConfig.url && /^([A-z]+:\/\/)/.test(requestConfig.url);

  const apiBaseUrl = `${config.backendBaseUrl}/api/v1`;


  if (!absolute && !requestConfig?.baseURL) {
    requestConfig.baseURL = apiBaseUrl;
    requestConfig.headers['Content-Type'] = 'application/json';
    requestConfig.headers['Accept'] = 'application/json';
  }

  if (
    (!absolute && requestConfig?.baseURL === apiBaseUrl) ||
    (absolute && requestConfig.url?.indexOf(`${apiBaseUrl}/`) === 0)
  ) {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      requestConfig.headers['Authorization'] = `Bearer ${authToken}`;
    }
  }

  return requestConfig;
});
