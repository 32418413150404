// Core libraries
import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import FormTextField from '@common/components/FormTextField';
import useEmailValidator from '@common/validators/emailValidator';
import FormErrors from '@common/components/FormErrors';
import ProgressButton from '@common/components/ProgressButton';

// Internal libs
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import { InProgressState } from '@common/model';
import { passwordReset } from '../store/actions/passwordReset';

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    field: {
      marginBottom: theme.spacing(2),
    },
  }),
);

// Props type
type PasswordResetProps = { className?: string };

// Component
const PasswordReset = ({ className, ...other }: PasswordResetProps) => {
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();
  const styles = useStyles();

  const [isOpenPasswordReset, setIsOpenPasswordReset] = useState<boolean>(false);

  const open = () => {
    setIsOpenPasswordReset(true);
    setSubmitState({ inProgress: false });
  };
  const close = () => setIsOpenPasswordReset(false);

  const form = useForm();

  const [submitState, setSubmitState] = useState<InProgressState>({ inProgress: false });

  const onSubmit = async (fields: any) => {
    setSubmitState({ inProgress: true });
    try {
      await dispatch(passwordReset(fields.email));
      setSubmitState({ inProgress: false, success: true });
    } catch (e) {
      setSubmitState({ inProgress: false, success: false, error: e });
    }
  };

  const { formErrors, getFieldErrors } = useResponseErrors(
    submitState?.error,
    'passwordReset',
    'auth',
  );

  const emailValidator = useEmailValidator(t('passwordReset.fields.email.errors.email'));

  return (
    <div className={clsx(styles.root, className)} {...other}>
      <Link component="button" onClick={open}>
        {t('passwordReset.label')}
      </Link>

      <Dialog open={isOpenPasswordReset} onClose={close}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogTitle>{t('passwordReset.title')}</DialogTitle>
            <DialogContent>
              {!submitState.success && (
                <>
                  <DialogContentText>{t('passwordReset.message')}</DialogContentText>

                  {/* Email */}
                  <FormTextField
                    className={styles.field}
                    name="email"
                    label={t('passwordReset.fields.email.label', '')}
                    rules={{
                      required: {
                        value: true,
                        message: t('passwordReset.fields.email.errors.required', ''),
                      },
                      validate: {
                        email: emailValidator,
                      },
                    }}
                    fullWidth={true}
                    errors={getFieldErrors('email')}
                  />

                  {/* Errors */}
                  <FormErrors errorMessages={formErrors} />
                </>
              )}
              {submitState.success && (
                <DialogContentText>{t('passwordReset.successMessage')}</DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              {!submitState.success && (
                <>
                  <Button onClick={close}>{t('passwordReset.cancelButton')}</Button>
                  <ProgressButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitState?.inProgress}
                    loading={submitState?.inProgress}
                  >
                    {t(`passwordReset.submitButton`, '')}
                  </ProgressButton>
                </>
              )}
              {submitState.success && (
                <Button variant="contained" color="primary" onClick={close}>
                  {t('passwordReset.finishButton')}
                </Button>
              )}
              `
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </div>
  );
};

export default PasswordReset;
