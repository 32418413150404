// Core libraries
import React, { useEffect, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { User } from '../model/User';
import { actions, selectors } from '../store';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import FormTextField from '@common/components/FormTextField';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormErrors from '@common/components/FormErrors';
import { DialogContentText } from '@material-ui/core';
import PhoneVerifySendAgainButton from '@features/user/containers/PhoneVerifySendAgainButton';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(2),
    },
    actions: { justifyContent: 'flex-end' },
    leftActions: { flexGrow: 1 },
  }),
);

// Props type
type PhoneVerifyModalProps = {};

// Component
const PhoneVerifyModal = ({}: PhoneVerifyModalProps) => {
  const { t } = useTranslation(['user', 'common']);
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const form = useForm();

  const userDataState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.user.updateUserDataState,
  );
  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  const missingPhone = useSelector(selectors.selectUserMissingPhone);
  // const phone = useSelector(selectors.selectUserMissingPhoneVerification);
  const phoneVerified = useSelector(selectors.selectUserPhoneVerified);

  const onClose = () => {
    history.push('.');
  };

  const [verifyPhoneState, setVerifyPhoneState] = useState<InProgressState>({ inProgress: false });

  const onSubmit = async (values: any) => {
    setVerifyPhoneState({ inProgress: true });
    try {
      await dispatch(actions.verifyUserPhone(values.code));
      setVerifyPhoneState({ inProgress: false, success: true });
      onClose();
    } catch (e) {
      setVerifyPhoneState({ inProgress: false, error: e, success: false });
    }
  };

  const { formErrors, getFieldErrors } = useResponseErrors(
    verifyPhoneState?.error,
    'verifyPhone',
    'user',
  );

  useEffect(() => {
    if (missingPhone) {
      history.push('../phone/edit');
    }
  }, [missingPhone]);

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogTitle>{t('verifyPhone.title', '')}</DialogTitle>
          <Divider />
          <DialogContent>
            {userDataState?.inProgress && <LinearProgress />}
            {userData && userData.phone && !userData.phoneVerifiedAt && (
              <>
                <DialogContentText>{t('verifyPhone.message', '')}</DialogContentText>
                <FormTextField
                  className={styles.field}
                  name="code"
                  label={t('verifyPhone.fields.code.label', '')}
                  rules={{
                    required: {
                      value: true,
                      message: t('verifyPhone.fields.code.errors.required', ''),
                    },
                  }}
                  fullWidth={true}
                  errors={getFieldErrors('code', 'token')}
                />

                {/* Errors */}
                <FormErrors errorMessages={formErrors} />
              </>
            )}
            {phoneVerified && (
              <DialogContentText>{t('verifyPhone.messages.alreadyVerified')}</DialogContentText>
            )}
          </DialogContent>
          <DialogActions className={styles.actions}>
            {userData && userData.phone && !userData.phoneVerifiedAt && (
              <>
                <div className={styles.leftActions}>
                  <PhoneVerifySendAgainButton
                    disabled={
                      (!userData?.phone && !!userData?.phoneVerifiedAt) ||
                      verifyPhoneState?.inProgress
                    }
                  />
                </div>

                <Button onClick={onClose}>{t('verifyPhone.cancelButton')}</Button>
                <ProgressButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={
                    verifyPhoneState?.inProgress || !userData?.phone || !!userData?.phoneVerifiedAt
                  }
                  loading={verifyPhoneState?.inProgress}
                >
                  {t(`verifyPhone.submitButton`, '')}
                </ProgressButton>
              </>
            )}
            {phoneVerified && <Button onClick={onClose}>{t('common:close')}</Button>}
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default PhoneVerifyModal;
