import { RootState } from '@app/store';
import { Middleware } from 'redux';
import { actions as redirectActions } from '@features/redirect/store/slice';
import { actions as authActions } from '../slice';

const onRegisterSuccessMiddleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
  next(action);

  if (action.type === authActions.registerSuccess.type) {
    store.dispatch(redirectActions.redirect('/login'));
  }
};

export const middlewares: Middleware[] = [onRegisterSuccessMiddleware];
