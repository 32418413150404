// Core libraries
import React, { useEffect, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { selectors } from '../store';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';

// Internal libs

// Internal components
import SwipeableTabPanels from '@common/components/tabs/SwipeableTabPanels';
import SwipeableTabPanel from '@common/components/tabs/SwipeableTabPanel';
import { ContractName, DriverContract } from '@features/admin/drivers/model/DriverContracts';
import DriverContractInfo from '@features/admin/drivers/conteiners/DriverContractInfo';
import { DocumentStatus, DocumentStatusEnum } from '@features/contracts/model/DocumentStatus';
import { IdentityDocument } from '@features/user/model/IdentityDocument';
import DriverExtraDocumentInfo from '@features/admin/drivers/conteiners/DriverExtraDocumentInfo';
import { TabName } from '@features/admin/drivers/conteiners/DriverContractModal';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

const findAvailableContract = (
  name: ContractName,
  availableContracts: DriverContract[] | undefined,
) => {
  return !availableContracts
    ? availableContracts
    : availableContracts.find((contract) => contract.name === name);
};

const disableSubmitButtonMap: Record<DocumentStatus, boolean> = {
  uploaded: false,
  accepted: false,
  rejected: true,
  terminated: true,
  pending: true,
};

const disableSubmitExtraDocumentButtonMap: Record<DocumentStatus, boolean> = {
  uploaded: false,
  accepted: false,
  rejected: true,
  terminated: true,
  pending: false,
};

// Props type
type DriverContractDetailsViewProps = {
  driverId: string;
  getFieldErrors: (
    fieldName: string,
    backendFieldName?: string,
    translateFullPrefix?: string,
  ) => string[];
  setTabName: (tabName: TabName) => void;
  isSubmitInProgress: boolean;
  onSubmitButtonPress: (disabled: boolean) => void;
};

// Component
const DriverContractDetailsView = ({
  driverId,
  getFieldErrors,
  setTabName,
  isSubmitInProgress,
  onSubmitButtonPress,
}: DriverContractDetailsViewProps) => {
  const styles = useStyles();
  const { t } = useTranslation(['contracts', 'common']);
  const driver = useSelector(selectors.selectDriverById(driverId));
  const availableContracts: DriverContract[] | undefined = driver?.availableContracts;
  const [tab, setTab] = useState('property_entrustment_agreement');
  const propertyContract = findAvailableContract(ContractName.property, availableContracts);
  const transportContract = findAvailableContract(ContractName.transport, availableContracts);
  const residenceProof = driver?.identityDocuments?.filter(
    (identityDocument) => identityDocument.documentName === 'residence_proof',
  )?.[0];
  const criminalRecord = driver?.identityDocuments?.filter(
    (identityDocument) => identityDocument.documentName === 'criminal_record',
  )?.[0];
  const disableButtonMap = (tabName: string): boolean => {
    switch (tabName) {
      case TabName.property:
        return disableSubmitButtonMap[
          (propertyContract?.contractStatus as DocumentStatusEnum) ?? DocumentStatusEnum.terminated
        ];
      case TabName.transport:
        return disableSubmitButtonMap[
          (transportContract?.contractStatus as DocumentStatusEnum) ?? DocumentStatusEnum.terminated
        ];
      case TabName.criminalRecord:
        return disableSubmitExtraDocumentButtonMap[
          (criminalRecord?.status as DocumentStatusEnum) ?? DocumentStatusEnum.terminated
        ];

      case TabName.residenceProof:
        return disableSubmitExtraDocumentButtonMap[
          (residenceProof?.status as DocumentStatusEnum) ?? DocumentStatusEnum.terminated
        ];
      default:
        return true;
    }
  };
  // This sets default button state
  useEffect(() => {
    onSubmitButtonPress(disableButtonMap(tab));
  }, [availableContracts, onSubmitButtonPress, tab]);

  return (
    <div className={styles.root}>
      <TabContext value={tab}>
        <TabList
          onChange={(event, newValue) => {
            setTab(newValue);
            setTabName(newValue);
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            value="property_entrustment_agreement"
            label={t('contractNames.property_entrustment_agreement.name')}
            disabled={!propertyContract}
          />
          <Tab
            value="transport_services_agreement"
            label={t('contractNames.transport_services_agreement.name')}
            disabled={!transportContract}
          />
          <Tab
            value="criminal_record"
            label={t('common:identityDocument.types.criminal_record')}
            disabled={!criminalRecord}
          />
          <Tab
            value="residence_proof"
            label={t('common:identityDocument.types.residence_proof')}
            disabled={!residenceProof}
          />
        </TabList>
        <SwipeableTabPanels
          value={tab}
          onChangeValue={setTab}
          values={[
            'property_entrustment_agreement',
            'transport_services_agreement',
            'criminal_record',
            'residence_proof',
          ]}
        >
          <SwipeableTabPanel value="property_entrustment_agreement">
            {propertyContract && (
              <DriverContractInfo
                identityDocuments={driver?.identityDocuments}
                contract={propertyContract}
                getFieldErrors={getFieldErrors}
                disableUploadButton={isSubmitInProgress}
              />
            )}
          </SwipeableTabPanel>
          <SwipeableTabPanel value="transport_services_agreement">
            {transportContract && (
              <DriverContractInfo
                identityDocuments={driver?.identityDocuments}
                contract={transportContract}
                getFieldErrors={getFieldErrors}
                disableUploadButton={isSubmitInProgress}
              />
            )}
          </SwipeableTabPanel>
          <SwipeableTabPanel value="criminal_record">
            {criminalRecord && (
              <DriverExtraDocumentInfo
                extraDocument={criminalRecord}
                getFieldErrors={getFieldErrors}
                disableUploadButton={isSubmitInProgress}
              />
            )}
          </SwipeableTabPanel>
          <SwipeableTabPanel value="residence_proof">
            {residenceProof && (
              <DriverExtraDocumentInfo
                extraDocument={residenceProof}
                getFieldErrors={getFieldErrors}
                disableUploadButton={isSubmitInProgress}
              />
            )}
          </SwipeableTabPanel>
        </SwipeableTabPanels>
      </TabContext>
    </div>
  );
};

export default DriverContractDetailsView;
