import {
  timestampable,
  timestampableFromSrc,
  timestampableSrc,
  timestampableToSrc,
} from '@common/model';
import {
  CountryDefinition,
  countryDefinitionFromSrc,
  CountryDefinitionSrc,
  countryDefinitionToSrc,
} from '@features/user/model/Company';

export interface Partner extends timestampable {
  id: string;
  userId: string;
  isPartner: boolean;
  name: string;
  taxIdentificationNumber: string;
  registrationNumber: string;
  registrationCountryCode: string;
  countryDefinition: CountryDefinition;
}

export interface PartnerSrc extends timestampableSrc {
  id: string;
  user_id: string;
  is_partner: boolean;
  name: string;
  tax_identification_number: string;
  registration_number: string;
  registration_country_code: string;
  country_definition: CountryDefinitionSrc;
}

export const partnerFromSrc = (partnerSrc: PartnerSrc): Partner => ({
  id: partnerSrc.id,
  userId: partnerSrc.user_id,
  isPartner: partnerSrc.is_partner,
  name: partnerSrc.name,
  taxIdentificationNumber: partnerSrc.tax_identification_number,
  registrationNumber: partnerSrc.registration_number,
  registrationCountryCode: partnerSrc.registration_country_code,
  countryDefinition: countryDefinitionFromSrc(partnerSrc.country_definition),
  ...timestampableFromSrc(partnerSrc),
});

export const partnerToSrc = (partner: Partner): PartnerSrc => ({
  id: partner.id,
  user_id: partner.userId,
  name: partner.name,
  tax_identification_number: partner.taxIdentificationNumber,
  registration_number: partner.registrationNumber,
  registration_country_code: partner.registrationCountryCode,
  country_definition: countryDefinitionToSrc(partner.countryDefinition),
  is_partner: partner.isPartner,
  ...timestampableToSrc(partner),
});
