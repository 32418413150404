// Core libraries
import React from 'react';

// External libs and components
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
  }),
);

// Props type
type SinglePageLayoutLabelProps = { label: string; className?: string };

// Component
const SinglePageLayoutLabel = ({ label, className }: SinglePageLayoutLabelProps) => {
  const styles = useStyles();

  return <div className={clsx(styles.root, className)}>{label}</div>;
};

export default SinglePageLayoutLabel;
