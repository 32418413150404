import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { UserState } from '../model';
import { UserUpdate } from '../../model/User';

export const updateUserDataStateClear = (state: UserState) => {
  state.updateUserDataState = undefined;
};

export const updateUserDataInit = (state: UserState, action: PayloadAction<UserUpdate | any>) => {
  state.updateUserDataState = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const updateUserDataSuccess = (state: UserState, action: Action) => {
  state.updateUserDataState = {
    inProgress: false,
    error: undefined,
    success: true,
  };
};
export const updateUserDataFailure = (state: UserState, action: PayloadAction<FailureResponse>) => {
  state.updateUserDataState = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
};

const updateUserDataReducers = {
  updateUserDataStateClear,
  updateUserDataInit,
  updateUserDataFailure,
  updateUserDataSuccess,
};
export default updateUserDataReducers;
