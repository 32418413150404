// Core libraries
import React, { useMemo, useRef, useState } from 'react';

// External libs and components
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LanguageIcon from '@material-ui/icons/Language';

// Internal libs
import { actions, selectors } from '@features/user';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import AppBarMenuItem from '@features/main/components/AppBarMenuItem';

// Props type
type I18nSwitchProps = {};

// Component
const I18nSwitch = ({}: I18nSwitchProps) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('common');

  const user = useSelector(selectors.selectUser);

  const setLanguageState = useSelector(selectors.selectSetLanguageState);

  const langs = useMemo(() => {
    return (
      (i18n.options.supportedLngs || [])
        // Remove testing language (continuous integration mode)
        .filter((lng) => lng !== 'cimode')
        // Move current language to top
        .sort((a, b) => (a === i18n.language ? -1 : b === i18n.language ? 1 : 0))
        // Add language id, name and native name (translation in referenced language)
        .map((lng) => ({
          id: lng,
          name: t(`languages.${lng}`, ''),
          nameNative: t(`languages.${lng}`, { lng, fallbackLng: [lng], defaultValue: '' }),
        }))
        // Add label (combin
        .map((lngObj) => ({
          ...lngObj,
          label:
            lngObj.name +
              (lngObj.nameNative && lngObj.nameNative !== lngObj.name
                ? ` - ${lngObj.nameNative}`
                : '') || lngObj.id,
        }))
    );
  }, [i18n, t]);

  const currentLang = useMemo(() => langs.find((langObj) => langObj.id === i18n.language), [
    langs,
    i18n.language,
  ]);

  const handleChange = async (lang: string) => {
    i18n.changeLanguage(lang);
    if (user) {
      try {
        await dispatch(actions.setLanguage(lang));
      } catch (e) {}
    }
    console.log(lang);
  };

  const anchorRef = useRef(null);
  // const [value, setValue] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <div className={styles.root}>
      <ProgressButton
        ref={anchorRef}
        onClick={openMenu}
        color="inherit"
        disabled={setLanguageState?.inProgress}
        loading={setLanguageState?.inProgress}
        startIcon={<LanguageIcon />}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {currentLang?.name}
      </ProgressButton>
      <Menu
        open={isMenuOpen}
        onClose={closeMenu}
        keepMounted
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {i18n.options.supportedLngs &&
          langs.map((lngObj) => (
            <AppBarMenuItem
              text={lngObj.label}
              onClick={() => handleChange(lngObj.id)}
              onClose={closeMenu}
              key={lngObj.id}
            />
          ))}
      </Menu>
    </div>
  );
};

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginRight: theme.spacing(2),
    },
  }),
);

export default I18nSwitch;
