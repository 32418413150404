// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TabPanel from '@material-ui/lab/TabPanel';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type SwipeableTabPanelProps = { children: React.ReactNode; value: string };

// Component
const SwipeableTabPanel = ({ children, value }: SwipeableTabPanelProps) => {
  const styles = useStyles();

  return (
    <>
      <TabPanel value={value}>{children}</TabPanel>
    </>
  );
};

export default SwipeableTabPanel;
