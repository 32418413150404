// Core libraries
import React, { useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import useMUIDataTableDefaultOptions from '@common/miscHooks/useMUIDataTableDefaultOptions';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';

// Internal libs
import { selectors } from '../../store';
import { Invoice, InvoiceType } from '@features/company/model/Invoice';
import { textSearch } from '@common/tools/textSearch';

// Internal components
import InvoicesFilter, { InvoicesFilterData } from './InvoicesFilter';
import TableFiltersProvider from '@common/components/filters/TableFiltersProvider';
import TableFiltersToolbar from '@common/components/filters/TableFiltersToolbar';
import InvoicesIcon from '@material-ui/icons/DescriptionOutlined';
import DownloadAction from '@features/company/containers/invoices/DownloadAction';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

const getInvoicesId = (item: string[]) => item[0];

// Props type
type InvoicesCardProps = {};

// Component
const InvoicesCard = ({}: InvoicesCardProps) => {
  const { t } = useTranslation('company');
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const styles = useStyles();
  const [filter, setFilter] = useState<InvoicesFilterData>();
  const invoices = useSelector(selectors.selectInvoices);
  const filteredInvoices = useMemo(() => {
    const from = filter?.dateFrom && new Date(filter.dateFrom);
    const to = filter?.dateTo && new Date(filter.dateTo);

    return (
      invoices
        // At least one date must be provided and be in given range.
        ?.filter(
          (invoice: Invoice) =>
            !(from || to) ||
            [invoice.soldAtTo, invoice.invoicedAt]
              .filter((date) => !!date)
              .map((dateStr: string) => new Date(dateStr))
              .some((date: Date) => (!from || date >= from) && (!to || date <= to)),
        )
        //  Search text
        .filter(
          (invoice: Invoice) =>
            !filter?.search ||
            textSearch(filter.search, invoice, (invoice) => [
              invoice.no,
              t(`model.invoice.type.map.${invoice.type}`),
              t(`model.invoice.status.map.${invoice.status}`),
            ]),
        )
        .map((invoice) => ({ ...invoice, invoice })) || []
    );
  }, [invoices, filter]);

  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'id',
        options: {
          display: false,
          viewColumns: false,
          filter: false,
        },
      },
      {
        name: 'no',
        label: t('model.invoice.no'),
      },
      {
        name: 'type',
        label: t('model.invoice.type.label'),
        options: {
          customBodyRender: (value) => t(`model.invoice.type.map.${value}`),
        },
      },
      {
        name: 'grossTotal',
        label: t('model.invoice.grossTotal'),
        options: {
          customBodyRender: (value) => (
            <NumberFormat
              value={value}
              thousandSeparator={' '}
              displayType={'text'}
              decimalScale={2}
            />
          ),
        },
      },
      {
        name: 'status',
        label: t('model.invoice.status.label'),
        options: {
          customBodyRender: (value) => t(`model.invoice.status.map.${value}`),
        },
      },
      {
        name: 'invoicedAt',
        label: t('model.invoice.invoicedAt'),
        options: {
          customBodyRender: (value: string) =>
            t('common:datetime.datetime', { date: new Date(value) }),
        },
      },
      {
        name: 'soldAtTo',
        label: t('model.invoice.soldAtTo'),
        options: {
          customBodyRender: (value: string) =>
            t('common:datetime.datetime', { date: new Date(value) }),
        },
      },
      {
        name: 'invoice',
        label: t('invoices.download'),
        options: {
          filter: false,
          customBodyRender: (invoice: Invoice) => {
            if (invoice.type === InvoiceType.Invoice) {
              return <DownloadAction invoice={invoice} children={<InvoicesIcon />} />;
            } else {
              return (
                <Button variant="contained" color="primary">
                  {t('model.invoice.generateInvoice')}
                </Button>
              );
            }
          },
        },
      },
    ],
    [t],
  );
  console.log(`${path}/${'s'}/generate`);
  const options: MUIDataTableOptions = {
    ...useMUIDataTableDefaultOptions(false),
    search: false,
    onRowClick: (currentRowsSelected: any, allRowsSelected: any) => {
      history.push(`${path}/invoice/${getInvoicesId(currentRowsSelected)}`);
    },
  };

  const updateFilter = (values: InvoicesFilterData) => {
    setFilter(values);
  };

  return (
    <Card>
      <TableFiltersProvider filters={<InvoicesFilter onChange={updateFilter} />}>
        <MUIDataTable
          columns={columns}
          data={filteredInvoices}
          title=""
          options={options}
          components={{
            TableToolbar: TableFiltersToolbar,
          }}
        />
      </TableFiltersProvider>
    </Card>
  );
};

export default InvoicesCard;
