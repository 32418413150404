// Core libraries
import React from 'react';

// External libs and components

// Internal libs
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

// Internal components
import CenteredPaperLayout from '@common/components/CenteredPaperLayout';
import LoginTraditional from './LoginTraditional';
import LoginSocial from './LoginSocial';

const Login = () => {
  const match = useRouteMatch();

  return (
    <CenteredPaperLayout showBgImage={true} maxWidth="sm" showLanguageSelector>
      <Switch>
        <Route exact path={`${match.path}/:socialType`}>
          <LoginSocial />
        </Route>
        <Route path={`${match.path}`}>
          <LoginTraditional />
        </Route>
      </Switch>
    </CenteredPaperLayout>
  );
};

export default Login;
