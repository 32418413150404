// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import useMUIDataTableDefaultOptions from '@common/miscHooks/useMUIDataTableDefaultOptions';
import Card from '@material-ui/core/Card';
import { useTranslation } from 'react-i18next';
import NumberFormat from "react-number-format";
// Internal libs

// Internal components
import { Invoice } from '@features/company/model/Invoice';


// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type InvoiceSummaryProps = { invoice: Invoice };

// Component
const InvoiceSummary = ({ invoice }: InvoiceSummaryProps) => {
  const { t } = useTranslation(['company']);
  const styles = useStyles();
  const options: MUIDataTableOptions = {
    ...useMUIDataTableDefaultOptions(false),
    search: false,
    filter: false,
    pagination: false,
    viewColumns: false,
  };

  const summary = [
    {
      text: t('company:invoicesDetails.summary.fields.total'),
      net: invoice.netTotal,
      vat: invoice.vatTotal,
      gross: invoice.grossTotal,
    },
  ];

  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'text',
        label: '#',
        options: {
          display: true,
          viewColumns: false,
          filter: false,
        },
      },
      {
        name: 'net',
        label: t('company:invoicesDetails.summary.fields.net'),
        options: {
          customBodyRender: (value) => <NumberFormat value={value} thousandSeparator={' '} displayType={'text'} decimalScale={2} />
        }
      },
      {
        name: 'vat',
        label: t('company:invoicesDetails.summary.fields.vat'),
        options: {
          customBodyRender: (value) => <NumberFormat value={value} thousandSeparator={' '} displayType={'text'} decimalScale={2} />
        }
      },
      {
        name: 'gross',
        label: t('company:invoicesDetails.summary.fields.gross'),
        options: {
          customBodyRender: (value) => <NumberFormat value={value} thousandSeparator={' '} displayType={'text'} decimalScale={2} />
        }
      },
    ],
    [],
  );

  return <MUIDataTable columns={columns} data={summary} title="" options={options} />;
};

export default InvoiceSummary;
