// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

// Internal libs
import { ThemeColorName } from '@common/components/theme/xdThemeColor';

// Internal components

// Styles hook
const useStyles = makeStyles<Theme, { color?: ThemeColorName }>((theme) =>
  createStyles({
    root: ({ color }: { color?: ThemeColorName }) => ({
      padding: theme.spacing(1),
      ...(color && theme.palette[color]?.light && theme.palette[color]?.main
        ? {
            // @ts-ignore
            backgroundColor: theme.palette[color]?.light,
            color: 'white',
            '&:hover': {
              backgroundColor: theme.palette[color]?.main,
            },
          }
        : {}),
    }),
    rootLoading: {
      padding: theme.spacing(1),
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    circularSpinner: ({ color }) => ({
      width: '1em !important',
      height: '1em !important',
      ...(color && theme.palette[color]?.main ? { color: theme.palette[color]?.main } : {}),
    }),
  }),
);

// Props type
type DataColorActionIconProps = {
  children: React.ReactNode;
  color?: ThemeColorName;
  onClick?: () => void;
  tooltip?: string;
  loading?: boolean;
  disabled?: boolean;
};

// Component
const DataColorActionIcon = ({
  children,
  color,
  onClick,
  tooltip,
  loading = false,
  disabled = false,
}: DataColorActionIconProps) => {
  const styles = useStyles({ color });

  const onClickHandler = () => !loading && onClick && onClick();

  return (
    <IconButton
      className={loading ? styles.rootLoading : styles.root}
      onClick={onClickHandler}
      disabled={disabled}
      {...(tooltip ? { title: tooltip } : {})}
    >
      {loading ? (
        <CircularProgress className={styles.circularSpinner} data-testid="loading-indicator" />
      ) : (
        children
      )}
    </IconButton>
  );
};

export default DataColorActionIcon;
