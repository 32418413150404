// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),

      display: 'flex',
      justifyContent: 'space-between',
    },
    field: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    title: { lineHeight: 1.25, color: theme.palette.text.primary },
    chips: {
      marginLeft: theme.spacing(0.25),
      fontSize: '0.5em',
      display: 'inline',
      verticalAlign: 'top',
    },
    chip: {
      marginLeft: theme.spacing(0.25),
      marginRight: theme.spacing(0.25),
      verticalAlign: 'middle',
    },
    subheader: { lineHeight: 1.25, color: theme.palette.text.secondary },
    actions: {
      marginLeft: theme.spacing(2),
    },
  }),
);

// Props type
type CardContentHeaderProps = {
  title: string | React.ReactNode;
  chips?: (string | ChipProps)[];
  subheader?: string | React.ReactNode;
  action?: React.ReactNode;
};

// Component
const SimpleHeader = ({ title, chips = [], subheader, action }: CardContentHeaderProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.field}>
        <Typography variant="h6" component="span" className={styles.title}>
          {title}
          {chips && (
            <span className={styles.chips}>
              {chips.map((chipProps, idx) => (
                <Chip
                  className={styles.chip}
                  {...{
                    key: idx,
                    size: 'small',
                    ...(typeof chipProps === 'string' ? { label: chipProps } : chipProps),
                  }}
                />
              ))}
            </span>
          )}
        </Typography>
        {subheader && (
          <Typography variant="subtitle1" component="span" className={styles.subheader}>
            {subheader}
          </Typography>
        )}
      </div>
      {action && <div className={styles.actions}>{action}</div>}
    </div>
  );
};

export default SimpleHeader;
