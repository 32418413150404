// Core libraries
import React, { useCallback, useEffect, useState } from 'react';

// External libs and components
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { actions } from '../store';

// Internal components
import VehicleForm from './VehicleForm';
import { VehicleInsert } from '../model/Vehicle';

// Component
const VehicleAddDialog = () => {
  const { t } = useTranslation('vehicles');
  const history = useHistory();
  const dispatch = useDispatch();

  const vehicleUpsertState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.vehicles.vehicleUpsertState,
  );

  const onClose = () => {
    history.push('.');
  };

  const [init, setInit] = useState(false);
  useEffect(() => {
    dispatch(actions.vehicleUpsertStateClear());
    setInit(true);
  }, [dispatch, setInit]);

  useEffect(() => {
    if (init && vehicleUpsertState?.success) {
      onClose();
    }
  }, [init, vehicleUpsertState, onClose]);

  const onSubmit = useCallback(
    async (values: VehicleInsert) => {
      const newVehicleId = await dispatch(actions.insertVehicle(values));
      if (!!newVehicleId) {
        history.push(`./activate/${newVehicleId}`);
      }
    },
    [dispatch, history],
  );

  return (
    <Dialog fullWidth onClose={onClose} open={true} disableBackdropClick>
      <DialogTitle>{t('upsert.title.insert')}</DialogTitle>
      <Divider />
      <VehicleForm onSubmit={onSubmit} onClose={onClose} />
    </Dialog>
  );
};

export default VehicleAddDialog;
