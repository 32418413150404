// Core libraries
import React, { useEffect } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Route, Switch, useRouteMatch } from 'react-router';

// Internal libs

// Internal components
import PageDefaultLayout from '@common/components/layouts/PageDefaultLayout';
import CorrectionModal from '@features/admin/corrections/containers/CorrectionModal';
import CorrectionsCard from '@features/admin/corrections/containers/CorrectionsCard';
import CorrectionRejectModal from '@features/admin/corrections/containers/CorrectionRejectModal';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type CorrectionsPageProps = {};

// Component
const CorrectionsPage = ({}: CorrectionsPageProps) => {
  const { t } = useTranslation('admin');
  const { path } = useRouteMatch();

  return (
    <PageDefaultLayout title={t('corrections.title')}>
      <Switch>
        <Route path={`${path}/:correctionId/reject`}>
          <CorrectionRejectModal />
        </Route>
        <Route path={`${path}/:correctionId`}>
          <CorrectionModal />
        </Route>
      </Switch>
      <CorrectionsCard />
    </PageDefaultLayout>
  );
};

export default CorrectionsPage;
