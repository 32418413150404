import { createTheme, ThemeOptions } from '@material-ui/core/styles';

const common: ThemeOptions = {
  overrides: {
    // @ts-ignore
    MUIDataTableHeadCell: {
      root: {
        // This "hack" fixes problem with border hidden by background on display: sticky
        borderBottomWidth: 2,
        paddingBottom: 0,
        paddingTop: 0,
        textAlign: 'center',
      },
      contentWrapper: {
        justifyContent: 'center',
      },
      toolButton: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MUIDataTableBodyCell: {
      root: { textAlign: 'center' },
      stackedCommon: {
        verticalAlign: 'middle',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
      },
    },
    MUIDataTablePagination: {
      tableCellContainer: {
        border: 'none',
      },
    },
  },
  shape: { borderRadius: 2 },
};

export const lightTheme = createTheme({
  palette: { type: 'light', disabled: { dark: '#999', main: '#ccc', light: '#eee' } },
  ...common,
});
export const darkTheme = createTheme({ palette: { type: 'dark' }, ...common });
