import { CorrectionReasonType } from '@features/admin/drivers/model/CorrectionReason';
import {
  CorrectionRideDetails,
  correctionRideDetailsFromSrc,
  CorrectionRideDetailsSrc,
  correctionRideDetailsToSrc,
} from '@features/rides/model';

export interface Correction extends CorrectionInfo {
  id: number;
  userId: number;
  rideId?: number;
  createdAt: string;
}

export interface CorrectionApprovePayload {
  payout: number;
  correctionReason: CorrectionReasonType | null;
  comment: string | undefined;
}

export interface CorrectionApprovePayloadSrc {
  payout: number;
  correction_reason: CorrectionReasonType | null;
  comment: string | undefined;
}

export const correctionApproveToSrc = (
  correctionPayload: CorrectionApprovePayload,
): CorrectionApprovePayloadSrc => ({
  payout: correctionPayload.payout,
  correction_reason: correctionPayload.correctionReason ?? null,
  comment: correctionPayload.comment ?? undefined,
});

export interface CorrectionRejectPayload {
  rejectionComment: string | null;
}
export interface CorrectionRejectPayloadSrc {
  rejection_comment: string | null;
}

export const correctionRejectToSrc = (
  correctionPayload: CorrectionRejectPayload,
): CorrectionRejectPayloadSrc => ({
  rejection_comment: correctionPayload.rejectionComment,
});

export interface CorrectionInfo {
  createdRideId?: number;
  rideNo: string;
  approved: boolean | null;
  clientsAddress?: string;
  clientsCity?: string;
  clientsZipCode?: string;
  extraDistance?: number;
  comment?: string;
  correctionReason?: CorrectionReasonType;
  rejectionComment?: string;
  driverName: string;
  driverEmail: string;
  companyName?: string;
  ride?: CorrectionRideDetails;
  realDistance?: number;
  realDuration?: number;
}

export interface CorrectionSrc {
  id: number;
  user_id: number;
  ride_id: number | null;
  ride_no: string;
  created_ride_id: number | null;
  approved: boolean | null;
  clients_address: string | null;
  clients_city: string | null;
  clients_zip_code: string | null;
  extra_distance: number | null;
  comment: string | null;
  correction_reason: CorrectionReasonType | null;
  rejection_comment: string | null;
  driver_name: string;
  driver_email: string;
  created_at: string;
  company_name: string | null;
  ride: CorrectionRideDetailsSrc | null;
  real_distance: number | null;
  real_duration: number | null;
}

export const correctionFromSrc = (correctionSrc: CorrectionSrc): Correction => ({
  id: correctionSrc.id,
  userId: correctionSrc.user_id,
  rideId: correctionSrc.ride_id || undefined,
  createdRideId: correctionSrc.created_ride_id || undefined,
  rideNo: correctionSrc.ride_no,
  approved: correctionSrc.approved ?? null,
  clientsAddress: correctionSrc.clients_address || undefined,
  clientsCity: correctionSrc.clients_city || undefined,
  clientsZipCode: correctionSrc.clients_zip_code || undefined,
  extraDistance: correctionSrc.extra_distance || undefined,
  comment: correctionSrc.comment || undefined,
  correctionReason: correctionSrc.correction_reason || undefined,
  rejectionComment: correctionSrc.rejection_comment || undefined,
  driverEmail: correctionSrc.driver_email,
  driverName: correctionSrc.driver_name,
  createdAt: correctionSrc.created_at,
  companyName: correctionSrc.company_name || undefined,
  ride: correctionSrc.ride ? correctionRideDetailsFromSrc(correctionSrc.ride) : undefined,
  realDuration: correctionSrc.real_duration || undefined,
  realDistance: correctionSrc.real_distance || undefined,
});

export const correctionToSrc = (correction: Correction): CorrectionSrc => ({
  id: correction.id,
  user_id: correction.userId,
  ride_id: correction.rideId || null,
  created_ride_id: correction.createdRideId || null,
  ride_no: correction.rideNo,
  approved: correction.approved ?? null,
  clients_address: correction.clientsAddress || null,
  clients_city: correction.clientsCity || null,
  clients_zip_code: correction.clientsZipCode || null,
  extra_distance: correction.extraDistance || null,
  comment: correction.comment || null,
  correction_reason: correction.correctionReason || null,
  rejection_comment: correction.rejectionComment || null,
  driver_name: correction.driverName,
  driver_email: correction.driverEmail,
  created_at: correction.createdAt,
  company_name: correction.companyName || null,
  ride: correction.ride ? correctionRideDetailsToSrc(correction.ride) : null,
  real_distance: correction.realDistance || null,
  real_duration: correction.realDuration || null,
});
