import { RootThunk } from '@common/model';
import { actions as simpleActions } from '../slice';
import * as api from '../../../api';
import {
  CorrectionUpdate,
  DriverRideCorrection,
} from '@features/admin/drivers/model/DriverRideCorrection';

export const addDriverRideCorrection = (
  createdCorrection: DriverRideCorrection,
): RootThunk => async (dispatch) => {
  dispatch(simpleActions.addDriverRideCorrectionInit({ createdCorrection }));
  try {
    await api.addDriverRideCorrection(createdCorrection);
    dispatch(simpleActions.addDriverRideCorrectionSuccess());
  } catch (e) {
    dispatch(
      simpleActions.addDriverRideCorrectionFailure(e.response?.data || { errors: 'unknown' }),
    );
    throw e.response?.data || { error: 'unknown' };
  }
};

export const updateDriverRideCorrection = (
  rideId: number,
  createdCorrection: CorrectionUpdate,
): RootThunk => async (dispatch) => {
  dispatch(simpleActions.updateDriverRideCorrectionInit({ createdCorrection, rideId }));
  try {
    await api.updateDriverRideCorrection(rideId, createdCorrection);
    dispatch(simpleActions.updateDriverRideCorrectionSuccess());
  } catch (e) {
    dispatch(
      simpleActions.updateDriverRideCorrectionFailure(e.response?.data || { errors: 'unknown' }),
    );
    throw e.response?.data || { error: 'unknown' };
  }
};

export const removeDriverRideCorrection = (rideId: number): RootThunk => async (dispatch) => {
  dispatch(simpleActions.removeDriverRideCorrectionInit({ rideId }));
  try {
    await api.removeDriverRideCorrection(rideId);
    dispatch(simpleActions.removeDriverRideCorrectionSuccess());
  } catch (e) {
    dispatch(
      simpleActions.removeDriverRideCorrectionFailure(e.response?.data || { errors: 'unknown' }),
    );
    throw e.response?.data || { error: 'unknown' };
  }
};
