// Core libraries
import React, { useEffect, useState } from 'react';

// External libs and components
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { actions } from '../store';
import { Vehicle, VehicleInsert } from '../model/Vehicle';

// Internal components
import VehicleForm from './VehicleForm';

// Props type
type VehicleEditDialogProps = {};

// Component
const VehicleEditDialog = ({}: VehicleEditDialogProps) => {
  const { t } = useTranslation('vehicles');
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const loadingVehicles = useSelector<RootState, boolean | undefined>(
    (state) => state.vehicles.vehiclesListState?.inProgress,
  );
  const vehiclesList = useSelector<RootState, Vehicle[] | undefined>(
    (state) => state.vehicles.vehiclesList,
  );

  const vehicle = vehiclesList?.find((vehicle) => vehicle.id === Number(id));

  const vehicleUpsertState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.vehicles.vehicleUpsertState,
  );

  const onClose = () => {
    history.push('..');
  };

  const [init, setInit] = useState(false);
  useEffect(() => {
    dispatch(actions.vehicleUpsertStateClear());
    setInit(true);
  }, []);

  useEffect(() => {
    if (init && vehicleUpsertState?.success) {
      onClose();
    }
  }, [vehicleUpsertState]);

  const onSubmit = (values: VehicleInsert) => {
    // It should never be falsy.
    if (vehicle) {
      dispatch(
        actions.updateVehicle({
          ...values,
          id: vehicle.id,
        }),
      );
    }
  };

  return (
    <Dialog fullWidth onClose={onClose} open={true} disableBackdropClick>
      <DialogTitle>{t('upsert.title.update')}</DialogTitle>
      <Divider />
      {!!loadingVehicles && (
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      )}
      {!loadingVehicles && vehicle && (
        <VehicleForm onSubmit={onSubmit} onClose={onClose} vehicle={vehicle} />
      )}
      {!loadingVehicles && !vehicle && (
        <>
          <DialogContent>{t('upsert.messages.vehicleNotFound', '')}</DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t('upsert.cancelButton', '')}</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default VehicleEditDialog;
