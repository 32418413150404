// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router';
import AddCorrection from '@material-ui/icons/Add';
import GoToReference from '@material-ui/icons/ArrowUpward';
import CloseRideDetails from '@material-ui/icons/Close';
import EditRide from '@material-ui/icons/Edit';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

// Internal libs
import { isCorrectionTypeMap } from '@features/admin/drivers/model/CorrectionType';
import { RideDetails } from '@features/rides/model';

// Internal components
import RemoveCorrectionButton from '@features/admin/drivers/conteiners/RemoveCorrectionButton';
import HasRoles from '@features/user/sharedComponents/HasRoles';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
    chooseButton: {
      flexShrink: 0,
    },
    removeButton: {
      flexShrink: 0,
      marginRight: theme.spacing(2),
    },
    backButton: {
      flexShrink: 0,
      marginRight: theme.spacing(2),
    },
    block: {
      flexShrink: 1,
      flexGrow: 1,
      marginLeft: theme.spacing(2),
    },
    closeButton: {
      justifyContent: 'flex-end',
    },
  }),
);

// Props type
type DriverRideCorrectionActionsProps = {
  rideDetails: RideDetails;
  getPartnerRides?: () => void;
};

// Component
const DriverRideCorrectionActions = ({
  rideDetails,
  getPartnerRides,
}: DriverRideCorrectionActionsProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { path } = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation('rides');
  const { driverId }: { driverId: string } = useParams();
  const isRideSettled = () => !!(rideDetails && rideDetails.settledAt && rideDetails.invoiceId);
  const isRideSynchronized = () => (rideDetails ? !isCorrectionTypeMap[rideDetails?.type] : false);
  const updateRideList = () => (getPartnerRides ? getPartnerRides() : undefined);
  const onClick = () =>
    history.push(
      !rideDetails || isRideSettled() || isRideSynchronized()
        ? `/admin/drivers/driver/${driverId}/profile/rides/${rideDetails.id}/add`
        : `/admin/drivers/driver/${driverId}/profile/rides/${rideDetails.id}/edit`,
    );
  const back = () =>
    history.push({
      pathname: generatePath(path, {
        driverId: driverId,
        rideId: rideDetails.referenceRideId,
      }),
    });
  const close = () => history.push('..');

  return (
    <HasRoles roles="ADMIN">
      <div className={styles.root}>
        {rideDetails?.id && !isRideSettled() && !isRideSynchronized() && (
          <div className={styles.removeButton}>
            <RemoveCorrectionButton
              rideId={rideDetails.id}
              text={t('driverRideForm.deleteButton')}
              afterSuccess={updateRideList}
              icon={matches}
            />
          </div>
        )}
        {matches ? (
          <Button
            className={styles.chooseButton}
            onClick={onClick}
            variant="contained"
            color="primary"
          >
            {!rideDetails || isRideSettled() || isRideSynchronized()
              ? t('driverRideForm.addCorrectionWithReference')
              : t('driverRideForm.submitButtonUpdate')}
          </Button>
        ) : (
          <IconButton className={styles.chooseButton} onClick={onClick} color="primary">
            {!rideDetails || isRideSettled() || isRideSynchronized() ? (
              <AddCorrection />
            ) : (
              <EditRide />
            )}
          </IconButton>
        )}
        <div className={styles.block} />
        {rideDetails.referenceRideId &&
          (matches ? (
            <Button className={styles.backButton} variant="contained" onClick={back}>
              {t('driverRideForm.backButton')}
            </Button>
          ) : (
            <IconButton className={styles.backButton} onClick={back}>
              <GoToReference />
            </IconButton>
          ))}
        {matches ? (
          <Button className={styles.closeButton} onClick={close}>
            {t('driverRideForm.closeButton')}
          </Button>
        ) : (
          <IconButton className={styles.closeButton} onClick={close}>
            <CloseRideDetails />
          </IconButton>
        )}
      </div>
    </HasRoles>
  );
};

export default DriverRideCorrectionActions;
