import { FailureResponse } from '@common/model';
import { PayloadAction } from '@reduxjs/toolkit';
import { DriversState } from '@features/admin/drivers/store/model';

export const updateDriverDocumentInit = (state: DriversState) => {};
export const updateDriverDocumentSuccess = (state: DriversState) => {};
export const updateDriverDocumentFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {};
