import { actions as simpleActions } from '../slice';
import { AuthThunk } from '@features/auth/store/model';
import { actions as userActions } from '@features/user';
// import * as api from '../../api';

/****** Place for complex actions *****/

export const init = (): AuthThunk => async (dispatch) => {
  dispatch(simpleActions.initInit());

  // Store token
  const authToken = localStorage.getItem('authToken');
  if (authToken) {
    await dispatch(simpleActions.initRestoreToken(authToken));
    // @ts-ignore
    await dispatch(userActions.getCurrentUser());
  }

  dispatch(simpleActions.initSuccess());
};
