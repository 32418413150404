import { createSlice } from '@reduxjs/toolkit';
import { RedirectState } from '@features/redirect/store/model';
import reducers from './reducers';

const redirectSlice = createSlice<RedirectState, typeof reducers>({
  name: 'redirect',
  initialState: {},
  reducers,
});

export const reducer = redirectSlice.reducer;
export const actions = redirectSlice.actions;
