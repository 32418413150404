import { actions as simpleActions } from '../slice';

import * as userActions from './userActions';
import * as regionActions from './regionActions';
import * as partnerActions from './partnerActions';
import * as companyActions from './companyActions';
import * as nationalityActions from './nationalityActions';
import * as identityDocumentActions from './identityDocumentActions';

export const actions = {
  ...simpleActions,
  ...userActions,
  ...regionActions,
  ...partnerActions,
  ...companyActions,
  ...nationalityActions,
  ...identityDocumentActions,
};
