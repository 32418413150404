// Core libraries
import React, { useEffect, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { User } from '../model/User';
import { actions } from '../store';

// Internal components
import { PhoneChangeRequest } from '@features/user/model/PhoneChangeRequest';
import PhoneChangeRequestForm from '@features/user/containers/forms/PhoneChangeRequestForm';
import PhoneChangeVerifyForm from '@features/user/containers/forms/PhoneChangeVerifyForm';
import LinearProgress from '@material-ui/core/LinearProgress';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(2),
    },
  }),
);

// Props type
type PhoneChangeModalProps = {};

// Component
const PhoneChangeModal = ({}: PhoneChangeModalProps) => {
  const { t } = useTranslation('user');
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const phoneChangeRequest = useSelector<RootState, PhoneChangeRequest | undefined>(
    (state) => state.user.phoneChangeRequest,
  );
  const getPhoneChangeRequestState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.user.getPhoneChangeRequestState,
  );
  const onClose = () => {
    history.push('..');
  };
  useEffect(() => {
    dispatch(actions.getPendingUserPhoneChangeRequest());
  }, [dispatch]);

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      {getPhoneChangeRequestState?.inProgress && <LinearProgress />}
      {!getPhoneChangeRequestState?.inProgress && phoneChangeRequest?.id && (
        <PhoneChangeVerifyForm onClose={onClose} phoneChangeRequest={phoneChangeRequest} />
      )}
      {!getPhoneChangeRequestState?.inProgress && !phoneChangeRequest?.id && (
        <PhoneChangeRequestForm onClose={onClose} />
      )}
    </Dialog>
  );
};

export default PhoneChangeModal;
