import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';
import { AuthState } from './model';

const authSlice = createSlice<AuthState, typeof reducers>({
  name: 'auth',
  initialState: {
    init: undefined,
    login: undefined,
    logout: undefined,
    user: undefined,
  },
  reducers: {
    ...reducers,
  },
});

export const reducer = authSlice.reducer;
export const actions = authSlice.actions;
