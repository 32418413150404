// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { User } from '../../model/User';
import { actions } from '../../store';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import FormTextField from '@common/components/FormTextField';
import usePhoneValidator, {
  phoneMask,
  phoneToNumber,
  numberToPhone,
} from '@common/validators/phoneValidator';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormErrors from '@common/components/FormErrors';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(2),
    },
  }),
);

// Props type
type PhoneChangeRequestFormProps = {
  onClose?: VoidFunction;
};

// Component
const PhoneChangeRequestForm = ({ onClose }: PhoneChangeRequestFormProps) => {
  const { t } = useTranslation('user');
  const styles = useStyles();
  const dispatch = useDispatch();

  const form = useForm();
  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  const [changePhoneState, setChangePhoneState] = useState<InProgressState>({ inProgress: false });

  const onSubmit = async (values: any) => {
    setChangePhoneState({ inProgress: true });
    try {
      await dispatch(
        actions.requestUserPhoneChangeToken(phoneToNumber(values.phone), values.password),
      );
      await dispatch(actions.getPendingUserPhoneChangeRequest());
      setChangePhoneState({ inProgress: false, success: true });
    } catch (e) {
      setChangePhoneState({ inProgress: false, error: e, success: false });
    }
  };

  const { formErrors, getFieldErrors } = useResponseErrors(
    changePhoneState?.error,
    'phoneChange',
    'user',
  );

  const phoneValidator = usePhoneValidator(t('changePhone.fields.phone.errors.phone', ''));

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle>{t('phoneChange.title', '')}</DialogTitle>
        <Divider />
        <DialogContent>
          <p>{t('phoneChange.body')}</p>
          {userData && (
            <>
              <FormTextField
                className={styles.field}
                name="phone"
                label={t('phoneChange.fields.phone.label', '')}
                placeholder="987 654 321"
                mask={phoneMask}
                rules={{
                  required: {
                    value: true,
                    message: t('phoneChange.fields.phone.errors.required', ''),
                  },
                  validate: {
                    phone: phoneValidator,
                  },
                }}
                fullWidth={true}
                errors={getFieldErrors('phone')}
              />
              <FormTextField
                className={styles.field}
                name="password"
                label={t('phoneChange.fields.password.label', '')}
                rules={{
                  required: {
                    value: true,
                    message: t('login.fields.password.errors.required', ''),
                  },
                }}
                fullWidth={true}
                type="password"
                errors={getFieldErrors('password')}
              />

              {/* Errors */}
              <FormErrors errorMessages={formErrors} />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('phoneChange.cancelButton')}</Button>
          <ProgressButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={changePhoneState?.inProgress}
            loading={changePhoneState?.inProgress}
          >
            {t(`phoneChange.submitButton`, '')}
          </ProgressButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default PhoneChangeRequestForm;
