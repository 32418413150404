import { RootThunk } from '@common/model';
import { actions as simpleActions } from '../slice';
import * as api from '../../api';
import { Contract } from '@features/contracts/model/Contract';
import { requestPinCodeInit } from '@features/contracts/store/reducers';

export const getContractTemplatesWithContracts = (): RootThunk => async (
  dispatch,
): Promise<any> => {
  dispatch(simpleActions.getContractTemplatesWithContractsInit());

  try {
    const contractTemplatesWithContracts = await api.getContractTemplatesWithContracts();
    dispatch(
      simpleActions.getContractTemplatesWithContractsSuccess(contractTemplatesWithContracts),
    );

    return contractTemplatesWithContracts;
  } catch (e) {
    dispatch(
      simpleActions.getContractTemplatesWithContractsFailure(
        e.response?.data || { error: 'unknown' },
      ),
    );
    throw e.response?.data || { error: 'unknown' };
  }
};

export const getContract = (contractTemplateId: number): RootThunk => async (
  dispatch,
): Promise<any> => {
  dispatch(simpleActions.getContractInit(contractTemplateId));

  try {
    const response = await api.getContract(contractTemplateId);
    dispatch(simpleActions.getContractSuccess());
    return response;
  } catch (e) {
    dispatch(simpleActions.getContractFailure(e.response?.data || { error: 'unknown' }));
    throw e.response?.data || { error: 'unknown' };
  }
};

export const downloadGeneratedContract = (contractTemplateId: number): RootThunk => async (
  dispatch,
): Promise<any> => {
  dispatch(simpleActions.downloadGeneratedContractInit(contractTemplateId));

  try {
    const response = await api.downloadContract(contractTemplateId);
    dispatch(simpleActions.downloadGeneratedContractSuccess());
  } catch (e) {
    dispatch(
      simpleActions.downloadGeneratedContractFailure(e.response?.data || { error: 'unknown' }),
    );
    throw e.response?.data || { error: 'unknown' };
  }
};

export const downloadContractPdf = (contract: Contract): RootThunk => async (
  dispatch,
): Promise<any> => {
  dispatch(simpleActions.downloadGeneratedContractInit(contract.contractTemplateId));

  try {
    const response = await api.downloadContractPdf(contract);
    dispatch(simpleActions.downloadGeneratedContractSuccess());
  } catch (e) {
    dispatch(
      simpleActions.downloadGeneratedContractFailure(e.response?.data || { error: 'unknown' }),
    );
    throw e.response?.data || { error: 'unknown' };
  }
};

export const signContract = (
  contractId: number,
  pinCode: string,
  confirmContract: boolean,
): RootThunk => async (dispatch): Promise<any> => {
  dispatch(simpleActions.signContractInit(contractId));

  try {
    const response = await api.signContract(contractId, pinCode, confirmContract);
    dispatch(simpleActions.signContractSuccess());
  } catch (e) {
    dispatch(simpleActions.signContractFailure(e.response?.data || { error: 'unknown' }));
    throw e.response?.data || { error: 'unknown' };
  }
};

export const requestPinCode = (contractId: number): RootThunk => async (dispatch): Promise<any> => {
  dispatch(simpleActions.requestPinCodeInit(contractId));

  try {
    const response = await api.requestPinCode(contractId);
    dispatch(simpleActions.requestPinCodeSuccess());
  } catch (e) {
    dispatch(simpleActions.requestPinCodeFailure(e.response?.data || { error: 'unknown' }));
    throw e.response?.data || { error: 'unknown' };
  }
};

export const uploadSignedContract = (
  contractId: number,
  contractFiles: File[],
  progressCallback: (event: { loaded: number; total: number }) => void,
): RootThunk => async (dispatch): Promise<any> => {
  dispatch(
    simpleActions.uploadSignedContractInit({
      contractTemplateId: contractId,
    }),
  );

  try {
    await api.uploadFile(contractId, contractFiles, progressCallback);
    dispatch(simpleActions.uploadSignedContractSuccess());
    dispatch(getContractTemplatesWithContracts());
  } catch (e) {
    dispatch(simpleActions.uploadSignedContractFailure(e.response?.data || { error: 'unknown' }));
    throw e.response?.data || { error: 'unknown' };
  }
};

export const downloadUploadedSignedDocument = (url: string, fileName: string): RootThunk => async (
  dispatch,
): Promise<any> => {
  dispatch(simpleActions.downloadUploadedSignedDocumentInit({ url, fileName }));

  try {
    const response = await api.downloadUploadedSignedDocument(url, fileName);
    dispatch(simpleActions.downloadUploadedSignedDocumentSuccess());
  } catch (e) {
    dispatch(
      simpleActions.downloadUploadedSignedDocumentFailure(e.response?.data || { error: 'unknown' }),
    );
    throw e.response?.data || { error: 'unknown' };
  }
};
