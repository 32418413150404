import { actions as simpleActions } from './slice';
import { RedirectThunk } from '@features/redirect/store/model';

const redirectTo = (to: string): RedirectThunk => async (dispatch) => {
  await dispatch(simpleActions.redirect(to));
  await dispatch(simpleActions.clear());
};

export const actions = {
  redirectTo,
};
