import { actions as simpleActions } from '../slice';
import { LOCAL_STORAGE_PRIVACY_POLICY_KEY } from '../model';
import { User } from '@features/user/model/User';
import { actions as userActions } from '@features/user';
import { RootThunk } from '@common/model';

/****** Place for complex actions *****/

// Action to be called on application init
export const getPrivacyPolicyConsentFromBrowser = (): RootThunk => async (dispatch) => {
  const isPrivacyPolicyAccepted = localStorage.getItem(LOCAL_STORAGE_PRIVACY_POLICY_KEY);
  if (isPrivacyPolicyAccepted === 'true') {
    dispatch(acceptPrivacyPolicy(true));
  }
};

export const acceptPrivacyPolicy = (accepted: boolean): RootThunk => async (dispatch, getState) => {
  localStorage.setItem(LOCAL_STORAGE_PRIVACY_POLICY_KEY, '' + accepted);
  dispatch(simpleActions.setIsPrivacyPolicyAccepted(accepted));

  const state = getState();
  if (state.user.user && !state.user.user.privacyPolicyAcceptedAt) {
    dispatch(userActions.userAcceptPrivacyPolicy());
  }
};

// Action to be called after user data is fetched
export const checkUserPrivacyPolicy = (user: User): RootThunk => async (dispatch, getState) => {
  dispatch(simpleActions.checkUserPrivacyPolicyInit());

  if (user.privacyPolicyAcceptedAt) {
    dispatch(acceptPrivacyPolicy(true));
  } else {
    const state = getState();
    if (state.privacyPolicy.isPrivacyPolicyAccepted) {
      dispatch(userActions.userAcceptPrivacyPolicy());
    }
  }
};
