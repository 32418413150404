// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router';

// Internal libs
import useMUIDataTableDefaultOptions from '@common/miscHooks/useMUIDataTableDefaultOptions';
import { Ride } from '@features/rides/model/Ride';
import { CorrectionType, CorrectionTypeEnum } from '@features/admin/drivers/model/CorrectionType';

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

const notApplicable = '-';

// Props type
type RideCorrectionListProps = { connectedCorrection: Ride[] };

// Component
const RideCorrectionList = ({ connectedCorrection }: RideCorrectionListProps) => {
  const styles = useStyles();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { driverId } = useParams() as { driverId: string };
  const { t } = useTranslation('rides');
  const corrections = useMemo(
    () =>
      connectedCorrection.map((correction) => ({
        ...correction,
        reason: { type: correction.type, value: correction.correctionReason },
      })),
    [connectedCorrection],
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: 'type',
      label: t('model.ride.type.shortLabel'),
      options: {
        customBodyRender: (value: string) => t(`model.ride.type.map.${value}`) || notApplicable,
        filter: false,
      },
    },
    {
      name: 'no',
      label: t('model.ride.shortNo'),
      options: {
        customBodyRender: (value: string) => value || notApplicable,
        filter: false,
      },
    },
    {
      name: 'payout',
      label: t('model.ride.payout'),
      options: {
        customBodyRender: (value: string) => value || notApplicable,
        filter: false,
      },
    },
    {
      name: 'reason',
      label: t('model.ride.correctionReason.label'),
      options: {
        customBodyRender: (reason: { type: CorrectionType; value: string }) =>
          (reason.type === CorrectionTypeEnum.correction &&
            t(`model.ride.correctionReason.map.${reason.value}`)) ||
          notApplicable,
        filter: false,
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...useMUIDataTableDefaultOptions(true),
    search: false,
    viewColumns: false,
    filter: false,
    onRowClick: (rowData, { dataIndex }) => {
      const row = connectedCorrection[dataIndex];
      if (row) {
        history.push({
          pathname: generatePath(path, { driverId: driverId, rideId: row.id }),
        });
      }
    },
  };

  return <MUIDataTable columns={columns} data={corrections} title="" options={options} />;
};

export default RideCorrectionList;
