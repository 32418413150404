// Core libraries
import React, { useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ExitToApp } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { Card } from '@material-ui/core';

// Internal libs
import { selectors } from '../../store';
import { Invoice, InvoiceType } from '@features/company/model/Invoice';

// Internal components
import SimpleHeader from '@common/components/cardContent/SimpleHeader';
import SwipeableTabPanels from '@common/components/tabs/SwipeableTabPanels';
import SwipeableTabPanel from '@common/components/tabs/SwipeableTabPanel';
import InvoiceUserData from '@features/company/containers/InvoiceInfo/InvoiceUserData';
import InvoicesItemsDetails from '@features/company/containers/InvoiceInfo/InvoicesItemsDetails';
import InvoiceAttachments from '@features/company/containers/InvoiceInfo/InvoiceAttachments';
import InvoiceSummary from '@features/company/containers/InvoiceInfo/InvoiceSummary';
import InvoicePaymentInfo from '@features/company/containers/InvoiceInfo/InvoicePaymentInfo';
import InvoiceGenerateForm from '@features/company/containers/InvoiceInfo/InvoiceGenerateForm';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);
// Props type
type InvoicesDetailsProps = {};

const invoiceTabs = {
  generate: 'generate',
  seller: 'seller',
  purchaser: 'purchaser',
  payment: 'payment',
  details: 'details',
  summary: 'summary',
  attachments: 'attachments',
};

// Component
const InvoiceDetails = ({}: InvoicesDetailsProps) => {
  const { t } = useTranslation(['user', 'company', 'rides']);
  const history = useHistory();
  const styles = useStyles();
  const { invoiceId }: { invoiceId: string } = useParams();
  const selectedInvoice: Invoice | undefined = useSelector(selectors.selectInvoiceById(invoiceId));
  const isSettlement = () => selectedInvoice?.type === InvoiceType.Settlement;
  const [tab, setTab] = useState(isSettlement() ? invoiceTabs.generate : invoiceTabs.seller);

  return !selectedInvoice ? (
    <LinearProgress data-testid="rdv-loading" />
  ) : (
    <Card>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={12} md={6} lg={4}>
          <SimpleHeader
            title={`${t(`company:model.invoice.type.map.${selectedInvoice?.type}`)}: ${
              selectedInvoice?.no
            }`}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4} className={styles.root}>
          <IconButton onClick={history.goBack} title={t('company:invoices.title')}>
            <ExitToApp />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <TabContext value={tab}>
        <TabList
          variant={'scrollable'}
          onChange={(event, newValue) => {
            setTab(newValue);
          }}
        >
          <Tab
            value={invoiceTabs.generate}
            label={t('company:model.invoice.generate')}
            disabled={!isSettlement()}
          />
          <Tab value={invoiceTabs.seller} label={t('company:model.invoice.seller')} />
          <Tab value={invoiceTabs.purchaser} label={t('company:model.invoice.purchaser')} />
          <Tab value={invoiceTabs.payment} label={t('company:invoicesDetails.payment')} />
          <Tab value={invoiceTabs.details} label={t('company:model.invoice.details')} />
          <Tab value={invoiceTabs.summary} label={t('company:invoicesDetails.summary.sum')} />
          <Tab value={invoiceTabs.attachments} label={t('company:model.invoice.attachments')} />
        </TabList>
        <SwipeableTabPanels value={tab} onChangeValue={setTab} values={Object.keys(invoiceTabs)}>
          <SwipeableTabPanel value={invoiceTabs.generate}>
            <InvoiceGenerateForm invoice={selectedInvoice} />
          </SwipeableTabPanel>
          <SwipeableTabPanel value={invoiceTabs.seller}>
            <InvoiceUserData
              address={selectedInvoice.addresses[0]}
              name={selectedInvoice.companyName}
              taxIdentificationNumber={selectedInvoice.companyTaxIdentificationNumber}
              registrationNumber={selectedInvoice.companyRegistrationNumber}
              countryDefinition={selectedInvoice.countryDefinition}
              phone={selectedInvoice.companyPhone}
            />
          </SwipeableTabPanel>
          <SwipeableTabPanel value={invoiceTabs.purchaser}>
            <InvoiceUserData
              address={selectedInvoice.addresses[1]}
              name={selectedInvoice.payerName}
              taxIdentificationNumber={selectedInvoice.payerNip}
              registrationNumber={selectedInvoice.payerRegon}
              countryDefinition={{
                taxIdentificationNumberName: 'nip',
                registrationNumberName: 'regon',
              }}
            />
          </SwipeableTabPanel>
          <SwipeableTabPanel value={invoiceTabs.payment}>
            <InvoicePaymentInfo invoice={selectedInvoice} />
          </SwipeableTabPanel>
          <SwipeableTabPanel value={invoiceTabs.details}>
            <InvoicesItemsDetails items={selectedInvoice.items} />
          </SwipeableTabPanel>
          <SwipeableTabPanel value={invoiceTabs.summary}>
            <InvoiceSummary invoice={selectedInvoice} />
          </SwipeableTabPanel>
          <SwipeableTabPanel value={invoiceTabs.attachments}>
            <InvoiceAttachments invoice={selectedInvoice} />
          </SwipeableTabPanel>
        </SwipeableTabPanels>
      </TabContext>
    </Card>
  );
};

export default InvoiceDetails;
