import { PayloadAction } from '@reduxjs/toolkit';
import { PrivacyPolicyState } from '../model';

export const setIsPrivacyPolicyAccepted = (
  state: PrivacyPolicyState,
  action: PayloadAction<boolean>,
) => {
  state.isPrivacyPolicyAccepted = action.payload;
};

export const checkUserPrivacyPolicyInit = () => {};

const privacyPolicyReducers = {
  setIsPrivacyPolicyAccepted,
  checkUserPrivacyPolicyInit,
};
export default privacyPolicyReducers;
