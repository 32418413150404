// Core libraries
import React from 'react';

// External libs and components
import {
  Controller,
  useFormContext,
  Validate,
  ValidationRule,
  ValidationValueMessage,
} from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';

// Internal libs

// Internal components

// Masked Input
interface CustomTextMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const CustomTextMask = ({ inputRef, ...other }: CustomTextMaskProps) => (
  <MaskedInput
    guide={false}
    showMask
    {...other}
    ref={(ref: any) => {
      inputRef(ref ? ref.inputElement : null);
    }}
  />
);

type FormTextFieldProps = {
  name: string;
  id?: string;
  label?: string;
  helperText?: string;
  placeholder?: string;
  className?: string;
  defaultValue?: string;
  multiline?: boolean;
  rules?:
    | Partial<{
        required: string | boolean | ValidationValueMessage<boolean>;
        min: ValidationRule<React.ReactText>;
        max: ValidationRule<React.ReactText>;
        maxLength: ValidationRule<React.ReactText>;
        minLength: ValidationRule<React.ReactText>;
        pattern: ValidationRule<RegExp>;
        validate: Validate | Record<string, Validate>;
      }>
    | undefined;
  mask?: (string | RegExp)[];
  maskOptions?: { keepCharPosition?: boolean; placeholderChar?: string; guide?: boolean };
  fullWidth?: boolean;
  type?: string;
  errors?: string[];
  autoFocus?: boolean;
  InputLabelProps?: any;
  disabled?: boolean;
  inputProps?: any;
  forceRequiredMark?: boolean;
};

const FormTextField = ({
  name,
  id,
  label,
  helperText,
  placeholder,
  className,
  defaultValue = '',
  rules,
  mask,
  maskOptions,
  fullWidth = false,
  type,
  errors = [],
  multiline = false,
  autoFocus,
  InputLabelProps,
  disabled,
  inputProps,
  forceRequiredMark = false,
}: FormTextFieldProps) => {
  const form = useFormContext();

  const maskInputProps = mask
    ? { inputComponent: CustomTextMask as any, inputProps: { mask, ...(maskOptions || {}) } }
    : {};

  const hasError = !!form.errors[name] || !!errors.length;
  const hTexts = !hasError
    ? helperText
    : [
        ...(form.errors[name] ? [form.errors[name].message] : []),
        ...errors.map((error) => <div>{error}</div>),
      ];

  return (
    <Controller
      name={name}
      as={
        <TextField
          id={id || name}
          helperText={hTexts}
          label={`${label}${rules?.required || forceRequiredMark ? '*' : ''}`}
          error={hasError}
          fullWidth={fullWidth}
          className={className}
          InputProps={maskInputProps}
          inputProps={inputProps}
          multiline={multiline}
          type={type}
          placeholder={placeholder}
          autoFocus={autoFocus}
          InputLabelProps={InputLabelProps}
          disabled={disabled}
        />
      }
      control={form.control}
      defaultValue={defaultValue}
      rules={rules}
    />
  );
};

export default FormTextField;
