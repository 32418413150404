// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Alert from '@material-ui/lab/Alert';
import DownloadIcon from '@material-ui/icons/GetApp';
import UploadIcon from '@material-ui/icons/Publish';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { User } from '../../model/User';
import { CompanyDocument } from '../../model/CompanyDocument';
import { actions } from '../../store';

// Internal components
import DataListItem from '@common/components/DataListItem';
import DataItem from '@common/components/cardContent/DataItem';
import CompanyDocumentUploadModal from './CompanyDocumentUploadModal';

// Props type
type CompanyCardUploadDocumentsProps = {};

const useStyles = makeStyles((theme) =>
  createStyles({
    downloadFileWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

// Component
const CompanyCardUploadDocuments = ({}: CompanyCardUploadDocumentsProps) => {
  const { t } = useTranslation('user');
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { path } = useRouteMatch();

  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  const upload = () => history.push(`${path}/upload-company-document`);

  const [downloadState, setDownloadState] = useState<InProgressState & { id?: number }>({
    inProgress: false,
  });
  const download = async (document: CompanyDocument | undefined) => {
    if (!document) {
      return;
    }

    setDownloadState({ inProgress: true, id: document.id });
    try {
      await dispatch(actions.downloadCompanyDocument(document));
      setDownloadState({ inProgress: false, success: true, id: document.id });
    } catch (e) {
      setDownloadState({ inProgress: false, success: false, error: e, id: document.id });
    }
  };

  return (
    <>
      <DataItem
        text={
          <>
            {!userData?.company?.document && (
              <Alert
                severity="warning"
                action={
                  <Button variant="text" onClick={upload} color="inherit" size="small">
                    {t('cards.settlementsData.company.missingCompanyDocument.uploadButton')}
                  </Button>
                }
              >
                {t('cards.settlementsData.company.missingCompanyDocument.message')}
              </Alert>
            )}
            {userData?.company?.document && (
              <div className={styles.downloadFileWrapper}>
                {t(`company.fields.documentType.${userData.company.document.documentType}`)}
              </div>
            )}
          </>
        }
        label={t('company.fields.document')}
        action={
          <>
            {userData?.company?.document && (
              <IconButton
                edge="end"
                title={t('cards.settlementsData.company.downloadDocument')}
                aria-label={t('cards.settlementsData.company.downloadDocument')}
                color="primary"
                onClick={() => download(userData?.company?.document)}
                data-testid={`btn-download-${userData?.company?.document?.id}`}
              >
                <DownloadIcon />
              </IconButton>
            )}
            <IconButton
              edge="end"
              title={
                !userData?.company?.document
                  ? t('cards.settlementsData.company.uploadDocument')
                  : t('cards.settlementsData.company.uploadNewDocument')
              }
              aria-label={
                !userData?.company?.document
                  ? t('cards.settlementsData.company.uploadDocument')
                  : t('cards.settlementsData.company.uploadNewDocument')
              }
              color="secondary"
              onClick={upload}
              data-testid={`btn-upload-company-document`}
            >
              <UploadIcon />
            </IconButton>
          </>
        }
      />

      <Switch>
        <Route path={`${path}/upload-company-document`} component={CompanyDocumentUploadModal} />
      </Switch>
    </>
  );
};

export default CompanyCardUploadDocuments;
