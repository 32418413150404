import { RootThunk } from '@common/model';
import { invoiceActions } from '../slice';
import * as api from '../../../api';
import { ResponseInfo } from '@features/admin/invoices/model/ResponseInfo';
import {
  downloadZippedInvoicesFileInit,
  exportInvoicesToEdiFileInit,
} from '@features/admin/invoices/store/reducers';

export const getInvoices = (dateFrom: string, dateTo: string, status?: string): RootThunk => async (
  dispatch,
) => {
  dispatch(invoiceActions.getInvoicesInit({ dateFrom, dateTo, status }));

  try {
    const invoices = await api.getInvoices(dateFrom, dateTo, status);
    dispatch(invoiceActions.getInvoicesSuccess(invoices));
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(invoiceActions.getInvoicesFailure(error));
    throw error;
  }
};

export const generateTransfersFile = (invoicesId: string[]): RootThunk => async (dispatch) => {
  dispatch(invoiceActions.generatedTransfersFileInit({ invoicesId }));

  try {
    await api.generateTransfersFile(invoicesId);
    dispatch(invoiceActions.generatedTransfersFileSuccess());
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(invoiceActions.generatedTransfersFileFailure(error));
    throw error;
  }
};

export const exportInvoicesToEdiFile = (invoicesId: string[]): RootThunk => async (dispatch) => {
  dispatch(invoiceActions.exportInvoicesToEdiFileInit({ invoicesId }));

  try {
    await api.exportInvoicesToEdiFile(invoicesId);
    dispatch(invoiceActions.exportInvoicesToEdiFileSuccess());
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(invoiceActions.exportInvoicesToEdiFileFailure(error));
    throw error;
  }
};

export const downloadZippedInvoicesFile = (invoicesId: string[]): RootThunk => async (dispatch) => {
  dispatch(invoiceActions.downloadZippedInvoicesFileInit({ invoicesId }));

  try {
    await api.downloadZippedInvoicesFile(invoicesId);
    dispatch(invoiceActions.downloadZippedInvoicesFileSuccess());
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(invoiceActions.downloadZippedInvoicesFileFailure(error));
    throw error;
  }
};

export const generateInvoices = (dateFrom: string, dateTo: string): RootThunk => async (
  dispatch,
): Promise<ResponseInfo> => {
  dispatch(invoiceActions.generateInvoicesInit({ dateFrom, dateTo }));

  try {
    const generate = await api.generateInvoices(dateFrom, dateTo);
    dispatch(invoiceActions.generateInvoicesSuccess());
    return generate;
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(invoiceActions.generateInvoicesFailure(error));
    throw error;
  }
};
