// Core libraries
import React, { useCallback, useEffect, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import {
  CorrectionReasonEnum,
  CorrectionReasonType,
} from '@features/admin/drivers/model/CorrectionReason';
import FormSelectField from '@common/components/FormSelectField';
import { InProgressState } from '@common/model';
import { FormProvider, useForm } from 'react-hook-form';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormErrors from '@common/components/FormErrors';
import { XpressDeliveryCompany } from '@common/model/XpressDeliveryCompany';
import { actions } from '@features/admin';
import { useDispatch } from 'react-redux';
import ProgressButton from '@common/components/ProgressButton';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type DriversDeleteModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

// Component
const DriversDeleteModal = ({ open, onClose, onSubmit }: DriversDeleteModalProps) => {
  const { t } = useTranslation('admin');
  const [submitState, setSubmitState] = useState<InProgressState>({ inProgress: false });
  const form = useForm();

  return (
    <FormProvider {...form}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{t('driversDelete.titleText')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('driversDelete.bodyText')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <ProgressButton onClick={onClose} color="primary" disabled={submitState.inProgress}>
            {t('driversDelete.form.cancelButton')}
          </ProgressButton>
          <ProgressButton
            type="submit"
            onClick={onSubmit}
            color="primary"
            disabled={submitState.inProgress}
            loading={submitState.inProgress}
            autoFocus
          >
            {t('driversDelete.form.submitButton')}
          </ProgressButton>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default DriversDeleteModal;
