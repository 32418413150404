// Core libraries
import React from 'react';

// External libs and components
import CircularProgress from '@material-ui/core/CircularProgress';

// Internal libs
import { Comment } from '@common/model/Comment';

// Internal components
import CommentList from './CommentList';
import { InProgressState } from '@common/model';

// Props type
type CommentListContainerProps = {
  list: Comment[];
  state: InProgressState;
};

// Component
const CommentListContainer = ({ list, state }: CommentListContainerProps) => {
  return state.inProgress ? <CircularProgress /> : <CommentList list={list} />;
};

export default CommentListContainer;
