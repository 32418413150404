// Core libraries
import React, { useEffect, useMemo, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

// Internal libs
import useMUIDataTableDefaultOptions from '@common/miscHooks/useMUIDataTableDefaultOptions';
import { invoiceActions, invoiceSelectors } from '../store';
import { Invoice, InvoiceType } from '@features/company/model/Invoice';
import { textSearch } from '@common/tools/textSearch';
import { setDayEnd, setDayStart } from '@common/tools/dateUtils';

// Internal components
import TableFiltersProvider from '@common/components/filters/TableFiltersProvider';
import TableFiltersToolbar from '@common/components/filters/TableFiltersToolbar';
import InvoicesFilter, {
  InvoicesFilterData,
} from '@features/company/containers/invoices/InvoicesFilter';
import InvoiceToolbarSelect from '@features/admin/invoices/conteiners/InvoiceToolbarSelect';
import LinearProgress from '@material-ui/core/LinearProgress';
import InvoiceAttachments from '@features/company/containers/InvoiceInfo/InvoiceAttachments';
import { InvoiceStatusEnum } from '@common/model/InvoiceStatus';
import { find } from 'lodash';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type AdminInvoicesListProps = {
  filter?: InvoicesFilterData;
  setFilter: (values: InvoicesFilterData) => void;
};

// Component
const AdminInvoicesList = ({ filter, setFilter }: AdminInvoicesListProps) => {
  const { t } = useTranslation('company');
  const dispatch = useDispatch();
  const getInvoicesStatus = useSelector(invoiceSelectors.selectGetInvoicesState);
  const invoices = useSelector(invoiceSelectors.selectInvoices);
  const [searchText, setSearchText] = useState<string>('');

  const getType = (invoice: Invoice): string => {
    if (invoice.status === InvoiceStatusEnum.generated && invoice.type === InvoiceType.Invoice) {
      return t(`model.invoice.type.map.${invoice.type}`) + ' ' + t('model.invoice.paid');
    }
    return t(`model.invoice.type.map.${invoice.type}`);
  };

  const filteredInvoices = useMemo(() => {
    const from = filter?.dateFrom && new Date(filter.dateFrom);
    const to = filter?.dateTo && new Date(filter.dateTo);
    return (
      invoices
        // At least one date must be provided and be in given range.
        ?.filter(
          (invoice: Invoice) =>
            !(from || to) ||
            [invoice.soldAtTo, invoice.invoicedAt]
              .filter((date) => !!date)
              .map((dateStr: string) => new Date(dateStr))
              .some((date: Date) => (!from || date >= from) && (!to || date <= to)),
        )
        //  Search text
        .filter((invoice: Invoice) =>
          textSearch(searchText, invoice, (invoice) => [
            invoice.no,
            invoice.grossTotal.toString(),
            t(`model.invoice.type.map.${invoice.type}`),
            t(`model.invoice.status.map.${invoice.status}`),
          ]),
        )
        .map((invoice: any) => {
          return {
            ...invoice,
            invoice,
            isSelectable: invoice.type != InvoiceType.Settlement,
            type: getType(invoice),
          };
        }) || []
    );
  }, [invoices, filter, searchText]);

  const getInvoices = async () => {
    try {
      await dispatch(
        invoiceActions.getInvoices(
          new Date(setDayStart(filter?.dateFrom || new Date())).toISOString(),
          new Date(setDayEnd(filter?.dateTo || new Date())).toISOString(),
        ),
      );
    } catch (e) {}
  };

  useEffect(() => {
    if (!getInvoicesStatus?.inProgress) {
      getInvoices();
    }
  }, [filter]);

  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'id',
        options: {
          display: false,
          viewColumns: false,
          filter: false,
        },
      },
      {
        name: 'companyName',
        label: t('model.invoice.companyName'),
      },
      {
        name: 'no',
        label: t('model.invoice.no'),
      },
      {
        name: 'type',
        label: t('model.invoice.type.label'),
      },
      {
        name: 'netTotal',
        label: t('model.invoice.netTotal'),
        options: {
          customBodyRender: (value) => (
            <NumberFormat
              value={value}
              thousandSeparator={' '}
              displayType={'text'}
              decimalScale={2}
            />
          ),
        },
      },
      {
        name: 'grossTotal',
        label: t('model.invoice.grossTotal'),
        options: {
          customBodyRender: (value) => (
            <NumberFormat
              value={value}
              thousandSeparator={' '}
              displayType={'text'}
              decimalScale={2}
            />
          ),
        },
      },
      {
        name: 'currency',
        label: t('model.invoice.currency'),
      },
      {
        name: 'status',
        label: t('model.invoice.status.label'),
        options: {
          customBodyRender: (value) => t(`model.invoice.status.map.${value}`),
        },
      },
      {
        name: 'status',
        options: {
          display: false,
          filter: false,
          viewColumns: false,
          sort: false,
        },
      },
      {
        name: 'invoicedAt',
        label: t('model.invoice.invoicedAt'),
        options: {
          customBodyRender: (value: string) =>
            t('common:datetime.datetime', { date: new Date(value) }),
        },
      },
      {
        name: 'soldAtTo',
        label: t('model.invoice.soldAtTo'),
        options: {
          customBodyRender: (value: string) =>
            t('common:datetime.datetime', { date: new Date(value) }),
        },
      },
      {
        name: 'exportedAt',
        label: t('model.invoice.exportedAt'),
        options: {
          customBodyRender: (value: string) =>
            value ? t('common:datetime.datetime', { date: new Date(value) }) : '-',
        },
      },
      {
        name: 'invoice',
        label: t('invoices.download'),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (invoice: Invoice) => (
            <InvoiceAttachments invoice={invoice} showSectionLabels={false} />
            /*[

              <DownloadAction invoice={invoice} children={<InvoicesIcon />} />
            ]*/
          ),
        },
      },
    ],
    [t],
  );

  const options: MUIDataTableOptions = {
    ...useMUIDataTableDefaultOptions(false),
    search: false,
    download: true,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    selectableRows: 'multiple',
    selectToolbarPlacement: 'above',
    sortOrder: {
      name: 'no',
      direction: 'desc',
    },
    isRowSelectable: (dataIndex) => {
      return filteredInvoices[dataIndex].isSelectable;
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <InvoiceToolbarSelect
        selectedRows={selectedRows}
        displayData={displayData}
        invoicesInTable={filteredInvoices}
        dateRange={{
          dateFrom: filter?.dateFrom || new Date(),
          dateTo: filter?.dateTo || new Date(),
        }}
      />
    ),
    onDownload: (buildHead, buildBody, columns, data) => {
      const customRenderColumns = ['invoicedAt', 'soldAtTo'];
      columns.pop();
      data.map((invoice: any) => {
        invoice.data.pop();
        invoice.data = invoice.data.map((value: any, index: number) => {
          const renderer = columns[index];
          if (customRenderColumns.includes(renderer.name)) {
            return renderer.customBodyRender(value);
          }
          return value;
        });
        return invoice;
      });
      return `${buildHead(columns)}${buildBody(data)}`.trim();
    },
  };

  const updateFilter = (values: InvoicesFilterData) => {
    setFilter(values);
  };

  return (
    <>
      {getInvoicesStatus?.inProgress && <LinearProgress data-testid="loading-rides" />}
      <TableFiltersProvider
        filters={
          <InvoicesFilter
            onChange={updateFilter}
            overrideDateFrom={filter?.dateFrom || new Date()}
            maxDateTo={new Date()}
            onChangeSearchText={{ searchText, setSearchText }}
          />
        }
      >
        <MUIDataTable
          columns={columns}
          data={filteredInvoices}
          title=""
          options={options}
          components={{
            TableToolbar: TableFiltersToolbar,
          }}
        />
      </TableFiltersProvider>
    </>
  );
};

export default AdminInvoicesList;
