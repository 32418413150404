import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { UserState } from '../model';
import { Region } from '../../model/Region';

export const getRegionsInit = (state: UserState, action: Action) => {
  state.getRegionsState = {
    inProgress: true,
    error: undefined,
    success: undefined,
  };
};
export const getRegionsSuccess = (state: UserState, action: PayloadAction<Region[]>) => {
  state.getRegionsState = {
    inProgress: false,
    error: undefined,
    success: true,
  };
  state.regions = action.payload;
};
export const getRegionsFailure = (state: UserState, action: PayloadAction<FailureResponse>) => {
  state.getRegionsState = {
    inProgress: false,
    error: action.payload,
    success: false,
  };
  state.user = undefined;
};

const regionsReducers = {
  getRegionsInit,
  getRegionsFailure,
  getRegionsSuccess,
};
export default regionsReducers;
