// Core libraries
import React, { ReactNode, useState } from 'react';

// External libs and components
import clsx from 'clsx';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';

// Internal libs
import MainContext, { MainContextData } from '../MainContext';

// Internal components
import { ReactComponent as Logo } from '@assets/xd_logo_long.svg';
import AppBarMenu from './AppBarMenu';
import DrawerMenu from './DrawerMenu';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => {
  const transitionIn = {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  };

  const transitionOut = {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  };

  return createStyles({
    root: { display: 'flex', maxWidth: '100%' },
    appBar: {
      display: 'flex',
      flexDirection: 'row',
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      flexGrow: 1,
      display: 'flex',
      maxWidth: '100%',
    },

    // Logos
    rightLogo: {
      overflow: 'auto',
      flexGrow: 1,
      flexShrink: 1,
      display: 'flex',
      opacity: 1,
      transition: theme.transitions.create(['opacity'], transitionIn),
    },
    hideRightLogo: {
      opacity: 0,
      transition: theme.transitions.create(['opacity'], transitionOut),
    },
    leftLogo: {
      overflow: 'hidden',
      display: 'flex',
      width: 0,
      backgroundColor: theme.palette.primary.dark,
      opacity: 0,

      transition: theme.transitions.create(['width', 'opacity'], transitionOut),
    },
    showLeftLogo: {
      width: `${drawerWidth}px`,
      opacity: 1,

      transition: theme.transitions.create(['width', 'opacity'], transitionIn),
    },
    logo: {
      flexShrink: 0,
      margin: theme.spacing(1),
      maxHeight: theme.spacing(4),
      maxWidth: `calc(${drawerWidth}px - ${theme.spacing(8)}px)`,
    },
    logoRight: {
      flexShrink: 1,
    },
    menuButton: {
      marginLeft: theme.spacing(-2.5),
      marginRight: theme.spacing(1),
      transition: theme.transitions.create(['margin-left'], transitionIn),
    },
    moveMenuButton: {
      marginLeft: theme.spacing(-9),
      transition: theme.transitions.create(['margin-left'], transitionOut),
    },

    title: { flexGrow: 1 },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      zIndex: `${theme.zIndex.drawer} !important` as any,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', transitionIn),
    },
    drawerClose: {
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      transition: theme.transitions.create('width', transitionOut),
    },
    drawerContent: {
      paddingTop: theme.spacing(6),
    },
    content: {
      flexGrow: 1,
      marginTop: theme.spacing(6),
      overflow: 'hidden',
    },
  });
});

interface MainPageProps {
  children: ReactNode;
}

const MainPage = ({ children }: MainPageProps) => {
  const theme = useTheme();
  const styles = useStyles();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isDrawerPermanent = useMediaQuery(theme.breakpoints.up('sm'));

  const [mainContextData] = useState<MainContextData>(() => {
    return new MainContextData();
  });

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const onSelect = () => {
    if (isDrawerOpen && !isDrawerPermanent) {
      closeDrawer();
    }
  };

  return (
    <MainContext.Provider value={mainContextData}>
      <div className={styles.root}>
        <CssBaseline />

        <AppBar position="fixed" className={styles.appBar}>
          <div
            className={clsx(styles.leftLogo, {
              [styles.showLeftLogo]: isDrawerOpen && isDrawerPermanent,
            })}
          >
            <Logo className={styles.logo} />
          </div>
          <Toolbar variant="dense" className={styles.toolbar}>
            <IconButton
              edge="start"
              className={clsx(styles.menuButton, {
                [styles.moveMenuButton]: isDrawerOpen && isDrawerPermanent,
              })}
              color="inherit"
              aria-label="menu"
              onClick={() => toggleDrawer()}
            >
              <MenuIcon />
            </IconButton>

            <div
              className={clsx(styles.rightLogo, {
                [styles.hideRightLogo]: isDrawerOpen && isDrawerPermanent,
              })}
            >
              <Logo className={clsx([styles.logo, styles.logoRight])} />
            </div>
            <AppBarMenu />
          </Toolbar>
        </AppBar>

        {/* Left menu */}
        <Drawer
          variant={isDrawerPermanent ? 'permanent' : 'temporary'}
          open={isDrawerPermanent || isDrawerOpen}
          className={clsx(styles.drawer, {
            [styles.drawerOpen]: isDrawerOpen,
            [styles.drawerClose]: !isDrawerOpen,
          })}
          classes={{
            paper: clsx(styles.drawerContent, {
              [styles.drawerOpen]: isDrawerOpen,
              [styles.drawerClose]: !isDrawerOpen,
            }),
          }}
          onBackdropClick={closeDrawer}
        >
          <DrawerMenu onSelect={onSelect} />
        </Drawer>

        {/* Content */}
        <div className={styles.content}>{children}</div>
      </div>
    </MainContext.Provider>
  );
};

export default MainPage;
