// Core libraries
import React, { useCallback, useEffect, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router';
import Card from '@material-ui/core/Card';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// Internal libs
import { InProgressState, PaginatedData } from '@common/model';
import { actions } from '../../store';
import { Ride } from '@features/rides/model';

// Internal components
import SwipeableTabPanels from '@common/components/tabs/SwipeableTabPanels';
import SwipeableTabPanel from '@common/components/tabs/SwipeableTabPanel';
import TabList from '@material-ui/lab/TabList';
import {
  CorrectionTypeEnum,
  isCorrectionTypeMap,
} from '@features/admin/drivers/model/CorrectionType';
import Grid from '@material-ui/core/Grid';
import TabContext from '@material-ui/lab/TabContext';
import { Driver } from '@common/model/Driver';
import DriverProfileBasicInfo from '@features/admin/drivers/conteiners/driverProfile/DriverProfileBasicInfo';
import DriverRides from '@features/admin/drivers/conteiners/driverRides/DriverRides';
import DriverAllowedCompanies from '@features/admin/drivers/conteiners/driverProfile/DriverAllowedCompanies';
import CommentsContainer from '@features/admin/components/CommentsContainer/CommentsContainer';
import DriverCommentsTab from '@features/admin/drivers/conteiners/driverProfile/DriverCommentsTab';
// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  }),
);

// Props type
type DriverProfilePageProps = {};

// Component
const DriverProfilePage = () => {
  const { t } = useTranslation(['rides', 'admin']);
  const styles = useStyles();
  const [tab, setTab] = useState('rides');
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const { driverId }: { driverId: string } = useParams();
  const [getDriverProfileState, setGetDriverProfileState] = useState<InProgressState>({
    inProgress: false,
  });
  const [driverProfile, setDriverProfile] = useState<Driver | undefined>(undefined);

  const getDriverProfile = useCallback(async () => {
    setGetDriverProfileState({ inProgress: true });
    try {
      const driverProfile: Driver = ((await dispatch(
        actions.getDriverProfile(+driverId),
      )) as unknown) as Driver;
      setDriverProfile(driverProfile);
      setGetDriverProfileState({ inProgress: false, success: true });
    } catch (e) {
      setGetDriverProfileState({ inProgress: false, success: false, error: e });
    }
  }, [dispatch, driverId]);

  // Init
  useEffect(() => {
    getDriverProfile();
  }, [driverId, getDriverProfile]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} lg={3}>
        <DriverProfileBasicInfo driver={driverProfile} />
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <TabContext value={tab}>
          <TabList
            onChange={(event, newValue) => {
              setTab(newValue);
            }}
          >
            <Tab value="rides" label={t('admin:driverProfile.menu.rides')} />
            <Tab value="allowedCompanies" label={t('admin:driverProfile.menu.allowedCompanies')} />
            <Tab value="comments" label={t('admin:driverProfile.menu.comments')} />
          </TabList>
          <SwipeableTabPanels
            value={tab}
            onChangeValue={setTab}
            values={['rides', 'allowedCompanies', 'comments']}
          >
            <SwipeableTabPanel value="rides">
              <DriverRides />
            </SwipeableTabPanel>
            <SwipeableTabPanel value="allowedCompanies">
              <DriverAllowedCompanies
                allowedCompanies={driverProfile?.allowedCompanies}
                afterSuccessSubmit={getDriverProfile}
              />
            </SwipeableTabPanel>
            <SwipeableTabPanel value="comments">
              <DriverCommentsTab driverId={driverId} />
            </SwipeableTabPanel>
          </SwipeableTabPanels>
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default DriverProfilePage;
