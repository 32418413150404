export interface timestampable {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export interface timestampableSrc {
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export const timestampableFromSrc = (src: timestampableSrc): timestampable => ({
  createdAt: src.created_at,
  updatedAt: src.updated_at,
  deletedAt: src.deleted_at || undefined,
});

export const timestampableToSrc = (src: timestampable): timestampableSrc => ({
  created_at: src.createdAt,
  updated_at: src.updatedAt,
  deleted_at: src.deletedAt || null,
});
