import initReducers from './init';
import loginReducers from './login';
import registerReducers from './register';
import passwordResetReducers from './passwordReset';

// Reducers object
const reducers = {
  ...initReducers,
  ...loginReducers,
  ...registerReducers,
  ...passwordResetReducers,
};

export default reducers;
