import { RootThunk } from '@common/model';
import { actions as simpleActions } from '../slice';
import * as api from '../../api';
import { actions as userActions } from '@features/user';
import { Partner } from '@features/user/model/Partner';

export const findPartner = (tin: string): RootThunk => async (dispatch): Promise<Partner> => {
  dispatch(simpleActions.findPartnerInit(tin));

  try {
    const partner = await api.findPartner(tin);
    dispatch(simpleActions.findPartnerSuccess(partner));
    return partner;
  } catch (e) {
    dispatch(simpleActions.findPartnerFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};

export const joinPartner = (partnerCompanyId: number): RootThunk => async (dispatch) => {
  dispatch(simpleActions.joinPartnerInit(partnerCompanyId));

  try {
    const response = await api.joinPartner(partnerCompanyId);
    dispatch(simpleActions.joinPartnerSuccess());
    dispatch(userActions.getCurrentUser());
  } catch (e) {
    dispatch(simpleActions.joinPartnerFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};

export const cancelPartnerRequest = (partnerRequestId: number): RootThunk => async (dispatch) => {
  dispatch(simpleActions.cancelPartnerRequestInit(partnerRequestId));

  try {
    const response = await api.cancelPartnerRequest(partnerRequestId);
    dispatch(simpleActions.cancelPartnerRequestSuccess());
    dispatch(userActions.getCurrentUser());
  } catch (e) {
    dispatch(simpleActions.cancelPartnerRequestFailure(e.response?.data || { error: 'unknown' }));
    throw e.response?.data || { error: 'unknown' };
  }
};
