import { actions as simpleActions } from '../slice';

import * as loginActions from './login';
import * as registerActions from './register';
import * as initActions from './init';
import * as passwordResetActions from './passwordReset';

export const actions = {
  ...initActions,
  ...loginActions,
  ...registerActions,
  ...passwordResetActions,
  ...simpleActions,
};
