// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import LinearProgress from '@material-ui/core/LinearProgress';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { User } from '../model/User';

// Internal components
import DataListItem from '@common/components/DataListItem';
import { numberToPhone } from '@common/validators/phoneValidator';
import { selectors as userSelectors } from '../store';

const ContactDataCard = () => {
  const { t } = useTranslation('user');
  const history = useHistory();
  const { path } = useRouteMatch();

  const userDataState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.user.getUserState,
  );
  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  const missingPhone = useSelector(userSelectors.selectUserMissingPhone);
  const missingPhoneVerification = useSelector(userSelectors.selectUserMissingPhoneVerification);

  const missingEmailVerification = useSelector(userSelectors.selectUserMissingEmailVerification);

  return (
    <Card>
      <CardHeader title={t('cards.contactData.title', '')} />
      <Divider />
      <CardContent>
        {userDataState?.inProgress && <LinearProgress />}
        {userData && (
          <List>
            {/* Email */}
            {missingEmailVerification && (
              <Alert
                severity="warning"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => history.push(`${path}/email/verify`)}
                    data-testid="missing-email-verify-button"
                  >
                    {t('cards.contactData.missingEmailVerification.action')}
                  </Button>
                }
                data-testid="missing-email-verification-alert"
              >
                {t('cards.contactData.missingEmailVerification.message')}
              </Alert>
            )}
            <DataListItem label={t('user.fields.email', '')} text={userData.email || '-'} />

            {/* Phone */}
            {missingPhone && (
              <Alert
                severity="warning"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => history.push(`${path}/phone/edit`)}
                    data-testid="missing-phone-edit-button"
                  >
                    {t('cards.contactData.missingPhone.action')}
                  </Button>
                }
                data-testid="missing-phone-alert"
              >
                {t('cards.contactData.missingPhone.message')}
              </Alert>
            )}
            {!missingPhone && missingPhoneVerification && (
              <Alert
                severity="warning"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => history.push(`${path}/phone/verify`)}
                    data-testid="missing-phone-verify-button"
                  >
                    {t('cards.contactData.missingPhoneVerification.action')}
                  </Button>
                }
                data-testid="missing-phone-verification-alert"
              >
                {t('cards.contactData.missingPhoneVerification.message')}
              </Alert>
            )}
            <DataListItem
              label={t('user.fields.phone', '')}
              text={userData.phone ? numberToPhone(userData.phone, userData.phonePrefix) : '-'}
              actions={
                <>
                  <IconButton
                    onClick={() =>
                      history.push(
                        !userData?.phoneVerifiedAt
                          ? `${path}/phone/edit`
                          : `${path}/phone-change/request`,
                      )
                    }
                    data-testid="phone-edit-button"
                  >
                    <EditIcon />
                  </IconButton>
                </>
              }
            />
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default ContactDataCard;
