import { createSlice } from '@reduxjs/toolkit';
import * as reducers from './reducers';
import { DriversState } from '@features/admin/drivers/store/model';
import { InProgressState } from '@common/model';

const driversSlice = createSlice<DriversState, typeof reducers>({
  name: 'drivers',
  initialState: {
    getDriversState: undefined,
    driversList: undefined,
    driversListPaginated: undefined,
    getDriverState: {
      inProgress: false,
    },
    getDriversFilters: {
      page: 1,
      itemsPerPage: 50,
      showDeleted: false,
    },
    getDriverCommentsState: {
      inProgress: false,
    },
    updateDriverState: {
      inProgress: false,
    },
    xpressDeliveryCompanies: undefined,
    getXpressDeliveryCompaniesState: {
      inProgress: false,
    },
  },
  reducers: {
    ...reducers,
  },
});

export const reducer = driversSlice.reducer;
export const actions = driversSlice.actions;
