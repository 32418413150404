export enum InvoiceStatusEnum {
  created = 'created',
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
  generated = 'generated',
}

export type InvoiceStatus =
  | InvoiceStatusEnum.created
  | InvoiceStatusEnum.pending
  | InvoiceStatusEnum.accepted
  | InvoiceStatusEnum.rejected
  | InvoiceStatusEnum.generated;
