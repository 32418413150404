import { RootState } from '@app/store';
import { Middleware } from 'redux';
import { actions as redirectActions } from '@features/redirect/store';
import { actions as authActions } from '../slice';
import { actions as userActions } from '@features/user';

const onLoginSuccessMiddleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
  next(action);

  switch (action.type) {
    case authActions.loginSuccess.type:
    case authActions.loginSocialConfirmTokenSuccess.type:
    case authActions.registerSuccess.type:
      // Store token
      localStorage.setItem('authToken', action.payload);

      // Get current user
      // @ts-ignore
      store.dispatch(userActions.getCurrentUser());

      //  Redirect to Main page
      // @ts-ignore
      store.dispatch(redirectActions.redirectTo('/'));
      break;
  }
};

export const middlewares: Middleware[] = [onLoginSuccessMiddleware];
