import { RootThunk } from '@common/model';
import { DriverJoinRequest, PartnerDriver } from '../../model';
import { actions as simpleActions } from '../slice';
import * as api from '../../api';

// Get Pending Driver Join Requests
export const getPendingDriverJoinRequests = (partnerCompanyId: number): RootThunk => async (
  dispatch,
): Promise<DriverJoinRequest[]> => {
  dispatch(simpleActions.getPendingDriverJoinRequestsInit(partnerCompanyId));

  try {
    const pendingRequests = await api.getPartnerPendingRequests(partnerCompanyId);
    dispatch(simpleActions.getPendingDriverJoinRequestsSuccess(pendingRequests));

    return pendingRequests;
  } catch (e) {
    dispatch(
      simpleActions.getPendingDriverJoinRequestsFailure(e.response?.data || { errors: 'unknown' }),
    );
    throw e.response?.data || { errors: 'unknown' };
  }
};

// Accept Driver Join Request
export const acceptDriverJoinRequest = (joinRequestId: number): RootThunk => async (dispatch) => {
  dispatch(simpleActions.acceptDriverJoinRequestInit(joinRequestId));

  try {
    const response = await api.acceptPartnerPendingRequest(joinRequestId);
    dispatch(simpleActions.acceptDriverJoinRequestSuccess());
  } catch (e) {
    dispatch(
      simpleActions.acceptDriverJoinRequestFailure(e.response?.data || { errors: 'unknown' }),
    );
    throw e.response?.data || { errors: 'unknown' };
  }
};

// Reject Driver Join Request
export const rejectDriverJoinRequest = (joinRequestId: number): RootThunk => async (dispatch) => {
  dispatch(simpleActions.rejectDriverJoinRequestInit(joinRequestId));

  try {
    const response = await api.rejectPartnerPendingRequest(joinRequestId);
    dispatch(simpleActions.rejectDriverJoinRequestSuccess());
  } catch (e) {
    dispatch(
      simpleActions.rejectDriverJoinRequestFailure(e.response?.data || { errors: 'unknown' }),
    );
    throw e.response?.data || { errors: 'unknown' };
  }
};

// Remove driver-partner from your partner profile
export const removeDriverPartner = (
  partnerCompanyId: number,
  driverId: number,
  terminationComment?: string,
): RootThunk => async (dispatch) => {
  dispatch(
    simpleActions.removeDriverPartnerInit({ partnerCompanyId, driverId, terminationComment }),
  );

  try {
    const response = await api.removeDriverPartner(partnerCompanyId, driverId, terminationComment);
    dispatch(simpleActions.removeDriverPartnerSuccess());
  } catch (e) {
    dispatch(simpleActions.removeDriverPartnerFailure(e.response?.data || { errors: 'unknown' }));
    throw e.response?.data || { errors: 'unknown' };
  }
};

// Get Partner Drivers
export const getPartnerDrivers = (partnerCompanyId: number): RootThunk => async (
  dispatch,
): Promise<PartnerDriver[]> => {
  dispatch(simpleActions.getPartnerDriversInit(partnerCompanyId));

  try {
    const drivers = await api.getPartnerDrivers(partnerCompanyId);
    dispatch(simpleActions.getPartnerDriversSuccess(drivers));

    return drivers;
  } catch (e) {
    dispatch(simpleActions.getPartnerDriversFailure(e?.response?.data || { errors: 'unknown' }));
    throw e?.response?.data || { errors: 'unknown' };
  }
};
