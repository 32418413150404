// Core libraries
import React, {useMemo} from 'react';

// External libs and components
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import {useTranslation} from 'react-i18next';
import InvoicesIcon from '@material-ui/icons/DescriptionOutlined';
import Grid from '@material-ui/core/Grid';

// Internal libs
import {Attachment, Invoice, InvoiceType} from '@features/company/model/Invoice';

// Internal components
import DownloadAction from '@features/company/containers/invoices/DownloadAction';
import DataItem from '@common/components/cardContent/DataItem';
import PdfFileIcon from '@common/components/icons/PdfFile';
import CsvFileIcon from '@common/components/icons/CsvFile';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    download: {
      marginTop: 15,
    },
  }),
);

const getDownloadLink = (type: string, attachments: Attachment[]): string | undefined =>
  attachments?.find((attachment) => attachment.type === type)?.download_link;

// Props type
type InvoiceAttachmentsProps = {
  invoice: Invoice,
  showSectionLabels?: boolean,
};

// Component
const InvoiceAttachments = ({ invoice, showSectionLabels = true }: InvoiceAttachmentsProps) => {
  const { t } = useTranslation(['user', 'company']);
  const styles = useStyles();
  const pdfAttachment = useMemo(() => getDownloadLink('rides_pdf', invoice.attachments), [
    invoice.attachments,
  ]);
  const csvAttachment = useMemo(() => getDownloadLink('rides_csv', invoice.attachments), [
    invoice.attachments,
  ]);

  return (
    <Grid container spacing={3} justify="space-between" alignItems="flex-start">
      <Grid item xs={12} md={6} lg={4}>
        {
          showSectionLabels &&
          [
            <DataItem text={t('company:model.invoice.downloadRides')} />,
            <Divider />
          ]
        }

        {pdfAttachment && (

            <DownloadAction
              invoice={invoice}
              children={<PdfFileIcon />}
              link={pdfAttachment}
              title={t('company:model.invoice.ridesPdf')}
            />
        )}
        {csvAttachment && (
            <DownloadAction
              invoice={invoice}
              children={<CsvFileIcon />}
              link={csvAttachment}
              title={t('company:model.invoice.ridesCsv')}
            />
        )}
      </Grid>
      {
        invoice.type === InvoiceType.Invoice && <Grid item xs={12} md={6} lg={4}>
          {
            showSectionLabels &&
            [
              <DataItem text={t('company:model.invoice.download')} />,
              <Divider />
            ]
          }
          <DownloadAction invoice={invoice}
                          children={<InvoicesIcon />}
                          title={t('company:model.invoice.invoicePdf')}
          />
        </Grid>
      }

    </Grid>
  );
};

export default InvoiceAttachments;
