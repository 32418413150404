// Core libraries
import React from 'react';

// External libs and components
import { Route, Switch } from 'react-router-dom';

// Internal libs
import { Main } from '@features/main';

// Internal components

import { AnonymousRoute, Login, PrivateRoute, Register, SetNewPasswordPage } from '@features/auth';
import { DriverRouting, DriverMenu } from '@features/driver';
import { EmailVerifyPage, RoleRoute } from '@features/user';
import { PrivacyPolicyPage } from '@features/privacyPolicy';
import HasRoles from '@features/user/sharedComponents/HasRoles';
import { AdminMenu, AdminRouting } from '@features/admin';

const Routing = () => (
  <Switch>
    {/* Email verification handler*/}
    <Route path="/action/verify-email/:tokenId/:token" children={<EmailVerifyPage />} />

    {/* Set new password link handler (password reset) */}
    <Route path="/action/reset_password/:tokenId/:token" children={<SetNewPasswordPage />} />

    {/*<Route path="/privacy-policy" children={<PrivacyPolicyPage />} />*/}

    <AnonymousRoute path="/login" redirect="/" children={<Login />} />
    <AnonymousRoute exact path="/register" redirect="/" children={<Register />} />
    <PrivateRoute path="/" redirect="/login">
      <Main>
        <HasRoles roles="ADMIN">
          <AdminMenu basePath="/admin" />
        </HasRoles>
        <HasRoles roles="DRIVER">
          <DriverMenu />
        </HasRoles>

        <Switch>
          <Route path="/no-access">
            <></>
          </Route>
          <RoleRoute path="/admin" redirect={'/no-access'} roles={'ADMIN'}>
            <AdminRouting basePath="/admin" />
          </RoleRoute>
          <RoleRoute path="/" redirect={'/admin'} roles={'DRIVER'}>
            <DriverRouting />
          </RoleRoute>
        </Switch>
      </Main>
    </PrivateRoute>
  </Switch>
);

export default Routing;
