// Core libraries
import React from 'react';

// External libs and components
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import { StepIconProps } from '@material-ui/core/StepIcon';

// Internal libs
import { ThemeColorName } from '@common/components/theme/xdThemeColor';

// Internal components

// Styles hook
const useStyles = makeStyles<Theme, { color?: ThemeColorName }>((theme) =>
  createStyles({
    completed: ({ color }) => ({
      ...(color && theme.palette[color]?.main
        ? // @ts-ignore
          { color: lighten(theme.palette[color]?.main, 0.25) }
        : { color: theme.palette.primary.light }),
    }),
    active: ({ color }) => ({
      ...(color && theme.palette[color]?.main
        ? { color: theme.palette[color]?.main }
        : { color: theme.palette.primary.main }),
    }),
    inactive: {
      color: theme.palette.disabled?.main,
    },
  }),
);

const stepIconComponentFactory = (
  icon: React.ElementType,
  color?: ThemeColorName,
): React.ElementType => {
  const StepIconComponent = ({ completed, active }: StepIconProps) => {
    const styles = useStyles({ color });
    const Icon = icon;

    return (
      <Icon
        className={clsx({
          [styles.inactive]: !active && !completed,
          [styles.active]: active,
          [styles.completed]: completed,
        })}
      />
    );
  };

  return StepIconComponent;
};

export default stepIconComponentFactory;
