// Core libraries
import React, { useEffect, useMemo, useState } from 'react';

// External libs and components
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';

// Internal libs
import { RootState } from '@app/store';
import { actions } from '@features/auth/store';
import { RegisterState } from '@features/auth/store/model';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import useEmailValidator from '@common/validators/emailValidator';
import usePasswordValidator from '@common/validators/passwordValidator';
import usePasswordConfirmationValidator from '@common/validators/passwordConfirmationValidation';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import FormTextField from '@common/components/FormTextField';
import FormSelectField from '@common/components/FormSelectField';
import FormCheckboxField from '@common/components/FormCheckboxField';
import FormErrors from '@common/components/FormErrors';
import { PrivacyPolicyModal } from '@features/privacyPolicy';
import Link from '@material-ui/core/Link';
import { Country } from '@common/model/Country';
import config from '@app/config';

interface RegisterFormProps {
  countries: Country[];
  defaultCountry?: string;
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(2),
  },
}));

const RegisterForm = ({ countries, defaultCountry }: RegisterFormProps) => {
  const { t, i18n } = useTranslation(['auth', 'common']);
  const form = useForm();
  const dispatch = useDispatch();
  const registerState = useSelector<RootState, RegisterState | undefined>(
    (state) => state.auth.register,
  );
  const watchCountry = form.watch('country');
  useEffect(() => {
    const country = countries.find((country) => country.id === watchCountry);
    if (country) {
      i18n.changeLanguage(country.language);
    }
  }, [watchCountry]);
  const userCountry = useMemo(
    () => countries.find((country) => country.code === (defaultCountry ?? '').toLowerCase()),
    [countries, defaultCountry],
  );
  useEffect(() => {
    if (userCountry) {
      form.setValue('country', userCountry.id);
      i18n.changeLanguage(userCountry.code);
    }
  }, [userCountry]);

  const { formErrors, getFieldErrors } = useResponseErrors(
    registerState?.error,
    'register',
    'auth',
  );

  const styles = useStyles();

  const onSubmit = (values: any) => {
    dispatch(
      actions.register({
        email: values.email,
        password: values.password,
        privacyPolicyAccepted: values.privacyPolicyAccepted,
        countryId: values.country,
        locale: i18n.language,
      }),
    );
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {/* Email */}
        <FormTextField
          className={styles.field}
          name="email"
          label={t('register.fields.email.label', '')}
          rules={{
            required: {
              value: true,
              message: t('register.fields.email.errors.required', ''),
            },
            validate: {
              email: useEmailValidator(t('register.fields.email.errors.email', '')),
            },
          }}
          fullWidth={true}
          errors={getFieldErrors('email')}
        />

        {/* Password */}
        <FormTextField
          className={styles.field}
          name="password"
          label={t('register.fields.password.label', '')}
          rules={{
            required: {
              value: true,
              message: t('register.fields.password.errors.required', ''),
            },
            minLength: {
              value: 7,
              message: t('register.fields.password.errors.minLength', ''),
            },
            validate: {
              password: usePasswordValidator(t('register.fields.password.errors.pattern', '')),
            },
          }}
          fullWidth={true}
          type="password"
          errors={getFieldErrors('password')}
        />

        {/* Password confirmation*/}
        <FormTextField
          className={styles.field}
          name="passwordConfirmation"
          label={t('register.fields.passwordConfirmation.label', '')}
          rules={{
            required: {
              value: true,
              message: t('register.fields.passwordConfirmation.errors.required', ''),
            },
            validate: {
              matchPassword: usePasswordConfirmationValidator(
                form.getValues,
                'password',
                t('register.fields.passwordConfirmation.errors.mismatch', ''),
              ),
            },
          }}
          fullWidth={true}
          type="password"
          errors={getFieldErrors('passwordConfirmation', 'password_confirmation')}
        />

        {/* Country selector */}
        <FormSelectField
          className={styles.field}
          name="country"
          label={t('auth:register.fields.country.label', '')}
          options={countries.map((country) => ({
            label: t(`common:countries.${country.code}`),
            value: country.id,
          }))}
          rules={{
            required: {
              value: true,
              message: t('auth:register.fields.country.errors.required', ''),
            },
          }}
          fullWidth={true}
          errors={getFieldErrors('country', 'country_id')}
          defaultValue={userCountry?.id}
        />

        {/* Consent Privacy Policy */}
        <FormCheckboxField
          data-testid="register-privacy-policy-accepted"
          className={styles.field}
          name="privacyPolicyAccepted"
          label={
            <Trans t={t} i18nKey="register.fields.privacyPolicyAccepted.label">
              {' '}
              <Link href={config.privacyPolicyUrl} target="_blank" />{' '}
            </Trans>
          }
          rules={{
            required: {
              value: true,
              message: t('register.fields.privacyPolicyAccepted.errors.required'),
            },
          }}
          fullWidth={true}
          errors={getFieldErrors('privacyPolicyAccepted', 'privacy_policy_accepted')}
        />

        {/* Errors */}
        <FormErrors errorMessages={formErrors} />

        {/* Submit Button */}
        <ProgressButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth={true}
          disabled={registerState?.inProgress}
          loading={registerState?.inProgress}
        >
          {t('register.submit.caption', '')}
        </ProgressButton>
      </form>
    </FormProvider>
  );
};

export default RegisterForm;
