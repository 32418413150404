// Core libraries
import React, { useEffect, useMemo, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { useShowSnackbar } from '@features/snackbars';
import { actions, selectors } from '@features/user';
import { actions as contractActions, selectors as contractSelectors } from '@features/contracts';

// Internal components
import ProgressButton from '@common/components/ProgressButton';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(2),
    },
    error: {
      color: theme.palette.error.main,
    },
  }),
);

// Props type
type ContractLanguageConfirmationModalProps = {
  isModalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  setContractLanguage: (lang: string, terminatePreviousContracts: boolean) => void;
  selectedLanguage: string;
};

// Component
const ContractLanguageConfirmationModal = (props: ContractLanguageConfirmationModalProps) => {
  const { t } = useTranslation(['user', 'common']);
  const showSnackbar = useShowSnackbar();

  const setLanguageState = useSelector(selectors.selectSetContractLanguageState);

  const changeLanguage = async () => {
    try {
      await props.setContractLanguage(props.selectedLanguage, true);
      showSnackbar({
        message: t('user:contractLanguageConfirmation.success'),
        severity: 'success',
      });
      props.setModalOpen(false);
    } catch (e) {}
  };

  return (
    <Dialog open={props.isModalOpen} fullWidth>
      <DialogTitle>{t('user:contractLanguageConfirmation.title')}</DialogTitle>
      <Divider />
      <DialogContent>
        {setLanguageState?.inProgress && <LinearProgress />}
        <DialogContentText>{t('user:contractLanguageConfirmation.body')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <>
          <Button onClick={() => props.setModalOpen(false)} data-testid="cancel-button">
            {t('user:contractLanguageConfirmation.cancelButton')}
          </Button>
          <ProgressButton
            variant="contained"
            color="primary"
            onClick={changeLanguage}
            disabled={setLanguageState?.inProgress}
            loading={setLanguageState?.inProgress}
            data-testid="request-button"
          >
            {t(`user:contractLanguageConfirmation.confirmButton`)}
          </ProgressButton>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default ContractLanguageConfirmationModal;
