import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { UserState } from '../model';
import { AccountType } from '@features/user/model/Settlements';

export const setUserAccountTypeInit = (state: UserState, action: PayloadAction<AccountType>) => {};
export const setUserAccountTypeSuccess = (state: UserState, action: Action) => {};
export const setUserAccountTypeFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

export const resignPartnerInit = (state: UserState, action: Action) => {};
export const resignPartnerSuccess = (state: UserState, action: Action) => {};
export const resignPartnerFailure = (
  state: UserState,
  action: PayloadAction<FailureResponse>,
) => {};

const settlementsReducers = {
  setUserAccountTypeInit,
  setUserAccountTypeSuccess,
  setUserAccountTypeFailure,
  resignPartnerInit,
  resignPartnerFailure,
  resignPartnerSuccess,
};
export default settlementsReducers;
