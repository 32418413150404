export enum CommentSeverityEnum {
  Info = 'info',
  Negative = 'negative',
  Positive = 'positive',
}

export interface Comment {
  id: number;
  body: string;
  severity: CommentSeverityEnum;
  createdAt: Date;
}

export interface CommentSrc {
  id: number;
  body: string;
  severity: CommentSeverityEnum;
  created_at: string;
}

export const commentFromSrc = (commentSrc: CommentSrc): Comment => ({
  id: commentSrc.id,
  body: commentSrc.body,
  severity: commentSrc.severity,
  createdAt: new Date(commentSrc.created_at),
});

export const commentToSrc = (comment: Comment): CommentSrc => ({
  id: comment.id,
  severity: comment.severity,
  body: comment.body,
  created_at: comment.createdAt.toString(),
});
