import { PartnerState } from '../model';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { DriverJoinRequest, PartnerDriver } from '../../model';

// Get Pending Driver Join Requests
export const getPendingDriverJoinRequestsInit = (
  state: PartnerState,
  action: PayloadAction<number>,
) => {
  state.getPendingDriverJoinRequestsState = {
    inProgress: true,
  };
};
export const getPendingDriverJoinRequestsSuccess = (
  state: PartnerState,
  action: PayloadAction<DriverJoinRequest[]>,
) => {
  state.getPendingDriverJoinRequestsState = {
    inProgress: false,
    success: true,
  };
  state.pendingDriverJoinRequests = action.payload;
};
export const getPendingDriverJoinRequestsFailure = (
  state: PartnerState,
  action: PayloadAction<FailureResponse>,
) => {
  state.getPendingDriverJoinRequestsState = {
    inProgress: false,
    success: false,
    error: action.payload,
  };
};

// Accept Driver Join Request
export const acceptDriverJoinRequestInit = (
  state: PartnerState,
  action: PayloadAction<number>,
) => {};
export const acceptDriverJoinRequestSuccess = (state: PartnerState, action: Action) => {};
export const acceptDriverJoinRequestFailure = (
  state: PartnerState,
  action: PayloadAction<FailureResponse>,
) => {};

// Reject Driver Join Request
export const rejectDriverJoinRequestInit = (
  state: PartnerState,
  action: PayloadAction<number>,
) => {};
export const rejectDriverJoinRequestSuccess = (state: PartnerState, action: Action) => {};
export const rejectDriverJoinRequestFailure = (
  state: PartnerState,
  action: PayloadAction<FailureResponse>,
) => {};

// Remove Driver from partners list
export const removeDriverPartnerInit = (
  state: PartnerState,
  action: PayloadAction<{
    partnerCompanyId: number;
    driverId: number;
    terminationComment?: string;
  }>,
) => {};
export const removeDriverPartnerSuccess = (state: PartnerState, action: Action) => {};
export const removeDriverPartnerFailure = (
  state: PartnerState,
  action: PayloadAction<FailureResponse>,
) => {};

// Get Partner Drivers
export const getPartnerDriversInit = (state: PartnerState, action: PayloadAction<number>) => {
  state.getPartnerDriversState = {
    inProgress: true,
  };
};
export const getPartnerDriversSuccess = (
  state: PartnerState,
  action: PayloadAction<PartnerDriver[]>,
) => {
  state.getPartnerDriversState = {
    inProgress: false,
    success: true,
  };
  state.partnerDrivers = action.payload;
};
export const getPartnerDriversFailure = (
  state: PartnerState,
  action: PayloadAction<FailureResponse>,
) => {
  state.getPartnerDriversState = {
    inProgress: false,
    success: false,
    error: action.payload,
  };
};
