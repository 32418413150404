// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

// Internal libs
import { InProgressState } from '@common/model';
import { AccountType } from '../../model/Settlements';
import { actions, selectors } from '../../store';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import FormErrors from '@common/components/FormErrors';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import { PartnerDriversAbandonmentConfirmation } from '@features/partner';

// Props type
type SetAccountTypeModalProps = {};

// Component
const SetAccountTypeModal = ({}: SetAccountTypeModalProps) => {
  const { t } = useTranslation('user');
  const dispatch = useDispatch();
  const history = useHistory();
  const { accountType }: { accountType: AccountType } = useParams();

  const close = () => {
    history.push('..');
  };

  const user = useSelector(selectors.selectUser);

  const [submitState, setSubmitState] = useState<InProgressState>({ inProgress: false });

  const submit = async () => {
    setSubmitState({ inProgress: true });

    try {
      await dispatch(actions.setUserAccountType(accountType));
      setSubmitState({ inProgress: false });
      close();
    } catch (e) {
      setSubmitState({ inProgress: false, success: false, error: e });
    }
  };

  const { formErrors } = useResponseErrors(
    submitState?.error,
    'settlements.setAccountType',
    'user',
  );

  const form = useForm();

  return (
    <Dialog open={true} onClose={close}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(submit)}>
          <DialogTitle>{t('settlements.setAccountType.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(`settlements.setAccountType.message.${accountType}`)}
            </DialogContentText>

            {!!user?.company?.isPartner && <PartnerDriversAbandonmentConfirmation />}

            <FormErrors errorMessages={formErrors} />
          </DialogContent>
          <DialogActions>
            <Button onClick={close}>{t('settlements.setAccountType.cancelButton')}</Button>
            <ProgressButton
              variant="contained"
              color="primary"
              disabled={submitState?.inProgress}
              loading={submitState?.inProgress}
              type="submit"
            >
              {t(`settlements.setAccountType.submitButton`, '')}
            </ProgressButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default SetAccountTypeModal;
