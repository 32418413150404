// Core libraries
import React, { useCallback, useEffect, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router';
import Card from '@material-ui/core/Card';
import LinearProgress from '@material-ui/core/LinearProgress';

// Internal libs
import { InProgressState, PaginatedData } from '@common/model';
import { actions } from '../../store';
import { Ride } from '@features/rides/model';

// Internal components
import RideModal from '@features/rides/containers/RideModal';
import DriverRideCorrectionModal from '@features/admin/drivers/conteiners/DriverRideCorrectionModal';
import DriverRidesList from '@features/admin/drivers/conteiners/driverRides/DriverRidesList';
import DriverRidesTopButtons from '@features/admin/drivers/conteiners/driverRides/DriverRidesTopButtons';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type DriverRidesProps = {};

// Component
const DriverRides = () => {
  const { t } = useTranslation('rides');
  const styles = useStyles();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const { driverId }: { driverId: string } = useParams();
  const [getPartnerRidesState, setGetPartnerRidesState] = useState<InProgressState>({
    inProgress: false,
  });
  const [paginatedRides, setPaginatedRides] = useState<PaginatedData<Ride> | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [showTotalItems, setShowTotalItems] = useState<number | undefined>(undefined);
  const [rideNo, setRideNo] = useState<string | undefined>(undefined);

  const getPartnerRides = useCallback(async () => {
    setGetPartnerRidesState({ inProgress: true });
    try {
      const paginatedRides: PaginatedData<Ride> = ((await dispatch(
        actions.getDriverRides(+driverId, page, showTotalItems, rideNo),
      )) as unknown) as PaginatedData<Ride>;
      setPaginatedRides(paginatedRides);
      setGetPartnerRidesState({ inProgress: false, success: true });
    } catch (e) {
      setGetPartnerRidesState({ inProgress: false, success: false, error: e });
    }
  }, [dispatch, driverId, page, showTotalItems, rideNo]);

  // Init
  useEffect(() => {
    getPartnerRides();
  }, [driverId, page, showTotalItems, getPartnerRides, rideNo]);

  return (
    <>
      <Card>
        {getPartnerRidesState?.inProgress && <LinearProgress data-testid="loading-rides" />}
        <DriverRidesTopButtons driverId={driverId} />
        <DriverRidesList
          driverId={driverId}
          paginatedRides={paginatedRides}
          setPage={setPage}
          setShowTotalItems={setShowTotalItems}
          searchRideNo={setRideNo}
        />
      </Card>
      <Switch>
        <Route path={`${path}/rides/add`}>
          <DriverRideCorrectionModal
            title={t('adminRideCorrection.addCorrectionModal.title')}
            getPartnerRides={getPartnerRides}
          />
        </Route>
        <Route path={`${path}/rides/:rideId/edit`}>
          <DriverRideCorrectionModal getPartnerRides={getPartnerRides} />
        </Route>
        <Route path={`${path}/rides/:rideId/add`}>
          <DriverRideCorrectionModal getPartnerRides={getPartnerRides} />
        </Route>

        <Route path={`${path}/rides/:rideId`}>
          <RideModal
            onClose={() => history.goBack()}
            isCorrectionButtonsVisible={true}
            getPartnerRides={getPartnerRides}
            isCommentsTabVisible={true}
          />
        </Route>
      </Switch>
    </>
  );
};

export default DriverRides;
