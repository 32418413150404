import { useMemo } from 'react';

// This default could be moved to configuration
const passwordValidatorOptions: passwordElements = {
  lowerCase: true,
  upperCase: true,
  digits: true,
  specialCharacters: false,
};

const lowerCasePattern = '[a-z]';
const upperCasePattern = '[A-Z]';
const digitsPattern = '\\d';
const specialCharactersPattern = '[!@#$%&? "]';

interface passwordElements {
  minLength?: false | number;
  lowerCase?: boolean | number;
  upperCase?: boolean | number;
  digits?: boolean | number;
  specialCharacters?: boolean | number;
}

export const passwordValidator = (
  message?: string,
  {
    minLength,
    lowerCase,
    upperCase,
    digits,
    specialCharacters,
  }: passwordElements = passwordValidatorOptions,
) => {
  const pattern =
    (minLength ? `(?=.{${minLength},})` : '') +
    (lowerCase
      ? typeof lowerCase === 'number'
        ? `(?=(.*${lowerCasePattern}){${lowerCase}})`
        : `(?=.*${lowerCasePattern})`
      : '') +
    (upperCase
      ? typeof upperCase === 'number'
        ? `(?=(.*${upperCasePattern}){${upperCase}})`
        : `(?=.*${upperCasePattern})`
      : '') +
    (digits
      ? typeof digits === 'number'
        ? `(?=(.*${digitsPattern}){${digits}})`
        : `(?=.*${digitsPattern})`
      : '') +
    (specialCharacters
      ? typeof specialCharacters === 'number'
        ? `(?=.*${specialCharactersPattern}{${specialCharacters}})`
        : `(?=.*${specialCharactersPattern})`
      : '');

  return (value: string) => RegExp(pattern).test(value) || message || false;
};

const usePasswordValidator = (message?: string) => {
  return useMemo(() => passwordValidator(message), [message]);
};

export default usePasswordValidator;
