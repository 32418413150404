import { useCallback } from 'react';

const useRequiredAnyValidator = (
  getValues: (field: string) => string,
  fieldNames: string[],
  message?: string,
) =>
  useCallback(() => !!fieldNames.find((fieldName) => !!getValues(fieldName)) || message || false, [
    getValues,
    fieldNames,
    message,
  ]);

export default useRequiredAnyValidator;
