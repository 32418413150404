// Core libraries
import React, { SetStateAction, useMemo, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';

// Internal libs
import {
  CorrectionTypeEnum,
  isCorrectionTypeMap,
} from '@features/admin/drivers/model/CorrectionType';
import { actions } from '../store';
import {
  CorrectionReasonEnum,
  CorrectionReasonType,
} from '@features/admin/drivers/model/CorrectionReason';
import { InProgressState } from '@common/model';
import { RideDetails } from '@features/rides/model';

// Internal components
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormTextField from '@common/components/FormTextField';
import useAmountValidator from '@common/validators/amount';
import ProgressButton from '@common/components/ProgressButton';
import FormErrors from '@common/components/FormErrors';
import FormSelectField from '@common/components/FormSelectField';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    field: {
      marginBottom: theme.spacing(1),
    },
    button: {
      padding: theme.spacing(2),
    },
  }),
);

// Props type
type DriverRideFormProps = {
  rideDetails?: RideDetails;
  isRideSettled?: boolean;
  isRideSynchronized?: boolean;
  afterSuccessSubmit?: (editMode: SetStateAction<boolean>) => void;
  getPartnerRides: () => void;
};

// Component
const DriverRideForm = ({
  rideDetails,
  afterSuccessSubmit,
  isRideSettled,
  isRideSynchronized,
  getPartnerRides,
}: DriverRideFormProps) => {
  const { t } = useTranslation('rides');
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { driverId }: { driverId: string } = useParams();
  const [submitState, setSubmitState] = useState<InProgressState>({ inProgress: false });
  const form = useForm();
  const rideType = form.watch('rideType');
  const { formErrors, getFieldErrors } = useResponseErrors(
    submitState?.error,
    'driverRideForm',
    'rides',
  );

  const option = useMemo(() => {
    const selectOptions = [
      {
        display:
          !rideDetails ||
          (rideDetails && isCorrectionTypeMap[rideDetails?.type] && !rideDetails?.referenceRide),
        option: {
          label: t('driverRideForm.fields.rideType.types.rideAdded'),
          value: CorrectionTypeEnum.rideAdded,
        },
      },
      {
        display: true,
        option: {
          label: t('driverRideForm.fields.rideType.types.correction'),
          value: CorrectionTypeEnum.correction,
        },
      },
      {
        display: true,
        option: {
          label: t('driverRideForm.fields.rideType.types.bonus'),
          value: CorrectionTypeEnum.bonus,
        },
      },
      {
        display: true,
        option: {
          label: t('driverRideForm.fields.rideType.types.ambassador'),
          value: CorrectionTypeEnum.ambassador,
        },
      },
    ];

    return selectOptions
      .filter((option) => option.display)
      .map((option) => option.option)
      .sort((optionOne, optionTwo) =>
        optionOne.label > optionTwo.label ? 1 : optionTwo.label > optionOne.label ? -1 : 0,
      );
  }, [rideDetails]);

  const isRideCorrectionType = () =>
    rideType
      ? rideType === CorrectionTypeEnum.correction
      : rideDetails?.type === CorrectionTypeEnum.correction;
  const onSuccess = () => (afterSuccessSubmit ? afterSuccessSubmit(false) : history.goBack());
  const onSubmit = async (values: any) => {
    setSubmitState({ inProgress: true });
    try {
      if (!(isRideSettled || isRideSynchronized) && rideDetails) {
        await dispatch(
          actions.updateDriverRideCorrection(rideDetails.id, {
            type: values.rideType || undefined,
            correctionReason: values.correctionReason || undefined,
            payout: values.payout || undefined,
          }),
        );
      } else {
        await dispatch(
          actions.addDriverRideCorrection({
            userId: +driverId,
            type: values.rideType,
            correctionReason: values.correctionReason || undefined,
            payout: values.payout,
            referenceRideId: rideDetails?.id || undefined,
          }),
        );
      }
      setSubmitState({ inProgress: false, success: true });
      onSuccess();
      getPartnerRides();
    } catch (e) {
      setSubmitState({ inProgress: false, success: false, error: e });
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid item xs={12} md={6} lg={4}>
            <FormSelectField
              className={styles.field}
              name="rideType"
              label={t('driverRideForm.fields.rideType.label')}
              options={option}
              rules={{
                required: {
                  value: true,
                  message: t('driverRideForm.fields.rideType.errors.required'),
                },
              }}
              fullWidth={true}
              errors={getFieldErrors('rideType', 'type')}
              defaultValue={
                rideDetails && isCorrectionTypeMap[rideDetails.type] ? rideDetails.type : ''
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormTextField
              className={styles.field}
              name="payout"
              label={t('driverRideForm.fields.payout.label', '')}
              rules={{
                required: {
                  value: true,
                  message: t('driverRideForm.fields.payout.errors.required'),
                },
                max: {
                  value: 999999.99,
                  message: t('driverRideForm.fields.payout.errors.max'),
                },
                validate: {
                  payout: useAmountValidator(
                    !isRideCorrectionType(),
                    t('driverRideForm.fields.payout.errors.payout'),
                  ),
                },
              }}
              fullWidth={true}
              errors={getFieldErrors('payout', 'payout')}
              defaultValue={
                rideDetails && isCorrectionTypeMap[rideDetails.type]
                  ? rideDetails?.payout.toString()
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {isRideCorrectionType() && (
              <FormSelectField
                className={styles.field}
                name="correctionReason"
                label={t('driverRideForm.fields.correctionReason.label', '')}
                options={[
                  {
                    label: t('driverRideForm.fields.correctionReason.types.other'),
                    value: CorrectionReasonEnum.other,
                  },
                  {
                    label: t(
                      'driverRideForm.fields.correctionReason.types.rideDistanceCalculatedIncorrectly',
                    ),
                    value: CorrectionReasonEnum.rideDistanceCalculatedIncorrectly,
                  },
                ]}
                rules={{
                  required: {
                    value: true,
                    message: t('driverRideForm.fields.correctionReason.errors.required'),
                  },
                }}
                fullWidth={true}
                errors={getFieldErrors('correctionReason', 'correction_reason')}
                defaultValue={(rideDetails?.correctionReason as CorrectionReasonType) || ''}
              />
            )}
          </Grid>
        </Grid>
        <Grid
          className={styles.button}
          container
          spacing={2}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <FormErrors errorMessages={formErrors} />
          <Button onClick={onSuccess}>{t('driverRideForm.cancelButton')}</Button>
          <ProgressButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={submitState.inProgress}
            loading={submitState.inProgress}
          >
            {!rideDetails || isRideSettled || isRideSynchronized
              ? t('driverRideForm.submitButtonAdd')
              : t('driverRideForm.saveChangesButton')}
          </ProgressButton>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default DriverRideForm;
