// Core libraries
import React, { useEffect } from 'react';

// External libs and components
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

// Internal libs
import { RootState } from '@app/store';
import { InProgressState } from '@common/model';
import { actions } from '../store';
import { Vehicle } from '../model/Vehicle';

// Internal components
import PageDefaultLayout from '@common/components/layouts/PageDefaultLayout';
import VehiclesList from './VehiclesList';
import VehicleEditDialog from './VehicleEditDialog';
import VehicleAddDialog from './VehicleAddDialog';
import VehicleActivateDialog from '@features/vehicles/containers/VehicleActivateDialog';

// Props type
type VehiclesProps = {};

// Component
const VehiclesPage = ({}: VehiclesProps) => {
  const { t } = useTranslation('vehicles');
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();

  const vehiclesListState = useSelector<RootState, InProgressState | undefined>(
    (state) => state.vehicles.vehiclesListState,
  );
  const vehiclesList = useSelector<RootState, Vehicle[] | undefined>(
    (state) => state.vehicles.vehiclesList,
  );

  const vehiclesUpsertSuccess = useSelector<RootState, boolean | undefined>(
    (state) => state.vehicles.vehicleUpsertState?.success,
  );

  useEffect(() => {
    dispatch(actions.getVehiclesList());
  }, []);

  useEffect(() => {
    if (vehiclesUpsertSuccess) {
      dispatch(actions.getVehiclesList());
    }
  }, [vehiclesUpsertSuccess]);

  const actionsBlock = (
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={() => history.push(`${path}/add`)}
    >
      {t('addVehicleButton', '')}
    </Button>
  );

  return (
    <PageDefaultLayout title={t('title', '')} actions={actionsBlock}>
      <Card>
        <CardContent>
          {vehiclesListState?.inProgress && <LinearProgress />}
          {vehiclesList && <VehiclesList vehicles={vehiclesList} />}
        </CardContent>
      </Card>

      <Route path={`${path}/edit/:id`} children={<VehicleEditDialog />} />
      <Route path={`${path}/add`} children={<VehicleAddDialog />} />
      <Route path={`${path}/activate/:id`} children={<VehicleActivateDialog />} />
    </PageDefaultLayout>
  );
};

export default VehiclesPage;
