// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

// Internal libs
import { Comment, CommentSeverityEnum } from '@common/model/Comment';
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Internal components

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    headerText: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    headerTextInfo: {
      color: theme.palette.info.main,
    },
    headerTextNegative: {
      color: theme.palette.error.main,
    },
    headerTextPositive: {
      color: theme.palette.success.main,
    },
  }),
);

// Props type
type CommentListItemProps = {
  item: Comment;
  displayDate?: boolean;
};

// Component
const CommentListItem = ({ item, displayDate = true }: CommentListItemProps) => {
  const { t } = useTranslation('admin');
  const classes = useStyles();

  const headerTextMap = {
    [CommentSeverityEnum.Info]: classes.headerTextInfo,
    [CommentSeverityEnum.Positive]: classes.headerTextPositive,
    [CommentSeverityEnum.Negative]: classes.headerTextNegative,
  };

  return (
    <ListItem>
      <ListItemText
        primary={
          <div className={classes.headerText}>
            <Typography
              component="span"
              variant="subtitle2"
              className={headerTextMap[item.severity]}
            >
              {t(`createComment.comment.fields.severity.${item.severity}`)}
            </Typography>
            {displayDate && (
              <Typography component="span" variant="caption">
                {'  '}
                {item.createdAt.toLocaleString()}
              </Typography>
            )}
          </div>
        }
        secondary={item.body}
      />
    </ListItem>
  );
};

export default CommentListItem;
