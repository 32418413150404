// Core libraries
import React from 'react';

// External libs and components
import SvgIcon from '@material-ui/core/SvgIcon';

type PackageVariantClosedProps = React.ComponentProps<typeof SvgIcon>;

// Component
const People = (props: PackageVariantClosedProps) => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      d="M5 6C3.9 6 3 6.9 3 8S3.9 10 5 10 7 9.11 7 8 6.11 6 5 6M12 4C10.9 4 10 4.89 10 6S10.9 8 12 8 14 7.11 14 6 13.11 4 12 4M19 2C17.9 2 17 2.9 17 4S17.9 6 19 6 21 5.11 21 4 20.11 2 19 2M3.5 11C2.67 11 2 11.67 2 12.5V17H3V22H7V17H8V12.5C8 11.67 7.33 11 6.5 11H3.5M10.5 9C9.67 9 9 9.67 9 10.5V15H10V20H14V15H15V10.5C15 9.67 14.33 9 13.5 9H10.5M17.5 7C16.67 7 16 7.67 16 8.5V13H17V18H21V13H22V8.5C22 7.67 21.33 7 20.5 7H17.5Z"
    />
  </SvgIcon>
);

export default People;
