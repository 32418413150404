import axios from 'axios';
import { Invoice, invoiceFromSrc, InvoiceSrc } from '@features/company/model/Invoice';

export const getInvoices = async (): Promise<Invoice[]> => {
  const response = await axios.get('/invoice/list');

  return response.data.map((invoiceSrc: InvoiceSrc) => invoiceFromSrc(invoiceSrc));
};

export const generateInvoice = async (invoiceId: number, invoiceNo: string): Promise<any> => {
  const response = await axios.post(`invoice/${invoiceId}/generate`, {
    invoice_no: invoiceNo
  });
}

export const downloadInvoice = async (invoiceId: number, invoiceNo: string): Promise<any> => {
  const fileName = `${invoiceNo.replaceAll('/', '_')}.pdf`;

  // Download file
  const file: { data: Blob } = await axios.get(`invoice/${invoiceId}/download`, {
    responseType: 'blob',
  });

  // Save file
  saveAs(file.data, fileName);
};

export const downloadInvoiceRidesAttachments = async (
  invoiceNo: string,
  link: string,
): Promise<any> => {
  const fileExtension = link.match(/\w*$/);
  const fileName = `${invoiceNo.replaceAll('/', '_')}${fileExtension ? '.' + fileExtension : ''}`;

  // Download file
  const file: { data: Blob } = await axios.get(`${link}`, {
    responseType: 'blob',
  });

  // Save file
  saveAs(file.data, fileName);
};
