import { actions as simpleActions } from '../slice';
import * as api from '../../../api';
import { RootThunk } from '@common/model';
import { CreateCommentPayload } from '../../../api';
import { GetDriversFilters } from '@features/admin/drivers/store/model';

/****** Place for complex actions *****/

// Drivers list Action
export const getDriversList = (showDeleted: boolean = false): RootThunk => async (dispatch) => {
  dispatch(simpleActions.getDriversListInit());

  try {
    const drivers = await api.getDriversList(showDeleted);

    dispatch(simpleActions.getDriversListSuccess(drivers));
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.getDriversListFailure(error));
    throw error;
  }
};

export const getDriversListPaginated = (filters: GetDriversFilters): RootThunk => async (
  dispatch,
) => {
  dispatch(simpleActions.getDriversListPaginatedInit({}));

  try {
    const drivers = await api.getDriversListPaginated(filters);

    dispatch(simpleActions.getDriversListPaginatedSuccess(drivers));
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.getDriversListPaginatedFailure(error));
    throw error;
  }
};

export const deleteDrivers = (driverIds: string[]): RootThunk => async (dispatch) => {
  dispatch(simpleActions.deleteDriversInit());

  try {
    await api.deleteDrivers(driverIds);
    dispatch(simpleActions.deleteDriversSuccess());
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.deleteDriversFailure(error));
    throw error;
  }
};

export const createDriverComment = (
  driverId: string,
  payload: CreateCommentPayload,
): RootThunk => async (dispatch) => {
  dispatch(simpleActions.createCommentInit());

  try {
    await api.createDriverComment(driverId, payload);
    dispatch(simpleActions.createCommentSuccess());
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.createCommentFailure(error));
    throw error;
  }
};

export const getDriverComments = (driverId: string): RootThunk => async (dispatch) => {
  dispatch(simpleActions.getCommentsInit());

  try {
    const comments = await api.getDriverComments(driverId);
    dispatch(simpleActions.createCommentSuccess());
    return comments;
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.createCommentFailure(error));
    throw error;
  }
};

export const restoreDriver = (driverId: string): RootThunk => async (dispatch) => {
  dispatch(simpleActions.restoreDriverInit());

  try {
    await api.restoreDriver(driverId);
    dispatch(simpleActions.restoreDriverSuccess());
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.restoreDriverFailure(error));
    throw error;
  }
};

export const setGetDriversFilters = (driverFilters: GetDriversFilters): RootThunk => async (
  dispatch,
) => {
  dispatch(simpleActions.setGetDriversFilters(driverFilters));
};

export const setGetDriversShowDeletedFilter = (showDeleted: boolean): RootThunk => async (
  dispatch,
) => {
  dispatch(simpleActions.setGetDriversShowDeletedFilter(showDeleted));
};
