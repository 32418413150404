// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
// Internal libs
import { Invoice } from '../../model/Invoice';
import { actions } from '../../store';

// Internal components
import ProgressIconButton from '@common/components/ProgressIconButton';
import { InProgressState } from '@common/model';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type DownloadInvoiceActionProps = {
  invoice: Invoice;
  children: React.ReactNode;
  link?: string | undefined;
  title?: string;
};

// Component
const DownloadAction = ({ invoice, children, link, title = '' }: DownloadInvoiceActionProps) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [downloadState, setDownloadState] = useState<InProgressState>({ inProgress: false });

  const download = async (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setDownloadState({ inProgress: true });
    try {
      link
        ? await dispatch(actions.downloadInvoiceRidesAttachments(invoice.id, invoice.no, link))
        : await dispatch(actions.downloadInvoice(invoice.id, invoice.no));
      setDownloadState({ inProgress: false, success: true });
    } catch (e) {
      setDownloadState({ inProgress: false, success: false, error: e });
    }
  };
  return (

    <Tooltip
      title={title}
    >
      <span>
        <ProgressIconButton
          onClick={download}
          loading={downloadState.inProgress}
          size={'small'}
        >
          {children}
        </ProgressIconButton>
      </span>
    </Tooltip>
  );
};

export default DownloadAction;
