import { RootThunk, PaginatedData } from '@common/model';
import * as api from '../../../api';
import { actions as simpleActions } from '../slice';
import { Ride } from '@features/rides/model';
import { CreateCommentPayload } from '../../../api';

export const getRides = (
  page?: number,
  showTotalItems?: number,
  rideNo?: string,
): RootThunk => async (dispatch): Promise<PaginatedData<Ride>> => {
  dispatch(simpleActions.getRidesInit({ page, showTotalItems, rideNo }));
  try {
    const paginatedRides = await api.getDriverRides(undefined, page, showTotalItems, rideNo);
    dispatch(simpleActions.getRidesSuccess(paginatedRides));

    return paginatedRides;
  } catch (e) {
    dispatch(simpleActions.getRidesFailure(e.response?.data || { error: 'unknown' }));
    throw e.response?.data || { error: 'unknown' };
  }
};

export const createRideComment = (
  driverId: string,
  payload: CreateCommentPayload,
): RootThunk => async (dispatch) => {
  dispatch(simpleActions.createCommentInit());

  try {
    await api.createRideComment(driverId, payload);
    dispatch(simpleActions.createCommentSuccess());
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.createCommentFailure(error));
    throw error;
  }
};

export const getRideComments = (driverId: string): RootThunk => async (dispatch) => {
  dispatch(simpleActions.getCommentsInit());

  try {
    const comments = await api.getRideComments(driverId);
    dispatch(simpleActions.createCommentSuccess());
    return comments;
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.createCommentFailure(error));
    throw error;
  }
};

export const downloadRideDocument = (
  rideId: number,
  documentId: number,
  originalFileName: string,
): RootThunk => async (dispatch) => {
  dispatch(simpleActions.downloadRideDocumentInit());

  try {
    await api.downloadRideDocument(rideId, documentId, originalFileName);
    dispatch(simpleActions.downloadRideDocumentSuccess());
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.downloadRideDocumentFailure(error));
    throw error;
  }
};
