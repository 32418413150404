// Core libraries
import React, { useMemo, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DisplayData } from 'mui-datatables';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';

// Internal libs
import { InProgressState } from '@common/model';
import { invoiceActions } from '../store';
import { Invoice } from '@features/company/model/Invoice';
import { DataRange } from '@features/admin/invoices/model/DateRange';
import { InvoiceStatusEnum } from '@common/model/InvoiceStatus';
import { setDayEnd, setDayStart } from '@common/tools/dateUtils';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import GenerateInvoicesAlert from '@features/admin/invoices/conteiners/GenerateInvoicesAlert';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    iconContainer: {
      marginRight: '24px',
    },
    inverseIcon: {
      transform: 'rotate(90deg)',
    },
  }),
);

// Props type
type InvoiceToolbarSelectProps = {
  selectedRows: {
    data: Array<{ index: number; dataIndex: number }>;
    lookup: { [key: number]: boolean };
  };
  displayData: DisplayData;
  invoicesInTable: Invoice[];
  dateRange: DataRange;
};

// Component
const InvoiceToolbarSelect = ({
  selectedRows,
  displayData,
  invoicesInTable,
  dateRange,
}: InvoiceToolbarSelectProps) => {
  const styles = useStyles();
  const { t } = useTranslation('admin');
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [generateState, setGenerateState] = useState<InProgressState>({ inProgress: false });
  const selectedInvoices = useMemo(() => {
    return invoicesInTable.filter((invoice, invoiceIndex) =>
      selectedRows.data.find((row) => row.dataIndex === invoiceIndex),
    );
  }, [invoicesInTable, selectedRows]);

  const shouldShowAlert = useMemo(() => {
    const invoicesAlreadyGenerated = (
      selectedInvoices.filter(
        (invoice, invoiceIndex) => invoice.status === InvoiceStatusEnum.generated,
      ) ?? []
    ).length;
    const currenciesCount = uniqBy(selectedInvoices ?? [], 'currency').length;
    return {
      invoicesAlreadyGenerated: !!invoicesAlreadyGenerated,
      invoicesMultipleCurrencies: currenciesCount > 1,
    };
  }, [selectedInvoices]);

  const selectedInvoicesIds = useMemo(
    () => selectedInvoices.map((invoice) => invoice.id.toString()),
    [selectedInvoices],
  );

  const generate = async () => {
    setGenerateState({ inProgress: true });
    try {
      handleClose();
      await dispatch(invoiceActions.generateTransfersFile(selectedInvoicesIds));
      setGenerateState({ inProgress: false, success: true });
      await dispatch(
        invoiceActions.getInvoices(
          new Date(setDayStart(dateRange.dateFrom)).toISOString(),
          new Date(setDayEnd(dateRange.dateTo)).toISOString(),
        ),
      );
    } catch (e) {
      setGenerateState({ inProgress: false, success: false, error: e });
    }
  };
  const [exportInvoicesState, setExportInvoicesState] = useState<InProgressState>({
    inProgress: false,
  });
  const exportInvoices = async () => {
    setExportInvoicesState({ inProgress: true });
    try {
      handleClose();
      await dispatch(invoiceActions.exportInvoicesToEdiFile(selectedInvoicesIds));
      setExportInvoicesState({ inProgress: false, success: true });
      await dispatch(
        invoiceActions.getInvoices(
          new Date(setDayStart(dateRange.dateFrom)).toISOString(),
          new Date(setDayEnd(dateRange.dateTo)).toISOString(),
        ),
      );
    } catch (e) {
      setExportInvoicesState({ inProgress: false, success: false, error: e });
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [downloadZippedInvoicesState, setDownloadZippedInvoicesState] = useState<InProgressState>({
    inProgress: false,
  });

  const downloadZippedInvoices = async () => {
    setDownloadZippedInvoicesState({ inProgress: true });
    try {
      handleClose();
      await dispatch(invoiceActions.downloadZippedInvoicesFile(selectedInvoicesIds));
      setDownloadZippedInvoicesState({ inProgress: false, success: true });
    } catch (e) {
      setDownloadZippedInvoicesState({ inProgress: false, success: false, error: e });
    }
  };

  return (
    <div className={styles.iconContainer}>
      <ProgressButton
        variant="contained"
        color="primary"
        type="button"
        onClick={downloadZippedInvoices}
        disabled={downloadZippedInvoicesState.inProgress}
        loading={downloadZippedInvoicesState.inProgress}
      >
        {t('invoices.toolbar.downloadZippedInvoices.button')}
      </ProgressButton>
      <ProgressButton
        variant="contained"
        color="primary"
        type="button"
        onClick={exportInvoices}
        disabled={exportInvoicesState.inProgress}
        loading={exportInvoicesState.inProgress}
      >
        {t('invoices.toolbar.exportInvoices.button')}
      </ProgressButton>
      <ProgressButton
        variant="contained"
        color="primary"
        type="button"
        onClick={Object.values(shouldShowAlert).some((val) => val) ? handleClickOpen : generate}
        disabled={generateState.inProgress}
        loading={generateState.inProgress}
      >
        {t('invoices.toolbar.button.label')}
      </ProgressButton>
      <GenerateInvoicesAlert
        open={open}
        onClose={handleClose}
        onAccept={generate}
        invoicesAlreadyGenerated={shouldShowAlert.invoicesAlreadyGenerated}
        invoicesMultipleCurrencies={shouldShowAlert.invoicesMultipleCurrencies}
      />
    </div>
  );
};

export default InvoiceToolbarSelect;
