// Core libraries
import React from 'react';

// External libs and components
import {
  Controller,
  useFormContext,
  Validate,
  ValidationRule,
  ValidationValueMessage,
} from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask';
import PinInput from 'react-pin-input';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Internal libs

// Internal components
const useStyles = makeStyles((theme) => ({
  wrapperSm: {
    padding: 20,
    display: 'flex',
    justifyContent: 'space-around',
  },
  wrapper: {
    padding: '10px 40px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -theme.spacing(1.5),
    marginLeft: -theme.spacing(1.5),
    color: theme.palette.secondary.main,
  },
}));

type FormPinCodeFieldProps = {
  name: string;
  id?: string;
  label?: string;
  helperText?: string;
  placeholder?: string;
  className?: string;
  defaultValue?: string;
  errors?: string[];
  autoFocus?: boolean;
  disabled?: boolean;
  length?: number;
};

const FormPinCodeField = ({
  name,
  id,
  label,
  helperText,
  className,
  defaultValue = '',
  placeholder,
  errors = [],
  autoFocus,
  disabled,
  length = 4,
}: FormPinCodeFieldProps) => {
  const form = useFormContext();
  const styles = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const hasError = !!form.errors[name] || !!errors.length;
  const hTexts = !hasError
    ? helperText
    : [
        ...(form.errors[name] ? [form.errors[name].message] : []),
        ...errors.map((error) => <div>{error}</div>),
      ];
  const wrapperStyle = isSm
    ? {
        padding: 20,
        display: 'flex',
        justifyContent: 'space-around',
      }
    : {
        padding: '10px 40px',
        display: 'flex',
        justifyContent: 'space-around',
      };
  const inputFocusStyle = {
    borderColor: theme.palette.primary.main,
    boxShadow: theme.shadows[7],
  };
  const inputStyle = {
    height: 64,
    width: 64,
    fontSize: 30,
    borderRadius: theme.shape.borderRadius * 3,
    boxShadow: theme.shadows[6],
    borderColor: 'white',
  };
  return (
    <Controller
      name={name}
      render={({ onChange, value, name }) => (
        <PinInput
          length={length}
          initialValue={defaultValue}
          onChange={(value, index) => {}}
          type="numeric"
          inputMode="number"
          style={wrapperStyle}
          inputStyle={inputStyle}
          inputFocusStyle={inputFocusStyle}
          onComplete={(value, index) => onChange(value)}
        />
      )}
      control={form.control}
      defaultValue={defaultValue}
    />
  );
};

export default FormPinCodeField;
