// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';

// Internal libs

// Internal components
import CenteredPaperLayout from '@common/components/CenteredPaperLayout';
import { ReactComponent as LogoDark } from '@assets/xd_logo.dark.svg';
import { ReactComponent as Logo } from '@assets/xd_logo.svg';
// import OAuthLinks from './OAuthLinks';
import RegisterForm from './RegisterForm';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { Country } from '@common/model/Country';
import useGeoLocation from '@common/miscHooks/useGeolocation';

const useStyle = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  extRegisterLabel: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  intRegisterLabel: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  login: {
    marginTop: theme.spacing(2),
  },
}));

const Register = () => {
  const styles = useStyle();
  const theme = useTheme();
  const { t } = useTranslation('auth');
  const { country } = useGeoLocation();

  const countries = useSelector<RootState, Country[] | undefined>(
    (state) => state.init.availableCountries?.list,
  );

  return (
    <CenteredPaperLayout showBgImage={true} maxWidth="sm" showLanguageSelector>
      {/* Logo */}
      <div className={styles.logoContainer}>
        {theme.palette.type === 'dark' ? <LogoDark width="200" /> : <Logo width="200px" />}
      </div>

      {/* "Register with" */}
      {/*<div className={styles.extRegisterLabel}>
        {t('register.messages.registerWithExternalService', '')}
      </div>*/}

      {/* External services (uber, google, facebook) */}
      {/*<OAuthLinks />
       */}
      {/* "or" */}
      {/*<div className={styles.intRegisterLabel}>
        {t('register.messages.registerTraditionally', '')}
      </div>*/}

      {/* Register form */}
      <RegisterForm countries={countries ?? []} defaultCountry={country} />

      {/* Go to Login */}
      <Button
        component={RouterLink}
        to="/login"
        fullWidth={true}
        color="primary"
        className={styles.login}
      >
        {t('register.goToLoginButton.caption', '')}
      </Button>
    </CenteredPaperLayout>
  );
};

export default Register;
