import React, { useContext, useEffect, useState } from 'react';
import { DrawerMenuItemData, IdentifiedDrawerMenuItem } from './model/DrawerMenuItemData';
import { BehaviorSubject } from 'rxjs';

// Context data class
export class MainContextData {
  static compare = (a: IdentifiedDrawerMenuItem, b: IdentifiedDrawerMenuItem) => {
    return (a.weight || 0) > (b.weight || 0) ? 1 : (a.weight || 0) < (b.weight || 0) ? -1 : 0;
  };

  private menu: IdentifiedDrawerMenuItem[] = [];
  public menu$: BehaviorSubject<IdentifiedDrawerMenuItem[]> = new BehaviorSubject(this.menu);

  public registerMenu(id: string, item: DrawerMenuItemData) {
    const path = id.split('.');
    let children: IdentifiedDrawerMenuItem[] | undefined = this.menu;

    // Handle nested menu (find correct items list)
    while (children && path.length > 1) {
      const pathElement = path.shift();

      children = children.find((child) => child.id === pathElement)?.children;
    }

    if (!children) {
      throw new Error(`Cannot register menu item "${id}". Parent does not exist.`);
    }

    // Path at this point has exactly 1 element but TS consider this expresion as potential undefined
    const leafId = path.shift() as string;
    const idx = children.findIndex((indexedItem) => indexedItem.id === leafId);

    if (idx !== -1) {
      children[idx] = { id: leafId, ...item, children: [] };
    } else {
      children.push({ id: leafId, ...item, children: [] });
    }

    children.sort(MainContextData.compare);

    this.updateMenu();
  }

  public unregisterMenu(id: string) {
    const path = id.split('.');
    let children: IdentifiedDrawerMenuItem[] | undefined = this.menu;

    // Handle nested menu (find correct items list)
    while (children && path.length > 1) {
      const pathElement = path.shift();

      children = children.find((child) => child.id === pathElement)?.children;
    }

    if (!children) {
      return;
    }

    const leafId = path.shift() as string;
    const idx = children.findIndex((indexedItem) => indexedItem.id === leafId);

    children.splice(idx, 1);

    this.updateMenu();
  }

  private updateMenu() {
    this.menu$.next([...this.menu]);
  }
}

// Context
const MainContext = React.createContext<MainContextData | undefined>(undefined);
export default MainContext;

// Hook
export const useMainContext = () => useContext(MainContext);

export const useMainMenu = () => {
  const mainContext = useMainContext();

  const [menu, setMenu] = useState<IdentifiedDrawerMenuItem[]>([]);

  useEffect(() => {
    const subscription = mainContext?.menu$.subscribe({
      next: (v) => setMenu(v),
    });

    return () => subscription?.unsubscribe();
  }, [mainContext]);

  return menu;
};
