// Core libraries
import React, { useEffect, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogContent from '@material-ui/core/DialogContent';

// Internal libs
import { isCorrectionTypeMap } from '@features/admin/drivers/model/CorrectionType';
import { actions } from '../../../rides/store';
import { InProgressState } from '@common/model';
import { RideDetails } from '@features/rides/model';

// Internal components
import DriverRideForm from '@features/admin/drivers/conteiners/DriverRideForm';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
type DriverRideCorrectionModalProps = {
  title?: string;
  getPartnerRides: () => void;
};

// Component
const DriverRideCorrectionModal = ({ title, getPartnerRides }: DriverRideCorrectionModalProps) => {
  const { t } = useTranslation('rides');
  const styles = useStyles();
  const dispatch = useDispatch();
  const { rideId } = useParams() as { rideId: string };

  const [getDetailsState, setGetDetailsState] = useState<InProgressState>({ inProgress: false });
  const [rideDetails, setRideDetails] = useState<RideDetails | undefined>(undefined);
  const titleToDisplay =
    title || `${t(`adminRideCorrection.correctionNumber.title`)} ${rideDetails?.no || ''}`;
  const isRideSettled = () => !!(rideDetails && rideDetails.settledAt && rideDetails.invoiceId);
  const isRideSynchronized = () => (rideDetails ? !isCorrectionTypeMap[rideDetails?.type] : false);
  const getRideDetails = async () => {
    if (!rideId) {
      return;
    }
    setGetDetailsState({ inProgress: true });

    try {
      const rideDetails: RideDetails = ((await dispatch(
        actions.getRideDetails(parseInt(rideId)),
      )) as unknown) as RideDetails;

      setRideDetails(rideDetails);
      setGetDetailsState({ inProgress: false, success: true });
    } catch (e) {
      setRideDetails(undefined);
      setGetDetailsState({ inProgress: false, success: false, error: e });
    }
  };

  useEffect(() => {
    getRideDetails();
  }, [rideId]);
  return (
    <Dialog open={true} className={styles.root} fullWidth maxWidth="md">
      <DialogTitle>{titleToDisplay}</DialogTitle>
      <Divider />
      {getDetailsState.inProgress && <LinearProgress data-testid="rdv-loading" />}
      <DialogContent>
        {!getDetailsState.inProgress ? (
          rideDetails ? (
            <DriverRideForm
              rideDetails={rideDetails}
              isRideSettled={isRideSettled()}
              isRideSynchronized={isRideSynchronized()}
              getPartnerRides={getPartnerRides}
            />
          ) : (
            <DriverRideForm getPartnerRides={getPartnerRides} />
          )
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default DriverRideCorrectionModal;
