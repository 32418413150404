import { Action, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponse } from '@common/model';
import { Driver } from '@common/model/Driver';
import { DriversState } from '@features/admin/drivers/store/model';
import { XpressDeliveryCompany } from '@common/model/XpressDeliveryCompany';

export const getDriverProfileInit = (state: DriversState, action: Action) => {
  state.getDriverState = {
    inProgress: true,
  };
};
export const getDriverProfileSuccess = (state: DriversState, action: PayloadAction<Driver>) => {
  state.getDriversState = {
    inProgress: false,
    success: true,
  };
  state.driver = action.payload;
};
export const getDriverProfileFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {
  state.getDriverState = {
    inProgress: true,
  };
  state.driver = undefined;
};

export const getXpressDeliveryCompaniesInit = (state: DriversState, action: Action) => {
  state.getXpressDeliveryCompaniesState = {
    inProgress: true,
  };
};
export const getXpressDeliveryCompaniesSuccess = (
  state: DriversState,
  action: PayloadAction<XpressDeliveryCompany[]>,
) => {
  state.getXpressDeliveryCompaniesState = {
    inProgress: false,
    success: true,
  };
  state.xpressDeliveryCompanies = action.payload;
};
export const getXpressDeliveryCompaniesFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {
  state.getXpressDeliveryCompaniesState = {
    inProgress: true,
  };
  state.xpressDeliveryCompanies = undefined;
};

export const setDriverAllowedCompaniesInit = (state: DriversState, action: Action) => {
  state.updateDriverState = {
    inProgress: true,
  };
};
export const setDriverAllowedCompaniesSuccess = (
  state: DriversState,
  action: PayloadAction<void>,
) => {
  state.updateDriverState = {
    inProgress: false,
    success: true,
  };
};
export const setDriverAllowedCompaniesFailure = (
  state: DriversState,
  action: PayloadAction<FailureResponse>,
) => {
  state.updateDriverState = {
    inProgress: true,
  };
};
