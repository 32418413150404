import { RootThunk } from '@common/model';
import { actions as simpleActions } from '../slice';
import * as api from '../../api';
import { CreateCompany, UpdateCompany } from '../../model/Company';
import { getCurrentUser } from './userActions';
import { CompanyDocumentType } from '../../model/CompanyDocumentType';
import { CompanyDocument } from '../../model/CompanyDocument';

/*export const upsertCompany = (company: CreateCompany | UpdateCompany): RootThunk => async (
  dispatch,
): Promise<any> => {
  return company.hasOwnProperty('id')
    ? // @ts-ignore
      dispatch(updateCompany(company))
    : dispatch(createCompany(company));
};*/

export const createCompany = (createCompany: CreateCompany): RootThunk => async (
  dispatch,
): Promise<any> => {
  dispatch(simpleActions.createCompanyInit(createCompany));

  try {
    const response = await api.createCompany(createCompany);
    dispatch(simpleActions.createCompanySuccess());
    dispatch(getCurrentUser());
  } catch (e) {
    dispatch(simpleActions.createCompanyFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};

export const updateCompany = (updateCompany: UpdateCompany): RootThunk => async (
  dispatch,
): Promise<any> => {
  dispatch(simpleActions.updateCompanyInit(updateCompany));

  try {
    const response = await api.updateCompany(updateCompany);
    dispatch(simpleActions.createCompanySuccess());
    dispatch(getCurrentUser());
  } catch (e) {
    dispatch(simpleActions.createCompanyFailure(e.response?.data || {}));
    throw e.response?.data || {};
  }
};

export const uploadCompanyDocument = (
  companyId: number,
  documentType: CompanyDocumentType,
  documentFile: File,
  progressCallback: (event: { loaded: number; total: number }) => void,
): RootThunk => async (dispatch): Promise<any> => {
  dispatch(
    simpleActions.uploadCompanyDocumentInit({
      companyId,
      documentType,
      fileName: documentFile.name,
      fileSize: documentFile.size,
    }),
  );

  try {
    await api.uploadCompanyDocument(companyId, documentType, documentFile, progressCallback);
    dispatch(simpleActions.uploadCompanyDocumentSuccess());
    dispatch(getCurrentUser());
  } catch (e) {
    dispatch(simpleActions.uploadCompanyDocumentFailure(e.response?.data || { error: 'unknown' }));
    throw e.response?.data || { error: 'unknown' };
  }
};

export const downloadCompanyDocument = (companyDocument: CompanyDocument): RootThunk => async (
  dispatch,
): Promise<any> => {
  dispatch(simpleActions.downloadCompanyDocumentInit(companyDocument.id));

  try {
    const response = await api.downloadCompanyDocument(companyDocument);
    dispatch(simpleActions.downloadCompanyDocumentSuccess());
  } catch (e) {
    dispatch(
      simpleActions.downloadCompanyDocumentFailure(e.response?.data || { error: 'unknown' }),
    );
    throw e.response?.data || { error: 'unknown' };
  }
};
