// Core libraries
import React, { useEffect, useMemo, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

// Internal libs

// Internal components
import PageDefaultLayout from '@common/components/layouts/PageDefaultLayout';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '@features/contracts';
import LinearProgress from '@material-ui/core/LinearProgress';
import DownloadContractSection from '@features/contracts/containers/common/DownloadContractSection';
import { getUserCountry } from '@features/init/api';
import * as api from '@features/contracts/api';
import ContractPdfViewer from '@features/contracts/containers/common/ContractPdfViewer';
import Typography from '@material-ui/core/Typography';
import ConfirmContractSection from '@features/contracts/containers/common/ConfirmContractSection';
import Container from '@material-ui/core/Container';

// Component
const ContractView = () => {
  const { t } = useTranslation('contracts');
  const dispatch = useDispatch();
  const actionsBlock = <></>;
  const { contractTemplateId } = useParams() as { contractTemplateId: string };

  const [lastPageViewed, setLastPageViewed] = useState(false);
  const [contractData, setContractData] = useState<any>(null);

  useEffect(() => {
    async function getContract() {
      try {
        const response = await api.getContract(parseInt(contractTemplateId));
        if (response) {
          setContractData(response);
        }
      } catch (err) {}
    }
    getContract();
  }, [dispatch]);
  if (!contractData) {
    return (
      <PageDefaultLayout title={t(`contractView.loadingHeader`)} actions={actionsBlock}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <LinearProgress data-testid="contract-templates-loading" />
          </Grid>
        </Grid>
      </PageDefaultLayout>
    );
  }
  return (
    <PageDefaultLayout actions={actionsBlock}>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Typography variant="h5" gutterBottom component="div">
              {t('contractView.readContractText')}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <ContractPdfViewer contract={contractData} setLastPageViewed={setLastPageViewed} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <DownloadContractSection contract={contractData} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ConfirmContractSection contract={contractData} disabled={!lastPageViewed} />
          </Grid>
        </Grid>
      </Container>
    </PageDefaultLayout>
  );
};

export default ContractView;
