// Core libraries
import React, { SetStateAction, useMemo, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// Internal libs
import { actions } from '../../store/index';
import {
  CorrectionReasonEnum,
  CorrectionReasonType,
} from '@features/admin/drivers/model/CorrectionReason';
import { InProgressState } from '@common/model';

// Internal components
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import FormTextField from '@common/components/FormTextField';
import useAmountValidator from '@common/validators/amount';
import ProgressButton from '@common/components/ProgressButton';
import FormErrors from '@common/components/FormErrors';
import FormSelectField from '@common/components/FormSelectField';
import { Correction } from '@features/admin/corrections/model/Correction';
import { Invoice } from '@features/company/model/Invoice';
import { selectors as userSelectors } from '@features/user';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    field: {
      marginBottom: theme.spacing(1),
    },
    button: {
      padding: theme.spacing(2),
    },
  }),
);

// Props type
type InvoiceGenerateFormProps = {
  afterSuccessSubmit?: (editMode: SetStateAction<boolean>) => void;
  invoice?: Invoice;
};

// Component
const InvoiceGenerateForm = ({ afterSuccessSubmit, invoice }: InvoiceGenerateFormProps) => {
  const { t } = useTranslation(['invoices', 'company']);
  const styles = useStyles();
  const { path } = useRouteMatch();
  const userData = useSelector(userSelectors.selectUser);

  const history = useHistory();
  const dispatch = useDispatch();
  const { invoiceId }: { invoiceId: string } = useParams();
  const [submitState, setSubmitState] = useState<InProgressState>({ inProgress: false });
  const form = useForm();
  const { formErrors, getFieldErrors } = useResponseErrors(
    submitState?.error,
    'invoiceGenerateForm',
    'company',
  );

  const onSuccess = () => (afterSuccessSubmit ? afterSuccessSubmit(false) : history.goBack());
  const onSubmit = async (values: any) => {
    setSubmitState({ inProgress: true });
    try {
      await dispatch(actions.generateInvoice(+invoiceId, values.invoiceNo));
      setSubmitState({ inProgress: false, success: true });
      onSuccess();
      await dispatch(actions.getInvoices());
    } catch (e) {
      setSubmitState({ inProgress: false, success: false, error: e });
    }
  };
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid item xs={12} md={6} lg={4}>
            <FormTextField
              className={styles.field}
              name="invoiceNo"
              label={t('company:invoiceGenerateForm.fields.invoiceNo.label')}
              rules={{
                required: {
                  value: true,
                  message: t('company:invoiceGenerateForm.fields.invoiceNo.errors.required'),
                },
              }}
              fullWidth={true}
              defaultValue={invoice?.no}
              errors={getFieldErrors('invoiceNo', 'invoice_no')}
            />
          </Grid>
        </Grid>
        {!userData?.canGenerateInvoice && (
          <Grid>
            <p>{t('company:invoiceGenerateForm.cannotGenerateInvoiceText')}</p>
          </Grid>
        )}
        <Grid
          container
          className={styles.button}
          spacing={2}
          direction="row"
          alignItems="center"
          justify="flex-end"
        >
          <FormErrors errorMessages={formErrors} />
          <ProgressButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={submitState.inProgress || !userData?.canGenerateInvoice}
            loading={submitState.inProgress}
          >
            {t('company:invoiceGenerateForm.submitButtonGenerate')}
          </ProgressButton>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default InvoiceGenerateForm;
