import { actions as simpleActions } from '../slice';
import * as api from '../../../api';
import { RootThunk } from '@common/model';

/****** Place for complex actions *****/

// Driver profile Action
export const getDriverProfile = (driverId: number): RootThunk => async (dispatch) => {
  dispatch(simpleActions.getDriverProfileInit());

  try {
    const driver = await api.getDriverProfile(driverId);
    dispatch(simpleActions.getDriverProfileSuccess(driver));
    return driver;
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.getDriverProfileFailure(error));
    throw error;
  }
};

export const getXpressDeliveryCompanies = (): RootThunk => async (dispatch) => {
  dispatch(simpleActions.getXpressDeliveryCompaniesInit());

  try {
    const xpressDeliveryCompanies = await api.getXpressDeliveryCompanies();
    dispatch(simpleActions.getXpressDeliveryCompaniesSuccess(xpressDeliveryCompanies));
    return xpressDeliveryCompanies;
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.getXpressDeliveryCompaniesFailure(error));
    throw error;
  }
};

export const setDriverAllowedCompanies = (
  driverId: number,
  allowedCompanies: string[],
): RootThunk => async (dispatch) => {
  dispatch(simpleActions.setDriverAllowedCompaniesInit());

  try {
    await api.setDriverAllowedCompanies(driverId, allowedCompanies);
    dispatch(simpleActions.setDriverAllowedCompaniesSuccess());
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.setDriverAllowedCompaniesFailure(error));
    throw error;
  }
};

export const setAllowedCompanyForDrivers = (
  driverIds: string[],
  allowedCompanyId: number,
): RootThunk => async (dispatch) => {
  dispatch(simpleActions.setAllowedCompanyForDriversInit());

  try {
    await api.setAllowedCompanyForDrivers(driverIds, allowedCompanyId);
    dispatch(simpleActions.setAllowedCompanyForDriversSuccess());
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.setAllowedCompanyForDriversFailure(error));
    throw error;
  }
};

export const removeAllowedCompanyForDrivers = (
  driverIds: string[],
  allowedCompanyId: number,
): RootThunk => async (dispatch) => {
  dispatch(simpleActions.removeAllowedCompanyForDriversInit());

  try {
    await api.removeAllowedCompanyForDrivers(driverIds, allowedCompanyId);
    dispatch(simpleActions.removeAllowedCompanyForDriversSuccess());
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.removeAllowedCompanyForDriversFailure(error));
    throw error;
  }
};
