// Core libraries
import React, { useMemo, useState } from 'react';

// External libs and components
import { Path } from 'history';
import { useHistory, useLocation } from 'react-router';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      overflow: 'unset',
    },
    header: {
      boxShadow: theme.shadows[0],
    },
    badge: {
      transform: 'scale(1) translate(60%, -35%)',
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      fontSize: 10,
      height: 18,
      minWidth: 18,
    },
  }),
);

type DrawerItemProps = {
  icon?: React.ReactNode;
  text: string;
  tooltip?: string | false;
  redirectTo?: Path;
  onSelect?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
  badge?: number | string;
};

// Component
const DrawerItem = ({
  icon,
  text,
  tooltip,
  redirectTo,
  children,
  onSelect,
  disabled,
  badge,
}: DrawerItemProps) => {
  const styles = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  const [expanded, setExpanded] = useState(false);

  const onClick = () => {
    if (redirectTo) {
      history.push(redirectTo);
    } else if (children) {
      setExpanded(!expanded);
    }

    if (onSelect) {
      onSelect();
    }
  };

  const selected = useMemo(() => {
    if (!redirectTo) {
      return false;
    }
    const currentPath = pathname.split('/');
    const expectedPath = redirectTo.split('/');

    if (expectedPath.length > currentPath.length) {
      return false;
    }

    for (let i = 0; i < expectedPath.length; i++) {
      if (expectedPath[i] !== currentPath[i]) {
        return false;
      }
    }
    return true;
  }, [pathname, redirectTo]);

  return (
    <div className={styles.root}>
      <Tooltip arrow title={tooltip || text} placement="right">
        <ListItem
          button
          className={styles.header}
          component="span"
          onClick={onClick}
          disabled={disabled}
          selected={selected}
        >
          {!!icon && (
            <ListItemIcon>
              <Badge classes={{ badge: styles.badge }} badgeContent={badge} color="secondary">
                {icon}
              </Badge>
            </ListItemIcon>
          )}
          <ListItemText>{text}</ListItemText>
        </ListItem>
      </Tooltip>

      {(selected || expanded) && !!children && (
        <div>
          {children}
          <Divider />
        </div>
      )}
    </div>
  );
};

export default DrawerItem;
