import { correctionActions as simpleActions } from '../slice';
import * as api from '../../../api';
import { RootThunk } from '@common/model';
import {
  CorrectionApprovePayload,
  CorrectionRejectPayload,
} from '@features/admin/corrections/model/Correction';

/****** Place for complex actions *****/

// Corrections list Action
export const getCorrectionsList = (): RootThunk => async (dispatch) => {
  dispatch(simpleActions.getCorrectionsListInit());

  try {
    const corrections = await api.getCorrectionsList();
    dispatch(simpleActions.getCorrectionsListSuccess(corrections));
  } catch (e) {
    const error = e.response?.data || { error: 'unknown' };
    dispatch(simpleActions.getCorrectionsListFailure(error));
    throw error;
  }
};

export const approveCorrection = (
  correctionId: number,
  approveCorrection: CorrectionApprovePayload,
): RootThunk => async (dispatch) => {
  dispatch(simpleActions.CorrectionApproveInit({ correctionId }));
  try {
    await api.approveCorrection(correctionId, approveCorrection);
    dispatch(simpleActions.CorrectionApproveSuccess());
  } catch (e) {
    dispatch(
      simpleActions.CorrectionApproveFailure(e.response?.data || { errors: 'unknown' }),
    );
    throw e.response?.data || { error: 'unknown' };
  }
};

export const rejectCorrection = (
  correctionId: number,
  rejectionPayload: CorrectionRejectPayload
): RootThunk => async (dispatch) => {
  dispatch(simpleActions.CorrectionRejectInit({ correctionId }));
  try {
    await api.rejectCorrection(correctionId, rejectionPayload);
    dispatch(simpleActions.CorrectionRejectSuccess());
  } catch (e) {
    dispatch(
      simpleActions.CorrectionRejectFailure(e.response?.data || { errors: 'unknown' }),
    );
    throw e.response?.data || { error: 'unknown' };
  }
};

