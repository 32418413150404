// Core libraries
import React, { useEffect, useState } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { FormHelperText } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

// Internal libs
import { DocumentStatus, DocumentStatusEnum } from '@features/contracts/model/DocumentStatus';
import {
  DriverContract,
  DriverContractComparison,
} from '@features/admin/drivers/model/DriverContracts';
import { InProgressState } from '@common/model';

// Internal components
import FormTextField from '@common/components/FormTextField';
import FormSelectField from '@common/components/FormSelectField';
import DriverContractComparisonModal from '@features/admin/drivers/conteiners/DriverContractComparisonModal';
import { useDispatch } from 'react-redux';
import { actions } from '@features/admin';
import { IdentityDocument } from '@features/user/model/IdentityDocument';
import DriverContractIdentityDocumentsContainer from '@features/admin/drivers/conteiners/DriverContractIdentityDocumentsContainer';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    field: {
      marginBottom: theme.spacing(1),
    },
    chooseButton: {
      flexShrink: 0,
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
    fileName: {
      flexShrink: 1,
      flexGrow: 1,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      wordWrap: 'break-word',
      overflow: 'hidden',
    },
    error: {
      fontSize: 16,
      color: theme.palette.error.main,
    },
    success: {
      fontSize: 16,
      color: theme.palette.success.main,
    },
  }),
);

// Props type
type DriverContractInfoProps = {
  contract: DriverContract;
  getFieldErrors: (
    fieldName: string,
    backendFieldName?: string,
    translateFullPrefix?: string,
  ) => string[];
  disableUploadButton: boolean;
  identityDocuments?: IdentityDocument[];
};

// Component
const DriverContractInfo = ({
  contract,
  getFieldErrors,
  disableUploadButton,
  identityDocuments,
}: DriverContractInfoProps) => {
  const { t } = useTranslation('contracts');
  const styles = useStyles();

  const dispatch = useDispatch();

  const [getDetailsState, setGetDetailsState] = useState<InProgressState>({ inProgress: false });
  const [contractComparison, setContractComparison] = useState<
    DriverContractComparison | undefined
  >(undefined);

  const getContractComparison = async () => {
    if (!contract.contractId) {
      return;
    }
    setGetDetailsState({ inProgress: true });

    try {
      const contractComparison: DriverContractComparison = ((await dispatch(
        actions.getContractComparison(contract.contractId),
      )) as unknown) as DriverContractComparison;

      setContractComparison(contractComparison);
      setGetDetailsState({ inProgress: false, success: true });
    } catch (e) {
      setContractComparison(undefined);
      setGetDetailsState({ inProgress: false, success: false, error: e });
    }
  };

  useEffect(() => {
    // getContractComparison();
  }, [contract.contractId]);

  const form = useFormContext();
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [isContractComparisonOpened, setContractComparisonOpen] = useState(false);
  const [file, setFile] = useState<any>();
  const selectFile = (event: any) => {
    setFile(event.target?.files ? event.target.files[0] : undefined);
    setIsFileSelected(!!file);
  };

  const isAnyActionToChoose: { [key: string]: boolean } = {
    [DocumentStatusEnum.uploaded]: true,
    [DocumentStatusEnum.accepted]: true,
    [DocumentStatusEnum.rejected]: false,
    [DocumentStatusEnum.terminated]: false,
    [DocumentStatusEnum.pending]: false,
  };
  const actionSelected = form.watch('adminAction');
  const isReasonFieldVisible = (status: string | undefined, selectedAction: string): boolean =>
    !status
      ? false
      : (status === DocumentStatusEnum.uploaded &&
          selectedAction === DocumentStatusEnum.rejected) ||
        (status === DocumentStatusEnum.accepted &&
          selectedAction === DocumentStatusEnum.terminated);

  const fieldToSelect: {
    [key: string]: Array<{ label: string; value: DocumentStatus | string; disabled?: boolean }>;
  } = {
    [DocumentStatusEnum.uploaded]: [
      {
        label: t('availableContracts.contractAction.accepted'),
        value: DocumentStatusEnum.accepted,
      },
      {
        label: t('availableContracts.contractAction.rejected'),
        value: DocumentStatusEnum.rejected,
      },
    ],
    [DocumentStatusEnum.accepted]: [
      {
        label: t('availableContracts.contractAction.terminated'),
        value: DocumentStatusEnum.terminated,
      },
    ],
    [DocumentStatusEnum.terminated]: [
      {
        label: t('availableContracts.contractAction.null'),
        value: 'empty',
        disabled: true,
      },
    ],
    [DocumentStatusEnum.rejected]: [
      {
        label: t('availableContracts.contractAction.null'),
        value: 'empty',
        disabled: true,
      },
    ],
    [DocumentStatusEnum.pending]: [
      {
        label: t('availableContracts.contractAction.null'),
        value: 'empty',
        disabled: true,
      },
    ],
  };

  return (
    <>
      {/*<DriverContractComparisonModal
        contractComparison={contractComparison}
        onClose={() => setContractComparisonOpen(false)}
        isOpen={isContractComparisonOpened}
        inProgress={getDetailsState.inProgress}
        error={getDetailsState.error?.error}
      />*/}
      <Grid container spacing={2} className={styles.field}>
        {/*<Grid item xs={12} md={6}>
          <Button onClick={() => setContractComparisonOpen(true)}>
            {t('contractAction.showComparison')}
          </Button>
        </Grid>*/}
        <Grid item xs={12} md={6}>
          {getDetailsState.inProgress && (
            <p>
              <CircularProgress size={18} />
              {t('agreements.comparison.loading')}
            </p>
          )}
          {contractComparison?.difference && (
            <p className={styles.error}>
              {t('agreements.comparison.differenceExists', {
                percentage: contractComparison?.similarity,
              })}
            </p>
          )}
          {contractComparison?.similarity === 100 && (
            <p className={styles.success}>
              {t('agreements.comparison.contractsMatch', {
                percentage: contractComparison?.similarity,
              })}
            </p>
          )}
          <DriverContractIdentityDocumentsContainer identityDocuments={identityDocuments} />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={styles.field}>
        <Grid item xs={12} md={6}>
          <FormTextField
            name="contractId"
            disabled
            label={t('agreements.fields.contractId.label')}
            fullWidth
            defaultValue={
              contract.contractId
                ? contract.contractId.toString()
                : t('availableContracts.contractAction.null')
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            disabled
            name="status"
            label={t('availableContracts.status.title')}
            fullWidth
            defaultValue={
              !contract?.contractStatus
                ? t('availableContracts.actualStatus.missing.tooltip')
                : t(`availableContracts.actualStatus.${contract?.contractStatus}.tooltip`)
            }
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <FormSelectField
            className={styles.field}
            name="adminAction"
            label={t('agreements.fields.action.label')}
            options={
              contract.contractStatus
                ? fieldToSelect[contract.contractStatus]
                : [
                    {
                      label: t('availableContracts.contractAction.null'),
                      value: 'empty',
                      disabled: true,
                    },
                  ]
            }
            rules={{
              required: {
                value: true,
                message: t('agreements.fields.action.errors.required'),
              },
            }}
            errors={getFieldErrors('adminAction')}
            fullWidth
            defaultValue={
              contract.contractStatus && isAnyActionToChoose[contract.contractStatus] ? '' : 'empty'
            }
          />
        </Grid>
        <Grid container justify="flex-start" alignItems="center">
          {isReasonFieldVisible(contract.contractStatus, actionSelected) && (
            <FormTextField
              className={styles.field}
              name="reason"
              multiline={true}
              label={t('agreements.fields.reason.label')}
              fullWidth
              errors={getFieldErrors('reason')}
              rules={{
                required: {
                  value: true,
                  message: t('agreements.fields.reason.errors.required'),
                },
                minLength: {
                  value: 4,
                  message: t('agreements.fields.reason.errors.minLength'),
                },
              }}
            />
          )}
          {/*{actionSelected === DocumentStatusEnum.accepted && (
            <>
              <label className={styles.chooseButton} htmlFor="contained-button-file">
                <input
                  name="file"
                  ref={form.register}
                  className={styles.input}
                  id="contained-button-file"
                  onChange={selectFile}
                  type="file"
                />
                <Button
                  variant="contained"
                  color="default"
                  component="span"
                  className={styles.button}
                  disabled={disableUploadButton}
                  startIcon={<CloudUploadIcon />}
                >
                  {t('agreements.upload.chooseFileButton')}
                </Button>
              </label>
              <div className={styles.fileName}>
                {isFileSelected ? (
                  <FormHelperText error={true}>
                    <span>{t('agreements.upload.errors.required')}</span>
                  </FormHelperText>
                ) : (
                  file && file.name
                )}
              </div>
            </>
          )}*/}
        </Grid>
      </Grid>
    </>
  );
};

export default DriverContractInfo;
