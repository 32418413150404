// Core libraries
import React, { useEffect, useMemo, useState } from 'react';

// External libs and components
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

// Internal libs
import { PartnerDriverInfo } from '@features/partner/model';
import { numberToPhone } from '@common/validators/phoneValidator';
import { selectors as userSelectors } from '@features/user';
import { actions, selectors } from '../store';

// Internal components
import useMUIDataTableDefaultOptions from '@common/miscHooks/useMUIDataTableDefaultOptions';
import AccountMinus from '@common/components/icons/AccountMinus';
import RemoveDriverModal from './RemoveDriverModal';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    actionsWrapper: {
      flexGrow: 1,
      display: 'flex',
      marginLeft: theme.spacing(-0.5),
      marginRight: theme.spacing(-0.5),
      justifyContent: 'center',
    },
  }),
);

// Props type
type PartnerDriversProps = {};

// Component
const PartnerDriversCard = ({}: PartnerDriversProps) => {
  const { t } = useTranslation('partner');
  const dispatch = useDispatch();
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const [driverId, setDriverId] = useState<number>();
  const user = useSelector(userSelectors.selectUser);
  const getPartnerDriversState = useSelector(selectors.selectGetPartnerDriversState);
  const partnerDrivers = useSelector(selectors.selectPartnerDrivers);

  const mappedDrivers = useMemo(() => {
    return (
      partnerDrivers &&
      partnerDrivers.map((driver) => ({
        ...driver,
        fullName:
          driver.firstName && driver.lastName
            ? `${driver.firstName} ${driver.lastName}`
            : undefined,
        phone: driver.phone ? numberToPhone(driver.phone, driver.phonePrefix) : '-',
      }))
    );
  }, [partnerDrivers]);

  const handleClickOpen = (driverId: number) => {
    setDriverId(driverId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getDriverInfo = (driverId?: number): PartnerDriverInfo | undefined => {
    if (partnerDrivers && driverId) {
      return partnerDrivers.find((driver) => driver.id === driverId);
    }
  };

  // Fetch drivers
  const getPartnerDrivers = async () => {
    if (!user?.company?.id) {
      return;
    }

    try {
      await dispatch(actions.getPartnerDrivers(user.company.id));
    } catch (e) {}
  };

  // Init
  useEffect(() => {
    getPartnerDrivers();
  }, [user]);

  // Render actions column
  const renderActions = (driverId: number, userId?: number) => (
    <div className={styles.actionsWrapper}>
      <Tooltip title={`${t('DriverJoinRequest.actions.remove.button')}`}>
        <IconButton onClick={() => handleClickOpen(driverId)} disabled={driverId === userId}>
          <AccountMinus />
        </IconButton>
      </Tooltip>
    </div>
  );

  // Columns definition
  const columns: MUIDataTableColumnDef[] = useMemo(
    () => [
      {
        name: 'fullName',
        label: t('DriverJoinRequest.fields.fullName'),
        options: {},
      },
      {
        name: 'email',
        label: t('DriverJoinRequest.fields.email'),
        options: {},
      },
      {
        name: 'phone',
        label: t('DriverJoinRequest.fields.phone'),
      },
      {
        name: 'id',
        label: t('common:fields.actions'),
        options: {
          customBodyRender: (driverId) => renderActions(driverId, user?.id),
          searchable: false,
          filter: false,
          print: false,
          sort: false,
        },
      },
    ],
    [],
  );
  const options: MUIDataTableOptions = {
    ...useMUIDataTableDefaultOptions(true),
    download: true,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: true,
      },
    },
  };

  return (
    <Card>
      {getPartnerDriversState?.inProgress && (
        <LinearProgress data-testid="loading-partner-drivers" />
      )}
      <MUIDataTable
        title={t('driversList.title')}
        data={mappedDrivers || []}
        columns={columns}
        options={options}
      />
      <Divider />
      <RemoveDriverModal
        open={open}
        onClose={handleClose}
        driverInfo={getDriverInfo(driverId)}
        companyId={user?.company?.id}
      />
    </Card>
  );
};

export default PartnerDriversCard;
