import { useMemo } from 'react';

export const icoMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const icoValidator = (message?: string) => (value: string) =>
  checkPattern(value) || message || false;

const checkPattern = (ico: string): boolean => /^\d{8,9}$/.test(ico);

const useIcoValidator = (message?: string) => {
  return useMemo(() => icoValidator(message), [message]);
};
export default useIcoValidator;
