// Core libraries
import React, { useState } from 'react';

// External libs and components
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Internal libs
import { InProgressState } from '@common/model';
import { PartnerActionEnum } from '../../partner/model/PartnerAction';
import { useShowSnackbar } from '@features/snackbars';
import { actions } from '../store';

// Internal components
import ProgressIconButton from '@common/components/ProgressIconButton';
import RejectDriver from '@common/components/icons/AccountCancel';
import AcceptDriver from '@common/components/icons/AccountCheck';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    actionsWrapper: {
      flexGrow: 1,
      display: 'flex',
      marginLeft: theme.spacing(-0.5),
      marginRight: theme.spacing(-0.5),
      justifyContent: 'space-evenly',
    },
    rejectButton: {
      color: theme.palette.error.main,
    },
    addButton: {
      color: theme.palette.success.main,
    },
  }),
);

// Props type
type ProcessJoinRequestActionsProps = { partnerCompanyId?: number; requestId: number };

// Component
const ProcessJoinRequestActions = ({
  partnerCompanyId,
  requestId,
}: ProcessJoinRequestActionsProps) => {
  const styles = useStyles();
  const { t } = useTranslation('partner');
  const showSnackbar = useShowSnackbar();
  const dispatch = useDispatch();

  // Fetch Requests helper
  const getPendingDriverJoinRequests = async () => {
    if (!partnerCompanyId) {
      return;
    }

    try {
      await dispatch(actions.getPendingDriverJoinRequests(partnerCompanyId));
    } catch (e) {}
  };

  // Fetch drivers helper
  const getPartnerDrivers = async () => {
    if (!partnerCompanyId) {
      return;
    }

    try {
      await dispatch(actions.getPartnerDrivers(partnerCompanyId));
    } catch (e) {}
  };

  // Process (accept/reject) join request
  const [processJoinRequestStatus, setProcessJoinRequestStatus] = useState<
    InProgressState & {
      joinRequestId?: number;
      action?: PartnerActionEnum.accept | PartnerActionEnum.reject;
    }
  >({ inProgress: false });
  const processJoinRequest = async (
    joinRequestId: number,
    action: PartnerActionEnum.accept | PartnerActionEnum.reject,
  ) => {
    if (processJoinRequestStatus.inProgress) {
      return;
    }

    setProcessJoinRequestStatus({ inProgress: true, joinRequestId, action });

    try {
      switch (action) {
        case PartnerActionEnum.accept:
          await dispatch(actions.acceptDriverJoinRequest(joinRequestId));
          break;
        case PartnerActionEnum.reject:
          await dispatch(actions.rejectDriverJoinRequest(joinRequestId));
          break;
      }
      await getPendingDriverJoinRequests();
      setProcessJoinRequestStatus({ inProgress: false, success: true, joinRequestId, action });

      if (action === PartnerActionEnum.accept) {
        await getPartnerDrivers();
      }

      // Show success message
      showSnackbar({
        severity: action === PartnerActionEnum.accept ? 'success' : 'info',
        message: t(`DriverJoinRequest.actions.${action}.success`),
      });
    } catch (e) {
      setProcessJoinRequestStatus({
        inProgress: false,
        success: false,
        error: e,
        joinRequestId,
        action,
      });

      // Show failure message
      showSnackbar({
        severity: 'error',
        message: t(
          `DriverJoinRequest.actions.${action}.errors.${e.error}`,
          t(`DriverJoinRequest.actions.${action}.errors.unknown`),
        ),
      });
    }
  };
  return (
    <div className={styles.actionsWrapper}>
      <ProgressIconButton
        className={styles.rejectButton}
        onClick={() => processJoinRequest(requestId, PartnerActionEnum.reject)}
        disabled={processJoinRequestStatus.inProgress}
        loading={
          processJoinRequestStatus.inProgress &&
          processJoinRequestStatus.action !== PartnerActionEnum.accept
        }
        size={'small'}
        title={`${t('DriverJoinRequest.actions.reject.button')}`}
        children={<RejectDriver />}
      />
      <ProgressIconButton
        className={styles.addButton}
        onClick={() => processJoinRequest(requestId, PartnerActionEnum.accept)}
        disabled={processJoinRequestStatus.inProgress}
        loading={
          processJoinRequestStatus.inProgress &&
          processJoinRequestStatus.action === PartnerActionEnum.accept
        }
        size={'small'}
        title={`${t('DriverJoinRequest.actions.accept.button')}`}
        children={<AcceptDriver />}
      />
    </div>
  );
};

export default ProcessJoinRequestActions;
