// Core libraries
import React, { useEffect, useState } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// Internal libs

// Internal components
import FormTextField from '@common/components/FormTextField';
import ProgressButton from '@common/components/ProgressButton';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  }),
);

// Props type
export interface PartnerRidesFilters {
  dateFrom: Date;
  dateTo: Date;
}

type PartnerRidesFilterProps = { onSubmit?: (filters: PartnerRidesFilters) => void };

// Component
const PartnerRidesFilter = ({ onSubmit }: PartnerRidesFilterProps) => {
  const { t } = useTranslation('rides');
  const styles = useStyles();

  const form = useForm();

  const [dateFrom, setDateFrom] = useState<Date>(() => {
    const date = new Date();
    date.setDate(date.getDate() - 14);

    return date;
  });
  const [dateTo, setDateTo] = useState<Date>(new Date());

  const submit = (values: any) => {
    if (onSubmit) {
      const from = new Date(values.dateFrom);
      from.setHours(0, 0, 0, 0);
      const to = new Date(values.dateTo);
      to.setHours(23, 59, 59, 0);

      onSubmit({
        dateFrom: from,
        dateTo: to,
      });
    }
  };

  useEffect(() => {
    submit({ dateFrom, dateTo });
  }, [dateFrom, dateTo]);

  return (
    <Box padding={2}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(submit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <FormTextField
                name="dateFrom"
                label={t('ridesList.filter.dateFrom.label')}
                type="date"
                fullWidth
                defaultValue={dateFrom.toISOString().substr(0, 10)}
                InputLabelProps={{
                  shrink: true,
                }}
                rules={{
                  required: {
                    value: true,
                    message: t('ridesList.filter.dateFrom.errors.required'),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <FormTextField
                name="dateTo"
                label={t('ridesList.filter.dateTo.label')}
                type="date"
                fullWidth
                defaultValue={dateTo.toISOString().substr(0, 10)}
                InputLabelProps={{
                  shrink: true,
                }}
                rules={{
                  required: {
                    value: true,
                    message: t('ridesList.filter.dateTo.errors.required'),
                  },
                }}
              />
            </Grid>
            {/*</Grid>*/}
            {/*<Grid container spacing={2}>*/}
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <ProgressButton variant="contained" type="submit">
                {t('ridesList.filter.filterButton')}
              </ProgressButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};

export default PartnerRidesFilter;
