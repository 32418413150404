// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { Description, InsertDriveFile } from '@material-ui/icons';
import { DocumentStatusEnum } from '@features/contracts/model/DocumentStatus';
import { useTranslation } from 'react-i18next';
import Badge from '@material-ui/core/Badge';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

// Internal libs

// Internal components
import { DriverContract } from '@features/admin/drivers/model/DriverContracts';
import { IdentityDocument } from '@features/user/model/IdentityDocument';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    empty: {},
    null: {
      opacity: 0.2,
    },
    uploaded: {
      backgroundColor: theme.palette.info.dark,
    },
    pending: {
      backgroundColor: theme.palette.warning.light,
    },
    accepted: {
      backgroundColor: theme.palette.success.dark,
    },
    rejected: {
      backgroundColor: theme.palette.error.dark,
    },
    terminated: {
      backgroundColor: theme.palette.error.light,
    },
  }),
);

// Props type
type DriverExtraDocumentStatusProps = { extraDocuments: IdentityDocument[]; driverId: number };

// Component
const DriverExtraDocumentStatus = ({
  extraDocuments,
  driverId,
}: DriverExtraDocumentStatusProps) => {
  const { t } = useTranslation(['admin', 'common']);
  const styles = useStyles();
  const { path } = useRouteMatch();
  const history = useHistory();
  const getStyleForStatus = (status: DocumentStatusEnum | undefined) => {
    return !status
      ? styles.empty
      : [
          DocumentStatusEnum.accepted,
          DocumentStatusEnum.pending,
          DocumentStatusEnum.rejected,
          DocumentStatusEnum.terminated,
        ].indexOf(status) !== -1
      ? styles[status]
      : styles.uploaded;
  };
  const getValuesToDisplay = (identityDocument: IdentityDocument) => ({
    identityDocumentId: identityDocument.id,
    status: identityDocument.status || null,
    badge: getStyleForStatus(identityDocument.status as DocumentStatusEnum),
    icon: identityDocument.status ? '' : styles.null,
    tooltip: `${t(`common:identityDocument.types.${identityDocument.documentName}`)} - 
            ${t(`common:identityDocument.extraDocument.status.${identityDocument.status || null}`)} 
            ${
              identityDocument.status === DocumentStatusEnum.rejected
                ? `(${identityDocument.statusComment})`
                : ''
            }`,
  });

  const items = useMemo(
    () => (extraDocuments || []).map((extraDocument) => getValuesToDisplay(extraDocument)),
    [extraDocuments],
  );
  const onIconClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    history.push(`${path}/contract/${driverId}`);
  };

  return (
    <Grid container justify="space-evenly" alignItems="flex-start">
      {items.map((agreement, index) => (
        <Tooltip title={agreement.tooltip} key={index}>
          <IconButton onClick={onIconClick}>
            <Badge classes={{ badge: agreement.badge }} variant="dot">
              <Description className={agreement.icon} />
            </Badge>
          </IconButton>
        </Tooltip>
      ))}
    </Grid>
  );
};

export default DriverExtraDocumentStatus;
