// Core libraries
import React from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

// Internal libs
import { InProgressState } from '@common/model';
import { CommentSeverityEnum } from '@common/model/Comment';
import { useResponseErrors } from '@common/validators/backendResponseErrors';

// Internal components
import FormSelectField from '@common/components/FormSelectField';
import FormErrors from '@common/components/FormErrors';
import ProgressButton from '@common/components/ProgressButton';
import FormTextField from '@common/components/FormTextField';

// Props type
type CreateCommentFormProps = {
  onSubmit: (severity: CommentSeverityEnum, body: string) => void;
  onSubmitCallback?: VoidFunction;
  state: InProgressState;
};

// Component
const CreateCommentForm = ({ onSubmit, state, onSubmitCallback }: CreateCommentFormProps) => {
  const { t } = useTranslation('admin');
  const form = useForm();

  const { formErrors, getFieldErrors } = useResponseErrors(
    state?.error,
    'createComment.form',
    'admin',
  );

  const submitHandler = async (values: any) => {
    onSubmit(values.severity, values.body);
    form.reset();
  };

  const severities = [
    {
      value: CommentSeverityEnum.Positive,
      label: t(`createComment.comment.fields.severity.${CommentSeverityEnum.Positive}`),
    },
    {
      value: CommentSeverityEnum.Info,
      label: t(`createComment.comment.fields.severity.${CommentSeverityEnum.Info}`),
    },
    {
      value: CommentSeverityEnum.Negative,
      label: t(`createComment.comment.fields.severity.${CommentSeverityEnum.Negative}`),
    },
  ];

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)}>
        <DialogContent>
          <FormSelectField
            name="severity"
            label={t('createComment.form.fields.severity.label')}
            options={severities}
            rules={{
              required: {
                value: true,
                message: t('createComment.form.fields.severity.errors.required'),
              },
            }}
            fullWidth={true}
            errors={getFieldErrors('severity', 'severity')}
            defaultValue={CommentSeverityEnum.Info}
          />
          <FormTextField
            name="body"
            label={t('createComment.form.fields.body.label')}
            rules={{
              required: {
                value: true,
                message: t('createComment.form.fields.body.errors.required'),
              },
            }}
            fullWidth={true}
            errors={getFieldErrors('body')}
            defaultValue={''}
          />
          <FormErrors errorMessages={formErrors} />
        </DialogContent>
        <DialogActions>
          <ProgressButton
            type="submit"
            color="primary"
            disabled={state.inProgress}
            loading={state.inProgress}
            autoFocus
          >
            {t('createComment.form.submitButton')}
          </ProgressButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default CreateCommentForm;
