import axios from 'axios';
import {
  AllowedIdentityDocument,
  allowedIdentityDocumentFromSrc,
  AllowedIdentityDocumentSrc,
} from '@features/user/model/IdentityDocument';
import { saveAs } from 'file-saver';

export const getAllowedIdentityDocuments = async (): Promise<AllowedIdentityDocument[]> => {
  return axios.get('/dictionary/identity_document').then((response): AllowedIdentityDocument[] => {
    const identityDocumentsSrc: AllowedIdentityDocumentSrc[] = response.data;

    return identityDocumentsSrc.map((identityDocumentSrc) =>
      allowedIdentityDocumentFromSrc(identityDocumentSrc),
    );
  });
};

export const uploadIdentityDocument = async (
  file: Blob,
  name: string,
  onUploadProgress: (event: { loaded: number; total: number }) => void,
) => {
  let formData = new FormData();

  formData.append('document_file', file);
  formData.append('name', name);

  return axios.post(`/user/identity_document`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
  });
};

export const downloadIdentityDocument = async (url: string, fileName: string): Promise<any> => {
  // Download file
  const file: { data: Blob } = await axios.get(url, {
    responseType: 'blob',
  });

  // Save file
  saveAs(file.data, fileName);
};
