// Core libraries
import React, { useRef, useState } from 'react';

// External libs and components
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import PinInput from 'react-pin-input';

// Internal libs
import { InProgressState } from '@common/model';
import { useResponseErrors } from '@common/validators/backendResponseErrors';
import { useShowSnackbar } from '@features/snackbars';
import { actions } from '../../store';

// Internal components
import ProgressButton from '@common/components/ProgressButton';
import FormErrors from '@common/components/FormErrors';
import Grid from '@material-ui/core/Grid';
import FormSelectField from '@common/components/FormSelectField';
import FormTextField from '@common/components/FormTextField';
import FormAddressGrid from '@common/components/FormAddressGrid';
import FormCheckboxField from '@common/components/FormCheckboxField';
import PartnerDriversAbandonmentConfirmation from '../../../partner/sharedComponents/PartnerDriversAbandonmentConfirmation';
import { Contract } from '@features/contracts/model/Contract';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormPinCodeField from '@common/components/FormPinCodeField';
import Typography from '@material-ui/core/Typography';
import { RootState } from '@app/store';
import { User } from '@features/user/model/User';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(2),
    },
    nipWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    findButton: {
      marginLeft: theme.spacing(2),
    },
    noCodeWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    submitButton: {
      marginTop: 26,
      marginBottom: 26,
    },
  }),
);

// Props type
type ConfirmContractSectionProps = {
  contract: Contract;
  disabled: boolean;
};

// Component
const ConfirmContractSection = ({ contract, disabled }: ConfirmContractSectionProps) => {
  const { t } = useTranslation('contracts');
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const showSnackbar = useShowSnackbar();
  const bottomRef = useRef(null);

  const form = useForm();

  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  const contractConfirmed = useWatch({ control: form.control, name: 'confirmContract' });
  const pinCodeProvided = useWatch({ control: form.control, name: 'pinCode' });

  const [pinCodeRequested, setPinCodeRequested] = useState<boolean>(false);

  // Find partner
  const [submitState, setSubmitState] = useState<InProgressState>({ inProgress: false });
  const [requestPinCodeState, setRequestPinCodeState] = useState<InProgressState>({
    inProgress: false,
  });
  // @ts-ignore
  const scrollToBottom = () => bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });

  const requestPinCode = async () => {
    setRequestPinCodeState({ inProgress: true });

    try {
      await dispatch(actions.requestPinCode(contract.id));

      setRequestPinCodeState({ inProgress: false, success: true });
      showSnackbar({
        message: t('contractView.pinCode.responses.success'),
        severity: 'success',
      });
    } catch (e) {
      setRequestPinCodeState({ inProgress: false, success: false, error: e });
      showSnackbar({
        message: t(
          `contractView.pinCode.responses.errors.${e.error?.replace('.', '_')}`,
          t(`contractView.pinCode.responses.errors.unknown`, ''),
        ),
        severity: 'error',
      });
    } finally {
      setPinCodeRequested(true);
      scrollToBottom();
    }
  };

  const submit = async (values: any) => {
    setSubmitState({ inProgress: true });

    try {
      await dispatch(actions.signContract(contract.id, values.pinCode, values.confirmContract));

      setSubmitState({ inProgress: false, success: true });
      showSnackbar({
        message: t('contractView.signContract.success'),
        severity: 'success',
      });
      history.push('/contracts');
    } catch (e) {
      setSubmitState({ inProgress: false, success: false, error: e });
    }
  };

  const { formErrors, getFieldErrors } = useResponseErrors(
    submitState?.error,
    'contractView.signContract',
    'contracts',
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormCheckboxField
              data-testid="confirm-contract"
              className={styles.field}
              name="confirmContract"
              label={t('contractView.signContract.fields.confirm.label')}
              rules={{}}
              fullWidth
              errors={getFieldErrors('confirmContract', 'confirm_contract')}
              defaultValue={false}
              disabled={disabled}
              // @ts-ignore
              onConfirmed={scrollToBottom}
            />
          </Grid>
          {!pinCodeRequested ? (
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2">{t('contractView.pinCode.preBody')}</Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ProgressButton
                  variant="contained"
                  color="primary"
                  disabled={!contractConfirmed || requestPinCodeState?.inProgress}
                  loading={requestPinCodeState?.inProgress}
                  fullWidth
                  onClick={() => requestPinCode()}
                >
                  {t(`contractView.pinCode.requestPinCode`, '')}
                </ProgressButton>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">{t('contractView.pinCode.header')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {t('contractView.pinCode.body', { phoneNumber: userData?.phone ?? '-' })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormPinCodeField name={'pinCode'} />
              </Grid>
              <Grid item xs={12} className={styles.noCodeWrapper}>
                <Typography variant="body1">{t('contractView.pinCode.noCode')}</Typography>
                <ProgressButton
                  variant="text"
                  color="primary"
                  disabled={requestPinCodeState?.inProgress}
                  loading={requestPinCodeState?.inProgress}
                  onClick={() => requestPinCode()}
                >
                  {t(`contractView.pinCode.requestCodeAgain`, '')}
                </ProgressButton>
              </Grid>
              <Grid item xs={12}>
                <FormErrors errorMessages={formErrors} />
                <ProgressButton
                  className={styles.submitButton}
                  variant="contained"
                  color="primary"
                  disabled={
                    !pinCodeProvided || !contractConfirmed || disabled || submitState?.inProgress
                  }
                  loading={submitState?.inProgress}
                  type="submit"
                  fullWidth
                >
                  {t(`contractView.signContract.fields.confirm.label`, '')}
                </ProgressButton>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="caption" ref={bottomRef}>
              {t(`contractView.pinCode.requestHelpText`, '')}
            </Typography>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ConfirmContractSection;
