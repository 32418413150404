import { VehicleSrc, VehicleType } from '@features/vehicles/model/Vehicle';
import {
  DriverContract,
  driverContractFromSrc,
  DriverContractSrc,
  driverContractToSrc,
} from '@features/admin/drivers/model/DriverContracts';
import { AccountTypeEnum } from '@features/user/model/Settlements';
import {
  XpressDeliveryCompany,
  xpressDeliveryCompanyFromSrc,
  XpressDeliveryCompanySrc,
  xpressDeliveryCompanyToSrc,
} from '@common/model/XpressDeliveryCompany';
import { Country, countryFromSrc, CountrySrc, countryToSrc } from '@common/model/Country';
import { Comment, commentFromSrc, CommentSrc, commentToSrc } from '@common/model/Comment';
import {
  IdentityDocument,
  identityDocumentFromSrc,
  IdentityDocumentSrc,
  identityDocumentToSrc,
} from '@features/user/model/IdentityDocument';
import { DriverStatus, DriverStatusEnum } from '@features/user/model/User';

export interface DriverInfo {
  id: number;
  firstName?: string;
  lastName?: string;
  region?: string;
  email: string;
  emailVerifiedAt?: string;
  phonePrefix?: string;
  phone?: string;
  phoneVerifiedAt?: string;
  accountType?: AccountTypeEnum;
  createdAt: string;
  country?: Country;
  status?: DriverStatusEnum;
}

export interface Driver extends DriverInfo {
  vehicle: VehicleType;
  isPersonalDataCompleted: boolean;
  availableContracts: DriverContract[];
  companyName?: string;
  isPartner?: boolean;
  allowedCompanies?: XpressDeliveryCompany[];
  partnerRequestedAt?: string;
  countryCode?: string;
  lastComment?: Comment;
  identityDocuments: IdentityDocument[];
}

export interface AllowedCompany {
  id: number;
  name: string;
}

export interface ActiveVehicleSrc extends Partial<VehicleSrc> {
  vehicle_type: VehicleType;
}

export interface DriverInfoSrc {
  id: number;
  first_name: string | null;
  last_name: string | null;
  email: string;
  email_verified_at: string | null;
  phone_prefix: string | null;
  phone: string | null;
  phone_verified_at: string | null;
  account_type: AccountTypeEnum | null;
  region: string | null;
  created_at: string;
  country: CountrySrc | null;
  status: DriverStatusEnum | null;
}

export interface DriverSrc extends DriverInfoSrc {
  active_vehicle: ActiveVehicleSrc;
  is_personal_data_completed: boolean;
  available_contracts: DriverContractSrc[];
  company_name: string | null;
  is_partner: boolean | null;
  allowed_companies: XpressDeliveryCompanySrc[] | null;
  partner_requested_at: string | null;
  country_code: string | null;
  last_comment: CommentSrc | null;
  identity_documents: IdentityDocumentSrc[] | null;
}

export const driverInfoFromSrc = (driverInfoSrc: DriverInfoSrc): DriverInfo => ({
  id: driverInfoSrc.id,
  firstName: driverInfoSrc.first_name || undefined,
  lastName: driverInfoSrc.last_name || undefined,
  region: driverInfoSrc.region || undefined,
  email: driverInfoSrc.email,
  emailVerifiedAt: driverInfoSrc.email_verified_at || undefined,
  phone: driverInfoSrc.phone || undefined,
  phonePrefix: driverInfoSrc.phone_prefix || undefined,
  phoneVerifiedAt: driverInfoSrc.phone_verified_at || undefined,
  accountType: driverInfoSrc.account_type || undefined,
  createdAt: driverInfoSrc.created_at,
  country: driverInfoSrc.country ? countryFromSrc(driverInfoSrc.country) : undefined,
  status: driverInfoSrc.status ?? undefined,
});

export const driverInfoToSrc = (driverInfo: DriverInfo): DriverInfoSrc => ({
  id: driverInfo.id,
  first_name: driverInfo.firstName || null,
  last_name: driverInfo.lastName || null,
  region: driverInfo.region || null,
  email: driverInfo.email,
  email_verified_at: driverInfo.emailVerifiedAt || null,
  phone_prefix: driverInfo.phonePrefix || null,
  phone: driverInfo.phone || null,
  phone_verified_at: driverInfo.phoneVerifiedAt || null,
  account_type: driverInfo.accountType || null,
  created_at: driverInfo.createdAt,
  country: driverInfo.country ? countryToSrc(driverInfo.country) : null,
  status: driverInfo.status || null,
});

export const driverFromSrc = (driverSrc: DriverSrc): Driver => ({
  ...driverInfoFromSrc(driverSrc),
  isPersonalDataCompleted: driverSrc.is_personal_data_completed,
  vehicle: driverSrc.active_vehicle?.vehicle_type || undefined,
  availableContracts: driverSrc.available_contracts.map((contractSrc) =>
    driverContractFromSrc(contractSrc),
  ),
  isPartner: driverSrc.is_partner || undefined,
  companyName: driverSrc.company_name || undefined,
  allowedCompanies:
    (driverSrc.allowed_companies || []).map(xpressDeliveryCompanyFromSrc) || undefined,

  partnerRequestedAt: driverSrc.partner_requested_at || undefined,
  countryCode: driverSrc.country_code || undefined,
  lastComment: driverSrc.last_comment ? commentFromSrc(driverSrc.last_comment) : undefined,
  identityDocuments: (driverSrc.identity_documents ?? []).map(identityDocumentFromSrc),
});

export const driverToSrc = (driver: Driver): DriverSrc => ({
  ...driverInfoToSrc(driver),
  active_vehicle: {
    vehicle_type: driver.vehicle,
  },
  is_personal_data_completed: driver.isPersonalDataCompleted,
  available_contracts: driver.availableContracts.map((contractToSrc) =>
    driverContractToSrc(contractToSrc),
  ),
  is_partner: driver.isPartner || null,
  company_name: driver.companyName || null,
  allowed_companies: (driver.allowedCompanies || []).map(xpressDeliveryCompanyToSrc) || null,

  partner_requested_at: driver.partnerRequestedAt || null,
  country_code: driver.countryCode || null,
  last_comment: driver.lastComment ? commentToSrc(driver.lastComment) : null,
  identity_documents: driver.identityDocuments.map(identityDocumentToSrc),
});
