// Core libraries
import React from 'react';

// External libs and components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import config from '@app/config';
import Link from '@material-ui/core/Link';

// Internal libs

// Internal components

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    h: {
      marginTop: theme.spacing(2),
    },
    def: { display: 'table-row' },
    defLabel: {
      display: 'table-cell',
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    defContent: {
      display: 'table-cell',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    liWrapper: {
      display: 'table-row',
    },
    liNumber: {
      display: 'table-cell',
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    liContent: {
      display: 'table-cell',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  }),
);

// Props type
type PrivacyPolicyPageProps = {};

// Component
const PrivacyPolicyContent = ({}: PrivacyPolicyPageProps) => {
  const styles = useStyles();

  return (
    <>
      <Typography variant="h4" component="h2">
        POLITYKA PRYWATNOŚCI
      </Typography>

      <div>
        <Typography variant="h5" component="h3" className={styles.h}>
          1. DEFINICJE
        </Typography>
        <p className={styles.def}>
          <span className={styles.defLabel}>
            <strong>Administrator</strong>
          </span>
          <span className={styles.defContent}>
            oznacza podmiot, który samodzielnie lub wspólnie z innymi ustala cele i sposoby
            przetwarzania Danych Osobowych; tutaj: Xpress Delivery sp. z o.o. z siedzibą we
            Wrocławiu (50-502), ul. Hubska 52/14, wpisaną do rejestru przedsiębiorców Krajowego
            Rejestru Sądowego przez Sąd Rejonowy dla Wrocławia Fabrycznej we Wrocławiu, VI Wydział
            Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000742591, REGON: 380925640,
            NIP: 8992848469, kapitał zakładowy: 7 000,00 złotych (<strong>„xpress→delivery”</strong>
            ).
          </span>
        </p>
        <p className={styles.def}>
          <span className={styles.defLabel}>
            <strong>Dane Osobowe</strong>
          </span>
          <span className={styles.defContent}>
            oznacza informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej,
            przetwarzane przez Administratora, w tym w szczególności informacje dotyczące
            Użytkowników go.xpress.delivery.
          </span>
        </p>

        <p className={styles.def}>
          <span className={styles.defLabel}>
            <strong>go.xpress.delivery</strong>
          </span>
          <span className={styles.defContent}>
            oznacza serwis internetowy go.xpress.delivery, znajdujący się pod adresem
            https://go.xpress.delivery, za pośrednictwem którego świadczone są Usługi.
          </span>
        </p>

        <p className={styles.def}>
          <span className={styles.defLabel}>
            <strong>Polityka Prywatności</strong>
          </span>
          <span className={styles.defContent}>
            oznacza niniejszy dokument zawierający informacje dotyczące przetwarzania Danych
            Osobowych przez Administratora.
          </span>
        </p>

        <p className={styles.def}>
          <span className={styles.defLabel}>
            <strong>Przetwarzanie</strong>
          </span>
          <span className={styles.defContent}>
            oznacza operację lub zestaw operacji wykonywanych na Danych Osobowych lub zestawach
            Danych Osobowych w sposób zautomatyzowany i niezautomatyzowany, w szczególności taką jak
            zbieranie, utrwalanie, organizowanie, porządkowanie, przechowywanie, adaptowanie lub
            modyfikowanie, pobieranie, przeglądanie, wykorzystywanie, ujawnianie poprzez przesłanie,
            rozpowszechnianie lub innego rodzaju udostępnianie, dopasowywanie lub łączenie,
            ograniczanie, usuwanie, niszczenie.
          </span>
        </p>

        <p className={styles.def}>
          <span className={styles.defLabel}>
            <strong>RODO</strong>
          </span>
          <span className={styles.defContent}>
            oznacza Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
            2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych
            i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
            rozporządzenie o ochronie danych).
          </span>
        </p>

        <p className={styles.def}>
          <span className={styles.defLabel}>
            <strong>Usługi</strong>
          </span>
          <span className={styles.defContent}>
            oznacza usługi świadczone przez Administratora dostępne za pośrednictwem
            go.xpress.delivery dla Użytkownika, w szczególności usługę informowania o ofercie,
            produktach i usługach xpress →delivery.
          </span>
        </p>

        <p className={styles.def}>
          <span className={styles.defLabel}>
            <strong>Użytkownik</strong>
          </span>
          <span className={styles.defContent}>oznacza podmiot, który korzysta z Usług.</span>
        </p>

        <Typography variant="h5" component="h3" className={styles.h}>
          2. POSTANOWIENIA OGÓLNE
        </Typography>

        <p>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>2.1.</span>
            <span className={styles.liContent}>
              Niniejsza Polityka Prywatności zawiera informacje dotyczące Przetwarzania Danych
              Osobowych w związku z działalnością prowadzoną przez Administratora, e-mail:
              rodo@xpress.delivery.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>2.2.</span>
            <span className={styles.liContent}>
              Polityka Prywatności ma zastosowanie do Przetwarzania Danych Osobowych w związku z
              działalnością prowadzoną przez Administratora, w tym z prowadzeniem
              go.xpress.delivery.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>2.3.</span>
            <span className={styles.liContent}>
              Administrator, mając na celu zapewnienie odpowiedniego bezpieczeństwa i poufności
              osób, których Dane Osobowe Przetwarza, stosuje odpowiednie środki techniczne lub
              organizacyjne, w tym ochronę przed niedozwolonym lub niezgodnym z prawem
              Przetwarzaniem oraz przypadkową utratą, zniszczeniem lub uszkodzeniem, a także ochronę
              przed nieuprawnionym dostępem do Danych Osobowych lub do sprzętu służącego do ich
              Przetwarzania, oraz przed nieuprawnionym korzystaniem z tych Danych Osobowych lub z
              tego sprzętu.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>2.4.</span>
            <span className={styles.liContent}>
              Administrator stosuje wszelkie możliwe i dostępne środki, aby Dane Osobowe były:
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>a)</span>
                <span className={styles.liContent}>
                  przetwarzane zgodnie z prawem, rzetelnie i w sposób przejrzysty dla podmiotów,
                  których dotyczą;
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>b)</span>
                <span className={styles.liContent}>
                  zbierane w konkretnych, wyraźnych i prawnie uzasadnionych celach i nieprzetwarzane
                  dalej w sposób niezgodny z tymi celami;
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>c)</span>
                <span className={styles.liContent}>
                  adekwatne, stosowne oraz ograniczone do tego, co niezbędne do celów, do których są
                  Przetwarzane;
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>d)</span>
                <span className={styles.liContent}>
                  prawidłowe i w razie potrzeby uaktualniane;
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>e)</span>
                <span className={styles.liContent}>
                  przechowywane w formie umożliwiającej identyfikację osoby, której dotyczą, przez
                  okres nie dłuższy, niż jest to niezbędne do celów, dla których są Przetwarzane.
                </span>
              </span>
            </span>
          </span>
        </p>

        <Typography variant="h5" component="h3" className={styles.h}>
          3. ŹRÓDŁA DANYCH OSOBOWYCH
        </Typography>

        <p>
          Dane Osobowe Przetwarzane przez Administratora mogą być pozyskiwane:
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>a</span>
            <span className={styles.liContent}>
              bezpośrednio od osoby, której dotyczą Dane Osobowe;
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>b</span>
            <span className={styles.liContent}>
              z innych źródeł, w tym:
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>(i)</span>
                <span className={styles.liContent}>
                  od osób trzecich, na przykład pracodawców, partnerów handlowych lub organizacji
                  branżowych, w zależności od okoliczności i celu Przetwarzania;
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>(ii)</span>
                <span className={styles.liContent}>ze źródeł dostępnych publicznie.</span>
              </span>
            </span>
          </span>
        </p>

        <Typography variant="h5" component="h3" className={styles.h}>
          4. CELE PRZETWARZANIA DANYCH OSOBOWYCH
        </Typography>
        <p>
          <strong>Nawiązanie potencjalnej współpracy z Użytkownikiem</strong>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>4.1.</span>
            <span className={styles.liContent}>
              W celu nawiązania potencjalnej współpracy w ramach świadczonych przez Administratora
              usług polegającym na kontakcie z zainteresowanym Użytkownikiem za pośrednictwem poczty
              elektronicznej lub telefonicznie na dane podane przez Użytkownika, Administrator
              Przetwarza następujące Dane Osobowe:
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>a)</span>
                <span className={styles.liContent}>imię i nazwisko Użytkownika,</span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>b)</span>
                <span className={styles.liContent}>adres e-mail,</span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>c)</span>
                <span className={styles.liContent}>numer telefonu,</span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>d)</span>
                <span className={styles.liContent}>miejscowość.</span>
              </span>
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>4.2.</span>
            <span className={styles.liContent}>
              Podstawę zgodnego z prawem Przetwarzania przez Administratora Danych Osobowych
              Użytkownika, w celach związanych z nawiązaniem potencjalnej współpracy, stanowi:
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>a)</span>
                <span className={styles.liContent}>
                  zgoda Użytkownika (art. 6 ust. 1 lit. a) RODO);
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>b)</span>
                <span className={styles.liContent}>
                  uzasadniony interes Administratora (art. 6 ust. 1 lit. f) RODO), polegający na:
                  <span className={styles.liWrapper}>
                    <span className={styles.liNumber}>(i)</span>
                    <span className={styles.liContent}>
                      prowadzeniu serwisu go.xpress.delivery i świadczeniu Usług za jego
                      pośrednictwem;
                    </span>
                  </span>
                  <span className={styles.liWrapper}>
                    <span className={styles.liNumber}>(ii)</span>
                    <span className={styles.liContent}>
                      nawiązaniu ewentualnej współpracy w ramach usług świadczonych przez
                      Administratora z Użytkownikiem.
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>4.3.</span>
            <span className={styles.liContent}>
              Użytkownik wyraża zgodę na Przetwarzanie jego Danych Osobowych przez podanie
              stosownych Danych Osobowych w stosownym formularzu, dostępnym w serwisie
              go.xpress.delivery, zaznaczenie odpowiedniego przycisku przy oświadczeniu o wyrażeniu
              zgody na Przetwarzanie Danych Osobowych i naciśnięcie przycisku „AKCEPTUJĘ”.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>4.4.</span>
            <span className={styles.liContent}>
              Dane Osobowe osób, które wyraziły zgodę na Przetwarzanie Danych Osobowych zgodnie z
              procedurą opisaną w pkt 4.3 są umieszczanie w Bazie Potencjalnych Współpracowników
              xpress →delivery.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>4.5.</span>
            <span className={styles.liContent}>
              Użytkownik, którego dotyczą Dane Osobowe, ma prawo w dowolnym momencie wycofać swoją
              zgodę, przez wysłanie wiadomości email na adres rodo@xpress.delivery. Wycofanie zgody
              przez Użytkownika nie wpływa na zgodność z prawem Przetwarzania jego Danych Osobowych,
              którego Administrator dokonywał na podstawie zgody przed jej wycofaniem przez
              Użytkownika.
            </span>
          </span>

          <strong>Marketing i promocja produktów i usług Administratora</strong>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>4.6.</span>
            <span className={styles.liContent}>
              W celu marketingu i promocji produktów i usług Administratora, polegających na
              przesyłaniu do Użytkowników ww. informacji, drogą elektroniczną (na podany adres
              email) lub przez kontakt telefoniczny Administrator Przetwarza następujące Dane
              Osobowe:
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>e)</span>
                <span className={styles.liContent}>imię i nazwisko Użytkownika,</span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>f)</span>
                <span className={styles.liContent}>adres e-mail Użytkownika</span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>g)</span>
                <span className={styles.liContent}>numer telefonu Użytkownika.</span>
              </span>
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>4.7.</span>
            <span className={styles.liContent}>
              Podstawę zgodnego z prawem Przetwarzania przez Administratora Danych Osobowych
              Użytkownika, w celach związanych marketingiem lub promocją produktów i usług
              Administratora, stanowi:
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>a)</span>
                <span className={styles.liContent}>
                  zgoda Użytkownika (art. 6 ust. 1 lit. a) RODO);
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>b)</span>
                <span className={styles.liContent}>
                  uzasadniony interes Administratora (art. 6 ust. 1 lit. f) RODO), polegający na
                  marketingu bezpośrednim produktów lub usług Administratora lub jego podmiotów
                  powiązanych.
                </span>
              </span>
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>4.8.</span>
            <span className={styles.liContent}>
              Użytkownik wyraża zgodę na Przetwarzanie jego Danych Osobowych przez podanie
              stosownych Danych Osobowych w stosownym formularzu, dostępnym w serwisie
              go.xpress.delivery, zaznaczenie odpowiedniego pola (checkbox) przy oświadczeniu o
              wyrażeniu zgody na Przetwarzanie Danych Osobowych i naciśnięcie przycisku „Akceptuję”.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>4.9.</span>
            <span className={styles.liContent}>
              Dane Osobowe osób, które wyraziły zgodę na Przetwarzanie Danych Osobowych zgodnie z
              procedurą opisaną w pkt 4.8 są umieszczanie w Bazie Odbiorców Newslettera xpress →
              delivery.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>4.10.</span>
            <span className={styles.liContent}>
              Użytkownik, którego dotyczą Dane Osobowe, ma prawo w dowolnym momencie wycofać swoją
              zgodę, przez wysłanie maila na adres rodo@xpress.delivery. Wycofanie zgody przez
              Użytkownika nie wpływa na zgodność z prawem Przetwarzania jego Danych Osobowych,
              którego Administrator dokonywał na podstawie zgody przed jej wycofaniem przez
              Użytkownika.
            </span>
          </span>
        </p>

        <Typography variant="h5" component="h3" className={styles.h}>
          5. ODBIORCY DANYCH OSOBOWYCH
        </Typography>
        <p>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>5.1.</span>
            <span className={styles.liContent}>
              Administrator może przechowywać Dane Osobowe na serwerach należących do podmiotów
              innych niż Administrator, o ile zapewnione jest odpowiednie bezpieczeństwo
              przechowywanych tam Danych Osobowych.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>5.2.</span>
            <span className={styles.liContent}>
              W toku Przetwarzania Danych Osobowych przez Administratora, Dane Osobowe mogą zostać
              przekazane do państwa trzeciego, z tym zastrzeżeniem, że jeżeli przepisy prawa w danym
              państwie trzecim nie przewidują adekwatnego poziomu ochrony Danych Osobowych,
              Administrator zobowiąże podmiot przetwarzający Dane Osobowe w imieniu Administratora w
              państwie trzecim do przestrzegania regulacji określonych w Polityce Prywatności oraz w
              przepisach prawa, w oparciu o które Polityka Prywatności została stworzona, w
              szczególności RODO.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>5.3.</span>
            <span className={styles.liContent}>
              Administrator może udostępniać Dane Osobowe następującym kategoriom odbiorców (w
              zależności od konkretnego celu Przetwarzania Danych Osobowych):
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>a)</span>
                <span className={styles.liContent}>podmiotom powiązanym z Administratorem,</span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>b)</span>
                <span className={styles.liContent}>
                  upoważnionym pracownikom lub zleceniobiorcom Administratora,
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>c)</span>
                <span className={styles.liContent}>
                  partnerom lub podmiotom świadczącym wybrane usługi na rzecz Administratora (w tym
                  usługi prawne, marketingowe, reklamowe, informatyczne w tym usługi przetwarzania w
                  chmurze — w takim zakresie, w jakim jest to niezbędne do świadczenia tych usług),
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>d)</span>
                <span className={styles.liContent}>
                  uprawnionym organom lub służbom, w przypadku wystąpienia przez nie ze stosownym,
                  uzasadnionym żądaniem, lub gdy na Administratorze ciąży taki obowiązek prawny.
                </span>
              </span>
            </span>
          </span>
        </p>

        <Typography variant="h5" component="h3" className={styles.h}>
          6.
        </Typography>
        <p>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>6.1.</span>
            <span className={styles.liContent}>
              Dane Osobowe przekazane Administratorowi są przechowywane przez okres wymagany do
              celów, w jakich zostały one zebrane lub wynikający z przepisów prawa.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>6.2.</span>
            <span className={styles.liContent}>
              Administrator przetwarza Dane Osobowe (w zależności od konkretnego celu Przetwarzania
              Danych Osobowych) przez okres:
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>a)</span>
                <span className={styles.liContent}>
                  w celu przesyłania informacji dotyczącej potencjalnej współpracy na podany adres
                  email oraz przekazywania jej przez kontakt telefoniczny – nie dłużej niż do czasu
                  cofnięcia przez osobę, której dotyczą, stosownej zgody,
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>b)</span>
                <span className={styles.liContent}>
                  w celu przesyłania informacji handlowej na podany adres email oraz przekazywania
                  jej przez kontakt telefoniczny – nie dłużej niż do czasu cofnięcia przez osobę,
                  której dane dotyczą, stosownej zgody,
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>c)</span>
                <span className={styles.liContent}>w celu archiwizacji dokumentów – 50 lat,</span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>d)</span>
                <span className={styles.liContent}>
                  w celach statystycznych, analitycznych i raportowych – 50 lat.
                </span>
              </span>
            </span>
          </span>
        </p>

        <Typography variant="h5" component="h3" className={styles.h}>
          7. ZABEZPIECZENIE DANYCH OSOBOWYCH
        </Typography>
        <p>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>7.1.</span>
            <span className={styles.liContent}>
              W celu niedopuszczenia do nieupoważnionego lub bezprawnego dostępu do Danych
              Osobowych, ich przypadkowej utraty, uszkodzenia lub zniszczenia, Administrator
              korzysta z odpowiednich rozwiązań technologicznych i środków bezpieczeństwa. W
              przypadku go.xpress.delivery do ochrony Danych Osobowych i innych danych
              niestanowiących Dane Osobowe stosowana jest technologia TLS (Transport Layer Socket) w
              wersji 1.2 przeznaczona do ochrony transmisji danych przez Internet oraz zapory
              sieciowe (tzw. firewalls).
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>7.2.</span>
            <span className={styles.liContent}>
              Dostęp do Danych Osobowych mają Administrator i osoby upoważnione przez
              Administratora, które zobowiązały się do zachowania poufności Danych Osobowych a także
              podmioty wskazane w punkcie 5.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>7.3.</span>
            <span className={styles.liContent}>
              Administrator prowadzi ewidencję osób upoważnionych do Przetwarzania Danych Osobowych.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>7.4.</span>
            <span className={styles.liContent}>
              W przypadku naruszenia ochrony Danych Osobowych, Administrator bez zbędnej zwłoki, a w
              miarę możliwości nie później niż w ciągu 72 (siedemdziesięciu dwóch) godzin po
              stwierdzeniu naruszenia, zgłasza naruszenie właściwemu organowi nadzorczemu. W
              sytuacji, gdy naruszenie Danych Osobowych osoby, której dotyczą Dane Osobowe, może
              powodować wysokie ryzyko naruszenia jej praw i wolności, Administrator bez zbędnej
              zwłoki zawiadamia tę osobę o takim naruszeniu.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>7.5.</span>
            <span className={styles.liContent}>
              Administrator dokumentuje wszelkie naruszenia ochrony Danych Osobowych, w tym
              okoliczności naruszenia, jego skutki oraz podjęte działania zaradcze.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>7.6.</span>
            <span className={styles.liContent}>
              Administrator prowadzi rejestr czynności Przetwarzania Danych Osobowych.
            </span>
          </span>
        </p>
        <Typography variant="h5" component="h3" className={styles.h}>
          8. PRAWA OSÓB, KTÓRYCH DOTYCZĄ DANE OSOBOWE
        </Typography>
        <p>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>8.1.</span>
            <span className={styles.liContent}>
              Osobie, której Dane Osobowe są Przetwarzane przez Administratora, przysługuje prawo:
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>a)</span>
                <span className={styles.liContent}>
                  dostępu do treści jej Danych Osobowych (art. 15 RODO) przez żądanie udostępnienia
                  lub przesłania kopii kompletu jej Danych Osobowych Przetwarzanych przez
                  Administratora,
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>b)</span>
                <span className={styles.liContent}>
                  żądania sprostowania jej Danych Osobowych (art. 16 RODO) przez wskazanie
                  nieprawidłowych Danych Osobowych, które jej dotyczą,
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>c)</span>
                <span className={styles.liContent}>
                  żądania usunięcia jej Danych Osobowych (art. 17 RODO) z zastrzeżeniem, że
                  Administrator ma prawo odmówić usunięcia Danych Osobowych w wypadkach określonych
                  przez prawo,
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>d)</span>
                <span className={styles.liContent}>
                  żądania ograniczenia Przetwarzania jej Danych Osobowych (art. 18 RODO) przez
                  wskazanie, które Dane Osobowe powinny zostać ograniczone w zakresie Przetwarzania,
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>e)</span>
                <span className={styles.liContent}>
                  do przenoszenia jej Danych Osobowych (art. 20 RODO) przez żądanie przygotowania i
                  przekazania przez Administratora Danych Osobowych przekazanych przez nią do niej
                  lub do innego administratora, w ustrukturyzowanym, powszechnie używanym formacie
                  nadającym się do odczytu maszynowego z zastrzeżeniem, że przenoszeniu mogą
                  podlegać jedynie Dane Osobowe uzyskane na podstawie zgody,
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>f)</span>
                <span className={styles.liContent}>
                  wniesienia sprzeciwu wobec Przetwarzania jej Danych Osobowych, opartego na art. 6
                  ust. 1 lit. e) lub f) RODO, z przyczyn związanych z jej szczególną sytuacją,
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>g)</span>
                <span className={styles.liContent}>
                  wniesienia skargi do organu nadzorczego, w szczególności w państwie członkowskim
                  jej zwykłego pobytu, miejsca pracy lub miejsca popełnienia domniemanego
                  naruszenia, w przypadku uznania, że Przetwarzanie jej Danych Osobowych narusza
                  przepisy RODO (art. 77 ust. 1 RODO).
                </span>
              </span>
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>8.2.</span>
            <span className={styles.liContent}>
              W przypadku chęci skorzystania z któregokolwiek z wskazanych wyżej praw, osoba, której
              dotyczą Dane Osobowe, powinna skontaktować się z Administratorem (sposoby kontaktu
              zostały wskazane w punkcie 2.1 niniejszej Polityki Prywatności).
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>8.3.</span>
            <span className={styles.liContent}>
              W sytuacji, gdy Przetwarzanie Danych Osobowych oparte jest na zgodzie wyrażonej przez
              osobę, której dotyczą Dane Osobowe, osoba ta ma prawo wycofać zgodę na Przetwarzanie
              jej Danych Osobowych w określonym celu, w każdej chwili, kontaktując się z
              Administratorem (sposoby kontaktu zostały wskazane w punkcie 2.1 niniejszej Polityki
              Prywatności).
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>8.4.</span>
            <span className={styles.liContent}>
              Cofnięcie zgody na Przetwarzanie Danych Osobowych nie ma wpływu na zgodność z prawem
              Przetwarzania, którego dokonano na podstawie wyrażonej zgody przed jej wycofaniem.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>8.5.</span>
            <span className={styles.liContent}>
              Cofnięcie zgody jest możliwe wyłącznie w zakresie Przetwarzania Danych Osobowych
              dokonywanego na podstawie zgody.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>8.6.</span>
            <span className={styles.liContent}>
              Administrator podejmuje działania mające na celu ułatwienie osobie, której dotyczą
              Dane Osobowe, wykonywania prawa dostępu do Danych Osobowych. Administrator jest
              zwolniony z tego obowiązku jedynie w sytuacji, w której nie jest w stanie
              zidentyfikować osoby, której dotyczą Dane Osobowe.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>8.7.</span>
            <span className={styles.liContent}>
              Administrator, w terminie miesiąca od dnia otrzymania żądania, udziela osobie, której
              dotyczą Dane Osobowe, informacji o działaniach podjętych w związku z jej żądaniem,
              dotyczącym prawa dostępu do Danych Osobowych, prawa sprostowania i usunięcia Danych
              Osobowych, prawa do ograniczenia Przetwarzania, obowiązku powiadomienia o sprostowaniu
              lub usunięciu Danych Osobowych oraz ograniczenia Przetwarzania, prawa do przenoszenia
              Danych Osobowych, prawa sprzeciwu oraz zautomatyzowanego podejmowania decyzji
              (profilowania). Z uwagi na skomplikowany charakter żądania lub liczbę żądań, termin, o
              którym mowa w zdaniu poprzednim, może zostać przedłużony o dodatkowe dwa miesiące. W
              przypadku przedłużenia terminu, Administrator, w terminie miesiąca od otrzymania
              żądania, poinformuje osobę, której dotyczą Dane Osobowe, o takim przedłużeniu, wraz z
              podaniem jego przyczyn.
            </span>
          </span>
        </p>
        <Typography variant="h5" component="h3" className={styles.h}>
          9. PLIKI COOKIES
        </Typography>
        <p>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>9.1.</span>
            <span className={styles.liContent}>
              Korzystanie z Usług oferowanych za pośrednictwem serwisu go.xpress.delivery może
              wiązać się z instalowaniem plików cookies na urządzeniu Użytkownika. Pliki cookies to
              pliki tekstowe, które są zapisywane na dysku urządzenia końcowego Użytkownika. Pliki
              cookies mogą zawierać ustawienia serwisu go.xpress.delivery lub być używane do
              śledzenia interakcji Użytkowników z serwisem go.xpress.delivery. Administrator
              wykorzystuje pliki cookies w celu:
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>a)</span>
                <span className={styles.liContent}>
                  rozpoznawania urządzenia Użytkownika dla celów statystycznych;
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>b)</span>
                <span className={styles.liContent}>
                  analizowania sposobu korzystania przez Użytkownika z serwisu go.xpress.delivery]
                  dla celów optymalizacji serwisu go.xpress.delivery.
                </span>
              </span>
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>9.2.</span>
            <span className={styles.liContent}>
              Pliki cookies instalowane są automatycznie na urządzeniu Użytkownika, o ile Użytkownik
              nie skorzysta z opcji wyłączenia pobierania i przechowywania plików cookies w używanej
              przez siebie przeglądarce internetowej. Wyłączenie plików cookies może doprowadzić do
              utrudnienia lub całkowitej utraty możliwości korzystania z niektórych Usług.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>9.3.</span>
            <span className={styles.liContent}>
              Użytkownik może w każdym czasie dokonać zmiany ustawień dotyczących plików cookies. W
              tym celu należy dokonać zmiany ustawień przeglądarki internetowej dotyczących plików
              cookies. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby
              blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej
              bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika. Szczegółowe
              informacje o możliwości i sposobach dokonania zmiany w ustawieniach dotyczących plików
              cookies w najpopularniejszych przeglądarkach internetowych można uzyskać pod adresami:
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>a)</span>
                <span className={styles.liContent}>
                  Google Chrome
                  <br />
                  <Link
                    href="https://support.google.com/chrome/answer/95647?hl=pl;"
                    target="_blank"
                  >
                    https://support.google.com/chrome/answer/95647?hl=pl;
                  </Link>
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>b)</span>
                <span className={styles.liContent}>
                  Firefox
                  <br />
                  <Link
                    href="https://support.mozilla.org/pl/kb/ciasteczka?esab=a&s=ciasteczka&r=0&as=s;"
                    target="_blank"
                  >
                    https://support.mozilla.org/pl/kb/ciasteczka?esab=a&s=ciasteczka&r=0&as=s;
                  </Link>
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>c)</span>
                <span className={styles.liContent}>
                  Internet Explorer
                  <br />
                  <Link
                    href="https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-delete-manage-cookies;"
                    target="_blank"
                  >
                    https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-delete-manage-cookies;
                  </Link>
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>d)</span>
                <span className={styles.liContent}>
                  Microsoft Edge
                  <br />
                  <Link
                    href="https://support.microsoft.com/pl-pl/help/4027947/microsoft-edge-delete-cookies"
                    target="_blank"
                  >
                    https://support.microsoft.com/pl-pl/help/4027947/microsoft-edge-delete-cookies
                  </Link>
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>e)</span>
                <span className={styles.liContent}>
                  Opera <br />
                  <Link href="http://help.opera.com/Windows/12.10/pl/cookies.html" target="_blank">
                    http://help.opera.com/Windows/12.10/pl/cookies.html
                  </Link>
                </span>
              </span>
              <span className={styles.liWrapper}>
                <span className={styles.liNumber}>f)</span>
                <span className={styles.liContent}>
                  Safari <br />
                  <Link
                    href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac."
                    target="_blank"
                  >
                    https://support.apple.com/pl-pl/guide/safari/sfri11471/mac.
                  </Link>
                </span>
              </span>
            </span>
          </span>
        </p>

        <Typography variant="h5" component="h3" className={styles.h}>
          10. POSTANOWIENIA KOŃCOWE
        </Typography>
        <p>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>10.1.</span>
            <span className={styles.liContent}>
              Administrator zastrzega sobie prawo do zmiany Polityki Prywatności, o czym osoby,
              których dotyczą Dane Osobowe, zostaną poinformowane przez informację zamieszczoną w
              serwisie go.xpress.delivery, przynajmniej na 14 (czternaście) dni przed wejściem w
              życie planowanej zmiany.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>10.2.</span>
            <span className={styles.liContent}>
              Administrator nie ponosi odpowiedzialności za linki umieszczone na stronach
              internetowych Administratora oraz poprzez go.xpress.delivery, które kierują i
              umożliwiają Użytkownikom bezpośrednie przejście do stron internetowych znajdujących
              się poza go.xpress.delivery i stronami internetowymi Administratora. W związku z
              powyższym, osoba, której dotyczą Dane Osobowe, powinna zapoznać się z treścią
              postanowień dotyczących prywatności, umieszczonych na stronach internetowych, do
              których prowadzą odwołania.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>10.3.</span>
            <span className={styles.liContent}>
              Usługi są kierowane do osób, powyżej 16 roku życia. W przypadku, gdy poprzez fałszywe
              oświadczenie złożone przez Użytkownika co do jego wieku, dojdzie do pozyskania Danych
              Osobowych, bez zgody rodzica lub opiekuna prawnego, od osoby niespełniającej kryterium
              wieku określonego w zdaniu poprzednim, Administrator zobowiązuje się usunąć takie Dane
              Osobowe, niezwłocznie po otrzymaniu informacji o pozyskaniu bez zgody rodzica lub
              opiekuna prawnego Danych Osobowych osoby poniżej 16 roku życia.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>10.4.</span>
            <span className={styles.liContent}>
              Wszelkie pytania lub wątpliwości, dotyczące Polityki Prywatności, powinny być
              kierowane do Administratora poprzez e-mail: rodo@xpress.delivery.
            </span>
          </span>
          <span className={styles.liWrapper}>
            <span className={styles.liNumber}>10.5.</span>
            <span className={styles.liContent}>
              Polityka Prywatności wchodzi w życie z dniem 1 stycznia 2021r..
            </span>
          </span>
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicyContent;
