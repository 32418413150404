import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.css';
import AppRouting from '@app/Routing';
import { lightTheme as theme } from '@theme';
// import { darkTheme as theme } from '@theme';
import { ThemeProvider } from '@material-ui/core/styles';
import { SplashScreen } from '@features/init';
import StoreRedirect from '@features/redirect/components/StoreRedirect';
// import { CookiesPolicy } from '@features/privacyPolicy';
import SnackbarsProvider from '@features/snackbars/components/SnackbarsProvider';
// import { IS_AUTHENTICATED_ROLE } from '@features/user/model/Role';
import { RolesProvider } from '@features/user';
// import GdprModal from '@features/user/containers/GdprModal';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SplashScreen useSuspense>
        <SnackbarsProvider>
          <RolesProvider map={{ SUPER_ADMIN: ['ADMIN'] }}>
            <Router>
              <StoreRedirect />
              <AppRouting />
              {/*<CookiesPolicy />*/}
              {/*<GdprModal />*/}
            </Router>
          </RolesProvider>
        </SnackbarsProvider>
      </SplashScreen>
    </ThemeProvider>
  );
}

export default App;
