import { PayloadAction } from '@reduxjs/toolkit';
import { PaginatedData } from '@common/model';
import { Ride } from '../../model/Ride';
import { RidesState } from '../model';
import { FailureResponse } from '@common/model';
import { RideDetails } from '@features/rides/model/RideDetails';

export const getRidesInit = (
  state: RidesState,
  action: PayloadAction<{ page?: number; showTotalItems?: number }>,
) => {};
export const getRidesSuccess = (
  state: RidesState,
  action: PayloadAction<PaginatedData<Ride>>,
) => {};
export const getRidesFailure = (state: RidesState, action: PayloadAction<FailureResponse>) => {};

export const getRideDetailsInit = (state: RidesState, action: PayloadAction<number>) => {};
export const getRideDetailsSuccess = (state: RidesState, action: PayloadAction<RideDetails>) => {};
export const getRideDetailsFailure = (
  state: RidesState,
  action: PayloadAction<FailureResponse>,
) => {};
