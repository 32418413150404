// Core libraries
import React, { useMemo } from 'react';

// External libs and components
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import UploadFile from '@common/components/UploadFile';

// Internal libs
import { RootState } from '@app/store';
import { User } from '../../model/User';
import { CompanyDocumentTypeEnum } from '../../model/CompanyDocumentType';
import { actions } from '../../store';

// Internal components
import FormSelectField from '@common/components/FormSelectField';
import { mergeResponseErrors } from '@common/tools/mergeResponseErrors';

// Styles hook
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    field: {
      marginBottom: theme.spacing(2),
    },
  }),
);

// Props type
type CompanyDocumentUploadModalProps = {};

// Component
const CompanyDocumentUploadModal = ({}: CompanyDocumentUploadModalProps) => {
  const { t } = useTranslation('user');
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const form = useForm();

  const userData = useSelector<RootState, User | undefined>((state) => state.user.user);

  const close = () => {
    history.push('.');
  };

  const upload = async (
    file: File,
    uploadProgress: (event: { loaded: number; total: number }) => void,
  ) => {
    if (!userData?.company) {
      return;
    }

    try {
      await dispatch(
        actions.uploadCompanyDocument(
          userData.company.id,
          form.getValues('documentType'),
          file,
          uploadProgress,
        ),
      );
      close();
    } catch (e) {
      const errorMessages = mergeResponseErrors(e)
        .map((error: string) => t(`settlements.uploadCompanyDocument.errors.${error}`, ''))
        .filter((msg: string) => !!msg);

      throw errorMessages.length
        ? errorMessages
        : [t('settlements.uploadCompanyDocument.errors.unknown')];
    }
  };

  const options = useMemo(
    () => [
      { label: t('company.fields.documentType.krs'), value: CompanyDocumentTypeEnum.krs },
      { label: t('company.fields.documentType.ceidg'), value: CompanyDocumentTypeEnum.ceidg },
    ],
    [],
  );

  return (
    <Dialog open={true} onClose={close} fullWidth>
      <DialogTitle>{t('settlements.uploadCompanyDocument.title')}</DialogTitle>
      <Divider />

      <DialogContent>
        <DialogContentText>{t('settlements.uploadCompanyDocument.message')}</DialogContentText>

        <FormProvider {...form}>
          <FormSelectField
            label={t('company.fields.documentType.label')}
            name="documentType"
            options={options}
            fullWidth
            defaultValue={options[0].value}
            className={styles.field}
          />
          <UploadFile onUpload={upload} />
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default CompanyDocumentUploadModal;
